import { ExperimentalForm } from '@torqit/torq-tools-react';
import { useApi } from 'App';
import { DateServiceSingleton } from 'App/utils/DateService';
import { useState } from 'react';
import { SentryApiClient } from '_generated/api';

export type FormEventLog = Omit<SentryApiClient.EventLogDTO, 'timestamp'> & {
  timestamp: moment.Moment;
};

export function useCreateEventLog(
  timezone: string | undefined,
  onCreate?: (log: SentryApiClient.EventLogDTO) => void,
  onError?: (error: Error) => void
) {
  const { eventLogApi } = useApi();
  const [isCreating, setCreating] = useState(false);

  const [form] = ExperimentalForm.useForm<FormEventLog>();

  const createEventLog = async (eventLog: FormEventLog) => {
    const timestamp = eventLog.timestamp.toDate();

    const actualLog: SentryApiClient.EventLogDTO = {
      ...eventLog,
      timestamp: timezone
        ? DateServiceSingleton.ConvertToLocalTime(timestamp, timezone)
        : timestamp,
    };

    setCreating(true);
    let resultingEventLog;

    try {
      resultingEventLog = await eventLogApi.post(actualLog);
    } catch (error) {
      onError && onError(error);
    }

    setCreating(false);
    onCreate && resultingEventLog && onCreate(resultingEventLog);
    form.resetFields();
  };

  return { createEventLog, isCreating, form };
}
