import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  FetchGridParameters,
  Stack,
  useAsyncData,
  useHistory,
} from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import {
  PagePresenter,
  PageState,
} from 'App/components/PagePresenter/PagePresenter';
import { CompanyTable } from 'Companies/components/CompanyTable/CompanyTable';
import { SentryApiClient } from '_generated/api';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { DEFAULT_PAGE_PARAMS } from 'App/components/TableHelpers/defaultPageSettings';
import { companyFilterMap } from 'Companies/components';
import { toOrderDirection } from 'App/utils/toOrderDirection';
import { useFirstLoadIsEmpty } from 'App/hooks/useFirstLoadIsEmpty';
import { useLocationState } from 'react-router-use-location-state';

export const CompaniesList: React.FC = () => {
  const { push } = useHistory();
  const { companyApi } = useApi();
  const [params, setParams] = useLocationState<
    FetchGridParameters<SentryApiClient.CompanyDTO>
  >('params', { ...DEFAULT_PAGE_PARAMS, orderKey: 'name' });

  const {
    data: companyPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(() => companyApi.getUserPermissions(-1), [companyApi]);

  const { data: companiesList, isLoading, error } = useAsyncData(
    () =>
      companyApi.get(
        params.pageSize,
        params.page,
        companyFilterMap.toFilter(params.orderKey),
        toOrderDirection(params.order),
        companyFilterMap.convert(params.filters)
      ),
    [params, companyApi]
  );

  const isInitialLoadEmpty = useFirstLoadIsEmpty(companiesList?.items);

  const getPageState = () => {
    if (error) return PageState.Error;
    if (isLoading) return PageState.Loading;
    else if (isInitialLoadEmpty) return PageState.Empty;
    else return PageState.Loaded;
  };

  return (
    <PagePresenter pageState={getPageState()}>
      <Card
        title={
          permissionsLoading ? (
            <LoadingOutlined />
          ) : (
            companyPermissions?.canCreate && (
              <Stack direction="horizontal" gap={'apart'}>
                <Stack>Companies</Stack>
                <Stack>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => push('/company/create')}
                  >
                    Add
                  </Button>
                </Stack>
              </Stack>
            )
          )
        }
      >
        <CompanyTable
          isLoading={isLoading}
          companies={companiesList?.items}
          total={companiesList?.totalItems}
          canAdd
          params={params}
          onChange={setParams}
        />
      </Card>
    </PagePresenter>
  );
};
