import { SentryApiClient } from '_generated/api';
import { SelectTableFilter } from './SelectTableFilter';
import { TableFilterProps } from '@torqit/torq-tools-react';

interface IProps {
  props: TableFilterProps;
}

export const PanelStateFilter: React.FC<IProps> = ({ props }) => {
  return (
    <SelectTableFilter
      {...props}
      options={[
        {
          text: getStateName(SentryApiClient.PanelStateType.Online),
          value: SentryApiClient.PanelStateType.Online,
        },
        {
          text: getStateName(SentryApiClient.PanelStateType.Offline),
          value: SentryApiClient.PanelStateType.Offline,
        },
        {
          text: getStateName(SentryApiClient.PanelStateType.NoData),
          value: SentryApiClient.PanelStateType.NoData,
        },
      ]}
    />
  );
};

function getStateName(type: SentryApiClient.PanelStateType) {
  return SentryApiClient.PanelStateType[type].replace(/([A-Z])/g, ' $1').trim();
}
