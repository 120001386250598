import { Box, FormInstance, Tooltip } from '@torqit/torq-tools-react';
import { MultiSelectList } from 'App';
import { buildFieldName } from 'Auth/buildFieldName';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { SelectableSensor } from './SelectableSensor';

export interface SingleSensorWithMultiSelectProps {
  targetSensor: SentryApiClient.SensorDTO;
  form: FormInstance;
  namespace?: string[] | string;
  addableSensors: SentryApiClient.SensorDTO[];
}

export const SingleSensorWithMultiSelect: React.FC<SingleSensorWithMultiSelectProps> = ({
  targetSensor,
  form,
  namespace,
  addableSensors,
}) => {
  const [selection, setSelection] = useState<number[]>([]);

  return (
    <>
      <Tooltip title="The current page's sensor must be added to the event log">
        <div>
          <SelectableSensor
            item={targetSensor}
            selected={true}
            disabled={false}
            isLastClicked={false}
          />
        </div>
      </Tooltip>
      <Box padding={{ vertical: 'sm' }}>
        <div className="ant-form-item-extra">AND</div>
      </Box>
      <MultiSelectList
        data={addableSensors.filter((s) => s.id !== targetSensor.id)}
        selected={selection}
        renderItem={(props) => <SelectableSensor {...props} />}
        itemId={(s) => s.id ?? -1}
        onSelect={(s) => {
          setSelection(s as number[]);
          form.setFields([
            {
              name: buildFieldName(namespace, 'sensorIds'),
              value: [targetSensor.id ?? -1].concat(s as number[]),
            },
          ]);
        }}
      />
    </>
  );
};
