import { UploadOutlined } from '@ant-design/icons';
import { Box, Button, Select, useAsyncData } from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import { LetterIcon } from 'App/components/LetterIcon';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { SensorCreate } from 'Sensors/pages/SensorCreate';
import { SentryApiClient } from '_generated/api';
import styles from './SensorSelectOrCreate.module.css';
import { SearchSelect } from 'App/components/SearchSelect';
import { DashboardItemEmpty } from 'App/components/DashboardItemEmpty';

interface SensorSelectOrCreateProps {
  siteId: number;
  onSelect?: (sensor: SentryApiClient.SensorDTO) => void;
  onError?: (error: Error) => void;
  excludedSensorIds?: number[];
}

const CREATE_ID = -1;

export const SensorSelectOrCreate: React.FC<SensorSelectOrCreateProps> = ({
  siteId,
  onSelect,
  onError,
  excludedSensorIds = [],
}) => {
  const [selectedSensor, setSelectedSensor] = useState<number>();

  const [search, setSearch] = useState<string>();
  const { sensorApi } = useApi();

  const {
    data: sensorPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(() => sensorApi.getUserPermissions(0), [sensorApi]);

  const { data: sensorData, isLoading, error } = useAsyncData(() => {
    const filters: SentryApiClient.QueryFiltersOfSensorFilters[] = [
      // We only want sensors that are within the given site
      {
        field: SentryApiClient.SensorFilters.SiteId,
        operator: SentryApiClient.Operator.Equals,
        value: siteId.toString(),
      },

      // We want to exclude sensors that are already associated to a panel
      {
        field: SentryApiClient.SensorFilters.ParentPanelId,
        operator: SentryApiClient.Operator.Equals,
        value: undefined,
      },
    ];

    if (search) {
      filters.push({
        field: SentryApiClient.SensorFilters.SensorName,
        operator: SentryApiClient.Operator.Contains,
        value: search,
      });
    }

    return sensorApi.get(
      100,
      1,
      SentryApiClient.SensorFilters.SensorName,
      SentryApiClient.OrderDirection.Ascending,
      filters
    );
  }, [search, siteId, sensorApi]);

  useEffect(() => {
    if (selectedSensor == null && sensorData) {
      const filteredSensorData = sensorData.items?.filter(
        (s) => !excludedSensorIds.some((r) => r === s.id)
      );

      setSelectedSensor(
        filteredSensorData && filteredSensorData.length !== 0
          ? filteredSensorData[0].id!
          : sensorPermissions?.canCreate
          ? CREATE_ID
          : undefined
      );
    }
  }, [selectedSensor, sensorData, excludedSensorIds, sensorPermissions]);

  useEffect(() => {
    if (error && onError) {
      onError(error);
    }
  }, [error, onError]);

  if (
    !permissionsLoading &&
    !isLoading &&
    sensorData?.totalItems === 0 &&
    !sensorPermissions?.canCreate
  ) {
    return (
      <DashboardItemEmpty message="There are no remaining sensors to add" />
    );
  }

  return (
    <div>
      <SearchSelect
        value={selectedSensor}
        loading={isLoading || permissionsLoading}
        onChange={(v) => setSelectedSensor(v)}
        className={styles.fullWidth}
        onDebouncedSearch={setSearch}
      >
        {sensorData?.items
          ?.filter((s) => !excludedSensorIds.some((r) => r === s.id))
          .map((s) => (
            <Select.Option value={s.id ?? -1} key={s.id ?? 0}>
              <LetterIcon name={s.sensorName ?? ''} small /> {s.sensorName}
            </Select.Option>
          ))}
        {sensorPermissions?.canCreate && (
          <Select.Option value={CREATE_ID}>Create new sensor...</Select.Option>
        )}
      </SearchSelect>
      {selectedSensor === CREATE_ID && (
        <>
          <Box padding="xs"></Box>
          <SensorCreate siteId={siteId} onCreate={onSelect} onError={onError} />
        </>
      )}
      {selectedSensor !== CREATE_ID && selectedSensor != null && (
        <>
          <Box padding="xs"></Box>
          <Button
            type="primary"
            icon={<UploadOutlined />}
            onClick={() =>
              onSelect &&
              onSelect(sensorData?.items?.find((s) => s.id === selectedSensor)!)
            }
          >
            Submit
          </Button>
        </>
      )}
    </div>
  );
};
