import { Redirect, Routable } from '@torqit/torq-tools-react';
import { userRoutes } from 'Users';
import { authRoutes } from 'Auth';
import { sensorRoutes } from 'Sensors';
import { SiteDashboard, siteRoutes } from 'Sites';
import { companyRoutes } from 'Companies';
import { GlobalLayout } from '../layouts/GlobalLayout';
import { panelRoutes } from 'Panels';
import { LoginRedirector } from 'Auth/Pages/LoginRedirector';
import { DashboardLayout } from 'App/layouts/DashboardLayout';
import { PageNotFound } from './PageNotFound';
import { SensorDashboard } from 'Sensors/pages/SensorDashboard/SensorDashboard';
import { BACK_OFFICE_ROLE, SUPER_USER_ROLE } from 'Auth/roles';
import { SiteRedirector } from 'Auth/Pages/SiteRedirector';
import { Dashboard } from './Dashboard';
import { UserNotificationsEdit } from 'Users/pages/UserNotificationsEdit';
import { announcementRoutes } from 'Announcements/pages/Announcements.routes';
import { termsConditionRoutes } from 'TermsConditions/pages/TermsConditions.routes';

const appRoutes: Routable[] = [
  {
    route: '/',
    content: <Redirect to="/sensors" from="/" />,
    exact: true,
  },
];

export const pages: Routable[] = [
  ...authRoutes,
  {
    authRequired: true,
    requiredRoles: [BACK_OFFICE_ROLE],
    //route: '/admin', ADD ME BACK IN when Torq Tools supports menu route flattening
    pages: [
      {
        layout: GlobalLayout,
        pages: [
          ...userRoutes,
          ...companyRoutes,
          ...siteRoutes,
          ...panelRoutes,
          ...sensorRoutes,
          ...announcementRoutes,
          ...appRoutes,
          ...termsConditionRoutes,
        ],
      },
    ],
  },
  {
    authRequired: true,
    route: '/dashboard',
    pages: [
      {
        layout: DashboardLayout,
        pages: [
          {
            route: '',
            content: <Dashboard />,
            exact: true,
          },
          {
            route: '/site/:siteId(\\d+)',
            content: <SiteDashboard />,
          },
          {
            route: '/sensor/:sensorId(\\d+)',
            content: <SensorDashboard />,
          },
          {
            route: '/user/:userId/notifications',
            content: <UserNotificationsEdit />,
          },
        ],
      },
      //We need to have a separate 404 page because of the way React Router
      // (and by extension, TorqRouter) works: If the route starts with
      // /dashboard, we enter this block and all subsequent routes outside of
      // this block are ignored. Technically, if this block had more nested
      // structures then each of those would actually need their own 404 as
      // well
      {
        route: '*',
        content: <PageNotFound />,
      },
    ],
  },
  {
    authRequired: true,
    requiredRoles: [BACK_OFFICE_ROLE],
    route: '*',
    content: <PageNotFound />,
  },
  //If none of the above routes matched, we're NOT a back office user and
  // we're anywhere OTHER than the dashboard, then redirect to the dashboard
  {
    route: '*',
    authRequired: true,
    content: <SiteRedirector />,
  },
  //If you're not authenticated at ALL then off to the login screen
  {
    route: '*',
    content: <LoginRedirector />,
  },
];
