import React, { useEffect } from 'react';
import { InteractionStatus } from '@azure/msal-browser';
import { Card, useHistory, useLocation } from '@torqit/torq-tools-react';
import { LoadingMessage } from 'App/components/PagePresenter/LoadingMessage';
import { ErrorMessage } from 'App/components/PagePresenter/ErrorMessage';
import { useAuthorization } from 'Auth';
import { useAuthentication } from 'Auth/useAuthentication';
import { buildRedirectUri } from 'Auth/redirectUri';

export const LoginRedirector: React.FC = () => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const { inProgress, isAuthenticated } = useAuthentication();
  const [token, , tokenError] = useAuthorization();

  useEffect(() => {
    if (
      (!isAuthenticated && inProgress === InteractionStatus.None) ||
      (isAuthenticated && !token && tokenError)
    ) {
      history.push(buildRedirectUri('/login', pathname, search));
    }
  }, [
    pathname,
    search,
    history,
    inProgress,
    isAuthenticated,
    token,
    tokenError,
  ]);

  const [, , error] = useAuthorization();

  if (error) {
    return (
      <Card>
        <ErrorMessage error="Something went wrong, contact an administrator" />
      </Card>
    );
  }

  return (
    <Card>
      <LoadingMessage
        phrases={[
          'Setting things up...',
          'Logging you in...',
          'Getting your sites...',
          'Finding your sensors...',
        ]}
      />
    </Card>
  );
};
