import { useMemo } from 'react';
import { SentryApiClient } from '_generated/api';
import { useAuthorization } from '../../Auth';

export const useApi = () => {
  const [token] = useAuthorization();
  const apiHost = window.location.protocol + '//' + window.location.host;
  const companyApi = useMemo(
    () =>
      new SentryApiClient.CompaniesClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );
  const siteApi = useMemo(
    () =>
      new SentryApiClient.SitesClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );
  const sensorApi = useMemo(
    () =>
      new SentryApiClient.SensorsClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );
  const sensorReadingApi = useMemo(
    () =>
      new SentryApiClient.SensorReadingsClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );
  const userApi = useMemo(
    () =>
      new SentryApiClient.UsersClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );
  const panelApi = useMemo(
    () =>
      new SentryApiClient.PanelsClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );
  const eventLogApi = useMemo(
    () =>
      new SentryApiClient.EventLogsClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );
  const sensorStateApi = useMemo(
    () =>
      new SentryApiClient.SensorStatesClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );
  const loginEventApi = useMemo(
    () =>
      new SentryApiClient.LoginEventClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );
  const announcementApi = useMemo(
    () =>
      new SentryApiClient.AnnouncementsClient(apiHost, {
        fetch: createFetchWithToken(token),
      }),
    [apiHost, token]
  );

  return {
    companyApi,
    siteApi,
    sensorApi,
    sensorReadingApi,
    userApi,
    panelApi,
    eventLogApi,
    sensorStateApi,
    loginEventApi,
    announcementApi,
  } as const;
};

const createFetchWithToken = (token: string) => (
  url: RequestInfo,
  init?: RequestInit
) => {
  const headers = new Headers(init?.headers || {});

  headers.set('authorization', `Bearer ${token}`);

  return fetch(url, {
    ...init,
    headers: headers,
  });
};
