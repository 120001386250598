import {
  FilteredTable,
  FilteredTableProps,
  Link,
  Stack,
  StringTableFilter,
  TableColumn,
} from '@torqit/torq-tools-react';
import { AddressDisplay } from 'Address/AddressDisplay';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'App/components/TableHelpers/defaultPageSettings';
import { LetterIcon } from 'App/components/LetterIcon';
import React, { useMemo } from 'react';
import { SentryApiClient } from '_generated/api';
import { StateIcon } from 'App/components/StateIcon';
import { useColumns } from 'App';
import { SiteStateIcon } from '../SiteStatusIcon';

const columns: TableColumn<SentryApiClient.SiteDTO>[] = [
  {
    title: 'State',
    field: 'stateType',
    render: (name, site) => <SiteStateIcon state={site.stateType} />,
  },
  {
    title: 'Site Name',
    field: 'name',
    filter: StringTableFilter,
    sortable: true,
    render: (name: string, site) => {
      return (
        <Stack>
          <Link to={`/site/${site.id}/details`}>
            <Stack alignment="middle" gap={10}>
              <LetterIcon name={name} small />
              {name}
            </Stack>
          </Link>
        </Stack>
      );
    },
  },
  {
    title: 'Address',
    field: 'address',
    render: (address: SentryApiClient.AddressDTO) => {
      return <AddressDisplay address={address} compact />;
    },
  },
];

export interface SiteTableProps
  extends Omit<
    FilteredTableProps<SentryApiClient.SiteDTO>,
    'columns' | 'data' | 'rowKey' | 'pageSizeOptions'
  > {
  sites?: SentryApiClient.SiteDTO[];
  disableFilterAndSort?: boolean;
  canAdd?: boolean;
}

export const SiteTable: React.FC<SiteTableProps> = ({
  sites,
  disableFilterAndSort,
  canAdd,
  ...props
}) => {
  const resultingColumns = useColumns(columns, disableFilterAndSort);

  return (
    <FilteredTable
      columns={resultingColumns}
      data={sites}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      rowKey={(u) => u.id ?? -1}
      {...props}
    />
  );
};
