import { Stack } from '@torqit/torq-tools-react';
import { LetterIcon } from 'App';
import React from 'react';
import { SentryApiClient } from '_generated/api';

export interface SensorLabelProps {
  sensor?: SentryApiClient.SensorDTO;
}

export const SensorLabel: React.FC<SensorLabelProps> = ({ sensor }) => {
  return (
    <Stack>
      <Stack alignment="middle" gap="sm">
        <LetterIcon name={sensor?.sensorName ?? ''} small />
        {sensor?.sensorName}
      </Stack>
    </Stack>
  );
};
