import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  FetchGridParameters,
  Link,
  Stack,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import { PagePresenter, PageState } from 'App/components/PagePresenter';
import { UserTable } from '../components/UserTable';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { SentryApiClient } from '_generated/api';
import { DEFAULT_PAGE_PARAMS } from 'App/components/TableHelpers/defaultPageSettings';
import { userFilterMap } from 'Users/components';
import { toOrderDirection } from 'App/utils/toOrderDirection';
import { useFirstLoadIsEmpty } from 'App/hooks/useFirstLoadIsEmpty';
import { useLocationState } from 'react-router-use-location-state';
import { EMPTY_GUID } from 'Users/forms';

export const Users: React.FC = () => {
  const { userApi } = useApi();

  const [params, setParams] = useLocationState<
    FetchGridParameters<SentryApiClient.UserDTO>
  >('params', { ...DEFAULT_PAGE_PARAMS, orderKey: 'displayName' });

  const {
    data: userPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(() => userApi.getUserPermissions(EMPTY_GUID), [userApi]);

  const { data: usersList, isLoading, error } = useAsyncData(
    () =>
      userApi.get(
        params.pageSize,
        params.page,
        userFilterMap.toFilter(params.orderKey),
        toOrderDirection(params.order),
        userFilterMap.convert(params.filters)
      ),
    [params, userApi]
  );

  const isInitialLoadEmpty = useFirstLoadIsEmpty(usersList?.items);

  const getPageState = () => {
    if (error) return PageState.Error;
    if (isLoading) return PageState.Loading;
    if (isInitialLoadEmpty) return PageState.Empty;
    else return PageState.Loaded;
  };

  return (
    <PagePresenter pageState={getPageState()}>
      <Card
        title={
          <Stack gap={'apart'}>
            <Stack>Users</Stack>
            {permissionsLoading && <LoadingOutlined />}
            {userPermissions?.canCreate && (
              <Stack>
                <Link to="user/create">
                  <Button type="primary" icon={<PlusOutlined />}>
                    Add
                  </Button>
                </Link>
              </Stack>
            )}
          </Stack>
        }
      >
        <UserTable
          users={usersList?.items}
          isLoading={isLoading}
          params={params}
          total={usersList?.totalItems}
          onChange={setParams}
        />
      </Card>
    </PagePresenter>
  );
};
