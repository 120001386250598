import { Button, Stack, useHistory } from '@torqit/torq-tools-react';
import React from 'react';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
} from '@ant-design/icons';

export interface FormNavigatorProps {
  steps?: number;
  currentStep?: number;
  onPrevious?: () => void;
  onNext?: () => void;
  onSubmit?: () => void;
  isSubmitting?: boolean;
}

export const FormNavigator: React.FC<FormNavigatorProps> = ({
  steps,
  currentStep,
  onPrevious,
  onNext,
  onSubmit,
  isSubmitting,
}) => {
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === (steps ?? 1) - 1;
  const singleStep = !steps || steps === 1;

  const history = useHistory();

  return (
    <Stack alignment="middle" gap={20}>
      <Button onClick={() => history.goBack()}>Cancel</Button>
      {singleStep || (
        <Button
          icon={<ArrowLeftOutlined />}
          onClick={onPrevious}
          disabled={isFirstStep}
        >
          Previous
        </Button>
      )}

      {!isLastStep && !singleStep && (
        <Button icon={<ArrowRightOutlined />} type="primary" onClick={onNext}>
          Next
        </Button>
      )}
      {(isLastStep || singleStep) && (
        <Button
          icon={<CheckOutlined />}
          type="primary"
          onClick={onSubmit}
          loading={isSubmitting}
        >
          Submit
        </Button>
      )}
    </Stack>
  );
};
