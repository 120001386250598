import React, { useState } from 'react';
import moment from 'moment';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Drawer, Stack } from '@torqit/torq-tools-react';
import { SentryApiClient } from '_generated/api';
import { SensorRangeCard } from './SensorRangeCard';
import { SensorSelector } from './SensorSelector';
import { SensorRange } from 'SensorReadings/interfaces';
import { usePropState } from 'App';
import { useMemo } from 'react';
import { ComparisonCard } from './ComparisonCard';

type MomentRange = [moment.Moment | null, moment.Moment | null];

interface KeyedRange {
  key: number;
  sensor: SentryApiClient.SensorDTO;
  range: MomentRange | null;
}

export interface SensorRangeComparisonDrawerProps {
  targetSensorRange?: SensorRange;
  comparisons?: SensorRange[];
  visible?: boolean;
  comparableSensors?: SentryApiClient.SensorDTO[];
  onClose?: () => void;
  onSubmit?: (primaryRange: SensorRange, sensorRanges: SensorRange[]) => void;
}

const toMomentRange = (from?: Date, to?: Date): MomentRange | null => {
  return from && to
    ? [from ? moment(from) : null, to ? moment(to) : null]
    : null;
};

const toKeyedRange = (range: SensorRange): KeyedRange => {
  return {
    sensor: range.sensor,
    key: Math.random(),
    range: [moment(range.from), moment(range.to)],
  };
};

const buildDefaultComparisonRange = (
  primarySensor: SentryApiClient.SensorDTO | undefined,
  primarySensorRange: MomentRange | null,
  selectedSensorToCompare: SentryApiClient.SensorDTO
): MomentRange | null => {
  if (
    selectedSensorToCompare.id === primarySensor?.id &&
    primarySensorRange &&
    primarySensorRange[0] &&
    primarySensorRange[1]
  ) {
    // When the user chooses to compare with the same sensor, we want to display a default
    // range that is equivalent in duration to the original range, but stepped back one
    // "timeframe". For example - if the original range is one week, we want the default comparison
    // range to be the previous week.
    const primaryRangeDuration = moment.duration(
      primarySensorRange[1].diff(primarySensorRange[0])
    );
    return [
      // Note that clone is necessary since subtract manipulates the original object
      primarySensorRange[0].clone().subtract(primaryRangeDuration),
      primarySensorRange[1].clone().subtract(primaryRangeDuration),
    ];
  } else {
    return primarySensorRange?.slice() as MomentRange | null;
  }
};

export const SensorRangeComparisonDrawer: React.FC<SensorRangeComparisonDrawerProps> = ({
  targetSensorRange,
  comparisons,
  visible,
  comparableSensors,
  onClose,
  onSubmit,
}) => {
  const targetMomentRange = useMemo(
    () => toMomentRange(targetSensorRange?.from, targetSensorRange?.to),
    [targetSensorRange]
  );
  const [sensorRange, setSensorRange] = usePropState(targetMomentRange);
  const timespan = useMemo(() => {
    if (sensorRange && sensorRange[0] && sensorRange[1]) {
      return sensorRange[1].diff(sensorRange[0]);
    }
    return 0;
  }, [sensorRange]);

  const translatedComparisons = useMemo(
    () => comparisons?.map(toKeyedRange) ?? [],
    [comparisons]
  );
  const [internalComparisons, setInternalComparisons] = usePropState(
    translatedComparisons
  );
  const addComparison = (sensor: SentryApiClient.SensorDTO) => {
    const copy = internalComparisons.concat({
      sensor,
      //Key generation could be a problem if someone manages to
      // press Add Sensor and select a sensor multiple times in
      // the span of one millisecond.
      key: new Date().getTime(),
      range: buildDefaultComparisonRange(
        targetSensorRange?.sensor,
        sensorRange,
        sensor
      ),
    });
    setInternalComparisons(copy);
  };
  const updateComparison = (key: number, range: MomentRange | null) => {
    //Deep copy since we're modifying one of the objects
    const copy = internalComparisons.map((c) => ({ ...c }));

    const target = copy.find((s) => s.key === key)!;
    target.range = range;

    setInternalComparisons(copy);
  };
  const removeComparison = (key: number) => {
    const copy = internalComparisons.slice();
    copy.splice(
      internalComparisons.findIndex((c) => c.key === key),
      1
    );

    setInternalComparisons(copy);
  };

  const canSubmit = () => {
    if (internalComparisons.length === 0) {
      return (
        sensorRange != null && sensorRange[0] != null && sensorRange[1] != null
      );
    }

    return (
      sensorRange != null &&
      sensorRange[0] != null &&
      sensorRange[1] != null &&
      internalComparisons.every(
        (s) =>
          s.sensor != null &&
          s.range != null &&
          s.range[0] != null &&
          s.range[1] != null
      )
    );
  };
  const submit = () => {
    onClose && onClose();

    onSubmit &&
      onSubmit(
        {
          sensor: targetSensorRange?.sensor!,
          from: sensorRange![0]?.toDate()!,
          to: sensorRange![1]?.toDate()!,
        },
        internalComparisons.map((s) => ({
          sensor: s.sensor!,
          from: s.range![0]?.toDate()!,
          //We very definitely want our comparison timespans to match our
          // primary timespan, so to just make things absolutely bullet proof,
          // we make our endpoint completely based on the start point
          to: s.range![0]?.clone().add(timespan).toDate()!,
        }))
      );
  };

  const reset = () => {
    setSensorRange(null);
    setInternalComparisons([]);
  };

  return (
    <Drawer
      visible={visible}
      title="Compare Custom Range"
      footer={
        <>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            disabled={!canSubmit()}
            onClick={submit}
          >
            {internalComparisons.length > 0 ? 'Compare' : 'Filter'}
          </Button>
          <Button
            onClick={() => {
              onClose && onClose();
              reset();
            }}
          >
            Cancel
          </Button>
        </>
      }
      width={460}
      onClose={onClose}
    >
      <Stack direction="vertical" gap="xl" stretch>
        <SensorRangeCard
          sensor={targetSensorRange?.sensor}
          range={sensorRange}
          onChange={setSensorRange}
        />

        {internalComparisons.map((s) => (
          <ComparisonCard
            key={s.key}
            timespan={timespan}
            sensor={s.sensor}
            start={s.range && s.range[0]}
            onChange={(start) =>
              updateComparison(
                s.key,
                start ? [start, start.clone().add(timespan)] : null
              )
            }
            onRemove={() => removeComparison(s.key)}
          />
        ))}
        <SensorSelector
          comparableSensors={comparableSensors}
          onSelect={addComparison}
        />
      </Stack>
    </Drawer>
  );
};
