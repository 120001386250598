import React from 'react';
import {
  Link,
  RouterLayoutProps,
  SideNavLayout,
  Stack,
} from '@torqit/torq-tools-react';
import { GlobalFooter } from './GlobalFooter';
import { GlobalHeader } from './GlobalHeader';
import styles from './GlobalLayout.module.css';
import { TokenForceRefresher } from 'Auth';

export const GlobalLayout: React.FC<RouterLayoutProps> = (props) => {
  return (
    <div id={styles.globalLayout}>
      <TokenForceRefresher>
        <SideNavLayout
          {...props}
          siderProps={{
            topContent: (
              <Stack gap={16} direction="vertical">
                <div></div>
                <Link to="/">
                  <img src="/sentry-logo.png" width="90%" />
                </Link>
                <div></div>
              </Stack>
            ),
            mode: 'inline',
          }}
          headerContent={<GlobalHeader />}
          footerContent={<GlobalFooter />}
        />
      </TokenForceRefresher>
    </div>
  );
};
