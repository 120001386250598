import {
  Card,
  FetchGridParameters,
  Stack,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { DEFAULT_PAGE_PARAMS, PagePresenter, PageState, useApi } from 'App';
import { useFirstLoadIsEmpty } from 'App/hooks/useFirstLoadIsEmpty';
import { PanelAdoptTable } from 'Panels';
import { useState } from 'react';
import { Sensors } from 'Sensors/pages/SensorsList';
import { SentryApiClient } from '_generated/api';

export const PanelsPendingAdoption: React.VFC = () => {
  const { panelApi } = useApi();
  const [paramsPendingAdoption, setParamsPendingAdoption] = useState<
    FetchGridParameters<SentryApiClient.PanelDTO>
  >({
    ...DEFAULT_PAGE_PARAMS,
    order: 'desc',
    orderKey: 'lastAdoptionRequestDate',
  });
  const { data: panelsPendingAdoption, isLoading, error } = useAsyncData(
    () =>
      panelApi.get(
        paramsPendingAdoption.pageSize,
        paramsPendingAdoption.page,
        undefined,
        null,
        [
          {
            field: SentryApiClient.PanelFilters.Adopted,
            operator: SentryApiClient.Operator.Equals,
            value: 'False',
          },
        ]
      ),
    [paramsPendingAdoption, panelApi]
  );

  const isInitialLoadEmpty = useFirstLoadIsEmpty(panelsPendingAdoption?.items);

  const getPageState = () => {
    if (error) return PageState.Error;
    if (isLoading) return PageState.Loading;
    else if (isInitialLoadEmpty) return PageState.Empty;
    else return PageState.Loaded;
  };

  return (
    <PagePresenter pageState={getPageState()}>
      <Card
        title={
          <Stack gap="apart" alignment="middle">
            <Stack alignment="middle" gap={10}>
              Panels Pending Adoption
            </Stack>
          </Stack>
        }
      >
        <PanelAdoptTable
          panels={panelsPendingAdoption?.items || []}
          isLoading={isLoading}
          total={panelsPendingAdoption?.totalItems}
          params={paramsPendingAdoption}
          onChange={(params) => setParamsPendingAdoption(params)}
        />
      </Card>
    </PagePresenter>
  );
};
