import {
  Drawer,
  FetchGridParameters,
  Result,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { useCompanySiteApi } from './useCompanySiteApi';
import { CompanySiteList } from './CompanySiteList';
import { CompanySiteSelector } from './CompanySiteSelector';
import { siteFilterMap } from 'Sites/components/SiteTable/siteFilterMap';
import { toOrderDirection } from 'App/utils/toOrderDirection';
import { useDebounce } from 'App';

export interface CompanySitePanelProps {
  company?: SentryApiClient.CompanyDTO;
  showSiteDrawer?: boolean;
  canRemoveSites?: boolean;
  onSiteDrawerChange?: (visible: boolean) => void;
}

const PAGE_SIZE = 20;

export const CompanySitePanel: React.FC<CompanySitePanelProps> = ({
  company,
  showSiteDrawer,
  canRemoveSites,
  onSiteDrawerChange,
}) => {
  const companyId = company?.id ?? -1;

  const { siteApi } = useApi();
  const { addSiteToCompany } = useCompanySiteApi();

  const [siteChangeError, setSiteChangeError] = useState<Error>();

  const [tableParams, setTableParams] = useState<
    FetchGridParameters<SentryApiClient.SiteDTO>
  >({
    page: 1,
    pageSize: PAGE_SIZE,
    orderKey: 'name',
    order: 'asc',
  });
  const {
    data: siteData,
    isLoading: sitesLoading,
    executeRequest: reloadSites,
    error: companySitesError,
  } = useAsyncData(
    async () =>
      companyId
        ? siteApi.get(
            PAGE_SIZE,
            tableParams.page,
            siteFilterMap.toFilter(tableParams.orderKey),
            toOrderDirection(tableParams.order),
            [
              {
                field: SentryApiClient.SiteFilters.CompanyId,
                operator: SentryApiClient.Operator.Equals,
                value: companyId.toString(),
              },
            ]
          )
        : undefined,
    [tableParams, siteApi, companyId]
  );

  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 300);
  const [page, setPage] = useState(1);
  const {
    data: unclaimedSites,
    isLoading: unclaimedSitesLoading,
    executeRequest: reloadUnclaimedSites,
    error: allSitesError,
  } = useAsyncData(() => {
    const filters: SentryApiClient.QueryFiltersOfSiteFilters[] = [
      {
        field: SentryApiClient.SiteFilters.CompanyId,
        operator: SentryApiClient.Operator.Equals,
        value: undefined,
      },
    ];

    if (debouncedSearch) {
      filters.push({
        field: SentryApiClient.SiteFilters.Name,
        operator: SentryApiClient.Operator.Contains,
        value: debouncedSearch,
      });
    }

    return siteApi.get(
      PAGE_SIZE,
      page,
      SentryApiClient.SiteFilters.Name,
      SentryApiClient.OrderDirection.Ascending,
      filters
    );
  }, [page, debouncedSearch, siteApi]);

  const addExistingSite = async (sites: SentryApiClient.SiteDTO[]) => {
    try {
      await addSites(sites);
    } catch (error) {
      setSiteChangeError(error);
    }

    onSiteDrawerChange && onSiteDrawerChange(false);
    reloadSites();
    reloadUnclaimedSites();
  };
  const addSites = (siteList: SentryApiClient.SiteDTO[]) => {
    return Promise.all(
      siteList.map(async (site) => addSiteToCompany(site, companyId))
    );
  };

  const refreshSites = () => {
    reloadSites();
    reloadUnclaimedSites();
  };

  if (siteChangeError || companySitesError || allSitesError) {
    return <Result status="error" title="Something went wrong" />;
  }

  return (
    <>
      <CompanySiteList
        companyId={companyId}
        sites={siteData?.items}
        params={tableParams}
        total={siteData?.totalItems}
        isLoading={sitesLoading}
        canRemoveSites={canRemoveSites}
        onChange={setTableParams}
        onSitesRemoved={refreshSites}
        onRemoveFailed={setSiteChangeError}
      />
      <CompanySiteSelector
        visible={showSiteDrawer}
        companyName={company?.name}
        allSites={unclaimedSites?.items}
        totalSites={unclaimedSites?.totalItems}
        loadingSites={unclaimedSitesLoading}
        page={page}
        pageSize={PAGE_SIZE}
        onPageChange={setPage}
        onSelect={addExistingSite}
        onSearch={setSearch}
        onClose={() => onSiteDrawerChange && onSiteDrawerChange(false)}
      />
    </>
  );
};
