import { useHistory } from '@torqit/torq-tools-react';
import { useApi } from 'App';
import { CREATED_COMPANY, NO_COMPANY_SELECTED } from 'Companies';
import { useCallback, useState } from 'react';
import { CREATED_SITE } from 'Sites';
import { SentryApiClient } from '_generated/api';

export interface PanelAdoptionFormResults {
  site: SentryApiClient.SiteDTO;
  company: SentryApiClient.CompanyDTO;
}

export function useAdoptionCallback(
  adoptablePanel: SentryApiClient.PanelDTO | undefined
) {
  const { companyApi, siteApi, panelApi } = useApi();
  const router = useHistory();

  const [isAdopting, setAdopting] = useState(false);
  const [hasError, setError] = useState(false);

  const onFinish = useCallback(
    async (result: PanelAdoptionFormResults) => {
      let targetSite: SentryApiClient.SiteDTO | undefined = undefined;
      let targetCompany: SentryApiClient.CompanyDTO | undefined = undefined;

      if (adoptablePanel == null) {
        return;
      }

      setAdopting(true);
      try {
        if (!result.company.id || result.company.id === CREATED_COMPANY) {
          result.company.id = undefined;
          targetCompany = await companyApi.post(result.company);
        } else if (result.company.id !== NO_COMPANY_SELECTED) {
          targetCompany = result.company;
        }

        if (!result.site.id || result.site.id === CREATED_SITE) {
          result.site.id = undefined;
          result.site.company = targetCompany?.id;
          targetSite = await siteApi.post(result.site);
        } else {
          targetSite = result.site;
        }

        const panel = await panelApi.put(adoptablePanel.id ?? -1, {
          ...adoptablePanel,
          siteId: targetSite?.id ?? result.site?.id,
        });

        router.push(`/panel/${panel.id}/details`);
      } catch (error) {
        setError(true);

        if (result.company.id === CREATED_COMPANY && targetCompany?.id)
          await companyApi.delete(targetCompany.id);
        if (result.site.id === CREATED_SITE && targetSite?.id)
          await siteApi.delete(targetSite?.id);
      }
    },
    [adoptablePanel, companyApi, panelApi, router, siteApi]
  );

  return { isAdopting, hasError, onFinish };
}
