import { createContext, useContext } from 'react';

export interface ChartPoint {
  date: Date;
}

export interface ChartClickerData {
  point?: ChartPoint;
  onClick: (point?: ChartPoint) => void;
}

const ChartClickerContext = createContext<ChartClickerData | null>(null);

export const ChartClickerProvider: React.FC<ChartClickerData> = ({
  children,
  ...props
}) => {
  return (
    <ChartClickerContext.Provider value={props}>
      {children}
    </ChartClickerContext.Provider>
  );
};

export function useChartClicker() {
  const result = useContext(ChartClickerContext);

  if (!result) {
    throw new Error(
      'useChartClicker() cannot be used without being wrapped by a ChartClickerProvider'
    );
  }

  return result;
}
