import {
  ExperimentalForm,
  FormInstance,
  Input,
  Select,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { Fieldset } from 'App/components/FormHelpers';
import { LetterIcon } from 'App/components/LetterIcon';
import { useApi } from 'App/hooks/useApi';
import React, { useState } from 'react';
import { buildFieldName } from 'Auth/buildFieldName';
import { CompanyFormFields } from '.';
import { SentryApiClient } from '_generated/api';
import { SearchSelect } from 'App/components/SearchSelect';
import { DashboardItemEmpty } from 'App/components/DashboardItemEmpty';

export const NO_COMPANY_SELECTED = -1;
export const CREATED_COMPANY = -2;

export interface CompanySelectOrCreateFieldsProps {
  active: boolean;
  visible: boolean;
  form: FormInstance<any>;
  namespace?: string | string[];
  allowEmpty?: boolean;
}

export const CompanySelectOrCreateFields: React.FC<CompanySelectOrCreateFieldsProps> = ({
  active,
  visible,
  form,
  namespace,
  allowEmpty = false,
}) => {
  const { companyApi } = useApi();

  const {
    data: companyPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(() => companyApi.getUserPermissions(0), [companyApi]);

  const [value, setValue] = useState(NO_COMPANY_SELECTED);
  const [search, setSearch] = useState<string>();

  const { data: companyList, isLoading } = useAsyncData(
    () =>
      companyApi.get(
        100,
        1,
        SentryApiClient.CompanyFilters.Name,
        SentryApiClient.OrderDirection.Ascending,
        search
          ? [
              {
                field: SentryApiClient.CompanyFilters.Name,
                operator: SentryApiClient.Operator.Contains,
                value: search,
              },
            ]
          : undefined
      ),
    [search, companyApi]
  );

  function updateFields(selectedCompanyId: number) {
    if (
      selectedCompanyId === NO_COMPANY_SELECTED ||
      selectedCompanyId === CREATED_COMPANY
    ) {
      form.setFields([
        { name: buildFieldName(namespace, 'name'), value: null },
        {
          name: buildFieldName(namespace, ['address', 'address1']),
          value: null,
        },
        {
          name: buildFieldName(namespace, ['address', 'address2']),
          value: null,
        },
        { name: buildFieldName(namespace, ['address', 'city']), value: null },
        {
          name: buildFieldName(namespace, ['address', 'country']),
          value: null,
        },
        {
          name: buildFieldName(namespace, ['address', 'region']),
          value: null,
        },
        { name: buildFieldName(namespace, ['address', 'zip']), value: null },
      ]);
    } else {
      const company = companyList?.items?.find(
        (c) => c.id === selectedCompanyId
      )!;
      form.setFields([
        { name: buildFieldName(namespace, 'id'), value: company.id },
        { name: buildFieldName(namespace, 'name'), value: company.name },
        {
          name: buildFieldName(namespace, ['address', 'address1']),
          value: company.address?.address1,
        },
        {
          name: buildFieldName(namespace, ['address', 'address2']),
          value: company.address?.address2,
        },
        {
          name: buildFieldName(namespace, ['address', 'city']),
          value: company.address?.city,
        },
        {
          name: buildFieldName(namespace, ['address', 'country']),
          value: company.address?.country,
        },
        {
          name: buildFieldName(namespace, ['address', 'region']),
          value: company.address?.region,
        },
        {
          name: buildFieldName(namespace, ['address', 'zip']),
          value: company.address?.zip,
        },
      ]);
    }

    setValue(selectedCompanyId);
  }

  if (
    !isLoading &&
    !permissionsLoading &&
    !allowEmpty &&
    companyList?.totalItems === 0 &&
    !companyPermissions?.canCreate
  ) {
    //If there are literally no companies to choose from, you're not allowed
    // to create one and you have to pick one, then there's literally no other
    // option here other than just stopping the user here (which we do by
    // slapping an invisible input here that always fails validation).
    //
    //In an idyllic world, we would never even get to this point, and the
    // create flow would just stop you immediately, but there are just way
    // too many routes to this component (and other components that use this
    // approach) to wrap my head around right now so we'll just fix the use
    // cases as they slap me in the face.
    return (
      <Fieldset id="user" active={active} visible={visible}>
        <ExperimentalForm.Item
          name={buildFieldName(namespace, 'id')}
          hidden
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Input />
        </ExperimentalForm.Item>
        <DashboardItemEmpty message="There are no companies to choose from" />
      </Fieldset>
    );
  }

  return (
    <Fieldset id="user" active={active} visible={visible}>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'id')}
        label={<span>Company</span>}
        initialValue={allowEmpty ? NO_COMPANY_SELECTED : undefined}
        rules={[
          {
            required: true,
            message: 'Please select an option',
          },
        ]}
      >
        <SearchSelect
          loading={isLoading && permissionsLoading}
          onChange={(v) => updateFields(v as number)}
          onDebouncedSearch={setSearch}
        >
          {allowEmpty && (
            <Select.Option value={NO_COMPANY_SELECTED}>None</Select.Option>
          )}
          {companyList?.items?.map((c) => (
            <Select.Option key={c.id} value={c.id ?? 0}>
              <LetterIcon name={c.name || ''} small /> {c.name}
            </Select.Option>
          ))}
          {companyPermissions?.canCreate && (
            <Select.Option value={CREATED_COMPANY}>
              Create new company...
            </Select.Option>
          )}
        </SearchSelect>
      </ExperimentalForm.Item>
      <CompanyFormFields
        active={active && value !== NO_COMPANY_SELECTED}
        visible={visible && value === CREATED_COMPANY}
        namespace={namespace}
      />
    </Fieldset>
  );
};
