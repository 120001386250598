import { LineSvgProps } from '@nivo/line';

//The event log chart clicking needs to know what the padding is
// to calculate the exact clicked spot, so we export these here
export const CHART_LEFT_PADDING = 65;
export const CHART_RIGHT_PADDING = 65;
export const CHART_TOP_PADDING = 20;
export const CHART_BOTTOM_PADDING = 55;

export const CommonLineChartProps: LineSvgProps = {
  data: [],
  curve: 'monotoneX',
  margin: {
    top: CHART_TOP_PADDING,
    right: CHART_RIGHT_PADDING,
    bottom: CHART_BOTTOM_PADDING,
    left: CHART_LEFT_PADDING,
  },
  xScale: { format: '%Y-%m-%dT%H:%M:%S.%L%Z', type: 'time' },
  areaOpacity: 1,
  enableArea: false,
  enableSlices: 'x',
  pointColor: { from: 'color' },
  pointSize: 1.5,
  pointBorderWidth: 2,
  pointBorderColor: { from: 'serieColor' },
  pointLabelYOffset: -12,
};
