import React, { useState } from 'react';
import {
  ExperimentalForm,
  FormInstance,
  Input,
  Select,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { Fieldset } from 'App/components/FormHelpers';
import { LetterIcon } from 'App/components/LetterIcon';
import { useApi } from 'App/hooks/useApi';
import { buildFieldName } from 'Auth/buildFieldName';
import UserFormFields, { UserFormFieldsShape } from './UserFormFields';
import { SentryApiClient } from '_generated/api';
import { SearchSelect } from 'App/components/SearchSelect';
import { EMPTY_GUID } from '.';
import { DashboardItemEmpty } from 'App/components/DashboardItemEmpty';

export const NO_USER = 'NONE';
export const CREATE_USER = 'CREATE';

export type UserSelectOrCreateFormFieldsShape = UserFormFieldsShape & {
  id?: string;
};

export interface UserSelectOrCreateFieldsProps {
  active: boolean;
  visible: boolean;
  form: FormInstance<any>;
  namespace?: string | string[];
  allowEmpty?: boolean;
}

export const UserSelectOrCreateFields: React.FC<UserSelectOrCreateFieldsProps> = ({
  active,
  visible,
  form,
  namespace,
  allowEmpty = false,
}) => {
  const [value, setValue] = useState(NO_USER);
  const { userApi } = useApi();

  const {
    data: userPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(() => userApi.getUserPermissions(EMPTY_GUID), [userApi]);

  const [search, setSearch] = useState<string>();
  const { data: usersList, isLoading } = useAsyncData(
    () =>
      userApi.get(
        100,
        1,
        SentryApiClient.UserFilters.DisplayName,
        SentryApiClient.OrderDirection.Ascending,
        search
          ? [
              {
                field: SentryApiClient.UserFilters.DisplayName,
                operator: SentryApiClient.Operator.Contains,
                value: search,
              },
            ]
          : undefined
      ),
    [userApi, search]
  );

  const updateFields = (selectedUserId: string) => {
    if (selectedUserId === NO_USER || selectedUserId === CREATE_USER) {
      form.setFields([
        { name: buildFieldName(namespace, 'first_name'), value: null },
        { name: buildFieldName(namespace, 'last_name'), value: null },
        { name: buildFieldName(namespace, 'email'), value: null },
        { name: buildFieldName(namespace, 'username'), value: null },
        { name: buildFieldName(namespace, 'displayName'), value: null },
      ]);
    } else {
      const user = usersList?.items?.find((u) => u.id === selectedUserId)!;

      form.setFields([
        {
          name: buildFieldName(namespace, 'first_name'),
          value: user.displayName?.split(' ')[0],
        },
        {
          name: buildFieldName(namespace, 'last_name'),
          value: user.displayName?.split(' ')[1],
        },
        { name: buildFieldName(namespace, 'email'), value: user.email },
        { name: buildFieldName(namespace, 'username'), value: user.username },
        {
          name: buildFieldName(namespace, 'userTypeId'),
          value: user.userTypeId,
        },
        {
          name: buildFieldName(namespace, 'displayName'),
          value: user.displayName,
        },
      ]);
    }

    setValue(selectedUserId);
  };

  if (
    !isLoading &&
    !permissionsLoading &&
    !allowEmpty &&
    usersList?.totalItems === 0 &&
    !userPermissions?.canCreate
  ) {
    //Refer to CompanySelectOrCreateFields
    return (
      <Fieldset id="user" active={active} visible={visible}>
        <ExperimentalForm.Item
          name={buildFieldName(namespace, 'id')}
          hidden
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Input />
        </ExperimentalForm.Item>
        <DashboardItemEmpty message="There are no users to choose from" />
      </Fieldset>
    );
  }

  return (
    <Fieldset id="user" active={active} visible={visible}>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'id')}
        label={<span>User</span>}
        initialValue={allowEmpty ? NO_USER : undefined}
        rules={[
          {
            required: true,
            message: 'Please select an option',
          },
        ]}
      >
        <SearchSelect
          loading={isLoading && permissionsLoading}
          onChange={(v) => updateFields(v as string)}
          onDebouncedSearch={setSearch}
        >
          {allowEmpty && <Select.Option value={NO_USER}>None</Select.Option>}
          {usersList?.items?.map((u) => (
            <Select.Option value={u.id as string} key={u.id}>
              <LetterIcon name={u.displayName || ''} small /> {u.displayName}
            </Select.Option>
          ))}
          {userPermissions?.canCreate && (
            <Select.Option value={CREATE_USER}>
              Create new user...
            </Select.Option>
          )}
        </SearchSelect>
      </ExperimentalForm.Item>
      <UserFormFields
        active={active && value !== NO_USER}
        visible={visible && value === CREATE_USER}
        namespace={namespace}
        displayName={false}
        userId={value !== NO_USER && value !== CREATE_USER ? value : undefined}
      />
    </Fieldset>
  );
};
