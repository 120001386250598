import React, { useState } from 'react';
import {
  Affix,
  Card,
  ExperimentalForm,
  Stack,
  useHistory,
} from '@torqit/torq-tools-react';
import { FormNavigator } from 'App/components/FormHelpers';
import { PagePresenter } from 'App/components/PagePresenter';
import { PageState } from 'App/components/PagePresenter/PagePresenter';
import {
  CompanySelectOrCreateFields,
  CREATED_COMPANY,
  NO_COMPANY_SELECTED,
} from 'Companies';
import { useApi } from 'App/hooks/useApi';
import { SentryApiClient } from '_generated/api';
import { UserCreateReview } from '../forms/UserCreateReview';
import UserFormFields, { UserFormFieldsShape } from '../forms/UserFormFields';
import { useSentryUser } from 'Users/hooks/SentryUserProvider';
import { UserType } from 'Users/userTypes';

interface FormResults {
  company: SentryApiClient.CompanyDTO;
  user: UserFormFieldsShape;
}

export interface UserCreateProps {
  company?: SentryApiClient.CompanyDTO | boolean;
}

export const UserCreate: React.FC<UserCreateProps> = ({ company }) => {
  const { user } = useSentryUser();

  const [form] = ExperimentalForm.useForm<FormResults>();
  const [formStep, setFormStep] = useState(0);
  const [nextFormStep, setNextFormStep] = useState<number>();
  const [formProgress, setFormProgress] = useState(0);

  const onPrevious = () => {
    if (formStep === 2 && company !== true) {
      setFormStep(0);
    } else {
      setFormStep(formStep - 1);
    }
  };

  const getNextStep = () => {
    if (nextFormStep) {
      return nextFormStep;
    } else if (formStep === 0 && company !== true) {
      return 2;
    } else {
      return formStep + 1;
    }
  };

  const onNext = () => {
    form.validateFields().then(() => {
      const targetStep = getNextStep();

      setFormStep(targetStep);
      setFormProgress(Math.max(targetStep, formProgress));

      //If we had a next step queued, it's definitely been consumed by now
      // so we'll just clear it.
      setNextFormStep(undefined);
    });
  };

  const onBackToCompany = () => {
    setFormStep(1);
    setNextFormStep(2);
  };

  const onBackToUser = () => {
    setFormStep(0);
    setNextFormStep(2);
  };

  const { companyApi, userApi } = useApi();
  const router = useHistory();
  const [pageState, setPageState] = useState(PageState.Loaded);
  const onFinish = async (formResult: FormResults) => {
    let company = {
      ...formResult.company,
      name: formResult.company.name,
    };

    let user;

    setPageState(PageState.Loading);

    try {
      if (!company.id || company.id === CREATED_COMPANY) {
        company.id = undefined;
        company = await companyApi.post(company);
      }

      user = await userApi.post({
        ...formResult.user,
        displayName: formResult.user.displayName,
        companies:
          formResult.company.id !== NO_COMPANY_SELECTED && company && company.id
            ? [company.id]
            : [],
        sites: [],
        offlineAlertsEnabled: formResult.user.offlineAlertsEnabled,
        redHighAlertsEnabled: formResult.user.redHighAlertsEnabled,
        redLowAlertsEnabled: formResult.user.redLowAlertsEnabled,
        amberHighAlertsEnabled: formResult.user.amberHighAlertsEnabled,
        amberLowAlertsEnabled: formResult.user.amberLowAlertsEnabled,
      });

      router.push(`/user/${user.id}/details`);
    } catch (error) {
      setPageState(PageState.Error);

      if (formResult.company.id === CREATED_COMPANY && company && company.id) {
        await companyApi.delete(company.id as number);
      }
      if (user && formResult.user.id === null)
        await userApi.delete(user.id as string);
    }
  };

  const [currentName, setCurrentName] = useState('Example User');
  const onChange = (_v: any, result: FormResults) => {
    setCurrentName(result.user.displayName);
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack direction="vertical" gap={10} stretch>
        <Card title={'New User'}>
          <ExperimentalForm<FormResults>
            labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 12 },
              md: { span: 10 },
            }}
            form={form}
            name="config"
            initialValues={typeof company === 'object' ? { company } : {}}
            onValuesChange={onChange}
            onFinish={onFinish}
            scrollToFirstError
          >
            <UserFormFields
              visible={formStep === 0}
              active /* always active */
              namespace={'user'}
              displayName={currentName}
            />
            <CompanySelectOrCreateFields
              visible={formStep === 1}
              active={formProgress >= 1}
              form={form}
              namespace={'company'}
              allowEmpty={
                user?.userTypeId ===
                UserType.Administrator /* IF THIS GETS CHANGED, be sure to look at [POST]/user and update the permissions as well*/
              }
            />
            <UserCreateReview
              active={formStep === 2}
              company={
                company === true ? form.getFieldsValue().company : undefined
              }
              user={form.getFieldsValue()?.user}
              onBackToCompany={onBackToCompany}
              onBackToUser={onBackToUser}
            />
          </ExperimentalForm>
        </Card>
        <Affix offsetBottom={0}>
          <Card>
            <FormNavigator
              currentStep={formStep}
              steps={3}
              onPrevious={onPrevious}
              onNext={onNext}
              onSubmit={() => form.submit()}
            />
          </Card>
        </Affix>
      </Stack>
    </PagePresenter>
  );
};
