import { TeamOutlined } from '@ant-design/icons';
import { Routable } from '@torqit/torq-tools-react';
import { CompaniesList } from './CompaniesList';
import { CompanyCreate } from './CompanyCreate';
import { CompanyDetails } from './CompanyDetails';
import CompanyEdit from './CompanyEdit/CompanyEdit';

export const companyRoutes: Routable[] = [
  {
    menuName: 'Companies',
    route: '/companies',
    icon: <TeamOutlined />,
    content: <CompaniesList />,
  },
  {
    route: '/company',
    pages: [
      {
        route: '/create',
        content: <CompanyCreate user={true} />,
      },
      {
        route: '/:companyId(\\d+)',
        pages: [
          {
            route: '/edit',
            content: <CompanyEdit />,
          },
          {
            route: '/details',
            content: <CompanyDetails />,
          },
        ],
      },
    ],
  },
];
