import { DateServiceSingleton } from 'App/utils/DateService';
import moment from 'moment';
import { SentryApiClient } from '_generated/api';

const HOURS_IN_WEEK = 24 * 7;
const HOURS_IN_MONTH = 24 * 31;
const HOURS_IN_YEAR = 24 * 365;

export function calculateTimeSeriesTicks(startDate: Date, endDate: Date) {
  const rangeInMinutes = moment(endDate).diff(startDate, 'minutes');
  const rangeInHours = moment(endDate).diff(startDate, 'hours');

  var tickValue;
  var timeFormat: string;

  switch (true) {
    case rangeInMinutes <= 30:
      tickValue = 'every minute';
      timeFormat = 'H:mm';
      break;

    case rangeInMinutes <= 60:
      tickValue = 'every 10 minutes';
      timeFormat = 'H:mm';
      break;

    case rangeInHours <= 2:
      tickValue = 'every 30 minutes';
      timeFormat = 'H:mm';
      break;

    case rangeInHours <= 24:
      tickValue = 'every hour';
      timeFormat = 'H:00';
      break;

    case rangeInHours <= 48:
      tickValue = 'every 12 hours';
      timeFormat = 'MMM D H:00';
      break;

    case rangeInHours <= HOURS_IN_WEEK:
      tickValue = 'every day';
      timeFormat = 'MMM D';
      break;

    case rangeInHours <= HOURS_IN_MONTH:
      tickValue = 'every week';
      timeFormat = 'MMM D';
      break;

    case rangeInHours <= HOURS_IN_YEAR:
      tickValue = 'every month';
      timeFormat = 'MMM';
      break;

    case rangeInHours > HOURS_IN_YEAR:
      tickValue = 'every year';
      timeFormat = 'YYYY';
      break;

    default:
      tickValue = 'every day';
      timeFormat = 'MMM D';
  }

  return {
    tickValues: tickValue,
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 35,
    format: (data: Date) => {
      return DateServiceSingleton.GetDateTime(data, true, timeFormat);
    },
  };
}
