import {
  FilteredTable,
  FilteredTableProps,
  Link,
  Stack,
  StringTableFilter,
  TableColumn,
} from '@torqit/torq-tools-react';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'App/components/TableHelpers/defaultPageSettings';
import { LetterIcon } from 'App/components/LetterIcon';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import { getUserTypeName, UserType } from '../userTypes';
import { SelectTableFilter } from 'App/components/SelectTableFilter/SelectTableFilter';
import { useColumns } from 'App';

export interface UserTableProps
  extends Omit<
    FilteredTableProps<SentryApiClient.UserDTO>,
    'columns' | 'data' | 'rowKey' | 'pageSizeOptions'
  > {
  users?: SentryApiClient.UserDTO[];
  disableFilterAndSort?: boolean;
}

const userTypes = [
  UserType.Customer,
  UserType.CustomerManager,
  UserType.ClientManager,
  UserType.Distributor,
  UserType.Administrator,
];

const columns: TableColumn<SentryApiClient.UserDTO>[] = [
  {
    title: 'Full Name',
    field: 'displayName',
    filter: StringTableFilter,
    sortable: true,
    render: (displayName: string, user: SentryApiClient.UserDTO) => {
      return (
        <Stack>
          <Link to={`/user/${user.id}/details`}>
            <Stack alignment="middle" gap={10}>
              <LetterIcon name={displayName} small />
              {displayName}
            </Stack>
          </Link>
        </Stack>
      );
    },
  },
  {
    title: 'Type',
    field: 'userTypeId',
    filter: (props) => (
      <SelectTableFilter
        {...props}
        options={userTypes.map((u) => ({ text: getUserTypeName(u), value: u }))}
        placeholder={'None...'}
      />
    ),
    sortable: true,
    render: getUserTypeName,
  },
  {
    title: 'Phone',
    filter: StringTableFilter,
    field: 'phoneNumber',
    sortable: true,
  },
  {
    title: 'Email',
    filter: StringTableFilter,
    field: 'email',
    sortable: true,
  },
  {
    title: 'Username',
    filter: StringTableFilter,
    field: 'username',
    sortable: true,
  },
];

export const UserTable: React.FC<UserTableProps> = ({
  users,
  disableFilterAndSort,
  ...props
}) => {
  const resultingColumns = useColumns(columns, disableFilterAndSort);

  return (
    <FilteredTable
      columns={resultingColumns}
      data={users}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      rowKey={(u) => u.id ?? -1}
      {...props}
    />
  );
};
