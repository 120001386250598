import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCertificate,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import { StateIcon } from 'App/components/StateIcon';
import React from 'react';
import { SentryApiClient } from '_generated/api';

interface IProps {
  state?: SentryApiClient.PanelStateType;
}

const PanelStateIcon: React.FC<IProps> = ({ state }) => {
  let icon: IconDefinition;
  let color: string;
  let label: string;

  switch (state) {
    case SentryApiClient.PanelStateType.Offline:
      icon = faPowerOff;
      color = '#aaa';
      label = getStateName(SentryApiClient.PanelStateType.Offline);
      break;

    case SentryApiClient.PanelStateType.Red:
      icon = faExclamationCircle;
      color = 'var(--torq-experimental-error-color)';
      label = getStateName(SentryApiClient.PanelStateType.Red);
      break;

    case SentryApiClient.PanelStateType.Amber:
      icon = faExclamationTriangle;
      color = 'var(--torq-experimental-warning-color)';
      label = getStateName(SentryApiClient.PanelStateType.Amber);
      break;

    case SentryApiClient.PanelStateType.Online:
      icon = faCheckCircle;
      color = 'var(--torq-experimental-success-color)';
      label = getStateName(SentryApiClient.PanelStateType.Online);
      break;

    case SentryApiClient.PanelStateType.AllOffline:
      icon = faPowerOff;
      color = '#000';
      label = getStateName(SentryApiClient.PanelStateType.AllOffline);
      break;

    default:
    case SentryApiClient.PanelStateType.NoData:
      icon = faCertificate;
      color = 'var(--torq-experimental-success-color)';
      label = getStateName(SentryApiClient.PanelStateType.NoData);
      break;
  }
  return <StateIcon icon={icon} color={color} label={label} />;
};

function getStateName(type: SentryApiClient.PanelStateType) {
  return SentryApiClient.PanelStateType[type].replace(/([A-Z])/g, ' $1').trim();
}

export { PanelStateIcon };
