import React, { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Button, Result } from '@torqit/torq-tools-react';
import { InteractionStatus } from '@azure/msal-browser';
import { AUTH_REDIRECT_DELAY } from './constants';
import { useAuthentication } from 'Auth/useAuthentication';
import { SentryLoader } from 'App/components/SentryLoader';
import { useImmediateSplashHiderEffect } from 'App/components/SentrySplash/useImmediateSplashHiderEffect';

export const Logout: React.VFC = () => {
  useImmediateSplashHiderEffect();

  const isAuthenticated = useIsAuthenticated();
  const { logout, inProgress } = useAuthentication();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    const timer = setTimeout(logout, AUTH_REDIRECT_DELAY);
    return () => clearTimeout(timer);
  }, [logout, isAuthenticated]);

  if (!isAuthenticated && inProgress === InteractionStatus.None) {
    return (
      <Result
        status="success"
        title="You're all set!"
        subTitle="You can now safely close the tab"
        extra={[<Button href="/login">Sign into another account</Button>]}
      />
    );
  }

  return <SentryLoader big />;
};
