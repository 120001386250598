import bounds from 'binary-search-bounds';

type Orderable = number | Date;

//Binary searches are used by the SensorReadingsChart to place event logs over
// the most relevant reading, but the syntax on this binary search library is
// really clumsy, so here's a wrapper that cleans things up and makes it a bit
// more obvious.

/**
 * @param array An _pre-sorted_ array to perform the search on
 * @param searchable An object containing the property that's being searched
 * on (I wish there was a better way but typescript does not make it easy)
 * @param getOrderedProperty A getter that returns the value that the objects
 * are ordered on
 * */
export function binarySearchLowerBound<T>(
  array: T[],
  searchable: Partial<T>,
  getOrderedProperty: (item: T) => Orderable | undefined
) {
  if (array.length === 0) {
    return undefined;
  }

  const partialArray = array as Partial<T>[];

  var index: number = bounds.le(
    partialArray,
    searchable,
    (a, b) =>
      toNumber(getOrderedProperty(a as T)) -
      toNumber(getOrderedProperty(b as T))
  );

  if (index < 0 || index >= partialArray.length) {
    return undefined;
  } else {
    return array[index];
  }
}

function toNumber(value?: Orderable) {
  if (!value) {
    return 0;
  }

  return typeof value == 'number' ? value : value.getTime();
}
