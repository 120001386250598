import React from 'react';
import { getFontColorForBackground } from '@torqit/torq-tools-react';
import classNames from 'classnames';
import styles from './LetterIcon.module.css';

//Acquired from https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
function hashCode(str: string) {
  // java String#hashCode
  var hash = 0;
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
}

//Acquired from same as above
function intToRGB(i: number) {
  var c = (i & 0x00ffffff).toString(16).toUpperCase();

  return '#' + '00000'.substring(0, 6 - c.length) + c;
}

function stringToColor(phrase: string) {
  return intToRGB(hashCode(phrase));
}

function getInitials(phrase: string) {
  const splitPhrase = phrase.split(' ');

  if (splitPhrase.length === 0) {
    return '--';
  } else if (splitPhrase.length === 1) {
    return splitPhrase[0].substr(0, 2);
  }

  return splitPhrase[0].charAt(0) + splitPhrase[1].charAt(0);
}

interface IProps {
  name: string;
  small?: boolean;
}

export const LetterIcon: React.FC<IProps> = ({ name, small }) => {
  const backgroundColor = stringToColor(name);
  const color = getFontColorForBackground(backgroundColor);

  return (
    <div
      className={classNames(styles.icon, { [styles.small]: small })}
      //The types get very angry if you don't explicitly say
      // that this is a CSSProperties object
      style={
        {
          '--letter-icon-bg-color': backgroundColor,
          '--letter-icon-color': color,
        } as React.CSSProperties
      }
    >
      {getInitials(name)}
    </div>
  );
};
