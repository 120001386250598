import {
  Affix,
  Card,
  ExperimentalForm,
  Stack,
  useHistory,
} from '@torqit/torq-tools-react';
import { FormNavigator } from 'App/components/FormHelpers';
import { PagePresenter, PageState } from 'App/components/PagePresenter';
import { useApi } from 'App/hooks/useApi';
import {
  CompanySelectOrCreateFields,
  CREATED_COMPANY,
  NO_COMPANY_SELECTED,
} from 'Companies';
import { PanelFormFields } from 'Panels/forms/PanelFormFields';
import React, { useState } from 'react';
import {
  CREATED_SITE,
  NO_SITE_SELECTED,
  SiteSelectOrCreateFields,
} from 'Sites/forms/SiteSelectOrCreateFields';
import { SentryApiClient } from '_generated/api';
import { PanelReview } from 'Panels/forms/PanelReview/PanelReview';

interface FormResults {
  panel: SentryApiClient.PanelDTO;
  site: SentryApiClient.SiteDTO;
  company: SentryApiClient.CompanyDTO;
}

export interface PanelCreateProps {
  site?: SentryApiClient.SiteDTO | boolean;
}

const PANEL_STEP = 0;
const SITE_STEP = 1;
const COMPANY_STEP = 2;
const REVIEW_STEP = 3;

export const PanelCreate: React.FC<PanelCreateProps> = ({ site }) => {
  const [formStep, setFormStep] = useState(0);
  const onPrevious = () => {
    if (
      formStep === REVIEW_STEP &&
      form.getFieldsValue()?.site.id !== CREATED_SITE
    ) {
      setFormStep(SITE_STEP);
    } else {
      setFormStep(formStep - 1);
    }
  };

  const [nextFormStep, setNextFormStep] = useState<number>();
  const getNextStep = () => {
    if (nextFormStep) {
      return nextFormStep;
    } else if (
      formStep === PANEL_STEP &&
      (typeof site === 'object' || site == null)
    ) {
      return REVIEW_STEP;
    } else if (
      formStep === SITE_STEP &&
      form.getFieldsValue()?.site.id !== CREATED_SITE
    ) {
      return REVIEW_STEP;
    }

    return formStep + 1;
  };

  const [form] = ExperimentalForm.useForm<FormResults>();
  const [formProgress, setFormProgress] = useState(0);
  const onNext = () => {
    form.validateFields().then(() => {
      const targetStep = getNextStep();

      setFormStep(targetStep);
      setFormProgress(Math.max(targetStep, formProgress));

      //If we had a next step queued, it's definitely been consumed by now
      // so we'll just clear it.
      setNextFormStep(undefined);
    });
  };

  const onBackToPanel = () => {
    setFormStep(PANEL_STEP);
    setNextFormStep(REVIEW_STEP);
  };

  const onBackToSite = () => {
    setFormStep(SITE_STEP);
    setNextFormStep(REVIEW_STEP);
  };

  const onBackToCompany = () => {
    setFormStep(COMPANY_STEP);
    setNextFormStep(REVIEW_STEP);
  };

  const [pageState, setPageState] = useState<PageState>(PageState.Loaded);
  const { panelApi, siteApi, companyApi } = useApi();
  const router = useHistory();
  const onFinish = async (result: FormResults) => {
    let creatablePanel: SentryApiClient.PanelDTO = {
      ...result.panel,
    };
    let targetSite = typeof site == 'object' ? site : undefined;
    let targetCompany: SentryApiClient.CompanyDTO | undefined = undefined;

    setPageState(PageState.Loading);
    try {
      if (!result.company.id || result.company.id === CREATED_COMPANY) {
        result.company.id = undefined;
        targetCompany = await companyApi.post(result.company);
      } else if (result.company.id !== NO_COMPANY_SELECTED) {
        targetCompany = result.company;
      }

      if (!result.site.id || result.site.id === CREATED_SITE) {
        result.site.id = undefined;
        result.site.company = targetCompany?.id;
        targetSite = await siteApi.post(result.site);
      } else if (result.site.id !== NO_SITE_SELECTED) {
        targetSite = result.site;
      }

      if (targetSite) {
        creatablePanel.siteId = targetSite.id;
      }

      creatablePanel = await panelApi.post(creatablePanel);

      router.push(`/panel/${creatablePanel.id}/details`);
    } catch (error) {
      setPageState(PageState.Error);

      if (result.company.id === CREATED_COMPANY && targetCompany?.id)
        await companyApi.delete(targetCompany.id);
      if (result.site.id === CREATED_SITE && targetSite?.id)
        await siteApi.delete(targetSite?.id);
      if (creatablePanel.id) await panelApi.delete(creatablePanel.id);
    }
  };

  const [currentName, setCurrentName] = useState('Example Panel');
  const onChange = (_v: any, result: FormResults) => {
    setCurrentName(result?.panel?.name || '');
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack direction="vertical" gap={10} stretch>
        <Card title={'New Panel'}>
          <ExperimentalForm<FormResults>
            labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 12 },
              md: { span: 10 },
            }}
            form={form}
            name="config"
            initialValues={{ site }}
            onValuesChange={onChange}
            onFinish={onFinish}
            scrollToFirstError
          >
            <PanelFormFields
              visible={formStep === PANEL_STEP}
              active /* Always active */
              namespace={'panel'}
              form={form}
              name={currentName}
            />
            <SiteSelectOrCreateFields
              visible={formStep === SITE_STEP}
              active={formProgress >= SITE_STEP}
              form={form}
              namespace={'site'}
              allowEmpty
            />
            <CompanySelectOrCreateFields
              visible={formStep === COMPANY_STEP}
              active={formProgress >= COMPANY_STEP}
              form={form}
              namespace={'company'}
              allowEmpty
            />
            <PanelReview
              active={formStep === REVIEW_STEP}
              panel={form.getFieldsValue()?.panel}
              site={form.getFieldsValue()?.site}
              company={form.getFieldsValue()?.company}
              canUpdatePanel
              onBackToPanel={onBackToPanel}
              onBackToSite={onBackToSite}
              onBackToCompany={onBackToCompany}
            />
          </ExperimentalForm>
        </Card>
        <Affix offsetBottom={0}>
          <Card>
            <FormNavigator
              currentStep={formStep}
              steps={4}
              onPrevious={onPrevious}
              onNext={onNext}
              onSubmit={() => form.submit()}
            />
          </Card>
        </Affix>
      </Stack>
    </PagePresenter>
  );
};
