const REDIRECT_URI = 'redirect_uri';

//Part of me thinks these could be hooks but the other part of me thinks that
// might be overkill
export function buildRedirectUri(
  targetRoute: string,
  redirectRoute: string,
  currentSearch: string
): string {
  if (redirectRoute == null || redirectRoute === '' || redirectRoute === '/') {
    return targetRoute;
  }

  const safeRedirect = redirectRoute.startsWith('/')
    ? redirectRoute
    : '/' + redirectRoute;

  const route = new URLSearchParams(currentSearch);
  route.append(REDIRECT_URI, safeRedirect);
  return targetRoute + '?' + route.toString();
}

export function getRedirectUri(currentSearch: string) {
  const searchParams = new URLSearchParams(currentSearch);
  return searchParams.get(REDIRECT_URI);
}
