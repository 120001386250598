import { CheckOutlined } from '@ant-design/icons';
import { Box, Button, ExperimentalForm } from '@torqit/torq-tools-react';
import { useApi } from 'App';
import { DateServiceSingleton } from 'App/utils/DateService';
import {
  MultiSensorEventLogFields,
  SingleSensorEventLogFields,
} from 'EventLogs/forms';
import moment from 'moment';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';

export type FormEventLog = Omit<SentryApiClient.EventLogDTO, 'timestamp'> & {
  timestamp: moment.Moment;
};

export interface EventLogEditProps {
  sensor: SentryApiClient.SensorDTO;
  eventLog: SentryApiClient.EventLogDTO;
  timezone: string;
  addableSensors: SentryApiClient.SensorDTO[];
  onEdit?: (eventLog: SentryApiClient.EventLogDTO) => void;
  onError?: (error: Error) => void;
}

export const EventLogEdit: React.FC<EventLogEditProps> = ({
  sensor,
  eventLog,
  timezone,
  addableSensors,
  onEdit,
  onError,
}) => {
  const { eventLogApi } = useApi();
  const [isEditing, setEditing] = useState(false);

  const [form] = ExperimentalForm.useForm<FormEventLog>();
  const [isSiteTimezone, setIsSiteTimezone] = useState(true);

  const editEventLog = async (log: FormEventLog) => {
    const timestamp = log.timestamp.toDate();

    const actualLog: SentryApiClient.EventLogDTO = {
      ...log,
      timestamp: timezone
        ? DateServiceSingleton.ConvertToLocalTime(timestamp, timezone)
        : timestamp,
    };

    setEditing(true);
    let resultingEventLog;

    try {
      resultingEventLog = await eventLogApi.put(eventLog.id!, actualLog);
    } catch (error) {
      onError && onError(error);
    }

    setEditing(false);
    onEdit && resultingEventLog && onEdit(resultingEventLog);
    form.resetFields();
  };

  return (
    <ExperimentalForm
      wrapperCol={{
        xs: { span: 24 },
      }}
      form={form}
      name="config"
      onFinish={editEventLog}
      scrollToFirstError
      initialValues={{
        ...eventLog,
        sensorIds: [sensor.id],
        timestamp: eventLog.timestamp
          ? moment(
              new Date(eventLog.timestamp).getTime() +
                DateServiceSingleton.GetMyTimezoneOffset() * 1000 * 60
            )
          : undefined,
      }}
    >
      <SingleSensorEventLogFields
        visible
        active
        targetSensor={sensor}
        form={form}
        timezone={timezone}
        addableSensors={addableSensors ?? []}
        isUsingSiteTimezone={isSiteTimezone}
        onUseSiteTimezone={setIsSiteTimezone}
      />
      <Box padding={{ top: 'sm' }}>
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={form.submit}
          loading={isEditing}
        >
          Submit
        </Button>
      </Box>
    </ExperimentalForm>
  );
};
