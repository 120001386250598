import React from 'react';
import {
  Col,
  ExperimentalForm,
  Input,
  Row,
  Stack,
  Switch,
} from '@torqit/torq-tools-react';
import { buildFieldName } from 'Auth/buildFieldName';
import styles from './CompanyFormFields.module.css';
import { Fieldset } from 'App/components/FormHelpers';
import { LetterIcon } from 'App/components/LetterIcon';
import { SentryApiClient } from '_generated/api';
import { useApi } from 'App';

interface CompanyFormFieldProps {
  active: boolean;
  visible: boolean;
  namespace?: string | string[];
  name?: string;
  companyId?: string;
}

const CompanyFormFields: React.FC<CompanyFormFieldProps> = ({
  active,
  visible,
  namespace,
  name,
  companyId,
}) => {
  const { companyApi } = useApi();
  return (
    <Fieldset id={styles.company} active={active} visible={visible}>
      {name && (
        <Stack direction="vertical" alignment="middle" gap={20}>
          <LetterIcon name={name} />
          <Stack.Item />
        </Stack>
      )}
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'name')}
        label={<span>Company Name</span>}
        rules={[
          {
            required: true,
            message: 'Please add a full name!',
          },
          {
            validator: async (rule, value) => {
              const companiesWithSameName = await companyApi.get(
                null,
                null,
                undefined,
                null,
                [
                  {
                    field: SentryApiClient.CompanyFilters.Name,
                    operator: SentryApiClient.Operator.Equals,
                    value: value as string,
                  },
                  {
                    field: SentryApiClient.CompanyFilters.Id,
                    operator: SentryApiClient.Operator.NotEquals,
                    value: companyId ? companyId : '-1',
                  },
                ]
              );
              if (
                active &&
                visible &&
                companiesWithSameName.items &&
                companiesWithSameName.items.length > 0
              ) {
                throw new Error(
                  'There already exists a company with this name'
                );
              }
            },
          },
        ]}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, ['address', 'address1'])}
        label={<span>Address</span>}
        extra={<span>Line 1</span>}
        rules={[
          {
            required: true,
            message: 'At least address 1 is required',
          },
        ]}
        className={styles.addressRow}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, ['address', 'address2'])}
        colon={false}
        label={<span></span>}
        extra={<span>Line 2</span>}
        className={styles.addressRow}
      >
        <Input />
      </ExperimentalForm.Item>
      <Row className={styles.addressRow}>
        <Col xs={0} sm={7}></Col>
        <Col sm={6} md={5}>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, ['address', 'city'])}
            wrapperCol={{ sm: 24 }}
            colon={false}
            extra={<span>City</span>}
            rules={[
              {
                required: true,
                message: 'Company must be in a city',
              },
            ]}
            className={styles.addressCol + ' ' + styles.addressRow}
          >
            <Input />
          </ExperimentalForm.Item>
        </Col>
        <Col sm={6} md={5}>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, ['address', 'country'])}
            wrapperCol={{ sm: 24 }}
            colon={false}
            extra={<span>Country</span>}
            rules={[
              {
                required: true,
                message: 'Company must be in a country',
              },
            ]}
            className={styles.addressCol + ' ' + styles.addressRow}
          >
            <Input />
          </ExperimentalForm.Item>
        </Col>
      </Row>
      <Row className={styles.addressRow}>
        <Col xs={0} sm={7}></Col>
        <Col sm={6} md={5}>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, ['address', 'region'])}
            colon={false}
            wrapperCol={{ sm: 24 }}
            extra={<span>Region</span>}
            rules={[
              {
                required: true,
                message: 'Need a province or state',
              },
            ]}
            className={styles.addressCol + ' ' + styles.addressRow}
          >
            <Input />
          </ExperimentalForm.Item>
        </Col>
        <Col sm={6} md={5}>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, ['address', 'zip'])}
            colon={false}
            wrapperCol={{ sm: 24 }}
            extra={<span>Postal Code</span>}
            rules={[
              {
                required: true,
                message: 'Need a postal code or zip code',
              },
            ]}
            className={styles.addressCol + ' ' + styles.addressRow}
          >
            <Input />
          </ExperimentalForm.Item>
        </Col>
      </Row>
    </Fieldset>
  );
};

export { CompanyFormFields };
