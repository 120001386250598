import React, { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useClickOutsideEffect } from './useClickOutsideEffect';

export interface SelectableItemProps {
  /** The selection state of the item */
  selected: boolean;
  /** The disabled state of the item */
  disabled: boolean;
  /**
   * Whether or not this item was the last one was clicked. Useful for styling
   * a visual anchor for shift clicking multiple items
   */
  isLastClicked: boolean;
}

export interface MultiSelectItemProps<Selectable> extends SelectableItemProps {
  /** The item being rendered */
  item: Selectable;
}

export interface MultiSelectListProps<Selectable> {
  /** An array of all of the selectable items */
  data: Selectable[];
  /** A function that renders each of the individual items */
  renderItem: (props: MultiSelectItemProps<Selectable>) => React.ReactNode;
  /**
   * A key of the selectable item or a function that fetches a unique ID
   * on the selectable. **Note:** Using a key is not type safe. Ensure your key
   * is referencing a string or number field.
   */
  itemId: keyof Selectable | ((item: Selectable) => React.Key);
  /**
   * A key of the selectable item or a function that returns whether or
   * not the item is disabled. If using a key, the resulting value is evaluated
   * for it's truthiness or falsiness.
   */
  itemDisabled?: keyof Selectable | ((item: Selectable) => boolean | undefined);
  /** An array of selected keys based on the supplied `itemId` prop*/
  selected?: React.Key[];
  /** A callback that fires every time the selection changes */
  onSelect?: (selection: React.Key[]) => void;
}

export const MultiSelectList = <Selectable extends object>({
  data,
  renderItem,
  itemId,
  itemDisabled,
  selected = [],
  onSelect,
}: MultiSelectListProps<Selectable>) => {
  const getItemId = (item: Selectable) => {
    return typeof itemId == 'function'
      ? itemId(item)
      : ((item[itemId] as unknown) as React.Key);
  };

  const isItemDisabled = (item: Selectable) => {
    if (!itemDisabled) {
      return false;
    }

    return typeof itemDisabled == 'function'
      ? itemDisabled(item)
      : ((item[itemDisabled] as unknown) as boolean | undefined);
  };

  const [lastIdClicked, setLastIdClicked] = useState<React.Key>();
  const listRef = useRef<HTMLDivElement>(null);
  useClickOutsideEffect(listRef, () => {
    setLastIdClicked(undefined);
  });
  useEffect(() => {
    if (!data.some((d) => getItemId(d) == lastIdClicked)) {
      setLastIdClicked(undefined);
    }
  }, [data, lastIdClicked]);

  const selectOrDeselect = (
    id: React.Key,
    itemSelected: boolean,
    shiftHeld: boolean
  ) => {
    if (!onSelect) {
      return;
    }

    if (shiftHeld && lastIdClicked) {
      const selectedRange = getRangeBetween(lastIdClicked, id);
      const enabledRange = isItemDisabled
        ? selectedRange.filter((s) => !isItemDisabled(s))
        : selectedRange;

      //If we shift clicked on an item that's selected, we want to deselect it
      // and all of the selected items between it and the previous item
      // we clicked.
      if (itemSelected) {
        const idsToDeselect = enabledRange
          .map((s) => getItemId(s))
          .filter((s) => selected.includes(s));

        onSelect(selected.filter((s) => !idsToDeselect.includes(s)));
      } else {
        //Otherwise, we shift clicked an non-selected item, so we want to select it
        // and every other unselected item between it and the previously clicked
        const idsToSelect = enabledRange
          .map((s) => getItemId(s))
          .filter((s) => !selected.includes(s));

        onSelect(selected.concat(idsToSelect));
      }
    } else {
      itemSelected ? deselect(id) : select(id);
    }

    setLastIdClicked(id);
  };

  const getRangeBetween = (previous: React.Key, clicked: React.Key) => {
    const previousIndex = data.findIndex(
      (item) => getItemId(item) === previous
    );
    const clickedIndex = data.findIndex((item) => getItemId(item) === clicked);

    const start = Math.min(previousIndex, clickedIndex);
    const end = Math.max(previousIndex, clickedIndex);

    return data.slice(start, end + 1);
  };

  const select = (id: React.Key) => {
    onSelect!(selected.concat(id));
  };

  const deselect = (id: React.Key) => {
    const index = selected.findIndex((itemId) => itemId === id);
    const copy = selected?.slice();
    copy?.splice(index, 1);
    onSelect!(copy);
  };

  const renderSelectable = (item: Selectable) => {
    const key = getItemId(item);
    const disabled = (isItemDisabled != null && isItemDisabled(item)) ?? false;
    const isSelected = selected?.includes(key);

    return (
      <div
        onClick={(e) =>
          !disabled && key && selectOrDeselect(key, isSelected, e.shiftKey)
        }
        key={key}
      >
        {renderItem({
          item,
          selected: isSelected,
          disabled,
          isLastClicked: lastIdClicked === key,
        })}
      </div>
    );
  };

  return <div ref={listRef}>{data.map(renderSelectable)}</div>;
};
