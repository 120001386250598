import { Switch } from '@torqit/torq-tools-react';
import React from 'react';
import styles from './SiteTimezoneSwitch.module.css';

export interface SiteTimezoneSwitchProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
}

export const SiteTimezoneSwitch: React.FC<SiteTimezoneSwitchProps> = ({
  checked,
  onChange,
}) => {
  return (
    <Switch
      className={styles.switch}
      unCheckedChildren="My Time"
      checkedChildren="Site Time"
      checked={checked}
      onChange={onChange}
    />
  );
};
