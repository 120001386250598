import { useEffect } from 'react';

export function useFullscreenMouseMove(
  event: (e: MouseEvent) => void,
  dependencies: React.DependencyList
) {
  useEffect(
    () => {
      document.addEventListener('mousemove', event);

      return () => document.removeEventListener('mousemove', event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  );
}

export function useFullscreenMouseUp(
  event: (e: MouseEvent) => void,
  dependencies: React.DependencyList
) {
  useEffect(
    () => {
      document.addEventListener('mouseup', event);

      return () => document.removeEventListener('mouseup', event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dependencies
  );
}
