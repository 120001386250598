import { ChartableSensorReading } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { SentryApiClient } from '_generated/api';
import { ReadingsPipe } from './ReadingsPipe';

export class NormalizePipe extends ReadingsPipe {
  private gap: number;

  public constructor(grouping?: SentryApiClient.GroupByParam) {
    super();

    this.gap = Infinity;
    var b = 0;

    //When hunting for gaps in the data, we need to keep in mind our actual grouping.
    // Typically, sensors emit a reading roughly every minute, so when there's no
    // grouping or minutely grouping, we'll pick something arbitrarily short, say
    // 30 minutes. If we're grouping for anything higher though, that isn't going to
    // work since hourly grouping is already larger than our gap. For that reason,
    // we'll adjust our minimum gap just so that larger groupings require a much
    // larger break in order to register a gap.
    switch (grouping) {
      case undefined:
      case SentryApiClient.GroupByParam.Minute:
        this.gap = 1000 * 60 * 30; //30 minutes
        break;
      case SentryApiClient.GroupByParam.Hour:
        this.gap = 1000 * 60 * 60 * 6; //6 hours
        break;
      case SentryApiClient.GroupByParam.Day:
        this.gap = 1000 * 60 * 60 * 24 * 3; //3 days
        break;
    }
  }

  public setGapOverride(gap: number) {
    this.gap = gap;
  }

  public enter(readings: ChartableSensorReading[]): void {
    this.exitListener && this.exitListener(this.normalize(readings));
  }

  private normalize(readings: SentryApiClient.SensorReadingDTO[]) {
    return readings.reduce(
      (
        normalizedReadings: SentryApiClient.SensorReadingDTO[],
        currentReading,
        i
      ) => {
        if (
          !currentReading.readingTime ||
          currentReading.rawValue === null ||
          currentReading.rawValue === undefined
        ) {
          return normalizedReadings;
        }

        const prevReading = i > 0 ? readings[i - 1] : undefined;
        let prevReadingTime = 0;
        let currentReadingTime = 0;

        if (prevReading?.readingTime) {
          prevReadingTime = new Date(prevReading.readingTime).getTime();
        }

        if (currentReading.readingTime) {
          currentReadingTime = new Date(currentReading.readingTime).getTime();
        }

        const isGap = currentReadingTime - prevReadingTime >= this.gap;

        if (isGap && currentReading.readingTime && prevReading?.readingTime) {
          const previousTime = new Date(prevReading?.readingTime);
          const currentTime = new Date(currentReading.readingTime);
          const nullReading = {
            ...currentReading,
            rawValue: null as any,
            stringValue: '',
          };

          //Since these are the dates that are going to get applied to our null
          // readings which are acting as a gap, we want them to actually display
          // between our two readings in question so that the gap actually gets
          // generated. If we don't adjust the time on them, then the null data
          // will have the exact same dates as the readings we're trying to
          // separate which might confuse Nivo
          previousTime?.setUTCSeconds(currentTime.getUTCSeconds() + 1);
          currentTime?.setUTCSeconds(currentTime.getUTCSeconds() - 1);

          normalizedReadings.push({
            ...nullReading,
            readingTime: currentTime,
          });

          normalizedReadings.push({
            ...nullReading,
            readingTime: previousTime,
          });
        }

        if (currentReading.readingTime) {
          normalizedReadings.push(currentReading);
        }

        return normalizedReadings;
      },
      []
    );
  }
}
