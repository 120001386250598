import { UploadOutlined } from '@ant-design/icons';
import { Box, Button, Stack } from '@torqit/torq-tools-react';
import { EntitySelector } from 'App/components/EntitySelector';
import { MultiSelectList } from 'App/components/MultiSelectList';
import { SentryLoader } from 'App/components/SentryLoader';
import React, { useState } from 'react';
import { UserType } from 'Users/userTypes';
import { SentryApiClient } from '_generated/api';
import { SelectableUser } from './SelectableUser';

export interface CompanyUserSelectorProps {
  companyName?: string;
  allUsers?: SentryApiClient.UserWithPermissionsDTO[];
  totalUsers?: number;
  page: number;
  pageSize: number;
  loadingUsers?: boolean;
  userIdsInCompany?: string[];
  visible?: boolean;
  search?: string;
  onSearch?: (search?: string) => void;
  onSelect?: (users: SentryApiClient.UserDTO[]) => Promise<void>;
  onPageChange?: (page: number) => void;
  onClose?: () => void;
}

export const CompanyUserSelector: React.FC<CompanyUserSelectorProps> = ({
  allUsers,
  totalUsers,
  loadingUsers,
  userIdsInCompany,
  companyName,
  page,
  pageSize,
  visible,
  search,
  onSearch,
  onPageChange,
  onSelect,
  onClose,
}) => {
  const [selection, setSelection] = useState<string[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const submit = async () => {
    setSubmitting(true);
    if (onSelect) {
      await onSelect(
        selection.map(
          (u) =>
            allUsers?.find(
              (userWithPermission) => userWithPermission.user?.id === u
            )?.user!
        )
      );
    }
    setSubmitting(false);

    setSelection([]);
  };

  return (
    <EntitySelector
      title={<>Add users to {companyName}</>}
      visible={visible}
      data={allUsers ?? []}
      renderItem={(props) => <SelectableUser {...props} />}
      itemId={(u) => u.user?.id ?? ''}
      itemDisabled={(u) =>
        userIdsInCompany?.some((i) => u.user?.id === i) ||
        !u.permissions?.canUpdate ||
        !u.permissions.canBeAddedToAdditionalCompany
      }
      selected={selection}
      onSelect={(selection) => setSelection(selection as string[])}
      page={page}
      onPageChange={onPageChange}
      isLoading={loadingUsers}
      pageSize={pageSize}
      total={totalUsers}
      onClose={onClose}
      search={search}
      onSearch={onSearch}
      emptyMessage="No users found"
      footer={
        <Button
          type="primary"
          icon={<UploadOutlined />}
          disabled={selection.length === 0}
          onClick={submit}
          loading={isSubmitting}
        >
          Add to {companyName}
        </Button>
      }
    />
  );
};
