import {
  Button,
  Card,
  Link,
  Stack,
  useAsyncData,
  useHistory,
  useParams,
} from '@torqit/torq-tools-react';
import React, { useEffect, useState } from 'react';
import { PagePresenter, PageState } from 'App/components/PagePresenter';
import { useApi } from 'App/hooks/useApi';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { LetterIcon } from 'App/components/LetterIcon';
import { FormReviewItem } from 'App/components/FormHelpers';
import { AddressDisplay } from 'Address/AddressDisplay';
import { ConfirmDialog } from 'App/components/ConfirmDelete';
import { SiteUserPanel } from 'Sites/components/SiteUsers/SiteUserPanel';
import { SitePanelCompartment } from 'Sites/components/SitePanels/SitePanelCompartment';
import { MobileIconButton } from 'App/components/MobileIconButton/MobileIconButton';

interface IProps {}

const SiteDetails: React.FC<IProps> = ({}) => {
  const { siteApi, companyApi } = useApi();
  const [showPanelDrawer, setShowPanelDrawer] = useState(false);
  const [showUserDrawer, setShowUserDrawer] = useState(false);
  const { push } = useHistory();
  const params = useParams<{ siteId?: string }>();
  const siteId = params.siteId && parseInt(params.siteId);
  const { data: siteDetails, isLoading, error } = useAsyncData(async () => {
    if (!siteId) {
      return undefined;
    }
    return siteApi.getById(siteId);
  }, [siteId, siteApi]);

  const {
    data: sitePermissions,
    isLoading: loadingPermissions,
  } = useAsyncData(
    async () => (siteId ? siteApi.getUserPermissions(siteId) : undefined),
    [siteApi, siteId]
  );

  const router = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const onDelete = async () => {
    try {
      setConfirmDelete(false);
      setPageState(PageState.Loading);

      if (siteId) await siteApi.delete(siteId);

      router.push('/sites');
    } catch (error) {
      setPageState(PageState.Error);
    }
  };

  const companyId = siteDetails?.company && siteDetails.company;
  const {
    data: companyDetails,
    isLoading: companyLoading,
    error: companyError,
  } = useAsyncData(
    async () => (companyId ? companyApi.getById(companyId) : undefined),
    [companyId, companyApi]
  );

  const [pageState, setPageState] = useState(PageState.Loaded);
  useEffect(() => {
    if (isLoading || companyLoading) setPageState(PageState.Loading);
    else if (error || companyError) setPageState(PageState.Error);
    else if (siteDetails) setPageState(PageState.Loaded);
  }, [siteDetails, isLoading, companyLoading, error, companyError]);

  return (
    <PagePresenter pageState={pageState}>
      <Stack direction="vertical" gap={20} stretch>
        <Card
          title={
            <Stack gap="apart" alignment="middle">
              <Stack alignment="middle" gap={10}>
                <Link to={`/company/${companyDetails?.id}/details`}>
                  {companyDetails?.name}
                </Link>{' '}
                {'>'} {siteDetails?.name} Site
              </Stack>
              <Stack alignment="end" gap={10}>
                {loadingPermissions && <LoadingOutlined />}
                {sitePermissions?.canUpdate && (
                  <Link to={`/site/${siteDetails?.id}/edit`}>
                    <MobileIconButton type="primary" icon={<EditOutlined />}>
                      Edit
                    </MobileIconButton>
                  </Link>
                )}
                {sitePermissions?.canDelete && (
                  <MobileIconButton
                    type="primary"
                    color="red"
                    icon={<DeleteOutlined />}
                    onClick={() => setConfirmDelete(true)}
                    danger
                  >
                    Delete
                  </MobileIconButton>
                )}
              </Stack>
            </Stack>
          }
        >
          <ConfirmDialog
            body={`Are you sure you want to delete ${siteDetails?.name}?`}
            onConfirm={onDelete}
            onCancel={() => setConfirmDelete(false)}
            open={confirmDelete}
            okButton={{ danger: true, children: 'Delete' }}
          />
        </Card>
        <Card
          title={
            <Stack gap={'apart'}>
              <Stack>Panels</Stack>
              <Stack>
                {loadingPermissions && <LoadingOutlined />}
                {sitePermissions?.canUpdate && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setShowPanelDrawer(true)}
                  >
                    Add
                  </Button>
                )}
              </Stack>
            </Stack>
          }
        >
          <SitePanelCompartment
            site={siteDetails}
            onPanelDrawerChange={setShowPanelDrawer}
            canRemovePanels={sitePermissions?.canUpdate}
            showPanelDrawer={showPanelDrawer}
          />
        </Card>
        <Card
          title={
            <Stack gap={'apart'}>
              <Stack>Users</Stack>
              <Stack>
                {loadingPermissions && <LoadingOutlined />}
                {sitePermissions?.canUpdate && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setShowUserDrawer(true)}
                  >
                    Add
                  </Button>
                )}
              </Stack>
            </Stack>
          }
        >
          <SiteUserPanel
            site={siteDetails}
            canRemoveUsers={sitePermissions?.canUpdate}
            onUserDrawerChange={setShowUserDrawer}
            showUserDrawer={showUserDrawer}
          />
        </Card>
      </Stack>
    </PagePresenter>
  );
};

export { SiteDetails };
