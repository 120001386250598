import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCertificate,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import { StateIcon } from 'App/components/StateIcon';
import React from 'react';
import { SentryApiClient } from '_generated/api';

interface IProps {
  state?: SentryApiClient.SensorStateType;
}

const SensorStateIcon: React.FC<IProps> = ({ state }) => {
  let icon: IconDefinition;
  let color: string;
  let label: string;

  switch (state) {
    case SentryApiClient.SensorStateType.Empty:
      icon = faCertificate;
      color = 'var(--torq-experimental-success-color)';
      label = 'No Data';
      break;

    case SentryApiClient.SensorStateType.RedHigh:
      icon = faExclamationCircle;
      color = 'var(--torq-experimental-error-color)';
      label = 'High';
      break;

    case SentryApiClient.SensorStateType.AmberHigh:
      icon = faExclamationTriangle;
      color = 'var(--torq-experimental-warning-color)';
      label = 'High';
      break;

    case SentryApiClient.SensorStateType.Normal:
      icon = faCheckCircle;
      color = 'var(--torq-experimental-success-color)';
      label = 'Normal';
      break;

    case SentryApiClient.SensorStateType.AmberLow:
      icon = faExclamationTriangle;
      color = 'var(--torq-experimental-warning-color)';
      label = 'Low';
      break;

    case SentryApiClient.SensorStateType.RedLow:
      icon = faExclamationCircle;
      color = 'var(--torq-experimental-error-color)';
      label = 'Low';
      break;

    default:
    case SentryApiClient.SensorStateType.Offline:
      icon = faPowerOff;
      color = '#aaa';
      label = 'Offline';
      break;
  }
  return <StateIcon icon={icon} color={color} label={label} />;
};

export { SensorStateIcon };
