import { Stack } from '@torqit/torq-tools-react';
import moment from 'moment';
import React from 'react';
import {
  DATE_SLIDER_NOW,
  DATE_SLIDER_ONE_YEAR_AGO,
  getDateSliderItemSize,
} from './getDateSliderItemSize';
import styles from './DateSlider.module.css';

interface DateTickProps {
  tick: moment.Moment;
}

const DateTick: React.FC<DateTickProps> = ({ tick }) => {
  return (
    <Stack direction="vertical" alignment="middle">
      <div className={styles.tick}></div>
      <span>{tick.format('MMM')}</span>
    </Stack>
  );
};

const ticks = [
  moment().subtract(10, 'months').startOf('month'),
  moment().subtract(7, 'months').startOf('month'),
  moment().subtract(4, 'months').startOf('month'),
  moment().subtract(1, 'months').startOf('month'),
];

export interface DateSliderTicksProps {}

export const DateSliderTicks: React.FC<DateSliderTicksProps> = ({}) => {
  return (
    <Stack>
      <Stack.Item
        size={getDateSliderItemSize(
          DATE_SLIDER_ONE_YEAR_AGO,
          ticks[0].toDate()
        )}
      ></Stack.Item>
      <DateTick tick={ticks[0]} />
      <Stack.Item
        size={getDateSliderItemSize(ticks[0].toDate(), ticks[1].toDate())}
      ></Stack.Item>
      <DateTick tick={ticks[1]} />
      <Stack.Item
        size={getDateSliderItemSize(ticks[1].toDate(), ticks[2].toDate())}
      ></Stack.Item>
      <DateTick tick={ticks[2]} />
      <Stack.Item
        size={getDateSliderItemSize(ticks[2].toDate(), ticks[3].toDate())}
      ></Stack.Item>
      <DateTick tick={ticks[3]} />
      <Stack.Item
        size={getDateSliderItemSize(ticks[3].toDate(), DATE_SLIDER_NOW)}
      ></Stack.Item>
    </Stack>
  );
};
