import { Box, Stack } from '@torqit/torq-tools-react';
import { LetterIcon } from 'App/components/LetterIcon';
import { MultiSelectItemProps } from 'App/components/MultiSelectList';
import classNames from 'classnames';
import React from 'react';
import { getUserTypeName, UserType } from 'Users/userTypes';
import { SentryApiClient } from '_generated/api';
import styles from './SelectableUser.module.css';

interface SelectableUserProps
  extends MultiSelectItemProps<SentryApiClient.UserWithPermissionsDTO> {}

export const SelectableUser: React.FC<SelectableUserProps> = ({
  item,
  selected,
  disabled,
  isLastClicked,
}) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
        [styles.lastClicked]: isLastClicked,
      })}
    >
      <Stack>
        <Stack alignment="middle" gap={10}>
          <LetterIcon name={item.user?.displayName ?? ''} />
          <div>
            <div className={styles.displayName}>{item.user?.displayName}</div>
            <small>
              {getUserTypeName(item.user?.userTypeId ?? UserType.Customer)}
            </small>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};
