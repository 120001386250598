import { FilterConverter } from 'App/utils/FilterConverter';
import { SentryApiClient } from '_generated/api';

const companyFilterMap = new FilterConverter<
  SentryApiClient.CompanyDTO,
  SentryApiClient.CompanyFilters
>({
  name: SentryApiClient.CompanyFilters.Name,
  archived: SentryApiClient.CompanyFilters.Archived,
});

export { companyFilterMap };
