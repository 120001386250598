import { Button, ButtonProps } from '@torqit/torq-tools-react';
import React from 'react';
import { useBreakpoints } from '../Theme';

export interface MobileIconButtonProps extends Omit<ButtonProps, 'icon'> {
  //Make icon mandatory since that's the whole point
  icon: React.ReactNode;
}

export const MobileIconButton: React.FC<MobileIconButtonProps> = ({
  children,
  ...props
}) => {
  const { smd } = useBreakpoints();

  return <Button {...props}>{smd && children}</Button>;
};
