import { Button, Stack, Tooltip } from '@torqit/torq-tools-react';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import styles from './SiteDetailsSection.module.css';

export interface SiteDetailsSectionProps {
  site: SentryApiClient.SiteDTO;
}

export const SiteDetailsSection: React.FC<SiteDetailsSectionProps> = ({
  site,
}) => {
  return (
    <Stack fill alignment="middle" gap="md" stretch>
      <Stack.Item>
        <Stack direction="vertical" gap="apart" fill>
          <Stack direction="vertical" gap="md">
            <div className={styles.tagLine}>{site?.tagLine}</div>
            <div className={styles.details}>{site?.details}</div>
          </Stack>
          <div></div>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
