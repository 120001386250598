import { CloseOutlined } from '@ant-design/icons';
import {
  Button,
  FetchGridParameters,
  Stack,
  useAsyncDataCallback,
} from '@torqit/torq-tools-react';
import React, { useState } from 'react';
import { SiteTable } from 'Sites';
import { SentryApiClient } from '_generated/api';
import { useCompanySiteApi } from './useCompanySiteApi';

export interface CompanySiteListProps {
  companyId: number;
  sites?: SentryApiClient.SiteDTO[];
  params?: FetchGridParameters<SentryApiClient.SiteDTO>;
  total?: number;
  isLoading?: boolean;
  canRemoveSites?: boolean;
  onChange?: (params: FetchGridParameters<SentryApiClient.SiteDTO>) => void;
  onSitesRemoved?: () => void;
  onRemoveFailed?: (error: Error) => void;
}

export const CompanySiteList: React.FC<CompanySiteListProps> = ({
  sites,
  params,
  total,
  companyId,
  isLoading,
  canRemoveSites,
  onSitesRemoved,
  onRemoveFailed,
  onChange,
}) => {
  const [selection, setSelection] = useState<string[]>([]);
  const { removeSiteFromCompany } = useCompanySiteApi();

  const getRemoveButtonText = () => {
    if (selection.length === 0) {
      return 'Remove selection';
    } else if (selection.length === 1) {
      return 'Remove site';
    } else {
      return `Remove ${selection.length} sites`;
    }
  };
  const {
    executeRequest: removeSelectedSites,
    isLoading: removingSelectedSites,
  } = useAsyncDataCallback(
    () =>
      Promise.all(
        selection.map((s) =>
          removeSiteFromCompany(
            sites?.find((u) => u.id?.toString() === s.toString()),
            companyId
          )
        )
      ),
    [selection, companyId]
  );

  const removeSelectedSitesAndReload = async () => {
    try {
      await removeSelectedSites();
    } catch (error) {
      if (onRemoveFailed) {
        onRemoveFailed(error);
      }
    }

    if (onSitesRemoved) {
      onSitesRemoved();
    }

    setSelection([]);
  };

  return (
    <SiteTable
      disableFilterAndSort
      header={
        canRemoveSites && (
          <Stack>
            <Stack gap={20} alignment="middle">
              <Button
                danger
                disabled={selection.length === 0}
                icon={<CloseOutlined />}
                onClick={() => removeSelectedSitesAndReload()}
                loading={removingSelectedSites}
              >
                {getRemoveButtonText()}
              </Button>
            </Stack>
          </Stack>
        )
      }
      isSelectable={canRemoveSites}
      sites={sites}
      params={params}
      total={total}
      isLoading={isLoading}
      selected={selection}
      onChange={onChange}
      onSelect={(s) => {
        setSelection(s as string[]);
      }}
    />
  );
};
