export function buildFieldName(...args: (string | string[] | undefined)[]) {
  return args.reduce(
    (builtNamespace: string[], current) =>
      builtNamespace.concat(toStringArray(current)),
    []
  );
}

function toStringArray(value?: string | string[]) {
  if (value && Array.isArray(value)) return value;
  else if (value) return [value];

  return [];
}
