import { useApi } from 'App/hooks/useApi';
import { SentryApiClient } from '_generated/api';

export function useCompanyUserApi() {
  const { userApi } = useApi();

  return {
    removeUserFromCompany: async (
      user: SentryApiClient.UserDTO | undefined,
      companyId: number
    ) => {
      if (!user || !user.id || companyId === -1) {
        return;
      }

      user.companies = user.companies
        ? user.companies.filter((c) => c !== companyId)
        : [];

      await userApi.put(user.id, user);
    },

    addUserToCompany: async (
      user: SentryApiClient.UserDTO | undefined,
      companyId: number
    ) => {
      if (!user || !user.id || companyId === -1) {
        return;
      }

      user.companies = user.companies
        ? user.companies.concat([companyId])
        : [companyId];

      await userApi.put(user.id, user);
    },
  };
}
