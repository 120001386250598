import React, { useEffect } from 'react';

//Typescriptified version of https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=/src/OutsideAlerter.js&file=/src/OutsideAlerter.js
export function useClickOutsideEffect(
  ref: React.MutableRefObject<HTMLElement | null>,
  onClickOutside: () => void,
  dependencies?: React.DependencyList
) {
  const safeDependencies = dependencies ?? [];

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (!ref.current?.contains(event.target as Node)) {
        onClickOutside();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };

    //Disabling warning here because this is a custom effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, onClickOutside, ...safeDependencies]);
}
