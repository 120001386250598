import {
  Breadcrumb,
  Link,
  RouterLayoutProps,
  Stack,
} from '@torqit/torq-tools-react';
import { TokenForceRefresher, useUser } from 'Auth';
import React from 'react';
import styles from './DashboardLayout.module.css';
import '../../styles/dashboard.css';
import { BreadcrumbItem, useBreadcrumbs } from 'App/hooks/BreadcrumbsProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { UserDropdown } from 'App/components/UserDropdown';

export interface DashboardLayoutProps extends RouterLayoutProps {}

export const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  ...props
}) => {
  const user = useUser();
  const username = user?.name;

  const { breadcrumbs } = useBreadcrumbs();

  const isSensotraq = window.location.href.includes('sensotraq');

  // Temporary hack that displays the Ambev logo to certain users
  const renderCompanyLogo = () => {
    // Note that the username here will actually be the user's "Name" property within Azure AD B2C
    if (
      username?.toLowerCase().includes('colin ragush') ||
      username?.toLowerCase().includes('paqd test account') ||
      username?.toLowerCase().includes('leonardo francisco') ||
      username?.toLowerCase().includes('daniil gorbachev')
    ) {
      return <img src="/ambev.png" height={80} alt="Ambev logo" />;
    }

    return null;
  };

  const renderBreadcrumb = (item: BreadcrumbItem, index: number) => {
    if (item.loading) {
      return (
        <Breadcrumb.Item key={item.display + '_' + index}>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </Breadcrumb.Item>
      );
    }

    return (
      <Breadcrumb.Item key={item.display + '_' + index}>
        {item.linkTo ? (
          <Link to={item.linkTo}>{item.display}</Link>
        ) : (
          item.display
        )}
      </Breadcrumb.Item>
    );
  };

  return (
    <TokenForceRefresher>
      <div id="sentry-dashboard" className={styles.page}>
        <Stack direction="vertical" stretch fill>
          <div className={styles.header}>
            <Stack gap="apart">
              <Stack alignment="middle" gap="md">
                {!isSensotraq && (
                  <Link to="/dashboard">
                    <img src="/sentry-logo.png" height={26} alt="Sentry Logo" />
                  </Link>
                )}
                {isSensotraq && (
                  <img
                    src="/sensotraqWhite.png"
                    height={36}
                    alt="Sensotraq logo"
                  />
                )}
              </Stack>

              <Stack alignment="middle" gap={40}>
                <UserDropdown linkTo="backoffice" />
              </Stack>
            </Stack>
          </div>
          <div className={styles.breadcrumbsWrapper}>
            <Stack alignment="end">
              <Stack.Item>
                <Breadcrumb className={styles.breadcrumbs} separator=">">
                  <Breadcrumb.Item>
                    <Link to="/dashboard">Dashboard</Link>
                  </Breadcrumb.Item>
                  {breadcrumbs.map(renderBreadcrumb)}
                </Breadcrumb>
              </Stack.Item>
              {renderCompanyLogo()}
              <Stack.Item></Stack.Item>
            </Stack>
          </div>
          <Stack.Item>
            <div className={styles.body}>{children}</div>
          </Stack.Item>
        </Stack>
      </div>
    </TokenForceRefresher>
  );
};
