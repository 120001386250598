import React from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Router } from '@torqit/torq-tools-react';
import { pages } from '../pages/App.routes';
import { useAuthorization } from 'Auth';
import { useSentryUser } from 'Users/hooks/SentryUserProvider';
import { UserType } from 'Users/userTypes';
import { BACK_OFFICE_ROLE, SUPER_USER_ROLE } from 'Auth/roles';
import { SentryApiClient } from '_generated/api';

export const RouteProvider: React.FC = () => {
  const isAuthenticated = useIsAuthenticated();
  const [token] = useAuthorization();

  const { user } = useSentryUser();

  function getUserRoles(user: SentryApiClient.UserDTO): string[] {
    let roles: string[] = [];
    if (user.userTypeId === UserType.Administrator) {
      roles = roles.concat([SUPER_USER_ROLE]);
    }
    if (user.userTypeId < UserType.Customer) {
      roles = roles.concat([BACK_OFFICE_ROLE]);
    }
    return roles;
  }

  return (
    <Router
      routables={pages}
      isAuthenticated={isAuthenticated && token != null}
      roles={user && getUserRoles(user)}
    />
  );
};
