import {
  Card,
  ExperimentalForm,
  useAsyncData,
  useParams,
} from '@torqit/torq-tools-react';
import { FormNavigator } from 'App/components/FormHelpers';
import { PagePresenter, PageState } from 'App/components/PagePresenter';
import { useApi } from 'App/hooks/useApi';
import { CompanySelectOrCreateFields, NO_COMPANY_SELECTED } from 'Companies';
import React, { useMemo, useState } from 'react';
import { SiteSelectOrCreateFields } from 'Sites/forms/SiteSelectOrCreateFields';
import { PanelReview } from 'Panels/forms/PanelReview/PanelReview';
import {
  PanelAdoptionFormResults,
  useAdoptionCallback,
} from './useAdoptionCallback';

export interface PanelAdoptProps {}

const SITE_STEP = 0;
const COMPANY_STEP = 1;
const REVIEW_STEP = 2;

export const PanelAdopt: React.FC<PanelAdoptProps> = () => {
  const { panelApi } = useApi();
  const params = useParams<{ panel?: string }>();
  const panelId = params.panel != null ? parseInt(params.panel) : -1;
  const { data: adoptablePanel } = useAsyncData(
    () => panelApi.getById(panelId),
    [panelId, panelApi]
  );

  const [formStep, setFormStep] = useState(0);
  const [form] = ExperimentalForm.useForm<PanelAdoptionFormResults>();
  const onPrevious = () => {
    if (
      formStep === REVIEW_STEP &&
      form.getFieldsValue().company.id === NO_COMPANY_SELECTED
    ) {
      setFormStep(SITE_STEP);
    } else {
      setFormStep(formStep - 1);
    }
  };

  const [nextFormStep, setNextFormStep] = useState<number>();
  const getNextStep = () => {
    if (nextFormStep) {
      return nextFormStep;
    } else if (form.getFieldsValue()?.site?.company) {
      return REVIEW_STEP;
    } else {
      return formStep + 1;
    }
  };

  const [formProgress, setFormProgress] = useState(0);
  const onNext = () => {
    form.validateFields().then(() => {
      const targetStep = getNextStep();

      setFormStep(targetStep);
      setFormProgress(Math.max(targetStep, formProgress));

      //If we had a next step queued, it's definitely been consumed by now
      // so we'll just clear it.
      setNextFormStep(undefined);
    });
  };

  const onBackToSite = () => {
    setFormStep(SITE_STEP);
    setNextFormStep(REVIEW_STEP);
  };

  const onBackToCompany = () => {
    setFormStep(COMPANY_STEP);
    setNextFormStep(REVIEW_STEP);
  };

  const { data: panelDetails, isLoading, error } = useAsyncData(async () => {
    if (!panelId) {
      return undefined;
    }
    return panelApi.getById(panelId);
  }, [panelId, panelApi]);

  const { isAdopting, hasError, onFinish } = useAdoptionCallback(
    adoptablePanel
  );

  const pageState = useMemo((): PageState => {
    if (isLoading || isAdopting) return PageState.Loading;
    if (error || hasError) return PageState.Error;

    return PageState.Loaded;
  }, [isLoading, isAdopting, error, hasError]);

  return (
    <PagePresenter pageState={pageState}>
      <div>
        <Card title={`Adopt ${panelDetails?.name || 'Panel'}`}>
          <ExperimentalForm<PanelAdoptionFormResults>
            labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 12 },
              md: { span: 10 },
            }}
            form={form}
            name="config"
            initialValues={{
              panel: panelDetails,
            }}
            onFinish={onFinish}
            scrollToFirstError
          >
            <SiteSelectOrCreateFields
              visible={formStep === SITE_STEP}
              active={formProgress >= SITE_STEP}
              form={form}
              namespace={'site'}
            />
            <CompanySelectOrCreateFields
              visible={formStep === COMPANY_STEP}
              active={formProgress >= COMPANY_STEP}
              form={form}
              namespace={'company'}
              allowEmpty
            />
            <PanelReview
              active={formStep === REVIEW_STEP}
              panel={adoptablePanel}
              site={form.getFieldsValue()?.site}
              company={form.getFieldsValue()?.company}
              onBackToSite={onBackToSite}
              onBackToCompany={onBackToCompany}
            />
          </ExperimentalForm>
          <FormNavigator
            currentStep={formStep}
            steps={3}
            onPrevious={onPrevious}
            onNext={onNext}
            onSubmit={() => form.submit()}
          />
        </Card>
      </div>
    </PagePresenter>
  );
};
