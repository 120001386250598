import { DateServiceSingleton } from 'App/utils/DateService';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSensorReadings } from 'SensorReadings/hooks/useSensorReadings';
import { SensorRange } from 'SensorReadings/interfaces';
import { SentryApiClient } from '_generated/api';

const now = new Date();
const oneWeekAgo = moment(now).subtract(1, 'week').toDate();

function applyTimezone(
  range: SensorRange,
  timezone: string | number
): SensorRange {
  if (process.env.NODE_ENV === 'development') {
    return {
      ...range,
      from: DateServiceSingleton.__APPLY_TIMEZONE_WRONGLY(range.from, timezone),
      to: DateServiceSingleton.__APPLY_TIMEZONE_WRONGLY(range.to, timezone),
    };
  }

  return {
    ...range,
    from: DateServiceSingleton.ConvertToLocalTime(range.from, timezone),
    to: DateServiceSingleton.ConvertToLocalTime(range.to, timezone),
  };
}

export function useSensorRanges(
  sensorDetails?: SentryApiClient.SensorDetailsDTO,
  site?: SentryApiClient.SiteDTO
) {
  const [timezone, setTimezone] = useState<string>();
  const [primaryRange, setPrimaryRange] = useState<
    Omit<SensorRange, 'sensor'>
  >();

  useEffect(() => {
    // If set, we want to use the site's timezone when displaying sensor reading data. Otherwise, we'll use the user's local timezone.
    if (site && !timezone) {
      const targetTimezone = site.timezone ?? moment.tz.guess();

      setTimezone(targetTimezone);
      setPrimaryRange({
        from: DateServiceSingleton.ConvertToTimezoneTime(
          oneWeekAgo,
          targetTimezone
        ),
        to: DateServiceSingleton.ConvertToTimezoneTime(now, targetTimezone),
      });
    }
  }, [site, timezone]);

  const [comparisons, setComparisons] = useState<SensorRange[]>([]);
  const {
    readings,
    setRanges,
    resetSensorRanges,
    refreshEventLogs,
    flipYAxis,
  } = useSensorReadings();

  useEffect(() => {
    if (sensorDetails) {
      resetSensorRanges(sensorDetails);
    }
  }, [sensorDetails, resetSensorRanges]);

  const updateTimezone = useCallback(
    (nextTimezone: string) => {
      if (!timezone || !primaryRange) {
        return;
      }

      const timezoneChange =
        (DateServiceSingleton.GetRelativeTimezoneOffset(timezone) -
          DateServiceSingleton.GetRelativeTimezoneOffset(nextTimezone)) *
        1000 *
        60;

      setTimezone(nextTimezone);

      setPrimaryRange({
        from: new Date(primaryRange.from.getTime() + timezoneChange),
        to: new Date(primaryRange.to.getTime() + timezoneChange),
      });
      setComparisons(
        comparisons.map((c) => ({
          sensor: c.sensor,
          from: new Date(c.from.getTime() + timezoneChange),
          to: new Date(c.to.getTime() + timezoneChange),
        }))
      );
    },
    [timezone, primaryRange, comparisons]
  );

  const timezonedPrimaryRange = useMemo<
    { timezone?: string | number; range?: SensorRange } | undefined
  >(() => {
    if (!primaryRange || !sensorDetails) {
      return undefined;
    }

    return {
      range: timezone
        ? applyTimezone({ sensor: sensorDetails, ...primaryRange }, timezone)
        : undefined,
      timezone: timezone,
    };
  }, [sensorDetails, primaryRange, timezone]);

  const timezonedComparisons = useMemo(() => {
    return timezone
      ? comparisons.map((c) => applyTimezone(c, timezone))
      : undefined;
  }, [comparisons, timezone]);

  useEffect(() => {
    if (
      !timezonedPrimaryRange ||
      !timezonedPrimaryRange.range ||
      !timezonedPrimaryRange.timezone ||
      !timezonedComparisons
    ) {
      return;
    }

    const allRanges = [timezonedPrimaryRange.range].concat(
      timezonedComparisons
    );

    setRanges(allRanges, timezonedPrimaryRange.range.from);
  }, [timezonedPrimaryRange, timezonedComparisons, setRanges]);

  const onComparison = useCallback(
    (primary: SensorRange, comparisons: SensorRange[]) => {
      setPrimaryRange({ from: primary.from, to: primary.to });
      setComparisons(comparisons);
    },
    []
  );

  const focusOnTime = useCallback(
    (time: Date) => {
      if (!sensorDetails) {
        return;
      }

      onComparison(
        {
          sensor: sensorDetails,
          from: moment(time).subtract(3, 'days').subtract(12, 'hours').toDate(),
          to: moment(time).add(3, 'days').add(12, 'hours').toDate(),
        },
        []
      );
    },
    [sensorDetails, onComparison]
  );

  return {
    primaryRange,
    comparisons,
    timezone,
    updateTimezone,
    readings,
    setRanges: onComparison,
    refreshEventLogs,
    flipYAxis,
    focusOnTime,
  };
}
