import { Box, Link, Stack } from '@torqit/torq-tools-react';
import { LetterIcon } from 'App/components';
import classNames from 'classnames';
import React from 'react';
import { SiteStateIcon } from 'Sites/components/SiteStatusIcon';
import { SentryApiClient } from '_generated/api';
import styles from './CompanySiteDisplay.module.css';

export interface CompanySiteDisplayProps {
  site: SentryApiClient.SiteDTO;
}

export const CompanySiteDisplay: React.FC<CompanySiteDisplayProps> = ({
  site,
}) => {
  return (
    <Link to={`/dashboard/site/${site.id}`} className={styles.wrapper}>
      <Stack alignment="middle" gap="apart">
        <Stack alignment="middle" gap="xs">
          <LetterIcon name={site.name} />
          <Stack direction="vertical">
            <h3>{site.name}</h3>
            <div className={styles.subtle}>{site.tagLine}</div>
          </Stack>
        </Stack>
        <SiteStateIcon state={site.stateType} />
      </Stack>
      <Box padding={{ top: 'xl' }}>{site.details}</Box>
      <div className={classNames(styles.strapLine, styles.subtle)}>
        {site.strapLine}
      </div>
    </Link>
  );
};
