import { Box, Button, Stack } from '@torqit/torq-tools-react';
import { useBreakpoints } from 'App';
import React from 'react';

interface Preset {
  label: string;
  hours: number;
}

const presets: Preset[][] = [
  [
    { label: '1 hour', hours: 1 },
    { label: '3 hours', hours: 3 },
    { label: '6 hours', hours: 6 },
  ],
  [
    { label: '1 day', hours: 24 },
    { label: '3 days', hours: 24 * 3 },
  ],
  [
    { label: '1 week', hours: 24 * 7 },
    { label: '3 weeks', hours: 24 * 21 },
  ],
];

interface PresetButtonsProps {
  lastPresetClicked?: number;
  onClick?: (hours: number) => void;
}

export const PresetButtons: React.FC<PresetButtonsProps> = ({
  lastPresetClicked,
  onClick,
}) => {
  const { lg, xxl } = useBreakpoints();

  return (
    <Stack
      direction={xxl ? 'horizontal' : 'vertical'}
      alignment={lg ? 'middle' : 'start'}
      gap={xxl ? 'lg' : 'sm'}
    >
      <div>Show data from last:</div>
      {presets.map((group, index) => (
        <Stack key={index} gap="lg">
          {group.map((p) => (
            <Button
              key={p.hours}
              onClick={() => onClick && onClick(p.hours)}
              type={lastPresetClicked === p.hours ? 'primary' : 'default'}
            >
              {p.label}
            </Button>
          ))}
        </Stack>
      ))}
    </Stack>
  );
};
