import {
  faBell,
  faChartLine,
  faChevronUp,
  faCogs,
  faKey,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dropdown,
  Link,
  Menu,
  Skeleton,
  Stack,
  useLocation,
} from '@torqit/torq-tools-react';
import { LetterIcon, useBreakpoints } from 'App';
import { useUser } from 'Auth';
import { buildRedirectUri } from 'Auth/redirectUri';
import React, { useState } from 'react';
import { useSentryUser } from 'Users/hooks/SentryUserProvider';
import { UserType } from 'Users/userTypes';
import styles from './UserDropdown.module.css';

interface DropdownPanelProps {
  linkTo: 'frontoffice' | 'backoffice';
}

const DropdownPanel: React.FC<DropdownPanelProps> = ({ linkTo }) => {
  const { pathname, search } = useLocation();
  const { user } = useSentryUser();

  return (
    <Menu className={'menu'} selectedKeys={[]}>
      {linkTo === 'frontoffice' && (
        <Menu.Item key="switchLink" className={styles.userMenuItem}>
          <FontAwesomeIcon
            icon={faChartLine}
            size="lg"
            className={styles.userMenuItemIcon}
          />
          Front Office
          <Link to="/dashboard" />
        </Menu.Item>
      )}
      {linkTo === 'backoffice' &&
        user &&
        user.userTypeId !== UserType.Customer && (
          <Menu.Item key="switchLink" className={styles.userMenuItem}>
            <FontAwesomeIcon
              icon={faCogs}
              size="lg"
              className={styles.userMenuItemIcon}
            />
            Back Office
            <Link to="/" />
          </Menu.Item>
        )}
      <Menu.Item key="userNotifications" className={styles.userMenuItem}>
        <FontAwesomeIcon
          icon={faBell}
          size="lg"
          className={styles.userMenuItem}
        />
        User Notification Settings
        <Link
          to={
            user?.userTypeId === 5
              ? `/dashboard/user/${user?.id}/notifications`
              : `/user/${user?.id}/notifications`
          }
        />
      </Menu.Item>
      <Menu.Item key="resetPassword" className={styles.userMenuItem}>
        <FontAwesomeIcon
          icon={faKey}
          size="lg"
          className={styles.userMenuItemIcon}
        />
        Reset Password
        <Link to={buildRedirectUri('/reset-password', pathname, search)} />
      </Menu.Item>
      <Menu.Item key="logout" className={styles.userMenuItem}>
        <FontAwesomeIcon
          icon={faSignOutAlt}
          size="lg"
          className={styles.userMenuItemIcon}
        />
        Logout
        <Link to="/logout" />
      </Menu.Item>
    </Menu>
  );
};

export interface UserDropdownProps {
  linkTo: 'frontoffice' | 'backoffice';
}

export const UserDropdown: React.FC<UserDropdownProps> = ({ linkTo }) => {
  const user = useUser();
  const username = user?.name;

  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const { smd } = useBreakpoints();

  return (
    <Dropdown
      overlay={<DropdownPanel linkTo={linkTo} />}
      visible={username !== undefined && showUserDropdown}
      onVisibleChange={(v) => username && setShowUserDropdown(v)}
    >
      {/* This needs a div to wrap it since dropdown injects into its onHover which Stack doesn't have */}
      <div>
        <Stack className="userDropdown" gap={8} alignment="middle">
          <LetterIcon name={username ?? '??'} small />
          {!username && smd && <Skeleton />}
          {username && smd && (
            <span className={styles.userLabel}>{username}</span>
          )}
          <FontAwesomeIcon
            className={styles.smoothIcon}
            icon={faChevronUp}
            rotation={showUserDropdown ? 180 : undefined}
          />
        </Stack>
      </div>
    </Dropdown>
  );
};
