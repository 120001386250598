import { ButtonProps, Modal } from '@torqit/torq-tools-react';

export interface ConfirmDialogProps {
  title?: string;
  body: string;
  okButton: ButtonProps;
  open: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  title = 'Confirm Delete',
  body,
  okButton,
  onConfirm,
  onCancel,
  open,
}) => {
  return (
    <Modal
      title={title}
      visible={open}
      onOk={onConfirm}
      onCancel={onCancel}
      okText={okButton.children}
      okButtonProps={okButton}
    >
      <p>{body}</p>
    </Modal>
  );
};
