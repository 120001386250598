import {
  FetchGridParameters,
  FilteredTable,
  FilteredTableProps,
  Link,
  Stack,
  StringTableFilter,
  TableColumn,
} from '@torqit/torq-tools-react';
import { useColumns } from 'App';
import { LetterIcon } from 'App/components/LetterIcon';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'App/components/TableHelpers/defaultPageSettings';
import React from 'react';
import { SensorStateIcon } from 'Sensors/components/SensorStateIcon';
import { SentryApiClient } from '_generated/api';
import { PanelStateIcon } from '../PanelStateIcon';
import { PanelStateFilter } from 'App/components/SelectTableFilter/PanelStateFilter';

interface SensorLinkProps {
  sensor?: SentryApiClient.SensorDetailsDTO;
}

const SensorLink: React.FC<SensorLinkProps> = ({ sensor }) => {
  if (!sensor) {
    return null;
  }

  return (
    <Link to={`/sensor/${sensor.id}/details`}>
      <Stack gap="md">
        {sensor.sensorName}
        <SensorStateIcon state={sensor.stateType} />
      </Stack>
    </Link>
  );
};

const columns: TableColumn<SentryApiClient.PanelDTO>[] = [
  {
    title: 'Panel Name',
    field: 'name',
    width: 160,
    filter: StringTableFilter,
    sortable: true,
    render: (name: string, panel: SentryApiClient.PanelDTO) => {
      return (
        <Stack>
          <Stack alignment="middle" gap={10}>
            <Link to={`/panel/${panel?.id}/details`}>
              <LetterIcon name={name ?? ''} small /> {name}
            </Link>
          </Stack>
        </Stack>
      );
    },
  },
  {
    title: 'State',
    field: 'stateType',
    width: 140,
    sortable: true,
    filter: (props) => <PanelStateFilter props={props} />,
    render: (name, panel) => <PanelStateIcon state={panel.stateType} />,
  },
  {
    title: 'Last Connection Change',
    field: 'connectionStateTimestamp',
    key: 'connectionStateTimestamp',
    width: 100,
    filter: StringTableFilter,
    sortable: true,
    render: (date: string) => {
      if (!date) return '';
      const dateTime = new Date(date + 'Z');

      return (
        <Stack>
          <Stack alignment="middle" gap={10}>
            {dateTime.toLocaleString()}
          </Stack>
        </Stack>
      );
    },
  },
  {
    title: 'Last Activity',
    field: 'lastActivity',
    key: 'lastActivity',
    filter: StringTableFilter,
    sortable: true,
    width: 100,
    render: (date: string) => {
      if (!date) return '';
      const dateTime = new Date(date + 'Z');

      return (
        <Stack>
          <Stack alignment="middle" gap={10}>
            {dateTime.toLocaleString()}
          </Stack>
        </Stack>
      );
    },
  },
  {
    title: 'Hardware ID',
    field: 'hardwareId',
    width: 160,
    filter: StringTableFilter,
    sortable: true,
    render: (hardwareId: string, panel: SentryApiClient.PanelDTO) => {
      return (
        <Stack>
          <Stack alignment="middle" gap={10}>
            {hardwareId}
          </Stack>
        </Stack>
      );
    },
  },
  {
    title: 'Site',
    field: 'site',
    key: 'site',
    width: 75,
    render: (site: SentryApiClient.SiteDTO) => {
      return (
        <Stack>
          <Stack alignment="middle" gap={10}>
            <Link to={`/site/${site?.id}/details`}>{site?.name ?? ''}</Link>
          </Stack>
        </Stack>
      );
    },
  },
  {
    title: 'Sensors',
    key: 'sensors',
    width: 160,
    render: (name: string, panel) => {
      return (
        <Stack direction="vertical">
          <SensorLink sensor={panel.bankOneSensor} />
          <SensorLink sensor={panel.bankTwoSensor} />
          <SensorLink sensor={panel.bankThreeSensor} />
          <SensorLink sensor={panel.bankFourSensor} />
        </Stack>
      );
    },
  },
];

export interface PanelTableProps
  extends Omit<
    FilteredTableProps<SentryApiClient.PanelDTO>,
    'columns' | 'data' | 'rowKey' | 'pageSizeOptions'
  > {
  panels?: SentryApiClient.PanelDTO[];
  disableFilterAndSort?: boolean;
  canAdd?: boolean;
  includeSite?: boolean;
  onChange: (params: FetchGridParameters<SentryApiClient.PanelDTO>) => void;
}

export const PanelTable: React.FC<PanelTableProps> = ({
  panels,
  canAdd,
  disableFilterAndSort,
  includeSite,
  onChange,
  ...props
}) => {
  const resultingColumns = useColumns(
    includeSite ? columns : columns.filter((c) => c.key !== 'site'),
    disableFilterAndSort
  );
  return (
    <FilteredTable
      columns={resultingColumns}
      data={panels}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      rowKey={(p) => p.id ?? -1}
      onChange={onChange}
      {...props}
    />
  );
};
