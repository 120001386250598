import React from 'react';
import {
  Card,
  ExperimentalForm,
  Input,
  Link,
  Select,
  Stack,
  Switch,
  Tabs,
  TabsProps,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { buildFieldName } from 'Auth/buildFieldName';
import { Fieldset } from 'App/components/FormHelpers';
import { LetterIcon } from 'App/components/LetterIcon';
import { SentryApiClient } from '_generated/api';
import { useApi } from 'App/hooks/useApi';
import { getUserTypeName, UserType } from 'Users/userTypes';
import { useSentryUser } from 'Users/hooks/SentryUserProvider';

export type UserFormFieldsShape = SentryApiClient.UserDTO;

export interface UserFormFieldsProps {
  displayName: string | false;
  active: boolean;
  visible: boolean;
  disableUserSettings?: boolean;
  disableNotificationSettings?: boolean;
  namespace?: string | string[];
  userId?: string;
}

const userTypes = [
  UserType.Administrator,
  UserType.Distributor,
  UserType.ClientManager,
  UserType.CustomerManager,
  UserType.Customer,
];

export const EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

// TODO: allow selecting valid user types for given user
export const UserFormFields: React.FC<UserFormFieldsProps> = ({
  displayName,
  active,
  disableUserSettings = false,
  disableNotificationSettings = false,
  visible,
  namespace,
  userId,
}) => {
  const { userApi } = useApi();
  const { user: sentryUser } = useSentryUser();
  const {
    data: userPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(
    () => userApi.getUserPermissions(sentryUser?.id ?? EMPTY_GUID),
    [userApi, sentryUser]
  );

  return (
    <Fieldset id="company" active={active} visible={visible}>
      {displayName && (
        <Stack direction="vertical" alignment="middle" gap={20}>
          <LetterIcon name={displayName} />
          <Stack.Item />
        </Stack>
      )}
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'displayName')}
        label={<span>Full Name</span>}
        hidden={disableUserSettings}
        rules={[
          {
            required: true,
            message: 'Please provide your full name',
          },
        ]}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'email')}
        label={<span>Email</span>}
        hidden={disableUserSettings}
        rules={[
          {
            type: 'email',
            message: 'Please enter a valid email address',
          },
          {
            required: true,
            message: 'Please enter an email address',
          },
          {
            validator: async (rule, value) => {
              const usersWithSameEmail = await userApi.get(
                null,
                null,
                undefined,
                null,
                [
                  {
                    field: SentryApiClient.UserFilters.Email,
                    operator: SentryApiClient.Operator.Equals,
                    value: value as string,
                  },
                  {
                    field: SentryApiClient.UserFilters.Id,
                    operator: SentryApiClient.Operator.NotEquals,
                    value: userId ? userId.toString() : EMPTY_GUID,
                  },
                ]
              );

              if (
                usersWithSameEmail.items &&
                usersWithSameEmail.items.length > 0
              ) {
                throw new Error('This email is taken');
              }
            },
          },
        ]}
        validateTrigger="onBlur"
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'phoneNumber')}
        label={<span>Phone Number</span>}
        rules={[
          {
            validator: async (rule, value) => {
              const telRegExp = /\+\d{10,15}/; //'+d{10,15}';
              var re = new RegExp(telRegExp);
              if (
                !re.test(value) &&
                value !== null &&
                value !== undefined &&
                value !== ''
              ) {
                throw new Error(
                  'Invalid phone number format. Use the +12341234 format'
                );
              }
            },
          },
        ]}
        validateTrigger="onBlur"
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'username')}
        label={
          <span>
            Username <em>(optional)</em>
          </span>
        }
        hidden={disableUserSettings}
        rules={[
          {
            validator: async (rule, value) => {
              if (!value) {
                return;
              }

              const usersWithSameUsername = await userApi.get(
                null,
                null,
                undefined,
                null,
                [
                  {
                    field: SentryApiClient.UserFilters.Username,
                    operator: SentryApiClient.Operator.Equals,
                    value: value as string,
                  },
                  {
                    field: SentryApiClient.UserFilters.Id,
                    operator: SentryApiClient.Operator.NotEquals,
                    value: userId ? userId.toString() : EMPTY_GUID,
                  },
                ]
              );

              if (
                usersWithSameUsername.items &&
                usersWithSameUsername.items.length > 0
              ) {
                throw new Error('This username is taken');
              }
            },
          },
        ]}
        validateTrigger="onBlur"
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'userTypeId')}
        label={<span>User Type</span>}
        hidden={disableUserSettings}
        initialValue={5}
      >
        <Select
          disabled={
            sentryUser?.userTypeId !== UserType.Administrator &&
            userId === sentryUser?.id
          }
        >
          {userTypes
            .filter((u) => userPermissions?.allowedUserTypes?.includes(u))
            .map((u) => (
              <Select.Option key={u} value={u}>
                {getUserTypeName(u)}
              </Select.Option>
            ))}
        </Select>
      </ExperimentalForm.Item>
      <Stack alignment={'middle'} direction={'vertical'}>
        By enabling SMS notifications, you authorize Sentry to send text
        messages. Message/data rates apply.{' '}
        <Link to={'/terms-conditions'} target={'_blank'}>
          Terms and Conditions
        </Link>
      </Stack>
      <Tabs centered={true}>
        <Tabs.TabPane tab="Email Notifications" key="1">
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'offlineAlertsEnabled')}
            label={<span>Offline</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'redHighAlertsEnabled')}
            label={<span>Red High</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'redLowAlertsEnabled')}
            label={<span>Red Low</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'amberHighAlertsEnabled')}
            label={<span>Amber High</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'amberLowAlertsEnabled')}
            label={<span>Amber Low</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'eventLogCreatedAlertsEnabled')}
            label={<span>Event Log Created</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab="SMS Notifications" key="2">
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'smsOfflineAlertsEnabled')}
            label={<span>Offline</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'smsRedHighAlertsEnabled')}
            label={<span>Red High</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'smsRedLowAlertsEnabled')}
            label={<span>Red Low</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'smsAmberHighAlertsEnabled')}
            label={<span>Amber High</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'smsAmberLowAlertsEnabled')}
            label={<span>Amber Low</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'smsEventLogCreatedAlertsEnabled')}
            label={<span>Event Log Created</span>}
            hidden={disableNotificationSettings}
            initialValue={true}
            valuePropName="checked" // weirdly this is the way to get the initial state to be checked (initialValue={true} does not work)
          >
            <Switch></Switch>
          </ExperimentalForm.Item>
        </Tabs.TabPane>
      </Tabs>
    </Fieldset>
  );
};

export default UserFormFields;
