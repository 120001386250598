import { CustomLayerProps } from '@nivo/line';
import React, { useMemo } from 'react';
import { ChartDataProps } from '../SensorReadingsGraph/Charts/LineChart';
import { EventLogIndicator } from './EventLogIndicator';
import { binarySearchLowerBound } from 'App/utils/binarySearch';
import { DateServiceSingleton } from 'App/utils/DateService';

export interface NoteLayerProps extends CustomLayerProps {
  chartData?: ChartDataProps[];
  timezone: string;
  enable?: boolean;
}

export const NoteLayer: React.FC<NoteLayerProps> = ({
  xScale,
  yScale,
  chartData,
  timezone,
  enable = true,
}) => {
  //Every example just shows this thing being used as a function
  // and it 100% works if I do that too;
  const xScaleFunc = (xScale as any) as (scale: Date) => number;
  const yScaleFunc = (yScale as any) as (scale: number) => number;

  const eventLogSets = useMemo(
    () =>
      chartData?.map((r) =>
        r.events
          ?.map((e) => {
            const targetDate = DateServiceSingleton.ConvertToTimezoneTime(
              new Date(e.timestamp + 'Z'),
              timezone
            );

            //In order to know where to vertically place our event log
            // note, we're going to need to find the closest reading
            // and use that as a reference
            const reading = binarySearchLowerBound(
              r.data,
              { actualDate: targetDate },
              (c) => c.actualDate as Date
            );

            if (!reading) {
              return undefined;
            }

            return {
              eventLog: e,
              x: xScaleFunc(reading.x as Date),
              y: yScaleFunc((reading.y as number) ?? 0),
            };
          })
          .filter((f) => f != null)
      ),
    [chartData, timezone, xScaleFunc, yScaleFunc]
  );

  //Based on how this is implemented, it's easier to just pass a flag to
  // disable this component than to not render it (which would require
  // juggling arrays and stuff)
  if (!enable) {
    return null;
  }
  return (
    <g>
      {eventLogSets?.map((set) =>
        set?.map((e) => {
          return (
            <EventLogIndicator
              key={e!.eventLog.id}
              eventLog={e!.eventLog}
              x={e!.x}
              y={e!.y}
            />
          );
        })
      )}
    </g>
  );
};
