import { SentryApiClient } from '_generated/api';
import { SelectTableFilter } from './SelectTableFilter';
import { TableFilterProps } from '@torqit/torq-tools-react';

interface IProps {
  props: TableFilterProps;
}

export const BooleanFilter: React.FC<IProps> = ({ props }) => {
  return (
    <SelectTableFilter
      {...props}
      options={[
        {
          text: 'True',
          value: 'True',
        },
        {
          text: 'False',
          value: 'False',
        },
      ]}
    />
  );
};
