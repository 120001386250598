import { UndoOutlined } from '@ant-design/icons';
import { Button, Card, Stack } from '@torqit/torq-tools-react';
import { FormReviewItem } from 'App/components/FormHelpers';
import React from 'react';
import { SentryApiClient } from '_generated/api';

export interface PanelFieldsReviewProps {
  canUpdatePanel?: boolean;
  panel?: SentryApiClient.PanelDTO;
  onBackToPanel?: () => void;
}

export const PanelFieldsReview: React.FC<PanelFieldsReviewProps> = ({
  canUpdatePanel,
  panel,
  onBackToPanel,
}) => {
  return (
    <Card
      title={
        <Stack alignment="middle" gap="apart">
          {!panel?.id ? 'New Panel' : 'Existing Panel'}
          {canUpdatePanel && (
            <Button
              type="primary"
              icon={<UndoOutlined />}
              onClick={onBackToPanel}
            >
              Update
            </Button>
          )}
        </Stack>
      }
    >
      <FormReviewItem field="Panel Name" value={panel?.name} />
      <FormReviewItem field="Hardware ID" value={panel?.hardwareId} />
    </Card>
  );
};
