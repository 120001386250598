import { ExperimentalForm, Input, Select } from '@torqit/torq-tools-react';
import { Col, DatePicker, Row } from 'antd';
import { FormInstance } from 'antd/es/form';
import { Fieldset } from 'App/components/FormHelpers';
import { DateServiceSingleton } from 'App/utils/DateService';
import { buildFieldName } from 'Auth/buildFieldName';
import { FormEventLog } from 'EventLogs/components/EventLogCreate/useCreateEventLog';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { SiteTimezoneSwitch } from 'Sites/components/SiteTimezoneSwitch';

export interface EventLogFormFieldsProps {
  active: boolean;
  visible: boolean;
  isUsingSiteTimezone: boolean;
  form: FormInstance<FormEventLog>;
  timezone: string;
  namespace?: string | string[];
  onUseSiteTimezone?: (useSiteTimezone: boolean) => void;
}

export const EventLogFormFields: React.FC<EventLogFormFieldsProps> = ({
  active,
  visible,
  form,
  timezone,
  namespace,
  isUsingSiteTimezone,
  onUseSiteTimezone,
}) => {
  const sensorIdField = useMemo(() => buildFieldName(namespace, 'sensorIds'), [
    namespace,
  ]);
  const timestampField = useMemo(() => buildFieldName(namespace, 'timestamp'), [
    namespace,
  ]);

  useEffect(() => {
    const currentTimestamp = form.getFieldValue(
      timestampField
    ) as moment.Moment;

    if (isUsingSiteTimezone) {
      form.setFields([
        {
          name: timestampField,
          value: moment(
            DateServiceSingleton.ConvertToTimezoneTime(
              currentTimestamp.toDate(),
              timezone
            )
          ),
        },
      ]);
    } else {
      form.setFields([
        {
          name: timestampField,
          value: moment(
            DateServiceSingleton.ConvertToLocalTime(
              currentTimestamp.toDate(),
              timezone
            )
          ),
        },
      ]);
    }
  }, [isUsingSiteTimezone, timezone, timestampField, form]);

  return (
    <Fieldset id="sensors" active={active} visible={visible}>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'notes')}
        extra={<span>Notes</span>}
        rules={[
          {
            required: true,
            message: 'Note must be between 1 and 1000 characters',
            max: 1000,
          },
        ]}
      >
        <Input.TextArea />
      </ExperimentalForm.Item>
      <Row>
        <Col sm={13}>
          <ExperimentalForm.Item
            name={timestampField}
            initialValue={moment()}
            extra={<span>Timestamp</span>}
          >
            <DatePicker showTime />
          </ExperimentalForm.Item>
        </Col>
        <Col sm={11}>
          <div style={{ marginTop: 2, marginLeft: 8 }}>
            <SiteTimezoneSwitch
              checked={isUsingSiteTimezone}
              onChange={onUseSiteTimezone}
            />
          </div>
        </Col>
      </Row>
      <ExperimentalForm.Item hidden name={sensorIdField}>
        <Select mode="multiple" />
      </ExperimentalForm.Item>
    </Fieldset>
  );
};
