import { useEffect } from 'react';
import { useSentrySplash } from '.';

export function useImmediateSplashHiderEffect() {
  const { hideSplashScreen } = useSentrySplash();

  useEffect(() => {
    hideSplashScreen();
  }, [hideSplashScreen]);
}
