import { CheckOutlined } from '@ant-design/icons';
import { Box, Button, ExperimentalForm } from '@torqit/torq-tools-react';
import { MultiSensorEventLogFields } from 'EventLogs';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { useCreateEventLog } from './useCreateEventLog';

export interface MultiSensorEventLogCreateProps {
  availableSensors: SentryApiClient.SensorDTO[];
  timezone: string;
  onCreate?: (eventLog: SentryApiClient.EventLogDTO) => void;
  onError?: (error: Error) => void;
}

export const MultiSensorEventLogCreate: React.VFC<MultiSensorEventLogCreateProps> = ({
  availableSensors,
  timezone,
  onCreate,
  onError,
}) => {
  const [isSiteTimezone, setIsSiteTimezone] = useState(true);

  const { createEventLog, isCreating, form } = useCreateEventLog(
    isSiteTimezone ? timezone : undefined,
    onCreate,
    onError
  );

  const [selectedSensorIds, setSelectedSensorIds] = useState<number[]>([]);

  return (
    <ExperimentalForm
      wrapperCol={{
        xs: { span: 24 },
      }}
      form={form}
      name="config"
      onFinish={createEventLog}
      scrollToFirstError
    >
      <MultiSensorEventLogFields
        visible
        active
        availableSensors={availableSensors}
        selectedSensorIds={selectedSensorIds}
        onSelectSensorIds={setSelectedSensorIds}
        form={form}
        timezone={timezone}
        isUsingSiteTimezone={isSiteTimezone}
        onUseSiteTimezone={setIsSiteTimezone}
      />
      <Box padding={{ top: 'sm' }}>
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={form.submit}
          loading={isCreating}
          disabled={selectedSensorIds.length == 0}
        >
          Submit
        </Button>
      </Box>
    </ExperimentalForm>
  );
};
