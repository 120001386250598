import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Stack } from '@torqit/torq-tools-react';
import { AnnouncementForm } from 'Announcements/components/AnnouncementForm';

export const Announcements: React.FC = () => {
  return (
    <>
      <Card
        title={
          <Stack gap={'apart'}>
            <Stack>Announcements</Stack>
          </Stack>
        }
      >
        <AnnouncementForm />
      </Card>
    </>
  );
};
