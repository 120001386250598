import {
  ExperimentalForm,
  FormInstance,
  Input,
} from '@torqit/torq-tools-react';
import { Fieldset } from 'App/components/FormHelpers';
import { buildFieldName } from 'Auth/buildFieldName';
import React from 'react';
import styles from './PanelFormFields.module.css';

export interface PanelFormFieldsProps {
  name?: string;
  active: boolean;
  visible: boolean;
  form: FormInstance<any>;
  namespace?: string | string[];
}

export const PanelFormFields: React.FC<PanelFormFieldsProps> = ({
  name,
  active,
  visible,
  form,
  namespace,
}) => {
  return (
    <Fieldset id={styles.panel} active={active} visible={visible}>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'name')}
        label={<span>Panel Name</span>}
        rules={[
          {
            required: true,
            message: 'Please add a panel name',
          },
        ]}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'hardwareId')}
        label={<span>Hardware ID</span>}
        rules={[
          {
            required: false,
            message:
              'Please provide the hardware ID for this panel, or let the panel phone home...',
          },
        ]}
      >
        <Input disabled={true} />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        hidden
        name={buildFieldName(namespace, 'id')}
      ></ExperimentalForm.Item>
      <ExperimentalForm.Item
        hidden
        name={buildFieldName(namespace, 'siteId')}
      ></ExperimentalForm.Item>
      <ExperimentalForm.Item
        hidden
        name={buildFieldName(namespace, 'bankOneSensor')}
      ></ExperimentalForm.Item>
      <ExperimentalForm.Item
        hidden
        name={buildFieldName(namespace, 'bankTwoSensor')}
      ></ExperimentalForm.Item>
      <ExperimentalForm.Item
        hidden
        name={buildFieldName(namespace, 'bankThreeSensor')}
      ></ExperimentalForm.Item>
      <ExperimentalForm.Item
        hidden
        name={buildFieldName(namespace, 'bankFourSensor')}
      ></ExperimentalForm.Item>
    </Fieldset>
  );
};
