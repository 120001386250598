import { UndoOutlined } from '@ant-design/icons';
import { Button, Card, Stack } from '@torqit/torq-tools-react';
import { AddressDisplay } from 'Address/AddressDisplay';
import { FormReviewItem } from 'App/components/FormHelpers';
import React from 'react';
import { CREATED_SITE } from 'Sites/forms/SiteSelectOrCreateFields';
import { SentryApiClient } from '_generated/api';

export interface SiteFieldsReviewProps {
  site?: SentryApiClient.SiteDTO;
  onBackToSite?: () => void;
}

export const SiteFieldsReview: React.FC<SiteFieldsReviewProps> = ({
  site,
  onBackToSite,
}) => {
  return (
    <Card
      title={
        <Stack alignment="middle" gap="apart">
          {!site?.id || site?.id === CREATED_SITE
            ? 'New Site'
            : 'Existing Site'}
          <Button type="primary" icon={<UndoOutlined />} onClick={onBackToSite}>
            Update
          </Button>
        </Stack>
      }
    >
      <FormReviewItem field="Name" value={site?.name} />
      <FormReviewItem field="Tag Line" value={site?.tagLine} />
      <FormReviewItem field="Strap Line" value={site?.strapLine} />
      <FormReviewItem field="Details" value={site?.details} />
      <FormReviewItem field="Timezone" value={site?.timezone} />
      <FormReviewItem
        field="Address"
        value={<AddressDisplay address={site?.address} />}
      />
    </Card>
  );
};
