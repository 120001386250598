import {
  FilteredTable,
  FilteredTableProps,
  Link,
  Stack,
  StringTableFilter,
  TableColumn,
} from '@torqit/torq-tools-react';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'App/components/TableHelpers/defaultPageSettings';
import { LetterIcon } from 'App/components/LetterIcon';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import { getSensorTypeName, SensorType } from '../../sensorTypes';
import { SelectTableFilter } from 'App/components/SelectTableFilter/SelectTableFilter';
import { SensorStateIcon } from '../SensorStateIcon/SensorStateIcon';
import { SensorStateFilter } from 'App/components/SelectTableFilter/SensorStateFilter';

const columns: TableColumn<SentryApiClient.SensorDetailsDTO>[] = [
  {
    title: 'State',
    field: 'stateType',
    sortable: true,
    filter: (props) => <SensorStateFilter props={props} />,
    render: (name, sensor) => {
      return <SensorStateIcon state={sensor.stateType} />;
    },
  },
  {
    title: 'Name',
    field: 'sensorName',
    filter: StringTableFilter,
    sortable: true,
    render: (name, sensor) => (
      <Link to={`/sensor/${sensor.id}/details`}>
        <Stack>
          <Stack alignment="middle" gap={10}>
            {name && <LetterIcon name={name} small />}
            {name}
          </Stack>
        </Stack>
      </Link>
    ),
  },
  {
    title: 'Panel',
    field: 'parentPanel',
    sortable: true,
    render: (name, sensor) => {
      return (
        <Stack direction="vertical">
          <Stack alignment="middle" gap={10}>
            {renderPanelLink(sensor)}
          </Stack>
        </Stack>
      );
    },
  },
  {
    field: 'sensorType',
    title: 'Type',
    filter: (props) => (
      <SelectTableFilter
        {...props}
        options={[{ text: 'Sentry Sensor', value: SensorType.Sentry }]}
      />
    ),
    render: getSensorTypeName,
  },
];

function renderPanelLink(sensor: SentryApiClient.SensorDetailsDTO) {
  if (sensor.parentPanel) {
    return (
      <Link to={`/panel/${sensor?.parentPanel?.id}/details`}>
        {sensor?.parentPanel?.name} ({sensor?.parentPanel?.hardwareId})
      </Link>
    );
  } else {
    return 'No Panel';
  }
}

export interface SensorTableProps
  extends Omit<
    FilteredTableProps<SentryApiClient.SensorDetailsDTO>,
    'columns' | 'data' | 'rowKey' | 'pageSizeOptions'
  > {
  sensors?: SentryApiClient.SensorDetailsDTO[];
}

export const SensorTable: React.FC<SensorTableProps> = ({
  sensors,
  ...props
}) => {
  return (
    <FilteredTable
      columns={columns}
      data={sensors}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      rowKey={(s) => s.id ?? -1}
      {...props}
    />
  );
};
