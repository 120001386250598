import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { EditOutlined, DeleteFilled, LoadingOutlined } from '@ant-design/icons';
import {
  useParams,
  useAsyncData,
  useHistory,
  useAsyncDataCallback,
  FetchGridParameters,
  Stack,
  Card,
  Drawer,
  Box,
  useLocalStorage,
} from '@torqit/torq-tools-react';
import {
  useApi,
  DEFAULT_PAGE_PARAMS,
  useBreakpoints,
  PageState,
  PagePresenter,
  MobileIconButton,
  ConfirmDialog,
} from 'App';
import {
  SensorReadingsCard,
  SensorEventLogCard,
  CalculationTooltip,
} from 'Sensors';
import { SentryApiClient } from '_generated/api';
import { SensorEdit } from '../SensorEdit';
import { useSensorRanges } from 'Sensors/hooks/useSensorRanges';
import styles from './SensorDetails.module.css';
import {
  CollapsibleCard,
  HorizontalCollapsibleCard,
} from 'App/components/CollapsibleCard';
import { SensorStateTable } from 'SensorStates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { redAndAmberFilters } from 'App/utils/redAndAmberFilters';
import { ChartPoint, ChartClickerProvider } from 'SensorReadings';

export interface SensorDetailsProps {}

const now = new Date();
const oneDayAgo = moment(now).subtract(1, 'day').toDate();
const oneWeekAgo = moment(now).subtract(1, 'week').toDate();

const SENSOR_STATE_TABLE_WIDTH = 660;

export const SensorDetails: React.FC<SensorDetailsProps> = ({}) => {
  const { sensorApi, sensorStateApi, siteApi, eventLogApi } = useApi();
  const params = useParams<{ sensorId?: string }>();
  const sensorId = (params.sensorId && parseInt(params.sensorId)) || -1;
  const [confirmDelete, setConfirmDelete] = useState(false);

  const {
    data: sensorDetails,
    isLoading: isLoadingSensor,
    error: sensorError,
    executeRequest: reloadSensors,
  } = useAsyncData(async () => sensorApi.getById(sensorId), [
    sensorId,
    sensorApi,
  ]);
  const { data: sensorSite } = useAsyncData(
    async () =>
      sensorDetails?.siteId ? siteApi.getById(sensorDetails.siteId) : undefined,
    [sensorDetails, siteApi]
  );

  const {
    data: sensorPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(
    async () => (sensorId ? sensorApi.getUserPermissions(sensorId) : undefined),
    [sensorApi, sensorId]
  );

  const {
    primaryRange,
    comparisons,
    timezone,
    updateTimezone,
    readings,
    setRanges,
    refreshEventLogs: reloadChartEventLogs,
    flipYAxis,
    focusOnTime,
  } = useSensorRanges(sensorDetails, sensorSite);

  const [clickedPoint, setClickedPoint] = useState<ChartPoint>();

  const { push } = useHistory();
  const {
    executeRequest: deleteSensor,
    isLoading: isDeleting,
    error: deleteError,
  } = useAsyncDataCallback(() => sensorApi.delete(sensorId), [
    sensorId,
    sensorApi,
  ]);
  const deleteAndRedirect = async () => {
    await deleteSensor();
    setConfirmDelete(false);
    push('/sensors');
  };

  const [eventLogParams, setEventLogParams] = useState<
    FetchGridParameters<SentryApiClient.EventLogDTO>
  >(DEFAULT_PAGE_PARAMS);
  const {
    data: eventLogsData,
    isLoading: loadingEventLogs,
    error: eventLogsError,
    executeRequest: reloadEventLogsTable,
  } = useAsyncData(
    () =>
      sensorApi.getEventLogs(
        sensorId,
        eventLogParams.pageSize,
        eventLogParams.page,
        SentryApiClient.EventLogFilters.Timestamp,
        SentryApiClient.OrderDirection.Descending
      ),
    [sensorId, eventLogParams, sensorApi]
  );

  const firstEventLogId =
    eventLogsData?.items && eventLogsData?.items.length !== 0
      ? eventLogsData?.items[0].id!
      : 0;
  const {
    data: eventLogPermissions,
    isLoading: eventLogPermissionsLoading,
  } = useAsyncData(
    async () =>
      loadingEventLogs
        ? undefined
        : eventLogApi.getUserPermissions(firstEventLogId, sensorId),
    [loadingEventLogs, eventLogApi, firstEventLogId, sensorId]
  );

  const reloadEventLogs = useCallback(() => {
    reloadChartEventLogs();
    reloadEventLogsTable();
  }, [reloadChartEventLogs, reloadEventLogsTable]);

  const [showStates, setShowStates] = useState(false);
  const [sensorStatesPageParams, setSensorStatesPageParams] = useState<
    FetchGridParameters<SentryApiClient.SensorStateDTO>
  >(DEFAULT_PAGE_PARAMS);
  const {
    data: sensorStatesData,
    isLoading: loadingSensorStates,
    error: sensorStatesError,
  } = useAsyncData(
    async () =>
      sensorDetails && sensorDetails.id
        ? sensorStateApi.get(
            sensorStatesPageParams.pageSize,
            sensorStatesPageParams.page,
            SentryApiClient.SensorStateFilters.StartTime,
            SentryApiClient.OrderDirection.Descending,
            [
              {
                field: SentryApiClient.SensorStateFilters.SensorId,
                operator: SentryApiClient.Operator.Equals,
                value: sensorDetails.id.toString(),
              },
              ...redAndAmberFilters,
            ]
          )
        : undefined,
    [sensorDetails, sensorStatesPageParams, sensorStateApi]
  );

  const { smd, xxl } = useBreakpoints();
  const [showEdit, setShowEdit] = useState(false);

  //By not setting a page number or page size when grabbing a list of site sensors
  // we are indeed grabbing the first 1000 sensors belonging to the site, but
  // this should never be a problem as no site should ever come even close to
  // having that many sensors
  const { data: siteSensors } = useAsyncData(
    async () =>
      sensorDetails?.siteId
        ? sensorApi.get(
            500,
            null,
            SentryApiClient.SensorFilters.SensorName,
            SentryApiClient.OrderDirection.Ascending,
            [
              {
                field: SentryApiClient.SensorFilters.SiteId,
                operator: SentryApiClient.Operator.Equals,
                value: sensorDetails.siteId.toString(),
              },
            ]
          )
        : undefined,
    [sensorDetails, sensorApi]
  );

  const [editError, setEditError] = useState<Error>();
  const getPageState = () => {
    if (isLoadingSensor || isDeleting) {
      return PageState.Loading;
    } else if (sensorError || editError || deleteError || eventLogsError) {
      return PageState.Error;
    }

    return PageState.Loaded;
  };

  const getSensorType = () => {
    switch (sensorDetails?.sensorType) {
      case 1:
        return 'Sentry';
      default:
        return 'Unknown';
    }
  };

  const eventLogsCardNode = (
    <SensorEventLogCard
      sensorDetails={sensorDetails}
      sensorId={sensorId}
      eventLogs={eventLogsData?.items}
      totalEventLogs={eventLogsData?.totalItems}
      eventLogParams={eventLogParams}
      permissionsLoading={eventLogPermissionsLoading}
      timezone={timezone ?? moment.tz.guess()}
      loadingEventLogs={loadingEventLogs}
      addableSensors={siteSensors?.items ?? []}
      createEventLogTimestamp={clickedPoint?.date}
      canCreate={eventLogPermissions?.canCreate}
      onError={setEditError}
      onUpdate={reloadEventLogs}
      onEventLogParamsChange={setEventLogParams}
      onClose={() => setClickedPoint(undefined)}
      onShowOnChartClicked={focusOnTime}
    />
  );

  const statusTableNode = (
    <SensorStateTable
      sensorStates={sensorStatesData?.items}
      total={sensorStatesData?.totalItems}
      isLoading={loadingSensorStates}
      params={sensorStatesPageParams}
      onChange={setSensorStatesPageParams}
      timezone={timezone ?? moment.tz.guess()}
      height={500}
    />
  );

  const { sm, md, lg } = useBreakpoints();

  const mobileStyling = {
    margin: {
      marginBottom: sm ? '0' : 'var(--torq-experimental-rhythm-sm)',
    },
  };

  return (
    <>
      <PagePresenter pageState={getPageState()}>
        <Stack direction="vertical" gap={20} stretch>
          <ConfirmDialog
            body={`Are you sure you want to delete ${sensorDetails?.sensorName?.toString()}?`}
            onConfirm={deleteAndRedirect}
            onCancel={() => setConfirmDelete(false)}
            open={confirmDelete}
            okButton={{ danger: true, children: 'Delete' }}
          />
          <Card
            title={
              <Stack
                alignment={md ? 'middle' : 'start'}
                gap="apart"
                direction={sm ? 'horizontal' : 'vertical'}
              >
                <Stack
                  direction={lg ? 'horizontal' : 'vertical'}
                  style={mobileStyling.margin}
                >
                  {sensorDetails?.sensorName} Details - Type: {getSensorType()}{' '}
                  {sensorDetails?.description && (
                    <Box padding={{ left: 'sm' }}>
                      •{' '}
                      <span className={styles.description}>
                        {sensorDetails?.description}
                      </span>
                    </Box>
                  )}
                </Stack>
                <Stack gap={8}>
                  {permissionsLoading && <LoadingOutlined />}
                  {sensorPermissions?.canUpdate && (
                    <MobileIconButton
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => setShowEdit(true)}
                    >
                      Edit
                    </MobileIconButton>
                  )}
                </Stack>
              </Stack>
            }
            bodyStyle={{ padding: 0 }}
          ></Card>
          {sensorDetails && primaryRange && (
            <ChartClickerProvider
              point={clickedPoint}
              onClick={(point) =>
                eventLogPermissions?.canCreate
                  ? setClickedPoint(point)
                  : undefined
              }
            >
              <SensorReadingsCard
                title={
                  <Stack>
                    Telemetry{' '}
                    <CalculationTooltip
                      calculation={sensorDetails.calculation}
                    />
                  </Stack>
                }
                startDate={primaryRange?.from ?? oneWeekAgo}
                endDate={primaryRange?.to ?? now}
                targetRange={{ sensor: sensorDetails, ...primaryRange }}
                comparisons={comparisons}
                timezone={timezone}
                comparableSensors={siteSensors?.items ?? []}
                readings={readings}
                enableImport={sensorPermissions?.canUpdate}
                onComparison={setRanges}
                onTimezoneChange={updateTimezone}
                onFlipAxis={flipYAxis}
              />
            </ChartClickerProvider>
          )}
          {xxl && (
            <Stack
              direction="horizontal"
              alignment="middle"
              stretch
              fill
              gap="md"
              style={{ overflowX: 'hidden' }}
            >
              {/* Unfortunately, ant tables do not play nicely with pure flex. Rather than
            shrinking and growing like any normal div, ant tables only grow and then
            refuse to shrink in a flex container. To make this work, we need to be explicit
            with our table widths */}
              <div
                style={{
                  width: `calc(100% - ${
                    showStates ? SENSOR_STATE_TABLE_WIDTH + 60 : 32
                  }px)`,
                  transition: '0.4s',
                }}
              >
                {eventLogsCardNode}
              </div>
              <HorizontalCollapsibleCard
                openWidth={SENSOR_STATE_TABLE_WIDTH}
                sider={
                  <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
                }
                siderTooltip="Sensor State History"
                openLeft
                isOpen={showStates}
                onChange={setShowStates}
                style={{ height: '100%' }}
              >
                {statusTableNode}
              </HorizontalCollapsibleCard>
            </Stack>
          )}
          {!xxl && (
            <>
              {eventLogsCardNode}
              <CollapsibleCard title="Sensor State History" defaultHidden>
                {statusTableNode}
              </CollapsibleCard>
            </>
          )}
        </Stack>
      </PagePresenter>
      <Drawer
        visible={showEdit}
        onClose={() => setShowEdit(false)}
        title={
          <Stack direction="horizontal" gap={10}>
            <Stack.Item>Edit Sensor</Stack.Item>
            <Stack.Item>
              {sensorPermissions?.canDelete && (
                <MobileIconButton
                  type="primary"
                  danger
                  icon={<DeleteFilled />}
                  onClick={() => setConfirmDelete(true)}
                >
                  Delete
                </MobileIconButton>
              )}
            </Stack.Item>
          </Stack>
        }
        width={smd ? 400 : 300}
      >
        {sensorDetails && (
          <SensorEdit
            sensor={sensorDetails}
            onEdit={() => {
              setShowEdit(false);
              reloadSensors();
            }}
            onError={setEditError}
          />
        )}
      </Drawer>
    </>
  );
};
