import { PlusOutlined } from '@ant-design/icons';
import { Button, Select, Stack } from '@torqit/torq-tools-react';
import { fullWidthStyles } from 'App';
import React from 'react';
import { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { SensorLabel } from './SensorLabel';

export interface SensorSelectorProps {
  comparableSensors?: SentryApiClient.SensorDTO[];
  onSelect?: (sensor: SentryApiClient.SensorDTO) => void;
}

export const SensorSelector: React.FC<SensorSelectorProps> = ({
  comparableSensors,
  onSelect,
}) => {
  const [isSelecting, setSelecting] = useState(false);

  const selectSensor = (id: number) => {
    const sensor = comparableSensors?.find((s) => s.id === id);

    if (sensor) {
      onSelect && onSelect(sensor);
      setSelecting(false);
    }
  };
  return (
    <>
      {!isSelecting && (
        <div>
          <Button icon={<PlusOutlined />} onClick={() => setSelecting(true)}>
            Add Sensor
          </Button>
        </div>
      )}
      {isSelecting && (
        <Stack alignment="middle" gap="xs">
          <div>Compare to:</div>
          <Stack.Item>
            <Select<number>
              showSearch
              className={fullWidthStyles.fullWidth}
              placeholder="Select a sensor..."
              onChange={(v) => selectSensor(v)}
              optionFilterProp={'label'}
            >
              {comparableSensors?.map((s) => (
                <Select.Option
                  key={s.id}
                  value={s.id ?? -1}
                  label={s.sensorName}
                >
                  <SensorLabel sensor={s} />
                </Select.Option>
              ))}
            </Select>
          </Stack.Item>
        </Stack>
      )}
    </>
  );
};
