import { SentryApiClient } from '_generated/api';

type OnUpdateListener = (readings: SentryApiClient.SensorReadingDTO[]) => void;
type OnFinishListener = () => void;

interface StoredReading {
  reading: SentryApiClient.SensorReadingDTO;
  rendered: boolean;
}

interface RenderedReading {
  reading: SentryApiClient.SensorReadingDTO;
  index: number;
}

function sleep(millis: number) {
  return new Promise((resolve) => setTimeout(resolve, millis));
}

export class SharpenRenderer {
  private onUpdate?: OnUpdateListener;
  private onFinish?: OnFinishListener;

  private input: StoredReading[] = [];
  private output: RenderedReading[] = [];

  private active = false;
  private stopped = false;

  private readonly maxStep: number;
  private readonly readingsPerFrame: number;

  public constructor(maxStep: number, readingsPerFrame: number) {
    this.maxStep = maxStep;
    this.readingsPerFrame = readingsPerFrame;
  }

  public setOnUpdate(listener: OnUpdateListener) {
    this.onUpdate = listener;
  }

  public setOnFinish(listener: OnFinishListener) {
    this.onFinish = listener;
  }

  public append(readings: SentryApiClient.SensorReadingDTO[]) {
    this.input = this.input.concat(
      readings.map((r) => ({ reading: r, rendered: false }))
    );

    this.checkForNullReadings();

    if (!this.active) {
      this.sharpen();
    }
  }

  private checkForNullReadings() {
    const nullReadings: RenderedReading[] = [];

    this.input.forEach((r, index) => {
      if (r.reading.rawValue == null && !r.rendered) {
        r.rendered = true;
        nullReadings.push({ reading: r.reading, index });
      }
    });

    this.insertReadings(nullReadings);
  }

  private async sharpen() {
    this.active = true;

    while (this.input.some((r) => !r.rendered) && !this.stopped) {
      await sleep(1);

      const readingsToAdd: RenderedReading[] = [];

      for (
        let j = 0;
        j < this.readingsPerFrame && this.input.some((r) => !r.rendered);
        j++
      ) {
        const gap = this.findLargestUnrenderedGap();
        const targetIndex = Math.min(
          gap.start + this.maxStep,
          Math.floor((gap.start + gap.end) / 2)
        );

        this.input[targetIndex].rendered = true;
        readingsToAdd.push({
          reading: this.input[targetIndex].reading,
          index: targetIndex,
        });
      }

      this.insertReadings(readingsToAdd);
    }

    this.active = false;
  }

  private findLargestUnrenderedGap() {
    let start = -1;
    let end = -1;
    let currentStart = -1;

    for (let j = 0; j < this.input.length; j++) {
      if (!this.input[j].rendered) {
        if (currentStart === -1) {
          currentStart = j;
        }
      } else {
        if (currentStart !== -1) {
          if (j - currentStart > end - start) {
            start = currentStart;
            end = j;
          }

          currentStart = -1;
        }
      }
    }

    if (currentStart !== -1 && this.input.length - currentStart > end - start) {
      start = currentStart;
      end = this.input.length;
    }

    return { start, end };
  }

  private insertReadings(readings: RenderedReading[]) {
    if (this.stopped) {
      return;
    }

    readings.forEach((readingToInsert) => {
      for (let j = 0; j < this.output.length; j++) {
        if (readingToInsert.index < this.output[j].index) {
          this.output.splice(j, 0, readingToInsert);
          return;
        }
      }

      this.output.push(readingToInsert);
    });

    this.onUpdate && this.onUpdate(this.output.map((r) => r.reading));

    if (this.input.every((r) => r.rendered)) {
      this.onFinish && this.onFinish();
    }
  }

  public stop() {
    this.stopped = true;
  }
}
