import React from 'react';
import { UndoOutlined } from '@ant-design/icons';
import { Button, Card, Stack } from '@torqit/torq-tools-react';
import { UserFormFieldsShape } from '../UserFormFields';
import { FormReviewItem } from 'App/components/FormHelpers';
import { getUserTypeName, UserType } from 'Users/userTypes';

export interface UserFieldsReviewProps {
  user?: UserFormFieldsShape;
  onBackToUser?: () => void;
}

export const UserFieldsReview: React.FC<UserFieldsReviewProps> = ({
  user,
  onBackToUser,
}) => {
  return (
    <Card
      title={
        <Stack alignment="middle" gap="apart">
          {!user?.id || user?.id === 'CREATE' ? 'New user' : 'Existing user'}
          <Button type="primary" icon={<UndoOutlined />} onClick={onBackToUser}>
            Update
          </Button>
        </Stack>
      }
    >
      <FormReviewItem field="Name" value={user?.displayName} />
      <FormReviewItem field="Email" value={user?.email} />
      <FormReviewItem field="Phone Number" value={user?.phoneNumber} />
      <FormReviewItem field="Username" value={user?.username} />
      <FormReviewItem
        field="Type"
        value={getUserTypeName(user?.userTypeId ?? UserType.Customer)}
      />
    </Card>
  );
};
