import { useMemo } from 'react';
import { SentryApiClient } from '_generated/api';

export const useConfigClient = () => {
  const apiHost = window.location.protocol + '//' + window.location.host;
  const configClient = useMemo(
    () => new SentryApiClient.ConfigurationClient(apiHost),
    [apiHost]
  );

  return configClient;
};
