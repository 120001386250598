import { useApi } from 'App';
import { useEffect, useRef, useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { MiniChartReadingFetcher } from './MiniChartReadingFetcher';

export function useMiniChartFetcher(sensor: SentryApiClient.SensorDetailsDTO) {
  const { sensorReadingApi } = useApi();
  const [readings, setReadings] = useState<SentryApiClient.SensorReadingDTO[]>(
    []
  );
  const [isLoading, setLoading] = useState(false);
  const fetcherRef = useRef<MiniChartReadingFetcher>();

  useEffect(() => {
    fetcherRef.current = new MiniChartReadingFetcher(sensor, sensorReadingApi);
    fetcherRef.current.setOnFinish((r) => {
      setReadings(r);
      setLoading(false);
    });

    fetcherRef.current.fetch();
    setLoading(true);

    return () => fetcherRef.current?.removeOnFinish();
  }, [sensor, sensorReadingApi]);

  return { readings, isLoading };
}
