import { PlusOutlined } from '@ant-design/icons';
import { Box, Button } from '@torqit/torq-tools-react';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import { SelectableSensor } from './SelectableSensor';

export interface SingleSensorWithPromptProps {
  targetSensor: SentryApiClient.SensorDTO;
  onAddPressed: () => void;
}

export const SingleSensorWithPrompt: React.FC<SingleSensorWithPromptProps> = ({
  targetSensor,
  onAddPressed,
}) => {
  return (
    <div>
      <SelectableSensor
        item={targetSensor}
        selected={false}
        disabled={false}
        isLastClicked={false}
      />
      <div className="ant-form-item-extra">Sensor</div>
      <Box padding={{ vertical: 'sm' }}>
        <Button icon={<PlusOutlined />} onClick={onAddPressed}>
          Add More Sensors
        </Button>
      </Box>
    </div>
  );
};
