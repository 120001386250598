import { CloseOutlined, SwapRightOutlined } from '@ant-design/icons';
import { Button, Card, Stack } from '@torqit/torq-tools-react';
import React from 'react';
import { SensorLabel } from './SensorLabel';
import { DatePicker } from 'antd';
import { SentryApiClient } from '_generated/api';
import moment from 'moment';

interface ComparisonCardProps {
  sensor: SentryApiClient.SensorDTO;
  timespan: number;
  start: moment.Moment | null;
  onChange?: (start: moment.Moment | null) => void;
  onRemove?: () => void;
}

export const ComparisonCard: React.FC<ComparisonCardProps> = ({
  sensor,
  timespan,
  start,
  onRemove,
  onChange,
}) => {
  const end = start?.clone().add(timespan, 'millisecond');

  return (
    <Card
      title={
        <Stack alignment="middle" gap="apart">
          <SensorLabel sensor={sensor} />

          <Button
            type="text"
            icon={<CloseOutlined />}
            onClick={onRemove}
          ></Button>
        </Stack>
      }
    >
      <Stack>
        <Stack gap="sm" alignment="middle">
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            value={start}
            onChange={onChange}
          />
          <SwapRightOutlined />
          <DatePicker
            showTime
            format="YYYY-MM-DD HH:mm"
            value={end}
            onChange={(value) =>
              onChange && onChange(value?.clone().subtract(timespan) ?? null)
            }
          />
        </Stack>
      </Stack>
    </Card>
  );
};
