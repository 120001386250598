import { useAuthentication } from 'Auth/useAuthentication';
import React, { useEffect } from 'react';
import { useLocation } from '@torqit/torq-tools-react';
import { AUTH_REDIRECT_DELAY } from './constants';
import { SentryLoader } from 'App/components/SentryLoader';
import { getRedirectUri } from 'Auth/redirectUri';

export interface ResetPasswordProps {}

export const ResetPassword: React.FC<ResetPasswordProps> = () => {
  const { search } = useLocation();
  const { resetPassword } = useAuthentication(getRedirectUri(search) ?? '/');

  useEffect(() => {
    const timer = setTimeout(resetPassword, AUTH_REDIRECT_DELAY);
    return () => clearTimeout(timer);
  }, [resetPassword]);

  return <SentryLoader big />;
};
