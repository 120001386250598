import {
  FilteredTable,
  FilteredTableProps,
  Link,
  Stack,
  StringTableFilter,
  TableColumn,
} from '@torqit/torq-tools-react';
import { AddressDisplay } from 'Address/AddressDisplay';
import { DEFAULT_PAGE_SIZE_OPTIONS } from 'App/components/TableHelpers/defaultPageSettings';
import { LetterIcon } from 'App/components/LetterIcon';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import { useMemo } from 'react';
import { useColumns } from 'App';
import { BooleanFilter } from 'App/components/SelectTableFilter/BooleanFilter';

const columns: TableColumn<SentryApiClient.CompanyDTO>[] = [
  {
    title: 'Company Name',
    field: 'name',
    filter: StringTableFilter,
    sortable: true,
    render: (name: string, company) => {
      return (
        <Stack>
          <Link to={`/company/${company.id}/details`}>
            <Stack alignment="middle" gap={10}>
              <LetterIcon name={name} small />
              {name}
            </Stack>
          </Link>
        </Stack>
      );
    },
  },
  {
    title: 'Address',
    field: 'address',
    render: (address: SentryApiClient.AddressDTO) => {
      return <AddressDisplay address={address} compact />;
    },
  },
  {
    title: 'Archived',
    field: 'archived',
    filter: (props) => <BooleanFilter props={props} />,
    sortable: true,
    render: (archived: boolean) => {
      return archived ? 'True' : 'False';
    },
  },
];

export interface CompanyTableProps
  extends Omit<
    FilteredTableProps<SentryApiClient.CompanyDTO>,
    'columns' | 'data' | 'rowKey' | 'pageSizeOptions'
  > {
  companies?: SentryApiClient.CompanyDTO[];
  disableFilterAndSort?: boolean;
  canAdd?: boolean;
}

export const CompanyTable: React.FC<CompanyTableProps> = ({
  companies,
  disableFilterAndSort,
  canAdd,
  ...props
}) => {
  const resultingColumns = useColumns(columns, disableFilterAndSort);

  return (
    <FilteredTable
      columns={resultingColumns}
      data={companies}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      rowKey={(s) => s.id ?? -1}
      {...props}
    />
  );
};
