import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  FetchGridParameters,
  notification,
  Stack,
  TableColumn,
  useAsyncDataCallback,
} from '@torqit/torq-tools-react';
import { DEFAULT_PAGE_PARAMS } from 'App/components/TableHelpers/defaultPageSettings';
import React, { useState } from 'react';
import { UserTable } from 'Users';
import { SentryApiClient } from '_generated/api';
import { useCompanyUserApi } from './useCompanyUserApi';

export interface CompanyUserListProps {
  companyId: number;
  users?: SentryApiClient.UserDTO[];
  isLoading?: boolean;
  canRemoveUsers?: boolean;
  onUsersRemoved?: () => void;
  onRemoveFailed?: (error: Error) => void;
}

export const CompanyUserList: React.FC<CompanyUserListProps> = ({
  users,
  companyId,
  isLoading,
  canRemoveUsers,
  onUsersRemoved,
  onRemoveFailed,
}) => {
  const [selection, setSelection] = useState<string[]>([]);
  const { removeUserFromCompany } = useCompanyUserApi();

  const getRemoveButtonText = () => {
    if (selection.length === 0) {
      return 'Remove selection';
    } else if (selection.length === 1) {
      return 'Remove user';
    } else {
      return `Remove ${selection.length} users`;
    }
  };
  const {
    executeRequest: removeSelectedUsers,
    isLoading: removingSelectedUsers,
  } = useAsyncDataCallback(
    () =>
      Promise.all(
        selection.map((s) =>
          removeUserFromCompany(
            users?.find((u) => u.id === s),
            companyId
          )
        )
      ),
    [selection, companyId]
  );

  const removeSelectedUsersAndReload = async () => {
    try {
      if (
        selection
          .map((s) => users?.find((u) => u.id === s))
          .some((u) => u?.companies?.length === 1)
      ) {
        notification.error({
          message:
            'Cannot remove. One or more selected users would be orphaned if removed from this company.',
        });
      } else {
        await removeSelectedUsers();
      }
    } catch (error) {
      if (onRemoveFailed) {
        onRemoveFailed(error);
      }
    }

    if (onUsersRemoved) {
      onUsersRemoved();
    }

    setSelection([]);
  };

  const [params, setParams] = useState<
    FetchGridParameters<SentryApiClient.UserDTO>
  >(DEFAULT_PAGE_PARAMS);

  return (
    <UserTable
      disableFilterAndSort
      header={
        canRemoveUsers && (
          <Stack>
            <Stack gap={20} alignment="middle">
              <Button
                danger
                disabled={selection.length === 0}
                icon={<CloseOutlined />}
                onClick={() => removeSelectedUsersAndReload()}
                loading={removingSelectedUsers}
              >
                {getRemoveButtonText()}
              </Button>
            </Stack>
          </Stack>
        )
      }
      isSelectable={canRemoveUsers}
      params={params}
      users={users}
      isLoading={isLoading}
      onChange={setParams}
      selected={selection}
      onSelect={(s) => {
        setSelection(s as string[]);
      }}
    />
  );
};
