import React, { useState } from 'react';
import {
  Affix,
  Card,
  ExperimentalForm,
  Stack,
  useHistory,
} from '@torqit/torq-tools-react';
import { FormNavigator } from 'App/components/FormHelpers';
import { PagePresenter, PageState } from 'App/components/PagePresenter';
import { useApi } from 'App/hooks/useApi';
import {
  CompanySelectOrCreateFields,
  CREATED_COMPANY,
  NO_COMPANY_SELECTED,
} from 'Companies';
import { SentryApiClient } from '_generated/api';
import { SiteFormFields } from '../../forms/SiteFormFields/SiteFormFields';
import { CreateSiteReview } from './CreateSiteReview';

interface FormResults {
  site: SentryApiClient.SiteDTO;
  company: SentryApiClient.CompanyDTO;
}

export interface SiteCreateProps {
  company?: Required<SentryApiClient.CompanyDTO> | boolean;
}

export const SiteCreate: React.FC<SiteCreateProps> = ({ company }) => {
  const [formStep, setFormStep] = useState(0);
  const onPrevious = () => {
    if (formStep === 2 && company !== true) {
      setFormStep(0);
    } else {
      setFormStep(formStep - 1);
    }
  };

  const [nextFormStep, setNextFormStep] = useState<number>();
  const getNextStep = () => {
    if (nextFormStep) {
      return nextFormStep;
    } else if (formStep === 0 && company !== true) {
      return 2;
    } else {
      return formStep + 1;
    }
  };

  const [form] = ExperimentalForm.useForm<FormResults>();
  const [formProgress, setFormProgress] = useState(0);
  const onNext = () => {
    form.validateFields().then(() => {
      const targetStep = getNextStep();

      setFormStep(targetStep);
      setFormProgress(Math.max(targetStep, formProgress));

      //If we had a next step queued, it's definitely been consumed by now
      // so we'll just clear it.
      setNextFormStep(undefined);
    });
  };

  const onBackToSite = () => {
    setFormStep(0);
    setNextFormStep(2);
  };

  const onBackToCompany = () => {
    setFormStep(1);
    setNextFormStep(2);
  };

  const [pageState, setPageState] = useState<PageState>(PageState.Loaded);
  const { companyApi, siteApi } = useApi();
  const router = useHistory();
  const onFinish = async (result: FormResults) => {
    let site;
    let company: SentryApiClient.CompanyDTO | undefined = undefined;

    setPageState(PageState.Loading);

    try {
      const companyPayload = {
        ...result.company,
        name: result.company.name,
      };

      if (!companyPayload.id || companyPayload.id === CREATED_COMPANY) {
        companyPayload.id = undefined;
        company = await companyApi.post(companyPayload);
      } else if (
        companyPayload.id &&
        companyPayload.id !== NO_COMPANY_SELECTED
      ) {
        company = await companyApi.put(companyPayload.id, companyPayload);
      }

      result.site.users = result.site.sensors = result.site.panels = [];

      result.site.company = company?.id;
      site = await siteApi.post(result.site);

      router.push(`/site/${site.id}/details`);
    } catch (error) {
      setPageState(PageState.Error);

      if (site) await siteApi.delete(site.id as number);
      if (company && result.company.id === null)
        await companyApi.delete(company.id as number);
    }
  };

  const [currentName, setCurrentName] = useState('Example Site');
  const onChange = (_v: any, result: FormResults) => {
    setCurrentName(result?.site?.name || '');
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack direction="vertical" gap={10} stretch>
        <Card title={'New Site'}>
          <ExperimentalForm<FormResults>
            labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 12 },
              md: { span: 10 },
            }}
            form={form}
            name="config"
            initialValues={{ company }}
            onValuesChange={onChange}
            onFinish={onFinish}
            scrollToFirstError
          >
            <SiteFormFields
              visible={formStep === 0}
              active /* Always active */
              namespace={'site'}
              form={form}
              name={currentName}
            />
            <CompanySelectOrCreateFields
              visible={formStep === 1}
              active={formProgress >= 1}
              form={form}
              namespace={'company'}
              allowEmpty
            />
            <CreateSiteReview
              active={formStep === 2}
              site={form.getFieldsValue()?.site}
              company={form.getFieldsValue()?.company}
              onBackToSite={onBackToSite}
              onBackToCompany={onBackToCompany}
            />
          </ExperimentalForm>
        </Card>
        <Affix offsetBottom={0}>
          <Card>
            <FormNavigator
              currentStep={formStep}
              steps={3}
              onPrevious={onPrevious}
              onNext={onNext}
              onSubmit={() => form.submit()}
            />
          </Card>
        </Affix>
      </Stack>
    </PagePresenter>
  );
};
