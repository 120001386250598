import React, { useCallback, useMemo } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { CommonLineChartProps } from '../CommonLineChartProps';
import { calculateTimeSeriesTicks } from '../calculateTimeSeriesTicks';
import { TimeSeriesLineChartProps } from '../LineChart';
import { TimeSeriesLineTooltip } from '../SensorChartTooltip/TimeSeriesLineTooltip';
import { DateServiceSingleton } from 'App/utils/DateService';
import { BandLayer } from '../../../BandLayer/BandLayer';
import { ClickedNoteLayer } from 'SensorReadings/components/ClickedNoteLayer';
import { NoteLayer } from 'SensorReadings/components/NoteLayer';

export const ScatterPlotChart: React.FC<TimeSeriesLineChartProps> = ({
  yLabel,
  yUnits,
  xLabel,
  xUnits,
  lines,
  leftYMin,
  leftYMax,
  rightYMin,
  rightYMax,
  earliestDate,
  latestDate,
  timezone,
  bands,
  showEventLogs,
}) => {
  const colors = lines.map((l) => {
    return l.color;
  });

  const rightAxisFormatter = useCallback(
    (value: number) => {
      if (!rightYMin || !rightYMax) {
        return 0;
      }

      const ratio = (rightYMax - rightYMin) / (leftYMax - leftYMin);
      return Math.round((value - leftYMin) * ratio + rightYMin);
    },
    [leftYMin, leftYMax, rightYMin, rightYMax]
  );

  return (
    <ResponsiveLine
      {...CommonLineChartProps}
      data={lines}
      colors={colors}
      xFormat={(data: any) => {
        return DateServiceSingleton.GetDateTime(
          data,
          false,
          'DD/MM/YYYY h:mm A ZZ'
        );
      }}
      lineWidth={0}
      axisBottom={{
        ...calculateTimeSeriesTicks(earliestDate, latestDate),
        legend: xLabel,
        legendOffset: 40,
        legendPosition: 'middle',
      }}
      yScale={{
        type: 'linear',
        min: leftYMin,
        max: leftYMax,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: yLabel,
        legendOffset: -50,
        legendPosition: 'middle',
      }}
      axisRight={
        rightYMin !== undefined &&
        rightYMax !== undefined &&
        rightYMin !== 0 &&
        rightYMax !== 0
          ? {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: yLabel,
              legendOffset: 50,
              legendPosition: 'middle',
              format: rightAxisFormatter,
            }
          : undefined
      }
      animate={false}
      pointBorderWidth={0.8}
      pointSize={0.8}
      sliceTooltip={({ slice }) => {
        return (
          <TimeSeriesLineTooltip
            points={slice.points}
            dateOffsets={lines.map((l) => ({ id: l.id, offset: l.dateOffset }))}
            yUnit={yUnits}
          />
        );
      }}
      enableGridX={false}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        'crosshair',
        'lines',
        'slices',
        'points',
        (props) => (
          <BandLayer
            redHigh={bands?.redHigh}
            amberHigh={bands?.amberHigh}
            amberLow={bands?.amberLow}
            redLow={bands?.redLow}
            {...props}
          />
        ),
        (props) => (
          <NoteLayer
            chartData={lines}
            timezone={timezone}
            enable={showEventLogs}
            {...props}
          />
        ),
        (props) => (
          <ClickedNoteLayer chartData={lines} timezone={timezone} {...props} />
        ),
        'legends',
      ]}
    />
  );
};
