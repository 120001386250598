import { FolderOpenFilled } from '@ant-design/icons';
import {
  Box,
  Drawer,
  Input,
  Pagination,
  Result,
  Stack,
} from '@torqit/torq-tools-react';
import { MultiSelectList } from 'App/components/MultiSelectList';
import { MultiSelectListProps } from 'App/components/MultiSelectList/MultiSelectList';
import { SentryLoader } from 'App/components/SentryLoader';
import React from 'react';

export interface EntitySelectorProps<Entity>
  extends MultiSelectListProps<Entity> {
  page: number;
  pageSize: number;
  total?: number;
  footer?: React.ReactNode;
  search?: string;
  isLoading?: boolean;
  title?: React.ReactNode;
  visible?: boolean;
  emptyMessage?: string;

  onPageChange?: (page: number) => void;
  onSearch?: (search?: string) => void;
  onClose?: () => void;
}

export const EntitySelector = <Entity extends object>({
  page,
  pageSize,
  total,
  footer,
  search,
  isLoading,
  onPageChange,
  onSearch,
  title,
  emptyMessage,
  visible,
  onClose,
  data,
  ...multiSelectProps
}: EntitySelectorProps<Entity>) => {
  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      title={
        <Stack direction="vertical" stretch gap="sm">
          {title}
          <Input.Search
            placeholder="Search..."
            value={search}
            onChange={(v) => onSearch && onSearch(v.currentTarget.value)}
          />
        </Stack>
      }
      width={460}
      footer={
        <Stack gap="apart">
          <div>{footer}</div>
          <Pagination
            current={page}
            pageSize={pageSize}
            showSizeChanger={false}
            total={total}
            onChange={(p) => onPageChange && onPageChange(p)}
            size="small"
          />
        </Stack>
      }
    >
      <Stack direction="vertical" stretch gap="md">
        {isLoading && (
          <Stack alignment="middle">
            <Stack>
              <Box padding="xl">
                <SentryLoader />
              </Box>
            </Stack>
          </Stack>
        )}
        {!isLoading && data.length !== 0 && (
          <MultiSelectList {...multiSelectProps} data={data} />
        )}
        {!isLoading && data.length === 0 && (
          <Result
            icon={<FolderOpenFilled style={{ color: '#ccc' }} />}
            subTitle={emptyMessage ?? 'No results'}
          ></Result>
        )}
      </Stack>
    </Drawer>
  );
};
