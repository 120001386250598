import { notification } from 'antd';
import Loader from 'react-spinners/BarLoader';
import { SensorRange } from 'SensorReadings/interfaces';
import { SentryApiClient } from '_generated/api';
import { SensorReadingsRangeLoader } from './SensorReadingsRangeLoader';
import { SensorReadingsRangeData } from './useSensorReadings';

type OnChangeListener = (readings: SensorReadingsRangeData[]) => void;

export class SensorReadingsManager {
  private sensorReadings: SentryApiClient.SensorReadingsClient;
  private sensors: SentryApiClient.SensorsClient;

  private rangeLoaders: SensorReadingsRangeLoader[] = [];

  private onChangeListener?: OnChangeListener;

  private primaryDate = new Date();

  public constructor(
    readingsApi: SentryApiClient.SensorReadingsClient,
    sensorsApi: SentryApiClient.SensorsClient
  ) {
    this.sensorReadings = readingsApi;
    this.sensors = sensorsApi;
  }

  public setOnChangeListener(listener: OnChangeListener) {
    this.onChangeListener = listener;
  }

  public removeOnChangeListener() {
    this.onChangeListener = undefined;
  }

  public stopAll() {
    this.rangeLoaders.forEach((l) => l.stop());
  }

  public set(ranges: SensorRange[], primaryDate: Date) {
    if (this.primaryDate.getTime() !== primaryDate.getTime()) {
      this.primaryDate = primaryDate;

      this.rangeLoaders.forEach((l) => l.stop());
      this.rangeLoaders = this.createRangeLoaders(ranges);

      return;
    }

    const nextLoaders: SensorReadingsRangeLoader[] = [];
    const pendingRanges: SensorRange[] = ranges.slice();

    this.rangeLoaders.forEach((l) => {
      const rangeIndex = pendingRanges.findIndex((r) => l.equals(r));

      if (rangeIndex !== -1) {
        //If we found a range that matches the current loader, there's no
        // need to recreate it, so we just add the existing loader and
        // toss the range since it's already done.
        nextLoaders.push(l);
        pendingRanges.splice(rangeIndex, 1);
      } else {
        //Otherwise, this loader is no longer needed, so we have to stop it
        l.stop();
      }
    });

    //For whatever ranges we didn't toss above, we'll need to create a new
    // loader for them.
    this.rangeLoaders = nextLoaders.concat(
      this.createRangeLoaders(pendingRanges)
    );

    //If we remove all of the ranges that are on the left axis, we still want
    // one there, otherwise the math gets really weird
    if (
      this.rangeLoaders.length > 0 &&
      this.rangeLoaders.every((r) => r.getReadings().isRightAxis)
    ) {
      this.rangeLoaders[0].flipYAxis();
    }

    this.fire();
  }

  public flipYAxis(rangeId: string) {
    const loader = this.rangeLoaders.find(
      (r) => r.getReadings().id === rangeId
    );

    if (loader) {
      if (
        !loader.getReadings().isRightAxis &&
        this.rangeLoaders.filter((r) => !r.getReadings().isRightAxis).length ===
          1
      ) {
        notification.warn({
          message: 'Cannot Switch Axes',
          description:
            'At least one range of sensor readings must be on the left axis',
          placement: 'bottomRight',
        });
      } else {
        loader.flipYAxis();
      }
    }
  }

  public reload(sensor: SentryApiClient.SensorDTO) {
    const rangesToRecreate = this.rangeLoaders
      .filter((r) => r.getRange().sensor.id === sensor.id)
      .map((r) => ({ ...r.getRange(), sensor }));
    this.rangeLoaders = this.rangeLoaders
      .filter((r) => r.getRange().sensor.id !== sensor.id)
      .concat(this.createRangeLoaders(rangesToRecreate));
  }

  public refreshEventLogs() {
    this.rangeLoaders.forEach((l) => l.loadEventLogs());
  }

  private createRangeLoaders(ranges: SensorRange[]) {
    const freshLoaders = ranges.map((r) => {
      const loader = new SensorReadingsRangeLoader(
        r,
        this.primaryDate,
        this.sensorReadings,
        this.sensors
      );
      loader.setOnChange(() => this.fire());

      return loader;
    });

    this.fetchData(freshLoaders);

    return freshLoaders;
  }

  private async fetchData(loaders: SensorReadingsRangeLoader[]) {
    await Promise.all(loaders.map((loader) => loader.loadFirstPage()));

    this.fire();

    loaders.forEach((loader) => loader.loadEventLogs());

    loaders.forEach((loader) => {
      loader.waterfallLoadAll();
    });
  }

  private fire() {
    this.onChangeListener &&
      this.onChangeListener(this.rangeLoaders.map((r) => r.getReadings()));
  }
}
