import classNames from 'classnames';
import React from 'react';
import styles from './Fieldset.module.css';

export interface FieldsetProps {
  active: boolean;
  visible: boolean;
  id: string;
}

export const Fieldset: React.FC<FieldsetProps> = ({
  active,
  visible,
  id,
  children,
}) => {
  if (!active) {
    return null;
  }

  return (
    <fieldset id={id} className={classNames({ [styles.hide]: !visible })}>
      {children}
    </fieldset>
  );
};
