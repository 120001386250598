import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useAuthContext } from '.';

export function useAuthentication(postAuthRedirect?: string) {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const authConfig = useAuthContext();

  return {
    login: () => {
      instance.loginRedirect({
        ...{ scopes: authConfig.clientScopes || [] },
        redirectStartPage: postAuthRedirect,
      });
    },
    logout: () => instance.logoutRedirect(),
    resetPassword: () =>
      instance.loginRedirect({
        authority: authConfig.clientResetPasswordUrl || '',
        scopes: authConfig.clientScopes || [],
        redirectStartPage: postAuthRedirect,
      }),
    inProgress,
    isAuthenticated,
  };
}
