import { CloseOutlined } from '@ant-design/icons';
import { Button, Card, Stack } from '@torqit/torq-tools-react';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import { DatePicker } from 'antd';
import { SensorLabel } from './SensorLabel';

export type MomentRange = [moment.Moment | null, moment.Moment | null];

export interface SensorRangeCardProps {
  sensor?: SentryApiClient.SensorDTO;
  range?: MomentRange | null;
  onChange?: (range: MomentRange | null) => void;
}

export const SensorRangeCard: React.FC<SensorRangeCardProps> = ({
  sensor,
  range,
  onChange,
}) => {
  return (
    <Card
      title={
        <Stack alignment="middle" gap="apart">
          <SensorLabel sensor={sensor} />
        </Stack>
      }
    >
      <DatePicker.RangePicker
        showTime
        format="YYYY-MM-DD HH:mm"
        value={range}
        onChange={(values) => onChange && onChange(values)}
      />
    </Card>
  );
};
