import { LetterIcon, Stack } from '@torqit/torq-tools-react';
import { Popover } from 'antd';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import styles from './EventLogIndicator.module.css';

export interface EventLogIndicatorProps {
  eventLog: SentryApiClient.EventLogDTO;
  x: number;
  y: number;
}

export const EventLogIndicator: React.FC<EventLogIndicatorProps> = ({
  eventLog,
  x,
  y,
}) => {
  //We stop propagation on the <g> so that opening the tooltip doesn't also
  // open the create an event log prompt
  return (
    <g onClick={(e) => e.stopPropagation()}>
      <Popover
        title={
          <Stack>
            <Stack alignment="middle" gap="xs">
              <LetterIcon
                name={eventLog.createdByUser?.displayName ?? ''}
                small
              />
              <div>{eventLog.createdByUser?.displayName}</div>
            </Stack>
          </Stack>
        }
        content={eventLog.notes}
        trigger="click"
      >
        <ellipse className={styles.icon} cx={x} cy={y} rx={4.2} ry={4.2} />
      </Popover>
    </g>
  );
};
