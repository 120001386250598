import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, Droppable, Stack } from '@torqit/torq-tools-react';
import React from 'react';
import { useState } from 'react';
import { SensorSelectOrCreate } from 'Sensors';
import { SentryApiClient } from '_generated/api';
import { DraggableSensor } from './DraggableSensor';
import styles from './SensorBank.module.css';

export interface SensorBankProps {
  bankTitle: string;
  siteId: number;
  sensor?: SentryApiClient.SensorDetailsDTO;
  editable?: boolean;
  activeSensorIds: number[];
  onChange?: (sensor?: SentryApiClient.SensorDetailsDTO) => void;
  onDrop?: (sensorId: number) => void;
  onError?: (error: Error) => void;
}

export const SensorBank: React.FC<SensorBankProps> = ({
  bankTitle,
  siteId,
  sensor,
  editable,
  activeSensorIds,
  onChange,
  onDrop,
  onError,
}) => {
  const [isCreating, setCreating] = useState(false);

  const select = (selection?: SentryApiClient.SensorDetailsDTO) => {
    onChange && onChange(selection);
    setCreating(false);
  };

  const onCreateError = (error: Error) => {
    onError && onError(error);
    setCreating(false);
  };

  return (
    <>
      <Stack direction="vertical" alignment="middle">
        <div className={styles.wire}></div>
        <div className={styles.bank}>
          <Droppable
            className={styles.droppable}
            dropClassName={styles.dropHover}
            onDrop={(item: { id: number }) => onDrop && onDrop(item.id)}
          >
            {sensor && (
              <DraggableSensor
                sensor={sensor}
                bankTitle={bankTitle}
                onArchive={() => select(undefined)}
                editable={editable}
              />
            )}
            <div className={styles.label}>{bankTitle}</div>
            {!sensor && editable && (
              <Stack alignment="middle" fill>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  size="large"
                  onClick={() => setCreating(true)}
                ></Button>
              </Stack>
            )}
          </Droppable>
        </div>
      </Stack>
      <Drawer
        visible={isCreating}
        onClose={() => setCreating(false)}
        width={400}
        title={bankTitle + ': Add Sensor'}
        destroyOnClose
      >
        <SensorSelectOrCreate
          siteId={siteId}
          onSelect={select}
          onError={onCreateError}
          excludedSensorIds={activeSensorIds} // when displaying available sensors, exclude those already on the panel
        />
      </Drawer>
    </>
  );
};
