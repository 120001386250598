import React from 'react';
import {
  FetchGridParameters,
  FilteredTable,
  FilteredTableProps,
  Link,
  Stack,
  Table,
  TableColumn,
} from '@torqit/torq-tools-react';
import { SentryApiClient } from '_generated/api';
import { DEFAULT_PAGE_SIZE_OPTIONS, LetterIcon } from 'App';

const columns: TableColumn<SentryApiClient.PanelDTO>[] = [
  {
    title: 'Name',
    field: 'name',
    render: (name: string, panel: SentryApiClient.PanelDTO) => {
      if (!name) return null;

      return (
        <Stack>
          <Stack alignment="middle" gap={10}>
            <Link to={`/panel/${panel?.id}/details`}>
              <LetterIcon name={name ?? ''} small /> {name}
            </Link>
          </Stack>
        </Stack>
      );
    },
  },
  {
    title: 'IP Address',
    field: 'ipAddress',
    render: (name: string) => {
      return (
        <Stack>
          <Stack alignment="middle" gap={10}>
            {name}
          </Stack>
        </Stack>
      );
    },
  },
  {
    title: 'Hardware Id',
    field: 'hardwareId',
    key: 'hardwareId',
    render: (name: string) => {
      return (
        <Stack>
          <Stack alignment="middle" gap={10}>
            {name}
          </Stack>
        </Stack>
      );
    },
  },
  {
    title: 'Last Adoption Request',
    field: 'lastAdoptionRequestDate',
    key: 'lastAdoptionRequestDate',
    render: (date: string) => {
      const dateTime = new Date(date);

      return (
        <Stack>
          <Stack alignment="middle" gap={10}>
            {dateTime.toLocaleString()}
          </Stack>
        </Stack>
      );
    },
  },
  {
    field: 'id',
    render: (id: number) => {
      return <Link to={`/panel/${id}/adopt`}>Adopt</Link>;
    },
  },
];

export interface PanelAdoptTableProps
  extends Omit<
    FilteredTableProps<SentryApiClient.PanelDTO>,
    'columns' | 'dataSource' | 'rowKey'
  > {
  panels: SentryApiClient.PanelDTO[];
  onChange: (params: FetchGridParameters<SentryApiClient.PanelDTO>) => void;
}

export const PanelAdoptTable: React.FC<PanelAdoptTableProps> = ({
  panels,
  ...props
}) => {
  return (
    <FilteredTable
      {...props}
      columns={columns}
      data={panels}
      pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
      rowKey={(p) => p.id ?? -1}
    />
  );
};
