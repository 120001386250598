import React, { useEffect, useState } from 'react';
import { MsalProvider } from '@azure/msal-react';
import {
  BrowserCacheLocation,
  PublicClientApplication,
} from '@azure/msal-browser';
import { useContext } from 'react';
import { SentryApiClient } from '../_generated/api';
import { useConfigClient } from '../ConfigClient/useConfigClient';

const AuthProviderContext = React.createContext<SentryApiClient.ClientAuthConfigurationViewModel>(
  {}
);

export function useAuthContext() {
  return useContext(AuthProviderContext);
}

export const AuthProvider: React.FC<{}> = (props) => {
  const [pca, setPca] = useState<PublicClientApplication>();
  const [
    authConfig,
    setAuthConfig,
  ] = useState<SentryApiClient.ClientAuthConfigurationViewModel>();
  const configClient = useConfigClient();

  useEffect(() => {
    configClient.getConfiguration().then((config) => {
      setAuthConfig(config.authConfig);
    });
  }, []);

  useEffect(() => {
    authConfig &&
      setPca(
        new PublicClientApplication({
          auth: {
            clientId: authConfig.clientId as string,
            authority: authConfig.clientSignInSignUpUrl,
            knownAuthorities: [`${authConfig.authorityName}.b2clogin.com`],
            redirectUri: authConfig.clientRedirectUrl,
            postLogoutRedirectUri: (authConfig.clientRedirectUrl +
              '/logout') as string,
          },
          cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: true,
          },
        })
      );
  }, [authConfig]);

  return authConfig && pca ? (
    <MsalProvider instance={pca}>
      <AuthProviderContext.Provider value={authConfig}>
        {props.children}
      </AuthProviderContext.Provider>
    </MsalProvider>
  ) : null;
};
