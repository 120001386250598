import { Select, SelectProps } from '@torqit/torq-tools-react';
import { useDebounce } from 'App';
import React, { useState } from 'react';
import { useEffect } from 'react';

export interface SearchSelectProps<T extends React.Key>
  extends Omit<SelectProps<T>, 'showSearch' | 'filterOption' | 'onSearch'> {
  onDebouncedSearch?: (search?: string) => void;
}

export const SearchSelect = <T extends React.Key>({
  onDebouncedSearch: onBebouncedSearch,
  ...props
}: SearchSelectProps<T>) => {
  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 300);

  useEffect(() => {
    onBebouncedSearch && onBebouncedSearch(debouncedSearch);
  }, [debouncedSearch, onBebouncedSearch]);

  //It would have been nice to also include the "None" and "Create new ???..." options
  // as well, however every SelectOrCreate component uses different values and
  // different labels to represent these options, so they'd need to get passed in as
  // props and you'd be looking at a system just as complicated as you had before.
  return (
    <Select {...props} showSearch filterOption={false} onSearch={setSearch} />
  );
};
