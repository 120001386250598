import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  Card,
  FetchGridParameters,
  Link,
  Stack,
  useAsyncData,
  useHistory,
  useParams,
} from '@torqit/torq-tools-react';
import { FormReviewItem } from 'App/components/FormHelpers';
import { LetterIcon } from 'App/components/LetterIcon';
import { PagePresenter } from 'App/components/PagePresenter';
import { PageState } from 'App/components/PagePresenter/PagePresenter';
import { useApi } from 'App/hooks/useApi';
import { ConfirmDialog } from 'App/components/ConfirmDelete';
import { SiteTable } from 'Sites';
import { CompanyTable } from 'Companies';
import { SentryApiClient } from '_generated/api';
import { DEFAULT_PAGE_PARAMS } from 'App/components/TableHelpers/defaultPageSettings';
import { MobileIconButton } from 'App/components/MobileIconButton/MobileIconButton';
import { getUserTypeName, UserType } from 'Users/userTypes';

interface UserDetailsProps {}

export const UserDetails: React.FC<UserDetailsProps> = ({}) => {
  const { userApi } = useApi();
  const { userId } = useParams<{ userId: string }>();

  const {
    data: userPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(() => userApi.getUserPermissions(userId), [userApi, userId]);

  const { data: userDetails, isLoading, error } = useAsyncData(async () => {
    if (!userId) {
      return undefined;
    }

    return userApi.getById(userId);
  }, [userId, userApi]);

  const router = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const onDelete = async () => {
    try {
      setConfirmDelete(false);
      setPageState(PageState.Loading);

      if (userId) await userApi.delete(userId);

      router.push('/users');
    } catch (error) {
      setPageState(PageState.Error);
    }
  };

  const {
    data: companies,
    isLoading: companiesLoading,
    error: companiesError,
  } = useAsyncData(
    async () => (userId ? userApi.getCompanies(userId) : undefined),
    [userId, userApi]
  );

  const {
    data: sites,
    isLoading: sitesLoading,
    error: sitesError,
  } = useAsyncData(
    async () => (userId ? userApi.getSites(userId) : undefined),
    [userId, userApi]
  );

  const [pageState, setPageState] = useState(PageState.Loaded);
  useEffect(() => {
    if (isLoading) setPageState(PageState.Loading);
    else if (error || sitesError || companiesError)
      setPageState(PageState.Error);
    else if (userDetails) setPageState(PageState.Loaded);
  }, [userDetails, isLoading, error, sitesError, companiesError]);

  const [tableParams, setParams] = useState<
    FetchGridParameters<SentryApiClient.SiteDTO>
  >(DEFAULT_PAGE_PARAMS);

  return (
    <PagePresenter pageState={pageState}>
      <Stack direction="vertical" gap={20} stretch>
        <Card
          title={
            <Stack gap="apart" alignment="middle">
              <Stack alignment="middle" gap={10}>
                {userDetails?.displayName} Details
              </Stack>
              <Stack alignment="end" gap={10}>
                {permissionsLoading && <LoadingOutlined />}
                {userPermissions?.canUpdate && (
                  <Link to={`/user/${userDetails?.id}/edit`}>
                    <MobileIconButton type="primary" icon={<EditOutlined />}>
                      Edit
                    </MobileIconButton>
                  </Link>
                )}
                {userPermissions?.canDelete && (
                  <MobileIconButton
                    type="primary"
                    color="red"
                    icon={<DeleteOutlined />}
                    onClick={() => setConfirmDelete(true)}
                    danger
                  >
                    Delete
                  </MobileIconButton>
                )}
              </Stack>
            </Stack>
          }
        >
          <Stack direction="vertical" alignment="middle" gap={20}>
            <LetterIcon name={userDetails?.displayName ?? ''} />
            <Stack.Item />
          </Stack>
          <FormReviewItem
            field="Display Name"
            value={userDetails?.displayName}
          />
          <FormReviewItem field="Email" value={userDetails?.email} />
          <FormReviewItem
            field="Phone Number"
            value={userDetails?.phoneNumber}
          />
          <FormReviewItem
            field="Type"
            value={getUserTypeName(
              userDetails?.userTypeId ?? UserType.Customer
            )}
          />
          <ConfirmDialog
            body={`Are you sure you want to delete ${userDetails?.displayName}?`}
            onConfirm={onDelete}
            onCancel={() => setConfirmDelete(false)}
            open={confirmDelete}
            okButton={{ danger: true, children: 'Delete' }}
          />
        </Card>
        <Card title="Companies">
          <CompanyTable
            disableFilterAndSort
            isLoading={companiesLoading}
            companies={companies}
            params={tableParams}
            onChange={setParams}
          />
        </Card>
        <Card title="Sites">
          <SiteTable
            disableFilterAndSort
            sites={sites}
            isLoading={sitesLoading}
            params={tableParams}
            onChange={setParams}
          />
        </Card>
      </Stack>
    </PagePresenter>
  );
};
