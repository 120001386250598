import { NotificationOutlined } from '@ant-design/icons';
import { Routable } from '@torqit/torq-tools-react';
import { SUPER_USER_ROLE } from 'Auth/roles';
import { Announcements } from './Announcements';

export const announcementRoutes: Routable[] = [
  {
    menuName: 'Announcements',
    route: '/announcements',
    requiredRoles: [SUPER_USER_ROLE],
    icon: <NotificationOutlined />,
    content: <Announcements />,
  },
];
