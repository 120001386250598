import { TableColumn } from '@torqit/torq-tools-react';
import { useMemo } from 'react';

export function useColumns<DataObject extends object>(
  columns: TableColumn<DataObject>[],
  disableFilteringAndSorting?: boolean
) {
  return useMemo(
    () =>
      disableFilteringAndSorting
        ? columns.map((c) => ({ ...c, filter: undefined, sortable: false }))
        : columns,
    [columns, disableFilteringAndSorting]
  );
}
