import React from 'react';
import { ScaleLoader } from 'react-spinners';

export interface SentryLoaderProps {
  big?: boolean;
}

export const SentryLoader: React.FC<SentryLoaderProps> = ({ big }) => {
  if (big) {
    return (
      <ScaleLoader
        height={100}
        width={12}
        radius={4}
        margin={4}
        color="var(--torq-experimental-primary-color)"
      />
    );
  }

  return (
    <ScaleLoader
      height={60}
      width={7}
      radius={3}
      margin={3}
      color="var(--torq-experimental-primary-color)"
    />
  );
};
