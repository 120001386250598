import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Drawer,
  FetchGridParameters,
  Stack,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import { EventLogCreate, EventLogTable } from 'EventLogs';
import { EventLogEdit } from 'EventLogs/components/EventLogEdit';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { SiteTimezoneSwitch } from 'Sites/components/SiteTimezoneSwitch';
import { SentryApiClient } from '_generated/api';
import styles from './SensorEventLogCard.module.css';

//The sensorId and sensorDetails need to be passed in separately since they're
// used for different purposes. The ID is specifically important because it's
// used to prevent redundant permissions calls that are for some reason slowing
// down the site
export interface SensorEventLogCardProps {
  sensorId: number;
  sensorDetails?: SentryApiClient.SensorDTO;
  eventLogs?: SentryApiClient.EventLogDTO[];
  permissionsLoading?: boolean;
  totalEventLogs?: number;
  eventLogParams?: FetchGridParameters<SentryApiClient.EventLogDTO>;
  loadingEventLogs?: boolean;
  timezone: string;
  createEventLogTimestamp?: Date;
  canCreate?: boolean;

  addableSensors: SentryApiClient.SensorDTO[];

  onShowOnChartClicked?: (time: Date) => void;
  onError?: (error: Error) => void;
  onUpdate?: () => void;
  onEventLogParamsChange?: (
    params: FetchGridParameters<SentryApiClient.EventLogDTO>
  ) => void;
  onClose?: () => void;
}

export const SensorEventLogCard: React.FC<SensorEventLogCardProps> = ({
  sensorDetails,
  sensorId,
  eventLogs,
  totalEventLogs,
  eventLogParams,
  loadingEventLogs,
  timezone,
  createEventLogTimestamp,
  addableSensors,
  canCreate,
  onShowOnChartClicked,
  onError,
  onUpdate,
  onEventLogParamsChange,
  onClose,
}) => {
  const [isSiteTime, setIsSiteTime] = useState(true);
  const [isCreatingLog, setCreatingLog] = useState(false);
  const [editEventLogId, setEditEventLogId] = useState<number>();

  const currentlyEditedEventLog = useMemo(
    () => eventLogs?.find((e) => e.id === editEventLogId),
    [editEventLogId, eventLogs]
  );

  const { eventLogApi } = useApi();
  const {
    data: eventLogPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(
    () =>
      eventLogApi.getManyUserPermissions(
        eventLogs?.map((e) => e.id!),
        sensorId
      ),
    [eventLogApi, eventLogs, sensorId]
  );

  const [isDeleting, setDeleting] = useState(false);
  const deleteEventLog = async (id: number) => {
    setDeleting(true);

    await eventLogApi.delete(id).catch((e) => onError && onError(e));

    onUpdate && onUpdate();
    setDeleting(false);
  };

  return (
    <>
      <Card
        className={styles.sensorEventLogCard}
        title={
          <Stack alignment="middle" gap="apart">
            <Stack alignment="middle" gap="md">
              <div>Event Logs</div>
              <SiteTimezoneSwitch
                checked={isSiteTime}
                onChange={setIsSiteTime}
              />
            </Stack>
            {permissionsLoading && <LoadingOutlined />}
            {canCreate && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setCreatingLog(true)}
              >
                Log an event
              </Button>
            )}
            {!canCreate && <div>{/*Trigger the apart*/}</div>}
          </Stack>
        }
        style={{ height: '100%' }}
      >
        <EventLogTable
          eventLogs={eventLogs}
          timezone={isSiteTime ? timezone : moment.tz.guess()}
          total={totalEventLogs}
          params={eventLogParams}
          isLoading={loadingEventLogs || isDeleting}
          editableEventLogs={eventLogPermissions
            ?.filter((p) => p.canUpdate)
            .map((p) => p.id!)}
          deletableEventLogs={eventLogPermissions
            ?.filter((p) => p.canDelete)
            .map((p) => p.id!)}
          onShowOnChartClicked={onShowOnChartClicked}
          onChange={onEventLogParamsChange}
          onEditClicked={setEditEventLogId}
          onDeleteClicked={deleteEventLog}
          height={300}
        />
      </Card>
      <Drawer
        title="Create Event Log"
        width={400}
        visible={
          (isCreatingLog || createEventLogTimestamp != null) && canCreate
        }
        onClose={() => {
          setCreatingLog(false);
          onClose && onClose();
        }}
        destroyOnClose
      >
        {sensorDetails && (
          <EventLogCreate
            sensor={sensorDetails}
            timestamp={createEventLogTimestamp}
            onError={onError}
            onCreate={() => {
              setCreatingLog(false);
              onUpdate && onUpdate();
              onClose && onClose();
            }}
            timezone={timezone}
            addableSensors={addableSensors ?? []}
          />
        )}
      </Drawer>
      <Drawer
        title="Edit Event Log"
        width={400}
        visible={editEventLogId != null}
        onClose={() => {
          setEditEventLogId(undefined);
          onClose && onClose();
        }}
        destroyOnClose
      >
        {sensorDetails && currentlyEditedEventLog && (
          <EventLogEdit
            sensor={sensorDetails}
            eventLog={currentlyEditedEventLog}
            timezone={timezone}
            addableSensors={addableSensors ?? []}
            onError={onError}
            onEdit={() => {
              setEditEventLogId(undefined);
              onUpdate && onUpdate();
              onClose && onClose();
            }}
          />
        )}
      </Drawer>
    </>
  );
};
