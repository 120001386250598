import { DateServiceSingleton } from 'App/utils/DateService';
import moment from 'moment';
import { SensorRange } from 'SensorReadings/interfaces';
import {
  CalculatorPipe,
  DateTransformPipe,
  DetectOutlierPipe,
  ModuloPipe,
  NormalizePipe,
  Pipeline,
} from 'SensorReadings/utils/Pipeline';
import { SentryApiClient } from '_generated/api';

const PAGE_SIZE = 1000;

type Listener = (readings: SentryApiClient.SensorReadingDTO[]) => void;

function checkForLocalServerFix(date: Date, timezone: string | number): Date {
  if (process.env.NODE_ENV === 'development') {
    return DateServiceSingleton.__APPLY_TIMEZONE_WRONGLY(date, timezone);
  }

  //Unlike in the main sensor fetcher, we DON'T want to apply the timezone here
  // because this date is only ever going to be computer generated. The mini charts
  // will only ever grab a range of one day ago to right now, and these two dates
  // will always be the same UTC time no matter what timezone we convert them to.
  // Really, this method only exists to catch the above case
  return date;
}

export class MiniChartReadingFetcher {
  private api: SentryApiClient.SensorReadingsClient;
  private sensor: SentryApiClient.SensorDetailsDTO;
  private pipeline: Pipeline;
  private timezone: string;

  private onFinish?: Listener;

  public constructor(
    sensor: SentryApiClient.SensorDetailsDTO,
    api: SentryApiClient.SensorReadingsClient
  ) {
    this.sensor = sensor;
    this.timezone = moment.tz.guess();
    this.api = api;

    //Typescript complains that pipeline might not be initialized if I don't
    // literally put it in the constructor >:(
    this.pipeline = new Pipeline();
    this.buildPipeline();
  }

  private buildPipeline() {
    this.pipeline.append(new DateTransformPipe());

    if (this.sensor.calculation) {
      this.pipeline.append(
        new CalculatorPipe('VALUE ' + this.sensor.calculation)
      );
    }

    const detectOutlierPipe = new DetectOutlierPipe();
    this.pipeline.append(detectOutlierPipe);

    const normalizePipe = new NormalizePipe(
      SentryApiClient.GroupByParam.Minute
    );
    this.pipeline.append(normalizePipe);

    const moduloPipe = new ModuloPipe(2);
    this.pipeline.append(moduloPipe);

    this.pipeline.setOnExit((r) => {
      this.onFinish && this.onFinish(r);
    });
  }

  public setOnFinish(listener: Listener) {
    this.onFinish = listener;
  }

  public removeOnFinish() {
    this.onFinish = undefined;
  }

  public async fetch() {
    let hasMore = true;
    let page = 1;

    let readings: SentryApiClient.SensorReadingDTO[] = [];
    while (hasMore) {
      const data = await this.fetchReadings(page);

      if (data.items) {
        readings = readings.concat(data.items);
      }

      if (page >= (data.totalPages ?? 0)) {
        hasMore = false;
      } else {
        page++;
      }
    }

    this.pipeline?.enter(readings);
  }

  private fetchReadings(page: number) {
    const now = new Date();
    const oneDayAgo = moment(now).subtract(1, 'day').toDate();

    return this.api.get(
      this.sensor.id,
      PAGE_SIZE,
      page,
      SentryApiClient.SensorReadingFilters.ReadingTime,
      SentryApiClient.OrderDirection.Ascending,
      [
        {
          field: SentryApiClient.SensorReadingFilters.ReadingTime,
          operator: SentryApiClient.Operator.GreaterThan,
          value: checkForLocalServerFix(oneDayAgo, this.timezone).toISOString(),
        },
        {
          field: SentryApiClient.SensorReadingFilters.ReadingTime,
          operator: SentryApiClient.Operator.LesserThan,
          value: checkForLocalServerFix(now, this.timezone).toISOString(),
        },
        {
          field: SentryApiClient.SensorReadingFilters.Unit,
          operator: SentryApiClient.Operator.Equals,
          value: 'CURRENT',
        },
      ]
    );
  }
}
