import { Drawer, Result, useAsyncData } from '@torqit/torq-tools-react';
import { useDebounce } from 'App';
import { useApi } from 'App/hooks/useApi';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { CompanyUserList } from './CompanyUserList';
import { CompanyUserSelector } from './CompanyUserSelector';
import { useCompanyUserApi } from './useCompanyUserApi';

export interface CompanyUserPanelProps {
  company?: SentryApiClient.CompanyDTO;
  showUserDrawer?: boolean;
  canRemoveUsers?: boolean;
  onUserDrawerChange?: (visible: boolean) => void;
}

const PAGE_SIZE = 20;

export const CompanyUserPanel: React.FC<CompanyUserPanelProps> = ({
  company,
  showUserDrawer,
  canRemoveUsers,
  onUserDrawerChange,
}) => {
  const companyId = company?.id ?? -1;

  const { companyApi, userApi } = useApi();
  const { addUserToCompany } = useCompanyUserApi();

  const [userChangeError, setUserChangeError] = useState<Error>();

  const {
    data: userData,
    isLoading: usersLoading,
    executeRequest: reloadUsers,
    error: companyUsersError,
  } = useAsyncData(
    async () => (companyId ? companyApi.getUsers(companyId) : undefined),
    [companyId, companyApi]
  );

  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 300);

  const [page, setPage] = useState(1);
  const {
    data: allUsers,
    isLoading: allUsersLoading,
    executeRequest: reloadAllUsers,
    error: allUsersError,
  } = useAsyncData(
    () =>
      userApi.getWithPermissions(
        PAGE_SIZE,
        page,
        SentryApiClient.UserFilters.DisplayName,
        SentryApiClient.OrderDirection.Ascending,
        debouncedSearch
          ? [
              {
                field: SentryApiClient.UserFilters.DisplayName,
                operator: SentryApiClient.Operator.Contains,
                value: debouncedSearch,
              },
            ]
          : undefined
      ),
    [page, debouncedSearch, userApi]
  );

  const addExistingUsers = async (users: SentryApiClient.UserDTO[]) => {
    try {
      await addUsers(users);
    } catch (error) {
      setUserChangeError(error);
    }

    onUserDrawerChange && onUserDrawerChange(false);
    reloadUsers();
    reloadAllUsers();
  };
  const addUsers = (userList: SentryApiClient.UserDTO[]) => {
    return Promise.all(
      userList.map(async (user) => addUserToCompany(user, companyId))
    );
  };

  const refreshUsers = () => {
    reloadUsers();
    reloadAllUsers();
  };

  if (userChangeError || companyUsersError || allUsersError) {
    return <Result status="error" title="Something went wrong" />;
  }

  return (
    <>
      <CompanyUserList
        companyId={companyId}
        users={userData}
        isLoading={usersLoading}
        canRemoveUsers={canRemoveUsers}
        onUsersRemoved={refreshUsers}
        onRemoveFailed={setUserChangeError}
      />
      <CompanyUserSelector
        visible={showUserDrawer}
        onClose={() => onUserDrawerChange && onUserDrawerChange(false)}
        companyName={company?.name}
        allUsers={allUsers?.items}
        totalUsers={allUsers?.totalItems}
        loadingUsers={allUsersLoading}
        page={page}
        pageSize={PAGE_SIZE}
        onPageChange={setPage}
        search={search}
        userIdsInCompany={
          userData?.map((u) => u.id).filter((u) => u != null) as string[]
        }
        onSearch={setSearch}
        onSelect={addExistingUsers}
      />
    </>
  );
};
