import { Card, Descriptions, Stack } from '@torqit/torq-tools-react';
import { PagePresenter, PageState } from 'App';

export const TermsConditionsDetails: React.FC = () => {
  return (
    <PagePresenter pageState={PageState.Loaded}>
      <Stack direction="vertical" gap={20} stretch>
        <Card
          title={
            <Stack gap="apart" alignment="middle">
              <Stack alignment="middle" gap={10}>
                {'Terms and Conditions'}
              </Stack>
            </Stack>
          }
        >
          <Descriptions
            layout={'vertical'}
            column={1}
            labelStyle={{ fontWeight: 'bold' }}
          >
            <Descriptions.Item label="Overview">
              This document outlines the terms for using the Twilio API as part
              of the services offered by SentryTM Water Technologies Inc.,
              adhering to the guidelines set by Twilio's Messaging Policy.
            </Descriptions.Item>
            <Descriptions.Item label="Campaign">
              We offer a service to send alert and alarm notifications from our
              water quality monitoring platform via SMS messages or email. This
              service is tailored for water and wastewater treatment plants,
              wherein we install sensors at various locations within these
              facilities to monitor water quality in real-time.
            </Descriptions.Item>
            <Descriptions.Item label="User Registration & Access">
              To utilize our SMS Notification services, users must be
              registered, logged in, and provide a valid phone number.
              Unauthorized access or use is strictly prohibited.
            </Descriptions.Item>
            <Descriptions.Item label="SMS Notifications">
              SMS notifications are disabled by default. Users have the option
              to opt in or out of SMS notifications at any time via our online
              site and can make these choices directly from their user page.
            </Descriptions.Item>
            <Descriptions.Item label="Consent, Privacy & Opt-In">
              On the page to enable SMS notifications, there is a clear message
              stating, "By enabling SMS notifications, you authorize Sentry to
              send text messages. Message/data rates apply." This message is
              accompanied by a link to our terms and conditions. By opting in,
              users consent to use Twilio's service under our enforced privacy
              policy. Users also agree that their mobile numbers and opt-in
              choices will not be shared with third-party entities.
            </Descriptions.Item>
            <Descriptions.Item label="Sample SMS" span={2}>
              <Stack direction={'vertical'}>
                <Stack direction={'vertical'}>
                  Example Case 1: Notifies user when a sensor is in the red
                  status.
                  <p>
                    • Sensor: XXX-XX-XXX-XXX has changed states to RedHigh at
                    4/24/2023 7:19:22 AM America/Los_Angeles (4/24/2023 2:19:22
                    PM UTC). Last SENTRY Signal reading: 35.024. Sign into your
                    SENTRY account for more details.
                  </p>
                </Stack>
                <Stack direction={'vertical'}>
                  Example Case 2: Notifies user when a sensor is offline.
                  <p>
                    • Sensor XXX-XX-XXX-XXX has changed states to Offline at
                    6/30/2023 9:22:17 PM Asia/Tel_Aviv (6/30/2023 6:22:17 PM
                    UTC). Last SENTRY Signal reading: 0. Sign into your SENTRY
                    account for more details.
                  </p>
                </Stack>
              </Stack>
            </Descriptions.Item>
            <Descriptions.Item label="Accuracy of Information">
              Users must ensure all information, including phone numbers,
              provided is accurate and up-to-date.
            </Descriptions.Item>
            <Descriptions.Item label="Changes">
              We can update these Terms at any time. Continued service use means
              users accept changes.
            </Descriptions.Item>
            <Descriptions.Item label="Getting in Touch">
              Should you have any questions or concerns related to these Terms
              or our services, please connect with us directly at{'\u00A0'}
              <a href="mailto:info@sentrywatertech.com">
                info@sentrywatertech.com
              </a>
              .
            </Descriptions.Item>
          </Descriptions>
        </Card>
      </Stack>
    </PagePresenter>
  );
};
