import { useIsAuthenticated } from '@azure/msal-react';
import { useAsyncData } from '@torqit/torq-tools-react';
import { useApi, useDebounce } from 'App';
import { useAuthorization, useUser } from 'Auth';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { SentryApiClient } from '_generated/api';

export interface SentryUserData {
  user?: SentryApiClient.UserDTO;
  isLoading: boolean;
}

const SentryUserContext = createContext<SentryUserData | null>(null);

export interface SentryUserProviderProps {}

export const SentryUserProvider: React.FC<SentryUserProviderProps> = ({
  children,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const [token] = useAuthorization();

  const canFetchUser = useMemo(() => isAuthenticated && token != null, [
    isAuthenticated,
    token,
  ]);

  const msalUser = useUser();
  const { userApi: api } = useApi();

  const { data: user, isLoading } = useAsyncData(
    async () =>
      msalUser && canFetchUser
        ? api.getById(msalUser.localAccountId)
        : undefined,
    [msalUser, canFetchUser, api]
  );
  return (
    <SentryUserContext.Provider value={{ user, isLoading }}>
      {children}
    </SentryUserContext.Provider>
  );
};

export function useSentryUser() {
  const result = useContext(SentryUserContext);

  if (!result) {
    throw new Error(
      'useSentryUser() cannot be used without being wrapped by a SentryUserProvider'
    );
  }

  return result;
}
