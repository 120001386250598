import { Link, Stack } from '@torqit/torq-tools-react';
import React from 'react';

export const GlobalFooter: React.FC = () => {
  return (
    <Stack gap={20}>
      <p>Copyright Sentry {new Date().getFullYear()}</p>
      <Link to="/terms-conditions">Terms and Conditions</Link>
    </Stack>
  );
};
