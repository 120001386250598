import { DeleteFilled, EditFilled, LoadingOutlined } from '@ant-design/icons';
import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  FilteredTable,
  FilteredTableProps,
  Link,
  PopConfirm,
  Stack,
  Tooltip,
} from '@torqit/torq-tools-react';
import { DateServiceSingleton } from 'App/utils/DateService';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import { LetterIcon } from '../../../App';

export interface EventLogTableProps
  extends Omit<
    FilteredTableProps<SentryApiClient.EventLogDTO>,
    'data' | 'columns' | 'rowKey'
  > {
  eventLogs?: SentryApiClient.EventLogDTO[];
  timezone: string;
  editableEventLogs?: number[];
  deletableEventLogs?: number[];
  onShowOnChartClicked?: (time: Date) => void;
  onEditClicked?: (id: number) => void;
  onDeleteClicked?: (id: number) => void;
}

export const EventLogTable: React.FC<EventLogTableProps> = ({
  eventLogs,
  timezone,
  editableEventLogs,
  deletableEventLogs,
  onShowOnChartClicked,
  onEditClicked,
  onDeleteClicked,
  ...props
}) => {
  return (
    <FilteredTable
      columns={[
        {
          field: 'timestamp',
          render: (t: string) => (
            <Stack>
              <Stack alignment="middle" gap={10}>
                <div>
                  {DateServiceSingleton.ConvertToTimezoneTime(
                    new Date(t + 'Z'),
                    timezone
                  ).toLocaleString()}
                </div>
                <Tooltip title="Show on chart">
                  <Button
                    icon={<FontAwesomeIcon icon={faCrosshairs} />}
                    onClick={() =>
                      onShowOnChartClicked &&
                      onShowOnChartClicked(new Date(t + 'Z'))
                    }
                  />
                </Tooltip>
              </Stack>
            </Stack>
          ),
        },
        { field: 'notes' },
        {
          title: 'User',
          render: (eventLog: SentryApiClient.EventLogDTO) => {
            let userDisplay;
            if (
              eventLog.createdByUser?.id ===
              '00000000-0000-0000-0000-000000000000'
            ) {
              // For event logs that were migrated from the legacy system, the user ID is set to the default system
              // value (all 0s), while the username is set to the username from the legacy system. In those cases,
              // we want to display the username, but since it's not linked to an actual user in the current system,
              // we don't display it as a link.
              userDisplay = (
                <Stack alignment="middle" gap={10}>
                  <LetterIcon
                    // Forcing to uppercase so that the letter icon appears with two uppercase letters
                    name={
                      eventLog.createdByUserName
                        ? eventLog.createdByUserName.toUpperCase()
                        : ''
                    }
                    small
                  />
                  {eventLog.createdByUserName}
                </Stack>
              );
            } else {
              userDisplay = (
                <Link to={`/user/${eventLog.createdByUser?.id}/details`}>
                  <Stack alignment="middle" gap={10}>
                    <LetterIcon
                      // Forcing to uppercase so that the letter icon appears with two uppercase letters
                      name={
                        eventLog.createdByUser?.displayName
                          ? eventLog.createdByUser.displayName.toUpperCase()
                          : ''
                      }
                      small
                    />
                    {eventLog.createdByUser?.displayName}
                  </Stack>
                </Link>
              );
            }
            return <Stack>{userDisplay}</Stack>;
          },
        },
        {
          title: editableEventLogs ? '' : <LoadingOutlined />,
          width: 150,
          render: (eventLog: SentryApiClient.EventLogDTO) => (
            <Stack gap={10}>
              {editableEventLogs?.includes(eventLog.id ?? -1) && (
                <Button
                  icon={<EditFilled />}
                  type="primary"
                  onClick={() =>
                    onEditClicked && onEditClicked(eventLog.id ?? -1)
                  }
                >
                  Edit
                </Button>
              )}
              {deletableEventLogs?.includes(eventLog.id ?? -1) && (
                <PopConfirm
                  title={"Are you sure you'd like to delete this event log?"}
                  onConfirm={() =>
                    onDeleteClicked && onDeleteClicked(eventLog.id ?? -1)
                  }
                  placement="topRight"
                >
                  <Button
                    icon={<DeleteFilled />}
                    danger
                    type="primary"
                  ></Button>
                </PopConfirm>
              )}
            </Stack>
          ),
        },
      ]}
      data={eventLogs}
      rowKey={(e) => e.id ?? -1}
      {...props}
    />
  );
};
