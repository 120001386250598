import { ClusterOutlined } from '@ant-design/icons';
import { Routable, useHistory } from '@torqit/torq-tools-react';
import { SentryApiClient } from '_generated/api';
import { PanelAdopt } from './PanelAdopt';
import { PanelCreate } from './PanelCreate';
import { PanelDetails } from './PanelDetails';
import { PanelEdit } from './PanelEdit';
import { Panels } from './PanelsList';
import { PanelsPendingAdoption } from './PanelsPendingAdoption';

const PanelCreateRouteHandler: React.VFC = () => {
  const history = useHistory<{
    siteDetails?: SentryApiClient.SiteDTO;
  }>();
  const siteDetails = history.location.state?.siteDetails;

  return <PanelCreate site={siteDetails ?? true} />;
};

export const panelRoutes: Routable[] = [
  {
    route: '/adoption',
    menuName: 'Pending Adoptions',
    icon: <ClusterOutlined />,
    content: <PanelsPendingAdoption />,
  },
  {
    menuName: 'Panels',
    route: '/panels',
    icon: <ClusterOutlined />,
    content: <Panels />,
  },
  {
    route: '/panel',
    pages: [
      {
        route: '/create',
        content: <PanelCreateRouteHandler />,
      },
      {
        route: '/:panel(\\d+)',
        pages: [
          {
            route: '/details',
            content: <PanelDetails />,
          },
          {
            route: '/edit',
            content: <PanelEdit />,
          },
          {
            route: '/adopt',
            content: <PanelAdopt />,
          },
        ],
      },
    ],
  },
];
