import { App, reportWebVitals } from 'App';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';

const favicon: any = document.getElementById('favicon');
if (window.location.href.includes('sensotraq')) {
  document.title = 'Sensotraq';
  favicon.href = '/sensotraq-favicon.ico';
} else {
  document.title = 'Sentry';
  favicon.href = '/sentry-favicon.ico';
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
