import { LoadingOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Box, Button, Stack, useAsyncData } from '@torqit/torq-tools-react';
import { fullWidthStyles } from 'App';
import { SentryLoader } from 'App/components';
import { CollapsibleCard } from 'App/components/CollapsibleCard';
import { DashboardItemEmpty } from 'App/components/DashboardItemEmpty';
import { useApi } from 'App/hooks';
import { useUser } from 'Auth';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { CompanySiteDisplay } from './CompanySiteDisplay';
import styles from './CompanySiteDisplay.module.css';

export interface DashboardCompanyCardProps {
  company: SentryApiClient.CompanyDTO;
  toggleFavourite: (companyId: number) => void;
  isFavourite?: boolean;
}

export const DashboardCompanyCard: React.FC<DashboardCompanyCardProps> = ({
  company,
  toggleFavourite,
  isFavourite,
}) => {
  const [isLoadingFavourite, setIsLoadingFavourite] = useState<boolean>(false);
  const { siteApi } = useApi();

  const { data: siteList, isLoading, error } = useAsyncData(
    () =>
      siteApi.get(
        null,
        null,
        SentryApiClient.SiteFilters.Name,
        SentryApiClient.OrderDirection.Ascending,
        [
          {
            field: SentryApiClient.SiteFilters.CompanyId,
            operator: SentryApiClient.Operator.Equals,
            value: company.id?.toString() ?? '0',
          },
        ]
      ),
    [company, siteApi]
  );

  return (
    <CollapsibleCard
      title={
        <Stack alignment="start" gap={1}>
          <Box width={25}>
            {!isLoadingFavourite ? (
              <div
                style={{ width: '25px' }}
                className={styles.clickable}
                onClick={async () => {
                  setIsLoadingFavourite(true);
                  company.id && (await toggleFavourite(company.id));
                  setIsLoadingFavourite(false);
                }}
              >
                {isFavourite ? <StarFilled /> : <StarOutlined />}
              </div>
            ) : (
              <LoadingOutlined />
            )}
          </Box>

          <Stack.Item>{company.name}</Stack.Item>
        </Stack>
      }
      className={fullWidthStyles.fullWidth}
      bodyPadding="sm"
    >
      {isLoading && (
        <Stack direction="vertical" alignment="middle" gap="xl">
          <Box padding="xl" />
          <SentryLoader />
          <Box padding="xl" />
        </Stack>
      )}
      {!isLoading && siteList?.items?.length !== 0 && (
        <Stack direction="vertical" stretch gap="xl">
          {siteList?.items?.map((s) => (
            <CompanySiteDisplay key={s.id} site={s} />
          ))}
        </Stack>
      )}
      {!isLoading && siteList?.items?.length === 0 && (
        <Box padding="md">
          <DashboardItemEmpty message="You are currently assigned to no sites within the company. Please contact admin to be assigned" />
        </Box>
      )}
    </CollapsibleCard>
  );
};
