import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCertificate,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import { StateIcon } from 'App/components/StateIcon';
import React from 'react';
import { SentryApiClient } from '_generated/api';

interface IProps {
  state?: SentryApiClient.SiteStateType;
}

const SiteStateIcon: React.FC<IProps> = ({ state }) => {
  let icon: IconDefinition;
  let color: string;
  let label: string;

  switch (state) {
    case SentryApiClient.SiteStateType.Empty:
      icon = faCertificate;
      color = 'var(--torq-experimental-success-color)';
      label = 'No Data';
      break;

    case SentryApiClient.SiteStateType.Red:
      icon = faExclamationCircle;
      color = 'var(--torq-experimental-error-color)';
      label = 'Danger';
      break;

    case SentryApiClient.SiteStateType.Amber:
      icon = faExclamationTriangle;
      color = 'var(--torq-experimental-warning-color)';
      label = 'Warning';
      break;

    case SentryApiClient.SiteStateType.Normal:
      icon = faCheckCircle;
      color = 'var(--torq-experimental-success-color)';
      label = 'Normal';
      break;

    case SentryApiClient.SiteStateType.AllOffline:
      icon = faPowerOff;
      color = '#000';
      label = 'All Offline';
      break;

    default:
    case SentryApiClient.SiteStateType.Offline:
      icon = faPowerOff;
      color = '#aaa';
      label = 'Offline';
      break;
  }
  return <StateIcon icon={icon} color={color} label={label} />;
};

export { SiteStateIcon };
