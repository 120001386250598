import { FormInstance } from 'antd';
import { MultiSelectList } from 'App';
import { buildFieldName } from 'Auth/buildFieldName';
import React, { useEffect, useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { EventLogFormFields } from './EventLogFormFields';
import { SelectableSensor } from './SelectableSensor';

export interface MultiSensorEventLogFieldsProps {
  active: boolean;
  visible: boolean;
  form: FormInstance;
  timezone: string;
  namespace?: string | string[];
  availableSensors: SentryApiClient.SensorDTO[];
  selectedSensorIds: number[];
  isUsingSiteTimezone: boolean;
  onSelectSensorIds: (ids: number[]) => void;
  onUseSiteTimezone?: (useSiteTimezone: boolean) => void;
}

export const MultiSensorEventLogFields: React.FC<MultiSensorEventLogFieldsProps> = ({
  active,
  visible,
  namespace,
  form,
  timezone,
  availableSensors,
  isUsingSiteTimezone,
  selectedSensorIds,
  onSelectSensorIds,
  onUseSiteTimezone,
}) => {
  useEffect(() => {
    const ids = availableSensors.map((s) => s.id ?? -1);

    onSelectSensorIds(ids);
    form.setFields([
      {
        name: buildFieldName(namespace, 'sensorIds'),
        value: ids,
      },
    ]);
  }, [availableSensors, form, namespace, onSelectSensorIds]);

  return (
    <>
      <EventLogFormFields
        active={active}
        visible={visible}
        namespace={namespace}
        form={form}
        timezone={timezone}
        isUsingSiteTimezone={isUsingSiteTimezone}
        onUseSiteTimezone={onUseSiteTimezone}
      />
      <MultiSelectList
        data={availableSensors}
        selected={selectedSensorIds}
        renderItem={(props) => <SelectableSensor {...props} />}
        itemId={(s) => s.id ?? -1}
        onSelect={(s) => {
          onSelectSensorIds(s as number[]);

          form.setFields([
            {
              name: buildFieldName(namespace, 'sensorIds'),
              value: s as number[],
            },
          ]);
        }}
      />
    </>
  );
};
