import { useAuthentication } from 'Auth/useAuthentication';
import React, { useEffect } from 'react';
import { useLocation } from '@torqit/torq-tools-react';
import { AUTH_REDIRECT_DELAY } from './constants';
import { SentryLoader } from 'App/components/SentryLoader';
import { useImmediateSplashHiderEffect } from 'App/components/SentrySplash/useImmediateSplashHiderEffect';

export const Login: React.VFC = () => {
  useImmediateSplashHiderEffect();

  const { search } = useLocation();
  const { login } = useAuthentication('/authorize' + search);

  useEffect(() => {
    const timer = setTimeout(login, AUTH_REDIRECT_DELAY);
    return () => clearTimeout(timer);
  }, [login]);

  return <SentryLoader big />;
};
