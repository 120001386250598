import { SentryApiClient } from '_generated/api';
import { SelectTableFilter } from './SelectTableFilter';
import { TableFilterProps } from '@torqit/torq-tools-react';

interface IProps {
  props: TableFilterProps;
}

export const SensorStateFilter: React.FC<IProps> = ({ props }) => {
  return (
    <SelectTableFilter
      {...props}
      options={[
        {
          text: getStateName(SentryApiClient.SensorStateType.Normal),
          value: SentryApiClient.SensorStateType.Normal,
        },
        {
          text: getStateName(SentryApiClient.SensorStateType.AmberHigh),
          value: SentryApiClient.SensorStateType.AmberHigh,
        },
        {
          text: getStateName(SentryApiClient.SensorStateType.AmberLow),
          value: SentryApiClient.SensorStateType.AmberLow,
        },
        {
          text: getStateName(SentryApiClient.SensorStateType.RedHigh),
          value: SentryApiClient.SensorStateType.RedHigh,
        },
        {
          text: getStateName(SentryApiClient.SensorStateType.RedLow),
          value: SentryApiClient.SensorStateType.RedLow,
        },
        {
          text: getStateName(SentryApiClient.SensorStateType.Offline),
          value: SentryApiClient.SensorStateType.Offline,
        },
        {
          text: 'No Data',
          value: SentryApiClient.SensorStateType.Empty,
        },
      ]}
    />
  );
};

function getStateName(type: SentryApiClient.SensorStateType) {
  return SentryApiClient.SensorStateType[type]
    .replace(/([A-Z])/g, ' $1')
    .trim();
}
