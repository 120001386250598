import { Box, Stack, useParams } from '@torqit/torq-tools-react';
import { CompanyFieldsReview, NO_COMPANY_SELECTED } from 'Companies';
import { PanelFieldsReview } from 'Panels/forms/PanelFieldsReview';
import React from 'react';
import { NO_SITE_SELECTED } from 'Sites';
import { SiteFieldsReview } from 'Sites/forms/SiteFieldsReview';
import { SentryApiClient } from '_generated/api';

export interface PanelReviewProps {
  active: boolean;
  canUpdatePanel?: boolean;
  panel?: SentryApiClient.PanelDTO;
  site?: SentryApiClient.SiteDTO;
  company?: SentryApiClient.CompanyDTO;
  onBackToPanel?: () => void;
  onBackToSite?: () => void;
  onBackToCompany?: () => void;
}

export const PanelReview: React.FC<PanelReviewProps> = ({
  active,
  canUpdatePanel,
  panel,
  site,
  company,
  onBackToSite,
  onBackToPanel,
  onBackToCompany,
}) => {
  if (!active) {
    return null;
  }
  return (
    <Box padding="sm">
      <Stack direction="vertical" gap={10} stretch>
        <PanelFieldsReview
          panel={panel}
          onBackToPanel={onBackToPanel}
          canUpdatePanel={canUpdatePanel}
        />
        {site && site.id !== NO_SITE_SELECTED && (
          <SiteFieldsReview site={site} onBackToSite={onBackToSite} />
        )}
        {company && company.id !== NO_COMPANY_SELECTED && (
          <CompanyFieldsReview
            company={company}
            onBackToCompany={onBackToCompany}
          />
        )}
      </Stack>
    </Box>
  );
};
