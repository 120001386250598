import { Dispatch, SetStateAction, useEffect, useState } from 'react';

/**
 * Accepts a prop and maps it to an internal state. Updates to the prop
 * propagate to the internal state
 */
export function usePropState<T>(prop: T): [T, Dispatch<SetStateAction<T>>] {
  const [internalState, setInternalState] = useState(prop);

  useEffect(() => {
    setInternalState(prop);
  }, [prop]);

  return [internalState, setInternalState];
}
