import {
  Button,
  Card,
  Link,
  Stack,
  useAsyncData,
  useHistory,
  useParams,
} from '@torqit/torq-tools-react';
import React, { useEffect, useState } from 'react';
import { PagePresenter, PageState } from 'App/components/PagePresenter';
import { useApi } from 'App/hooks/useApi';
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  ShrinkOutlined,
} from '@ant-design/icons';
import { ConfirmDialog } from 'App/components/ConfirmDelete';
import { FormReviewItem } from 'App/components/FormHelpers';
import { SentryApiClient } from '_generated/api';
import { LetterIcon } from 'App/components/LetterIcon';
import { BankConfigurer } from '../../components/BankConfigurer';
import { MobileIconButton } from 'App/components/MobileIconButton/MobileIconButton';

interface PanelDetailsProps {}

const PanelDetails: React.FC<PanelDetailsProps> = ({}) => {
  const { panelApi, siteApi } = useApi();
  const params = useParams<{ panel: string }>();
  const panelId = params.panel && parseInt(params.panel);

  const {
    data: panelPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(
    async () => (panelId ? panelApi.getUserPermissions(panelId) : undefined),
    [panelId, panelApi]
  );

  const [pageState, setPageState] = useState(PageState.Loaded);
  const {
    data: panelDetails,
    isLoading,
    error,
    executeRequest,
  } = useAsyncData(async () => {
    if (!panelId) {
      return undefined;
    }
    return panelApi.getById(panelId);
  }, [panelId, panelApi]);
  const siteId = panelDetails?.siteId && panelDetails.siteId;
  const {
    data: siteDetails,
    isLoading: siteLoading,
    error: siteError,
  } = useAsyncData(async () => (siteId ? siteApi.getById(siteId) : undefined), [
    siteId,
    siteApi,
  ]);

  useEffect(() => {
    if (isLoading || siteLoading) setPageState(PageState.Loading);
    else if (error || siteError) setPageState(PageState.Error);
    else if (panelDetails) setPageState(PageState.Loaded);
  }, [panelDetails, isLoading, error, siteLoading, siteError]);

  const router = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const onDelete = async () => {
    try {
      setConfirmDelete(false);
      setPageState(PageState.Loading);

      if (panelId) await panelApi.delete(panelId);

      router.push('/panels');
    } catch (error) {
      setPageState(PageState.Error);
    }
  };

  const [confirmUnadopt, setConfirmUnAdopt] = useState(false);
  const onUnadopt = async () => {
    try {
      setConfirmUnAdopt(false);
      setPageState(PageState.Loading);

      if (panelId && panelDetails) {
        const unadoptedPanel: SentryApiClient.PanelDTO = {
          ...panelDetails,
          siteId: undefined,
        };

        await panelApi.put(panelId, unadoptedPanel);

        await executeRequest();
      } else {
        setPageState(PageState.Loaded);
      }
    } catch (error) {
      setPageState(PageState.Error);
    }
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack direction="vertical" gap={20} stretch>
        <Card
          title={
            <Stack gap="apart" alignment="middle">
              <Stack alignment="middle" gap={10}>
                Panel {panelDetails?.name} Details
              </Stack>
              <Stack alignment="end" gap={10}>
                {permissionsLoading && <LoadingOutlined />}
                {panelPermissions?.canUpdate && (
                  <Link to={`/panel/${panelDetails?.id}/edit`}>
                    <MobileIconButton type="primary" icon={<EditOutlined />}>
                      Edit
                    </MobileIconButton>
                  </Link>
                )}
                {!panelDetails?.adopted && panelPermissions?.canUpdate && (
                  <MobileIconButton
                    icon={<ShrinkOutlined />}
                    onClick={() => router.push(`/panel/${panelId}/adopt`)}
                  >
                    Adopt
                  </MobileIconButton>
                )}
                {panelDetails?.adopted && panelPermissions?.canUpdate && (
                  <MobileIconButton
                    icon={<ShrinkOutlined />}
                    onClick={() => setConfirmUnAdopt(true)}
                  >
                    Unadopt
                  </MobileIconButton>
                )}
                {panelPermissions?.canDelete && (
                  <MobileIconButton
                    color="red"
                    icon={<DeleteOutlined />}
                    onClick={() => setConfirmDelete(true)}
                    danger
                  >
                    Delete
                  </MobileIconButton>
                )}
              </Stack>
            </Stack>
          }
        >
          <Stack direction="vertical" alignment="middle" gap={20}>
            <LetterIcon name={panelDetails?.name ?? ''} />
            <Stack.Item />
          </Stack>
          <FormReviewItem field="Panel Name" value={panelDetails?.name} />
          <FormReviewItem
            field="Site"
            value={
              siteDetails && (
                <Link to={`/site/${siteDetails?.id}/details`}>
                  <LetterIcon name={siteDetails?.name ?? ''} small />{' '}
                  {siteDetails?.name}
                </Link>
              )
            }
          />
          <ConfirmDialog
            body={`Are you sure you want to delete ${panelDetails?.name?.toString()}?`}
            onConfirm={onDelete}
            onCancel={() => setConfirmDelete(false)}
            open={confirmDelete}
            okButton={{ danger: true, children: 'Delete' }}
          />
          <ConfirmDialog
            title={'Confirm Unadoption'}
            body={`Are you sure you want to unadopt ${panelDetails?.name?.toString()}?`}
            onConfirm={onUnadopt}
            onCancel={() => setConfirmUnAdopt(false)}
            open={confirmUnadopt}
            okButton={{ type: 'primary', children: 'Unadopt' }}
          />
        </Card>
        <Card title="Sensors">
          {panelDetails && <BankConfigurer panel={panelDetails} />}
        </Card>
      </Stack>
    </PagePresenter>
  );
};

export { PanelDetails };
