import { Stack } from '@torqit/torq-tools-react';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import styles from './AddressDisplay.module.css';

interface AddressFieldProps {
  title?: string;
}

export const AddressField: React.FC<AddressFieldProps> = ({
  title,
  children,
}) => {
  if (!children) {
    return null;
  }

  return (
    <div className={styles.addressField}>
      <div>{children}</div>
      <div className={styles.addressFieldTitle}>{title}</div>
    </div>
  );
};

export interface AddressDisplayProps {
  address?: SentryApiClient.AddressDTO;
  compact?: boolean;
}

export const AddressDisplay: React.FC<AddressDisplayProps> = ({
  address,
  compact,
}) => {
  return (
    <div className={compact ? styles.compact : undefined}>
      <AddressField title="Line 1">{address?.address1}</AddressField>
      <AddressField title="Line 2">{address?.address2}</AddressField>
      <Stack>
        <AddressField title="City">{address?.city}</AddressField>
        <AddressField title="Country">{address?.country}</AddressField>
        <AddressField title="Region">{address?.region}</AddressField>
      </Stack>
      <AddressField title="Postal Code">{address?.zip}</AddressField>
    </div>
  );
};
