import { FormInstance } from '@torqit/torq-tools-react';
import { Fieldset } from 'App/components/FormHelpers';
import { FormEventLog } from 'EventLogs/components/EventLogCreate/useCreateEventLog';
import React from 'react';
import { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { EventLogFormFields } from './EventLogFormFields';
import { SingleSensorWithMultiSelect } from './SingleSensorWithMultiSelect';
import { SingleSensorWithPrompt } from './SingleSensorWithPrompt';

export interface SingleSensorEventLogFieldsProps {
  form: FormInstance<FormEventLog>;
  targetSensor: SentryApiClient.SensorDTO;
  active: boolean;
  visible: boolean;
  timezone: string;
  namespace?: string | string[];
  addableSensors: SentryApiClient.SensorDTO[];
  isUsingSiteTimezone: boolean;
  onUseSiteTimezone?: (useSiteTimezone: boolean) => void;
}

export const SingleSensorEventLogFields: React.FC<SingleSensorEventLogFieldsProps> = ({
  form,
  timezone,
  targetSensor,
  addableSensors,
  active,
  visible,
  namespace,
  isUsingSiteTimezone,
  onUseSiteTimezone,
}) => {
  const [allowSelection, setAllowSelection] = useState(false);

  return (
    <>
      <EventLogFormFields
        active={active}
        visible={visible}
        namespace={namespace}
        form={form}
        timezone={timezone}
        isUsingSiteTimezone={isUsingSiteTimezone}
        onUseSiteTimezone={onUseSiteTimezone}
      />
      {!allowSelection && (
        <SingleSensorWithPrompt
          targetSensor={targetSensor}
          onAddPressed={() => setAllowSelection(true)}
        />
      )}
      {allowSelection && (
        <SingleSensorWithMultiSelect
          targetSensor={targetSensor}
          form={form}
          namespace={namespace}
          addableSensors={addableSensors}
        />
      )}
    </>
  );
};
