import { Box, Card, CardProps, Stack } from '@torqit/torq-tools-react';
import Collapse from '@kunukn/react-collapse';
import React from 'react';
import { useState } from 'react';
import styles from './CollapsibleCard.module.css';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { RhythmScale } from '@torqit/torq-tools-react/dist/UserInterface/Theme';

export interface CollapsibleCardProps extends CardProps {
  defaultHidden?: boolean;
  enableToggling?: boolean;
  bodyPadding?: RhythmScale | false;
}

export const CollapsibleCard: React.FC<CollapsibleCardProps> = ({
  title,
  bodyStyle,
  defaultHidden,
  className,
  children,
  bodyPadding,
  enableToggling = true,
  ...props
}) => {
  const [visible, setVisible] = useState(!defaultHidden);

  return (
    <Card
      {...props}
      className={classNames(styles.card, className)}
      title={
        <div className={classNames({ enableToggling })}>
          <Box padding={{ vertical: 'md', horizontal: 'xl' }}>
            <Stack alignment="middle" gap="md">
              <Stack.Item>{title}</Stack.Item>
              <FontAwesomeIcon
                className={classNames(styles.clickable, styles.smoothIcon)}
                icon={faChevronUp}
                rotation={visible ? 180 : undefined}
                onClick={
                  enableToggling ? () => setVisible(!visible) : undefined
                }
              />
            </Stack>
          </Box>
        </div>
      }
      bodyStyle={{ padding: 0, ...bodyStyle }}
    >
      <Collapse isOpen={visible}>
        {/* Collapsible looks jumpy if its immediate child has padding so I added a div here */}
        <div>
          <Box
            padding={bodyPadding === false ? undefined : bodyPadding ?? 'xl'}
          >
            {children}
          </Box>
        </div>
      </Collapse>
    </Card>
  );
};
