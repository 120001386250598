import {
  ExperimentalForm,
  FormInstance,
  Input,
  InputNumber,
  Stack,
} from '@torqit/torq-tools-react';
import { Fieldset } from 'App/components/FormHelpers';
import { buildFieldName } from 'Auth/buildFieldName';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { SentryApiClient } from '_generated/api';
import { SensorBandFormIndicator } from './SensorBandFormIndicator';

export interface SensorFormFieldsProps {
  active: boolean;
  visible: boolean;
  form: FormInstance<SentryApiClient.SensorDTO>;
  namespace?: string | string[];
}

export const SensorFormFields: React.FC<SensorFormFieldsProps> = ({
  active,
  visible,
  form,
  namespace,
}) => {
  const [redHigh, setRedHigh] = useState<number>();
  const [amberHigh, setAmberHigh] = useState<number>();
  const [amberLow, setAmberLow] = useState<number>();
  const [redLow, setRedLow] = useState<number>();

  useEffect(() => {
    setRedHigh(
      form.getFieldValue(buildFieldName(namespace, 'redHighBand')) as number
    );
    setAmberHigh(
      form.getFieldValue(buildFieldName(namespace, 'amberHighBand')) as number
    );
    setAmberLow(
      form.getFieldValue(buildFieldName(namespace, 'amberLowBand')) as number
    );
    setRedLow(
      form.getFieldValue(buildFieldName(namespace, 'redLowBand')) as number
    );
  }, [form, namespace]);

  const deNaNify = (possiblyNaN?: number) => {
    return possiblyNaN
      ? isNaN(possiblyNaN)
        ? undefined
        : possiblyNaN
      : undefined;
  };

  const clampFromRedHigh = (value?: number) => {
    form.setFields([
      clamp('amberHighBand', setAmberHigh, value, 'min'),
      clamp('amberLowBand', setAmberLow, value, 'min'),
      clamp('redLowBand', setRedLow, value, 'min'),
    ]);
    setRedHigh(value);
  };

  const clampFromAmberHigh = (value?: number) => {
    form.setFields([
      clamp('redHighBand', setRedHigh, value, 'max'),
      clamp('amberLowBand', setAmberLow, value, 'min'),
      clamp('redLowBand', setRedLow, value, 'min'),
    ]);
    setAmberHigh(value);
  };

  const clampFromAmberLow = (value?: number) => {
    form.setFields([
      clamp('redHighBand', setRedHigh, value, 'max'),
      clamp('amberHighBand', setAmberHigh, value, 'max'),
      clamp('redLowBand', setRedLow, value, 'min'),
    ]);
    setAmberLow(value);
  };

  const clampFromRedLow = (value?: number) => {
    form.setFields([
      clamp('redHighBand', setRedHigh, value, 'max'),
      clamp('amberHighBand', setAmberHigh, value, 'max'),
      clamp('amberLowBand', setAmberLow, value, 'max'),
    ]);
    setRedLow(value);
  };

  /**
   * Updates a form field value based on the modification of another form field. For example:
   * if redHigh = 100 and we set amberHigh = 150, redHigh needs to be higher than amberHigh
   * so we clamp its value up to 150 as well.
   *
   * @param name The name of the form field to potentially modify
   * @param setState The local state of the form field to display on the sidebar
   * @param target The value that another one of the states was set to
   * @param clamp How to modify the current value of this form field based on the value from the other form field
   */
  const clamp = (
    name: string,
    setState: (value?: number) => void,
    target: number | undefined,
    clamp: 'min' | 'max'
  ) => {
    const current = form.getFieldValue(
      buildFieldName(namespace, name)
    ) as number;

    if (!current) {
      return {
        name: buildFieldName(namespace, name),
        value: undefined,
      };
    }

    const clampFunc = clamp === 'min' ? Math.min : Math.max;

    const result = target ? clampFunc(current, target) : current;

    setState(result);
    return {
      name: buildFieldName(namespace, name),
      value: result,
    };
  };

  return (
    <Fieldset id="sensors" active={active} visible={visible}>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'sensorName')}
        extra={<span>Sensor Name</span>}
        rules={[
          {
            required: true,
            message: 'Please add a full name!',
          },
        ]}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'description')}
        extra={<span>Description</span>}
        rules={[{ max: 128 }]}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'sensorType')}
        hidden
        initialValue={1}
      >
        <InputNumber />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'calculation')}
        extra={<span>Y Evaluation Function</span>}
        rules={[
          {
            pattern: /(?:(?:^|[-+_*/])(?:\s*-?\d+(\.\d+)?(?:[eE][+-]?\d+)?\s*))+$/,
            message:
              'Please provide a valid expression using decimal values and operators * + - / only. Note that brackets are not allowed. Valid example: * 2 + 3 - 100.0',
          },
        ]}
      >
        <Input addonBefore="Value" />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'readingsUnit')}
        extra={<span>Custom Reading Units</span>}
      >
        <Input addonBefore="Value" />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'readingsLabel')}
        extra={<span>Custom Y Axis Label</span>}
      >
        <Input addonBefore="Value" />
      </ExperimentalForm.Item>
      <Stack stretch gap="md">
        <SensorBandFormIndicator
          redHigh={redHigh}
          amberHigh={amberHigh}
          amberLow={amberLow}
          redLow={redLow}
        />
        <div>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'redHighBand')}
            extra={<span>Red High</span>}
          >
            <InputNumber
              placeholder="None"
              onBlur={(e) =>
                clampFromRedHigh(deNaNify(parseInt(e.currentTarget.value)))
              }
            />
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'amberHighBand')}
            extra={<span>Amber High</span>}
          >
            <InputNumber
              placeholder="None"
              onBlur={(e) =>
                clampFromAmberHigh(deNaNify(parseInt(e.currentTarget.value)))
              }
            />
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'amberLowBand')}
            extra={<span>Amber Low</span>}
          >
            <InputNumber
              placeholder="None"
              onBlur={(e) =>
                clampFromAmberLow(deNaNify(parseInt(e.currentTarget.value)))
              }
            />
          </ExperimentalForm.Item>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, 'redLowBand')}
            extra={<span>Red Low</span>}
          >
            <InputNumber
              placeholder="None"
              onBlur={(e) =>
                clampFromRedLow(deNaNify(parseInt(e.currentTarget.value)))
              }
            />
          </ExperimentalForm.Item>
        </div>
      </Stack>
      <ExperimentalForm.Item name={buildFieldName(namespace, 'siteId')} hidden>
        <Input />
      </ExperimentalForm.Item>
    </Fieldset>
  );
};
