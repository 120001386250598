import { useApi } from 'App/hooks/useApi';
import { SentryApiClient } from '_generated/api';

export function useSitePanelApi() {
  const { panelApi } = useApi();

  return {
    removePanelFromSite: async (
      panel: SentryApiClient.PanelDTO | undefined,
      siteId: number
    ) => {
      if (!panel || !panel.id || siteId === -1) {
        return;
      }
      panel.siteId = undefined;

      await panelApi.put(panel.id, panel);
    },

    addPanelToSite: async (
      panel: SentryApiClient.PanelDTO | undefined,
      siteId: number
    ) => {
      if (!panel || !panel.id || siteId === -1) {
        return;
      }

      panel.siteId = siteId;

      await panelApi.put(panel.id, panel);
    },
  };
}
