import { CloudDownloadOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { Box, Button, notification, Stack } from '@torqit/torq-tools-react';
import { useApi } from 'App';
import { DateServiceSingleton } from 'App/utils/DateService';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { SensorRange } from 'SensorReadings/interfaces';

export interface ImportExportProps {
  range: SensorRange;
  enableImport?: boolean;
  timezone?: string;
}

export const ImportExportButtons: React.FC<ImportExportProps> = ({
  range,
  enableImport,
  timezone,
}) => {
  const inputFile = useRef<HTMLInputElement>(null);

  const { sensorApi } = useApi();
  const [isImporting, setIsImporting] = useState(false);
  const onImport = async (event: any) => {
    setIsImporting(true);
    const file = event.target.files[0];
    try {
      await sensorApi.import(range.sensor.id ?? -1, {
        fileName: file.name,
        data: file,
      });
      const notificationArguments = {
        message:
          'Import request successfully filed. After a few minutes, please refresh the page to view the imported data.',
      };
      notification.success(notificationArguments);
    } catch (e) {
      const notificationArguments = {
        message: 'Import failed',
        description: 'Please ensure that your file is in the correct format.',
        btn: (
          <Button
            onClick={async () => {
              var response = await sensorApi.getImportExampleFile();
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'import-example.csv');
              document.body.appendChild(link);
              link.click();
            }}
          >
            Download an example import file
          </Button>
        ),
      };
      notification.error(notificationArguments);
    }

    setIsImporting(false);
  };

  const [isExporting, setIsExporting] = useState(false);
  const onExport = async (from: Date, to: Date) => {
    setIsExporting(true);
    from = timezone
      ? DateServiceSingleton.ConvertToLocalTime(from, timezone)
      : from;
    to = timezone ? DateServiceSingleton.ConvertToLocalTime(to, timezone) : to;

    let result = await sensorApi.export(
      range.sensor.id ?? -1,
      from,
      to,
      timezone
    );

    if (result.succeeded) {
      notification.success({
        message: result.responseMessage,
      });
    } else {
      notification.warning({
        message: result.responseMessage,
      });
    }

    setIsExporting(false);
  };

  return (
    <Stack direction="vertical" gap="xs">
      <strong>{enableImport ? 'Import/Export' : 'Export'}</strong>
      <Stack>
        {enableImport && (
          <>
            <Button
              loading={isImporting}
              onClick={() => inputFile.current?.click()}
              icon={<CloudUploadOutlined />}
            >
              Import
            </Button>
            <input
              ref={inputFile}
              type="file"
              accept=".csv"
              style={{ display: 'none' }}
              onChange={onImport}
            />
          </>
        )}
        <Box padding={{ left: enableImport ? 'xs' : undefined }}>
          <Button
            loading={isExporting}
            onClick={() => onExport(range.from, range.to)}
            icon={<CloudDownloadOutlined />}
          >
            Export
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
