import { UploadOutlined } from '@ant-design/icons';
import { Box, Button, Stack } from '@torqit/torq-tools-react';
import { EntitySelector } from 'App';
import { MultiSelectList } from 'App/components/MultiSelectList';
import { SentryLoader } from 'App/components/SentryLoader';
import { SelectableUser } from 'Companies/pages/CompanyDetails/SelectableUser';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';

export interface SiteUserSelectorProps {
  siteName?: string;
  visible?: boolean;
  allUsers?: SentryApiClient.UserWithPermissionsDTO[];
  page: number;
  pageSize: number;
  loadingUsers?: boolean;
  userIdsInCompany?: string[];
  onSearch?: (value?: string) => void;
  onSelect?: (users: SentryApiClient.UserDTO[]) => Promise<void>;
  onClose?: () => void;
}

export const SiteUserSelector: React.FC<SiteUserSelectorProps> = ({
  onSelect,
  visible,
  page,
  pageSize,
  allUsers,
  loadingUsers,
  userIdsInCompany,
  siteName,
  onClose,
  onSearch,
}) => {
  const [selection, setSelection] = useState<string[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const submit = async () => {
    setSubmitting(true);
    if (onSelect) {
      await onSelect(
        selection.map(
          (u) =>
            allUsers?.find(
              (userWithPermission) => userWithPermission.user?.id === u
            )?.user!
        )
      );
    }
    setSubmitting(false);

    setSelection([]);
  };

  return (
    <EntitySelector
      title={<>Add users to {siteName}</>}
      visible={visible}
      page={page}
      pageSize={pageSize}
      isLoading={loadingUsers}
      data={allUsers ?? []}
      renderItem={(props) => <SelectableUser {...props} />}
      itemId={(u) => u.user?.id!}
      itemDisabled={(u) => userIdsInCompany?.some((i) => u.user?.id === i)}
      selected={selection}
      onSearch={onSearch}
      footer={
        <Button
          type="primary"
          icon={<UploadOutlined />}
          disabled={selection.length === 0}
          onClick={submit}
          loading={isSubmitting}
        >
          Add to {siteName}
        </Button>
      }
      onSelect={(selection) => setSelection(selection as string[])}
      onClose={onClose}
      emptyMessage="No users found"
    />
  );
};
