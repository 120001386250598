import React from 'react';
import { RouteProvider } from 'App/components/RouteProvider';
import { AuthProvider } from 'Auth';
import './App.css';
import { ThemeProvider } from '../Theme';
import { BreadcrumbsProvider } from 'App/hooks/BreadcrumbsProvider';
import { SentryUserProvider } from 'Users/hooks/SentryUserProvider';
import { SentrySplashProvider } from '../SentrySplash';

export const App: React.VFC = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <SentryUserProvider>
          <BreadcrumbsProvider>
            <SentrySplashProvider>
              <RouteProvider />
            </SentrySplashProvider>
          </BreadcrumbsProvider>
        </SentryUserProvider>
      </AuthProvider>
    </ThemeProvider>
  );
};
