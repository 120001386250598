import { RollbackOutlined } from '@ant-design/icons';
import { Box, Button, Card, useHistory } from '@torqit/torq-tools-react';
import { TokenForceRefresher } from 'Auth';
import React from 'react';

export interface PageNotFoundProps {}

export const PageNotFound: React.FC<PageNotFoundProps> = ({}) => {
  const { goBack } = useHistory();

  return (
    <TokenForceRefresher>
      <div className="sentry-gradient-content">
        <Card
          title={<img src="/sentry-logo.png" alt="Sentry Logo" />}
          headStyle={{
            backgroundColor: 'var(--torq-experimental-primary-color)',
          }}
        >
          <Box padding={{ left: 'sm', bottom: 'lg', right: 'sm' }}>
            <span style={{ fontSize: 15 }}>
              The page you are looking for could not be found!
            </span>
          </Box>
          <div>
            <Button
              type="link"
              icon={<RollbackOutlined />}
              onClick={goBack}
              size="large"
            >
              Go Back
            </Button>
          </div>
        </Card>
      </div>
    </TokenForceRefresher>
  );
};
