import { ChartableSensorReading } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { SentryApiClient } from '_generated/api';
import { ReadingsPipe } from './ReadingsPipe';

//Before we do our modulo application and date rounding, we need to figure out
// which readings in here actually bypass our modulo. We have to figure this
// out now and we have to attach our results to the reading because once the
// outliers have been detected, there's no way to externally know which
// readings are outliers without attaching a flag.
export class DetectOutlierPipe extends ReadingsPipe {
  public constructor() {
    super();
  }

  public enter(readings: ChartableSensorReading[]): void {
    const { highThreshold, lowThreshold } = this.getOutlierThreshold(readings);

    this.exitListener &&
      this.exitListener(
        readings.map((r) => ({
          ...r,
          isOutlier:
            r.rawValue != null &&
            (r.rawValue > highThreshold || r.rawValue < lowThreshold),
        }))
      );
  }

  //Outlier detection philosophy is based on
  // https://stackoverflow.com/questions/20811131/javascript-remove-outlier-from-an-array
  // which itself is based upon Wolfram's outlier detection
  //
  //Long story short, we sort all the readings, figure out the range of values
  // between the middle 50%. Once we got that, anything that's 1.5x that range
  // above or below the 50% is an outlier.
  private getOutlierThreshold(readings: SentryApiClient.SensorReadingDTO[]) {
    const sortedReadings = readings
      .slice()
      .filter((r) => r.rawValue != null)
      .sort((a, b) => a.rawValue! - b.rawValue!);

    if (sortedReadings.length === 0) {
      return { highThreshold: 0, lowThreshold: 0 };
    }

    const firstQuartileIndex = Math.floor(sortedReadings.length * 0.25);
    const thirdQuartileIndex = Math.floor(sortedReadings.length * 0.75);

    const interQuartileRange =
      sortedReadings[thirdQuartileIndex].rawValue! -
      sortedReadings[firstQuartileIndex].rawValue!;

    return {
      highThreshold:
        sortedReadings[thirdQuartileIndex].rawValue! + 1.5 * interQuartileRange,
      lowThreshold:
        sortedReadings[firstQuartileIndex].rawValue! - 1.5 * interQuartileRange,
    };
  }
}
