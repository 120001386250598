import { binarySearchLowerBound } from 'App/utils/binarySearch';
import { DateServiceSingleton } from 'App/utils/DateService';
import {
  CHART_LEFT_PADDING,
  CHART_RIGHT_PADDING,
} from './Charts/CommonLineChartProps';
import { ChartDataProps } from './Charts/LineChart';

interface Point {
  x: number;
  y: number;
}

interface Range<T> {
  min: T;
  max: T;
}

export function calculateClickedPoint(
  point: Point,
  chartRect: DOMRect,
  xRange: Range<Date>,
  timezone: string,
  readings?: ChartDataProps[]
) {
  const xPercentage =
    (point.x - CHART_LEFT_PADDING) /
    (chartRect.width - CHART_LEFT_PADDING - CHART_RIGHT_PADDING);

  const clickedDate = DateServiceSingleton.ConvertToLocalTime(
    new Date(
      (xRange.max.getTime() - xRange.min.getTime()) * xPercentage +
        xRange.min.getTime()
    ),
    timezone
  );

  //We want to display a dot on our primary range, but it's kind of hard
  // to figure out which range that is since they all kind of get jumbled,
  // so honestly, the best bet we have is to just guess. Here, we're just
  // fetching all the lines that belong to the primary sensor.
  const primarySensorLines = readings?.filter((d) => d.isPrimarySensor);

  if (primarySensorLines?.length === 0 || !point) {
    return undefined;
  }

  const potentialPoints = primarySensorLines?.map((p) =>
    binarySearchLowerBound(
      p.data,
      { actualDate: clickedDate },
      (c) => c.actualDate as Date
    )
  );

  const targetDate = potentialPoints?.find((p) => p != null)?.actualDate;

  //We only want to display one dot here so we fetch the first valid dot
  // since ideally, multiple ranges on the same sensor should all be
  // completely different time ranges, so there should only be one valid dot
  // to begin with
  return targetDate ? { date: targetDate as Date } : undefined;
}
