import { CheckOutlined } from '@ant-design/icons';
import {
  Box,
  Button,
  ExperimentalForm,
  FormInstance,
} from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import { SingleSensorEventLogFields } from 'EventLogs/forms/EventLogFormFields/SingleSensorEventLogFields';
import moment from 'moment';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { useCreateEventLog } from './useCreateEventLog';

export interface EventLogCreateProps {
  sensor: SentryApiClient.SensorDTO;
  addableSensors?: SentryApiClient.SensorDTO[];
  timezone: string;
  timestamp?: Date;
  onCreate?: (eventLog: SentryApiClient.EventLogDTO) => void;
  onError?: (error: Error) => void;
}

export const EventLogCreate: React.VFC<EventLogCreateProps> = ({
  sensor,
  timezone,
  timestamp,
  onCreate,
  onError,
  addableSensors,
}) => {
  const [isSiteTimezone, setIsSiteTimezone] = useState(true);

  const { createEventLog, isCreating, form } = useCreateEventLog(
    isSiteTimezone ? timezone : undefined,
    onCreate,
    onError
  );

  return (
    <ExperimentalForm
      wrapperCol={{
        xs: { span: 24 },
      }}
      form={form}
      name="config"
      onFinish={createEventLog}
      scrollToFirstError
      initialValues={{
        sensorIds: [sensor.id],
        timestamp: timestamp ? moment(timestamp) : undefined,
      }}
    >
      <SingleSensorEventLogFields
        visible
        active
        targetSensor={sensor}
        form={form}
        timezone={timezone}
        addableSensors={addableSensors ?? []}
        isUsingSiteTimezone={isSiteTimezone}
        onUseSiteTimezone={setIsSiteTimezone}
      />
      <Box padding={{ top: 'sm' }}>
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={form.submit}
          loading={isCreating}
        >
          Submit
        </Button>
      </Box>
    </ExperimentalForm>
  );
};
