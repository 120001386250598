import { useEffect, useState } from 'react';

export function useWindowWidth() {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  useEffect(() => {
    const updateWidth = (e: UIEvent) => {
      setPageWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return pageWidth;
}
