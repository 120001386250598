import { CloseOutlined } from '@ant-design/icons';
import {
  Button,
  FetchGridParameters,
  Stack,
  useAsyncDataCallback,
} from '@torqit/torq-tools-react';
import { PanelTable } from 'Panels';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { useSitePanelApi } from './useSitePanelApi';

export interface SitePanelListProps {
  siteId: number;
  panels?: SentryApiClient.PanelDTO[];
  totalPanels?: number;
  isLoading?: boolean;
  canRemovePanels?: boolean;
  onPanelsRemoved?: () => void;
  onRemoveFailed?: (error: Error) => void;
  onChange: (params: FetchGridParameters<SentryApiClient.PanelDTO>) => void;
  params: FetchGridParameters<SentryApiClient.PanelDTO>;
}

export const SitePanelList: React.FC<SitePanelListProps> = ({
  panels,
  siteId,
  isLoading,
  canRemovePanels,
  totalPanels,
  params,
  onPanelsRemoved,
  onRemoveFailed,
  onChange,
}) => {
  const [selection, setSelection] = useState<number[]>([]);
  const { removePanelFromSite } = useSitePanelApi();

  const getRemoveButtonText = () => {
    if (selection.length === 0) {
      return 'Remove selection';
    } else if (selection.length === 1) {
      return 'Remove panel';
    } else {
      return `Remove ${selection.length} panels`;
    }
  };

  const {
    executeRequest: removeSelectedPanels,
    isLoading: removingSelectedPanels,
  } = useAsyncDataCallback(
    () =>
      Promise.all(
        selection.map((s) =>
          removePanelFromSite(
            panels?.find((p) => p.id === s),
            siteId
          )
        )
      ),
    [selection, siteId]
  );

  const removeSelectedPanelsAndReload = async () => {
    try {
      await removeSelectedPanels();
    } catch (error) {
      if (onRemoveFailed) {
        onRemoveFailed(error as Error);
      }
    }

    if (onPanelsRemoved) {
      onPanelsRemoved();
    }

    setSelection([]);
  };

  return (
    <PanelTable
      header={
        canRemovePanels && (
          <Stack>
            <Stack gap={20} alignment="middle">
              <Button
                danger
                disabled={selection.length === 0}
                icon={<CloseOutlined />}
                onClick={() => removeSelectedPanelsAndReload()}
                loading={removingSelectedPanels}
              >
                {getRemoveButtonText()}
              </Button>
            </Stack>
          </Stack>
        )
      }
      isSelectable={canRemovePanels}
      panels={panels}
      params={params}
      total={totalPanels}
      isLoading={isLoading}
      selected={selection}
      onChange={onChange}
      onSelect={(s) => {
        setSelection(s as number[]);
      }}
    />
  );
};
