import { LoadingOutlined } from '@ant-design/icons';
import { Stack } from '@torqit/torq-tools-react';
import { usePropState } from 'App';
import classNames from 'classnames';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { SentryApiClient } from '_generated/api';
import styles from './DateSlider.module.css';
import { DateSliderTicks } from './DateSliderTicks';
import {
  DATE_SLIDER_NOW,
  DATE_SLIDER_ONE_YEAR_AGO,
  getDateSliderItemSize,
  YEAR_SPAN,
} from './getDateSliderItemSize';
import {
  useFullscreenMouseMove,
  useFullscreenMouseUp,
} from './useFullscreenMouseEvents';
import { useYearOfSensorStates } from './useYearOfSensorStates';

function getStyleForState(stateType?: SentryApiClient.SensorStateType) {
  switch (stateType) {
    case SentryApiClient.SensorStateType.Normal:
      return [];
    case SentryApiClient.SensorStateType.AmberHigh:
      return [styles.amber, styles.high];
    case SentryApiClient.SensorStateType.AmberLow:
      return [styles.amber, styles.low];
    case SentryApiClient.SensorStateType.RedHigh:
      return [styles.red, styles.high];
    case SentryApiClient.SensorStateType.RedLow:
      return [styles.red, styles.low];
    default:
      return [styles.offline];
  }
}

export interface DateSliderProps {
  sensorId: number;
  start: Date;
  end: Date;
  onChange?: (start: Date, end: Date) => void;
}

export const DateSlider: React.FC<DateSliderProps> = ({
  sensorId,
  start,
  end,
  onChange,
}) => {
  const [isDrawingBar, setDrawingBar] = useState(false);

  const [anchorDate, setAnchorDate] = useState<Date>();
  const [startDate, setStartDate] = usePropState<Date>(start);
  const [endDate, setEndDate] = usePropState<Date>(end);

  const [isDraggingBar, setDraggingBar] = useState(false);

  const { states } = useYearOfSensorStates(sensorId);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const getPositionDate = (clientX: number) => {
    const bounds = wrapperRef.current?.getBoundingClientRect();

    if (!bounds) {
      return DATE_SLIDER_ONE_YEAR_AGO;
    }

    //Clamp the percentage between 0 and 1 so we're not picking dates outside
    // the actual slider
    const percentage = Math.max(
      0,
      Math.min(1, (clientX - bounds.left) / bounds.width)
    );
    return new Date(
      DATE_SLIDER_ONE_YEAR_AGO.getTime() + YEAR_SPAN * percentage
    );
  };

  const onMouseDown = (e: React.MouseEvent) => {
    const date = getPositionDate(e.clientX);

    setAnchorDate(date);
    setStartDate(date);
    setEndDate(date);
    setDrawingBar(true);

    //We need to preventDefault on all our mouse events, otherwise the browser
    // flips out at you for trying to drag something that's not "draggable"
    // and interupts the drag
    e.preventDefault();
  };

  //We hook up our mouseMove and mouseUp events to a fullscreen listener. This
  // way, our dragging and releasing works no matter where the mouse is,
  // instead of only working when right on top of the date slider
  useFullscreenMouseMove(
    (e) => {
      if (isDrawingBar && anchorDate && startDate && endDate) {
        const targetDate = getPositionDate(e.clientX);

        if (targetDate < anchorDate) {
          setStartDate(targetDate);
          setEndDate(anchorDate);
        } else {
          setStartDate(anchorDate);
          setEndDate(targetDate);
        }

        e.preventDefault();
      }
    },
    [isDrawingBar, anchorDate, startDate, endDate, setStartDate, setEndDate]
  );

  const onStartDraggingBar = (e: React.MouseEvent) => {
    setDraggingBar(true);
    e.preventDefault();
    e.stopPropagation();
  };

  useFullscreenMouseMove(
    (e) => {
      if (!isDraggingBar) {
        return;
      }

      const bounds = wrapperRef.current?.getBoundingClientRect();

      if (!bounds || !startDate || !endDate) {
        return;
      }

      //For a while, I was baffled why my slider was moving twice as fast as
      // fast as my mouse despite the fact that all the math checks out. Turns
      // out movementX is in windows space, not browser space. For example, if
      // your monitor has 200% scaling, then moving your mouse 1px on your
      // monitor only actually moves it 0.5px in the browser since every pixel
      // is twice is big in the browser, so we need to account for that here
      // by dividing our movement by the device pixel ratio.
      const spanChange = Math.round(
        (e.movementX / window.devicePixelRatio / bounds.width) * YEAR_SPAN
      );

      //We need to clamp our span change so that we're not translating our
      // selection right out of the slider.
      const targetSpanChange = Math.max(
        DATE_SLIDER_ONE_YEAR_AGO.getTime() - startDate.getTime(),
        Math.min(DATE_SLIDER_NOW.getTime() - endDate.getTime(), spanChange)
      );

      //The start/end date states were really not playing nicely with the full
      // screen hook and its dependencies for some reason that I can't really
      // discern, so instead, we're going to use this different, uglier syntax
      // which at least guarantees that we're operating on the most current
      // version of the state and not one from a few frames ago.
      setStartDate(
        (currentStart) => new Date(currentStart.getTime() + targetSpanChange)
      );
      setEndDate(
        (currentEnd) => new Date(currentEnd.getTime() + targetSpanChange)
      );
    },
    [isDraggingBar, startDate, endDate, setStartDate, setEndDate]
  );

  useFullscreenMouseUp(
    (e) => {
      if (isDrawingBar || isDraggingBar) {
        setDrawingBar(false);
        setDraggingBar(false);
        setAnchorDate(undefined);

        e.preventDefault();

        onChange && onChange(startDate, endDate);
      }
    },
    [isDrawingBar, isDraggingBar, onChange, startDate, endDate]
  );

  return (
    <div>
      <div>
        <strong>{startDate ? moment(startDate).format('ll LT') : '--'}</strong>{' '}
        to <strong>{endDate ? moment(endDate).format('ll LT') : '--'}</strong>
      </div>
      <div
        ref={wrapperRef}
        className={styles.wrapper}
        onMouseDown={onMouseDown}
      >
        <Stack alignment="end" fill>
          {states
            ?.slice()
            .reverse()
            .map((s) => (
              <Stack.Item
                size={getDateSliderItemSize(s.startTime, s.endTime)}
                key={s.id}
              >
                <div
                  className={classNames(
                    styles.item,
                    getStyleForState(s.stateType)
                  )}
                ></div>
              </Stack.Item>
            ))}
        </Stack>
        <div className={styles.selectionOverlay}>
          <Stack fill stretch>
            {startDate && (
              <Stack.Item
                size={getDateSliderItemSize(
                  DATE_SLIDER_ONE_YEAR_AGO,
                  startDate
                )}
              >
                <div className={styles.unselectedArea}></div>
              </Stack.Item>
            )}
            {startDate && endDate && (
              <Stack.Item size={getDateSliderItemSize(startDate, endDate)}>
                <div
                  className={styles.selectedArea}
                  onMouseDown={onStartDraggingBar}
                ></div>
              </Stack.Item>
            )}
            {endDate && (
              <Stack.Item
                size={getDateSliderItemSize(endDate, DATE_SLIDER_NOW)}
              >
                <div className={styles.unselectedArea}></div>
              </Stack.Item>
            )}
          </Stack>
        </div>
      </div>
      <DateSliderTicks />
    </div>
  );
};
