import { DragAndDropProvider, Stack } from '@torqit/torq-tools-react';
import { useBreakpoints } from 'App/components/Theme';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import styles from './BankConfigurer.module.css';
import { SensorBank } from './SensorBank';

export interface BankConfigurerProps {
  panel: SentryApiClient.PanelDTO;
  editable?: boolean;
  onChange?: (panel: SentryApiClient.PanelDTO) => void;
}

export const BankConfigurer: React.FC<BankConfigurerProps> = ({
  panel,
  editable,
  onChange,
}) => {
  const { xxl: allBanksSameLine } = useBreakpoints();

  const internalSensors = [
    panel.bankOneSensor,
    panel.bankTwoSensor,
    panel.bankThreeSensor,
    panel.bankFourSensor,
  ];
  const setBank = (bank: number, sensor?: SentryApiClient.SensorDetailsDTO) => {
    const nextSensors = [...internalSensors];
    nextSensors[bank] = sensor;

    updateSensors(nextSensors);
  };

  const swapInSensor = (bank: number, sensorId: number) => {
    const nextSensors = [...internalSensors];
    const originalIndex = nextSensors.findIndex((s) => s?.id === sensorId)!;

    const bucket = nextSensors[bank];
    nextSensors[bank] = nextSensors[originalIndex];
    nextSensors[originalIndex] = bucket;

    updateSensors(nextSensors);
  };

  const updateSensors = (
    nextSensors: (SentryApiClient.SensorDetailsDTO | undefined)[]
  ) => {
    const updatedPanel: SentryApiClient.PanelDTO = {
      ...panel,
      bankOneSensor: nextSensors[0],
      bankTwoSensor: nextSensors[1],
      bankThreeSensor: nextSensors[2],
      bankFourSensor: nextSensors[3],
    };

    onChange && onChange(updatedPanel);
  };

  return (
    <DragAndDropProvider>
      <div className={styles.wrapper}>
        <Stack
          direction={allBanksSameLine ? 'horizontal' : 'vertical'}
          gap={allBanksSameLine ? 12 : 0}
          stretch
        >
          <Stack.Item>
            <Stack gap={12}>
              <Stack.Item>
                <SensorBank
                  bankTitle="Bank One"
                  siteId={panel.siteId!}
                  sensor={internalSensors[0]}
                  editable={editable}
                  activeSensorIds={internalSensors
                    .filter((s) => s != null)
                    .map((s) => s?.id!)}
                  onChange={(s) => setBank(0, s)}
                  onDrop={(id) => swapInSensor(0, id)}
                />
              </Stack.Item>
              <Stack.Item>
                <SensorBank
                  bankTitle="Bank Two"
                  siteId={panel.siteId!}
                  sensor={internalSensors[1]}
                  editable={editable}
                  activeSensorIds={internalSensors
                    .filter((s) => s != null)
                    .map((s) => s?.id!)}
                  onChange={(s) => setBank(1, s)}
                  onDrop={(id) => swapInSensor(1, id)}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item>
            <Stack gap={12}>
              <Stack.Item>
                <SensorBank
                  bankTitle="Bank Three"
                  siteId={panel.siteId!}
                  sensor={internalSensors[2]}
                  editable={editable}
                  activeSensorIds={internalSensors
                    .filter((s) => s != null)
                    .map((s) => s?.id!)}
                  onChange={(s) => setBank(2, s)}
                  onDrop={(id) => swapInSensor(2, id)}
                />
              </Stack.Item>
              <Stack.Item>
                <SensorBank
                  bankTitle="Bank Four"
                  siteId={panel.siteId!}
                  sensor={internalSensors[3]}
                  editable={editable}
                  activeSensorIds={internalSensors
                    .filter((s) => s != null)
                    .map((s) => s?.id!)}
                  onChange={(s) => setBank(3, s)}
                  onDrop={(id) => swapInSensor(3, id)}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </div>
    </DragAndDropProvider>
  );
};
