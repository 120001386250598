import moment from 'moment';
import {
  ChartDataProps,
  LineChart,
  SensorReadingBands,
} from './Charts/LineChart';
import { ScatterPlotChart } from './Charts/SensorScatterPlotChart';
import { useCallback, useMemo } from 'react';
import { SensorReadingsRangeData } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { DateServiceSingleton } from 'App/utils/DateService';
import { getNiceColor } from 'App/utils/niceColors';
import { ChartTypes } from 'Sensors';
import { useLocalStorage } from '@torqit/torq-tools-react';
import { useChartClicker } from './ChartClickerProvider';
import { calculateClickedPoint } from './calculateClickedPoint';
import { SensorRange } from 'SensorReadings/interfaces';

function getTimezoneAdjustedTime(date: Date | undefined, offset: number) {
  return (date?.getTime() ?? 0) - offset * 1000 * 60;
}

//Say we're comparing two ranges; left axis is 0-300 and right axis is
// 350-400. Because the right axis is purely cosmetic, the displayed range
// only respects data on the left axis which means our 0-300 range is not
// going to display the other data. To actually display our right axis
// data, we need to transform it so that it actually fits on the left axis
function formatRightAxisData(
  value: number,
  leftYMin: number | undefined,
  leftYMax: number,
  rightYMin: number,
  rightYMax: number
) {
  const effectiveMin = leftYMin ?? 0;
  const ratio = (leftYMax - effectiveMin) / (rightYMax - rightYMin);

  return (value - rightYMin) * ratio + effectiveMin;
}

export interface SensorReadingsGraphProps {
  type: ChartTypes;
  targetRange: SensorRange;
  startDate: Date;
  endDate: Date;
  readings?: SensorReadingsRangeData[];
  yMin?: number;
  yMax?: number;
  rightYMin?: number;
  rightYMax?: number;
  bands?: SensorReadingBands;
  yLabel?: string;
  yUnit?: string;
  timezone?: string;
  showEventLogs?: boolean;
}

export const SensorReadingsGraph: React.FC<SensorReadingsGraphProps> = ({
  type = ChartTypes.line,
  readings,
  yMin,
  yMax,
  rightYMin,
  rightYMax,
  startDate,
  endDate,
  bands,
  yLabel = 'Carbon Consumption Rate',
  yUnit,
  timezone,
  showEventLogs,
  targetRange,
}) => {
  const Chart = type === ChartTypes.line ? LineChart : ScatterPlotChart;

  const timezoneOffset = DateServiceSingleton.GetMyTimezoneOffset();
  const relativeTimezoneOffset = useMemo(
    () =>
      timezone ? DateServiceSingleton.GetRelativeTimezoneOffset(timezone) : 0,
    [timezone]
  );

  const { onClick } = useChartClicker();

  const bounds = useMemo(() => {
    return {
      maxLeftAxis: readings
        ?.filter((r) => !r.isRightAxis)
        .reduce(
          (maxReadingValue, currentRange) =>
            currentRange.maxReading?.rawValue &&
            currentRange.maxReading.rawValue > maxReadingValue
              ? currentRange.maxReading.rawValue
              : maxReadingValue,
          0
        ),
      maxRightAxis:
        (readings
          ?.filter((r) => r.isRightAxis)
          .reduce(
            (maxReadingValue, currentRange) =>
              currentRange.maxReading?.rawValue &&
              currentRange.maxReading.rawValue > maxReadingValue
                ? currentRange.maxReading.rawValue
                : maxReadingValue,
            0
          ) ?? 1000) * 1.1,
    };
  }, [readings]);

  const autoMaxY = useMemo(
    () =>
      bounds.maxLeftAxis
        ? bounds.maxLeftAxis
        : Math.max(
            bands?.redHigh ?? 0,
            bands?.amberHigh ?? 0,
            bounds.maxLeftAxis ?? 0
          ) * 1.1,
    [bands, bounds]
  );

  const chartData = useMemo(() => {
    const data: ChartDataProps[] = [];

    readings?.forEach((r, index) => {
      if (r.data == null) {
        return;
      }

      const chartData: ChartDataProps = {
        id:
          r.sensor.sensorName +
          ' - ' +
          new Date(
            r.startDate.getTime() - relativeTimezoneOffset * 1000 * 60
          ).toLocaleString(),
        color: getNiceColor(index),
        isPrimarySensor: r.sensor.id === targetRange.sensor.id,
        data: r.data.map((s) => {
          return {
            rangeId: r.id,
            x: moment(
              getTimezoneAdjustedTime(
                s.roundedDate,
                relativeTimezoneOffset - timezoneOffset
              )
            ).toDate(),
            y:
              s.rawValue !== null && s.rawValue !== undefined
                ? r.isRightAxis
                  ? formatRightAxisData(
                      s.rawValue,
                      yMin,
                      yMax ?? autoMaxY,
                      rightYMin ?? 0,
                      rightYMax ?? bounds.maxRightAxis
                    )
                  : s.rawValue
                : null,
            //We want our data points to align perfectly on the grid, but we
            // don't want to forget what time they actually happened at. To
            // keep track, we tack on an extra field here that the tooltip
            // then makes use of.
            actualDate: moment(
              getTimezoneAdjustedTime(s.readingTime, relativeTimezoneOffset)
            ).toDate(),
            actualValue: s.rawValue,
            temperature: s.temperature,
          };
        }),
        dateOffset: r.offset,
        events: r.events,
      };

      data.push(chartData);
    });

    return data;
  }, [
    readings,
    relativeTimezoneOffset,
    yMin,
    yMax,
    autoMaxY,
    bounds,
    rightYMin,
    rightYMax,
    targetRange,
    timezoneOffset,
  ]);

  const range = useMemo(() => {
    let lowestDate = startDate;
    let highestDate = endDate;
    let highestReading = 0;

    chartData.forEach((l) => {
      l.data.forEach((d) => {
        const x = d.actualDate as Date;

        if (x < lowestDate) {
          lowestDate = x;
        }

        if (x > highestDate) {
          highestDate = x;
        }
      });
    });

    return { lowestDate, highestDate, highestReading };
  }, [chartData, startDate, endDate]);

  return (
    <div
      style={{
        width: '100%',
        height: '520px',
        borderRight: '1px solid #aeaeb7',
      }}
      onClick={(e) => {
        const rect = e.currentTarget.getBoundingClientRect();
        onClick(
          calculateClickedPoint(
            {
              x: e.clientX - rect.left,
              y: e.clientY - rect.top,
            },
            rect,
            { min: range.lowestDate, max: range.highestDate },
            timezone ?? moment.tz.guess(),
            chartData
          )
        );
      }}
    >
      <Chart
        lines={chartData.concat({
          id: null as any,
          color: 'white',
          data: [
            {
              x: moment(
                getTimezoneAdjustedTime(startDate, -timezoneOffset)
              ).toDate(),
              y: null,
            },
            {
              x: moment(
                getTimezoneAdjustedTime(endDate, -timezoneOffset)
              ).toDate(),
              y: null,
            },
          ],
          dateOffset: 0,
          isPrimarySensor: false,
        })}
        yLabel={yLabel}
        xLabel="Primary Date Range"
        yUnits={yUnit}
        point={false}
        leftYMin={yMin ?? 0}
        leftYMax={yMax ?? autoMaxY}
        rightYMin={rightYMin ?? 0}
        rightYMax={rightYMax ?? bounds.maxRightAxis}
        earliestDate={range.lowestDate}
        latestDate={range.highestDate}
        timezone={timezone ?? moment.tz.guess()}
        bands={bands}
        showEventLogs={showEventLogs}
      />
    </div>
  );
};
