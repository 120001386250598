import { ChartableSensorReading } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { ReadingsPipe } from './ReadingsPipe';

export class OffsetDatePipe extends ReadingsPipe {
  private offset: number;

  public constructor(millisecondOffset: number) {
    super();
    this.offset = millisecondOffset;
  }

  public enter(readings: ChartableSensorReading[]): void {
    readings.forEach((r) => {
      r.readingTime = r.readingTime
        ? new Date(r.readingTime.getTime() + this.offset)
        : undefined;
      r.roundedDate = r.roundedDate
        ? new Date(r.roundedDate.getTime() + this.offset)
        : undefined;
    });

    this.exitListener && this.exitListener(readings);
  }
}
