import {
  Box,
  Card,
  Layout,
  Link,
  Redirect,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { PagePresenter, PageState } from 'App/components';
import { useApi } from 'App/hooks';
import { fullWidthStyles } from 'App/styles';
import { useUser } from 'Auth';
import React, { useState } from 'react';
import { DashboardCompanyCard } from './DashboardCompanyCard';
import { useWindowWidth } from 'App/hooks/useWindowWidth';
import { DashboardItemEmpty } from 'App/components/DashboardItemEmpty';
import styles from './Dashboard.module.css';
import { SentryApiClient } from '_generated/api';
import { LoadingOutlined } from '@ant-design/icons';

export interface DashboardProps {}

const redStates = [
  SentryApiClient.SensorStateType.RedHigh,
  SentryApiClient.SensorStateType.RedLow,
];

const amberStates = [
  SentryApiClient.SensorStateType.AmberHigh,
  SentryApiClient.SensorStateType.AmberLow,
];

export const Dashboard: React.FC<DashboardProps> = ({}) => {
  const user = useUser();
  const { siteApi, userApi, sensorStateApi } = useApi();
  const [companyFavourites, setCompanyFavourites] = useState<number[]>([]);

  const pageWidth = useWindowWidth();

  //We fetch the very first site that comes up along with a total number
  // of sites this user has access to. If they have only 1, we can redirect
  // them immediately
  const { data: sites, isLoading: loadingSites } = useAsyncData(
    () => siteApi.get(1),
    [siteApi]
  );

  const {
    data: apiUser,
    isLoading: isLoadingUserData,
  } = useAsyncData(async () => {
    let apiUser = user?.localAccountId
      ? await userApi.getById(user?.localAccountId)
      : undefined;

    apiUser?.companyFavourites
      ? setCompanyFavourites(apiUser.companyFavourites)
      : setCompanyFavourites([]);

    return apiUser;
  }, [userApi, user]);

  const { data: companiesList, isLoading, error } = useAsyncData(
    async () =>
      user?.localAccountId
        ? userApi.getCompanies(user.localAccountId)
        : undefined,
    [userApi, user, companyFavourites]
  );

  const {
    data: sensorStateCounts,
    isLoading: isLoadingStateCounts,
  } = useAsyncData(
    async () =>
      user?.localAccountId
        ? sensorStateApi.getAlertCounts(user?.localAccountId)
        : undefined,
    [sensorStateApi, user]
  );

  const toggleCompanyUserFavourites = async (companyId: number) => {
    if (apiUser && user) {
      if (!apiUser.companyFavourites) {
        apiUser.companyFavourites = [];
      }
      apiUser.companyFavourites = apiUser.companyFavourites.includes(companyId)
        ? apiUser.companyFavourites.filter((id) => id != companyId)
        : apiUser.companyFavourites.concat(companyId);

      await userApi.put(user?.localAccountId, apiUser);
      setCompanyFavourites(apiUser.companyFavourites);
    }
  };

  const redCount =
    (sensorStateCounts?.redHighCount || 0) +
    (sensorStateCounts?.redLowCount || 0);

  const amberCount =
    (sensorStateCounts?.amberHighCount || 0) +
    (sensorStateCounts?.amberLowCount || 0);

  const getPageState = () => {
    if (isLoading || loadingSites) {
      return PageState.Loading;
    }

    if (error) {
      return PageState.Error;
    }

    return PageState.Loaded;
  };

  if (sites && sites.items && sites?.totalItems === 1) {
    return <Redirect to={`/dashboard/site/${sites.items[0].id}`} />;
  }

  return (
    <div>
      <PagePresenter pageState={getPageState()}>
        <Box padding={{ vertical: 'xl', horizontal: 'md' }}>
          <Box padding={{ bottom: 'xl' }}>
            <div className={styles.title}>Welcome back, {user?.name}</div>
            <div className={styles.subTitle}>
              Across your sites, you have{' '}
              {isLoadingStateCounts ? <LoadingOutlined /> : redCount} danger
              alert
              {redCount === 1 ? '' : 's'} and{' '}
              {isLoadingStateCounts ? <LoadingOutlined /> : amberCount} warning
              alert
              {amberCount === 1 ? '' : 's'}.
            </div>
          </Box>
        </Box>
        <Layout size={Math.max(pageWidth - 36, 1400)} gutter={12}>
          {companiesList?.length !== 0 &&
            companiesList?.map((c) => (
              <Layout.Item key={c.id} oneThird>
                <DashboardCompanyCard
                  company={c}
                  toggleFavourite={toggleCompanyUserFavourites}
                  isFavourite={c.id ? companyFavourites.includes(c.id) : false}
                />
              </Layout.Item>
            ))}
          {companiesList?.length === 0 && (
            <Layout.Item oneThird>
              <DashboardItemEmpty message="You are currently not assigned to any companies." />
            </Layout.Item>
          )}
        </Layout>
      </PagePresenter>
    </div>
  );
};
