import { Result } from '@torqit/torq-tools-react';
import React, { useEffect, useState } from 'react';
import { EmptyMessage } from './EmptyMessage';
import { ErrorMessage } from './ErrorMessage';
import { LoadingMessage } from './LoadingMessage';
import styles from './pagePresenter.module.css';

export enum PageState {
  Empty,
  Loading,
  Error,
  Loaded,
}

export interface PageStateProps {
  pageState: PageState;
  subject?: string;
}

const PagePresenter: React.FC<PageStateProps> = ({
  pageState,
  children,
  subject,
}) => {
  const [loadingPhrases, setLoadingPhrases] = useState<string[]>([
    'Loading...',
  ]);

  const onAllPhrasesDisplayed = () => {
    setLoadingPhrases([
      'Assembling table rows...',
      'Building other stuff...',
      'Taking my time...',
    ]);
  };

  const renderCover = () => {
    switch (pageState) {
      case PageState.Loading:
        return (
          <div className={styles.pageStateWrapper}>
            <LoadingMessage
              phrases={subject ? [`Loading ${subject}...`] : loadingPhrases}
              onAllPhrasesDisplayed={onAllPhrasesDisplayed}
            />
          </div>
        );
      case PageState.Error:
        return (
          <div className={styles.pageStateWrapper}>
            <ErrorMessage />
          </div>
        );
      case PageState.Empty:
        return (
          <div className={styles.pageStateWrapper}>
            <EmptyMessage subject={subject} />
          </div>
        );
    }
  };

  return (
    <>
      {renderCover()}
      {/* Even if the children shouldn't be visible, we're still going to
      render them, otherwise some features start behaving weirdly when
      they get repeatedly mounted/unmounted as the page state changes
      (namely the useLocationState() hooks on all the entity tables) */}
      <div
        style={{ display: pageState === PageState.Loaded ? 'block' : 'none' }}
      >
        {children}
      </div>
    </>
  );
};

export { PagePresenter };
