import { FolderOpenFilled } from '@ant-design/icons';
import { Stack } from '@torqit/torq-tools-react';
import React from 'react';
import styles from './DashboardItemEmpty.module.css';

export interface DashboardItemEmptyProps {
  message?: string;
}

export const DashboardItemEmpty: React.FC<DashboardItemEmptyProps> = ({
  message,
}) => {
  return (
    <Stack direction="vertical" stretch alignment="middle">
      <FolderOpenFilled style={{ color: '#d1d1d1', fontSize: 75 }} />
      <div className={styles.text}>{message}</div>
    </Stack>
  );
};
