import { Result } from '@torqit/torq-tools-react';
import { FolderOpenFilled } from '@ant-design/icons';
import React from 'react';
import styles from './EmptyMessage.module.css';

export interface EmptyMessageProps {
  subject?: string;
}

const EmptyMessage: React.FC<EmptyMessageProps> = ({ subject }) => {
  return (
    <div id={styles.pagePresenter}>
      <Result
        icon={<FolderOpenFilled className={styles.icon} />}
        title={subject ? `No ${subject} found` : 'Empty'}
        subTitle="There's nothing here yet!"
      />
    </div>
  );
};

export { EmptyMessage };
