import React, { useEffect, useState } from 'react';
import {
  Card,
  useAsyncData,
  Tooltip,
  FetchGridParameters,
  Stack,
  useHistory,
} from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import { PagePresenter } from 'App/components/PagePresenter';
import { PageState } from 'App/components/PagePresenter/PagePresenter';
import { SensorTable } from '../components/SensorTable/SensorTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { SentryApiClient } from '_generated/api';
import { DEFAULT_PAGE_PARAMS } from 'App/components/TableHelpers/defaultPageSettings';
import { sensorFilterMap } from 'Sensors/components/SensorTable/sensorFilterMap';
import { toOrderDirection } from 'App/utils/toOrderDirection';
import { useFirstLoadIsEmpty } from 'App/hooks/useFirstLoadIsEmpty';
import { useLocationState } from 'react-router-use-location-state';

export const Sensors: React.FC = () => {
  const { sensorApi } = useApi();

  const [params, setParams] = useLocationState<
    FetchGridParameters<SentryApiClient.SensorDetailsDTO>
  >('params', { ...DEFAULT_PAGE_PARAMS, orderKey: 'sensorName' });

  const { data: sensorsList, isLoading, error } = useAsyncData(
    () =>
      sensorApi.get(
        params.pageSize,
        params.page,
        sensorFilterMap.toFilter(params.orderKey),
        toOrderDirection(params.order),
        sensorFilterMap.convert(params.filters)
      ),
    [params, sensorApi]
  );

  const isInitialLoadEmpty = useFirstLoadIsEmpty(sensorsList?.items);

  const getPageState = () => {
    if (error) return PageState.Error;
    if (isLoading) return PageState.Loading;
    else if (isInitialLoadEmpty) return PageState.Empty;
    else return PageState.Loaded;
  };

  return (
    <>
      <PagePresenter pageState={getPageState()} subject="sensors">
        <Card
          title={
            <Stack gap={'apart'}>
              <Stack>Sensors</Stack>
              <Stack>
                <Tooltip
                  overlay={'Sensors must be created in the panel details'}
                  placement="left"
                >
                  <span>Add</span> <FontAwesomeIcon icon={faQuestionCircle} />
                </Tooltip>
              </Stack>
            </Stack>
          }
        >
          <SensorTable
            sensors={sensorsList?.items}
            params={params}
            total={sensorsList?.totalItems}
            onChange={setParams}
          />
        </Card>
      </PagePresenter>
    </>
  );
};
