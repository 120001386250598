import { useApi } from 'App/hooks/useApi';
import { SentryApiClient } from '_generated/api';

export function useCompanySiteApi() {
  const { siteApi } = useApi();

  return {
    removeSiteFromCompany: async (
      site: SentryApiClient.SiteDTO | undefined,
      companyId: number
    ) => {
      if (!site || !site.id || companyId === -1) {
        return;
      }

      site.company = undefined;

      await siteApi.put(site.id, site);
    },

    addSiteToCompany: async (
      site: SentryApiClient.SiteDTO | undefined,
      companyId: number
    ) => {
      if (!site || !site.id || companyId === -1) {
        return;
      }

      site.company = companyId;

      await siteApi.put(site.id, site);
    },
  };
}
