import { ResponsiveLine } from '@nivo/line';
import { Card, Link, Stack } from '@torqit/torq-tools-react';
import { SensorHeader, SentryLoader } from 'App';
import { DashboardItemEmpty } from 'App/components/DashboardItemEmpty';
import { getNiceColor } from 'App/utils/niceColors';
import { ChartDataProps } from 'Graphs/interfaces';
import moment from 'moment';
import React, { useMemo } from 'react';
import { BandLayer } from 'SensorReadings';
import { SensorStateIcon } from 'Sensors/components/SensorStateIcon';
import { SentryApiClient } from '_generated/api';
import { useMiniChartFetcher } from './useMiniChartFetcher';
import styles from './SiteSensorCard.module.css';
import { DateServiceSingleton } from 'App/utils/DateService';

const now = new Date();
const oneDayAgo = moment(now).subtract(1, 'day').toDate();

export interface SiteSensorCardProps {
  sensor: SentryApiClient.SensorDetailsDTO;
  timezone: string;
  tall?: boolean;
}

export const SiteSensorCard: React.FC<SiteSensorCardProps> = ({
  sensor,
  timezone,
  tall,
}) => {
  const { readings, isLoading } = useMiniChartFetcher(sensor);

  const chartData = useMemo(() => {
    const chartData: ChartDataProps[] = [
      {
        id: sensor.id?.toString() ?? '',
        color: getNiceColor(0),
        data:
          readings?.map((s) => ({
            x: moment(s.readingTime).toDate(),
            y:
              s.rawValue !== null && s.rawValue !== undefined
                ? s.rawValue
                : null,
          })) ?? [],
      },
    ];

    return chartData;
  }, [sensor, readings]);

  const highestReading = useMemo(() => {
    let highestReading = 0;

    chartData.forEach((l) => {
      l.data.forEach((d) => {
        const y = (d.y as number) ?? 0;

        if (y > highestReading) {
          highestReading = y;
        }
      });
    });

    return highestReading;
  }, [chartData]);

  const autoMaxY = Math.max(
    sensor?.redHighBand ?? 0,
    sensor?.amberHighBand ?? 0,
    highestReading
  );

  return (
    <Link to={`/dashboard/sensor/${sensor.id}`}>
      <Card
        title={
          <SensorHeader
            sensorState={sensor.stateType}
            calculation={sensor.calculation}
            sensorName={sensor.sensorName}
            sensorDescription={sensor.description}
          />
        }
        bodyStyle={{ height: tall ? 700 : 300 }}
      >
        {isLoading && (
          <Stack alignment="middle">
            <SentryLoader big />
          </Stack>
        )}
        {!isLoading && readings.length === 0 && (
          <Stack alignment="middle" fill>
            <DashboardItemEmpty message="No readings over the last 24 hours" />
          </Stack>
        )}
        {!isLoading && readings.length !== 0 && (
          <ResponsiveLine
            colors={getNiceColor(0)}
            margin={{ left: 40, bottom: 30 }}
            xScale={{ format: '%Y-%m-%dT%H:%M:%S.%L%Z', type: 'time' }}
            data={chartData.concat({
              id: null as any,
              color: 'white',
              data: [
                {
                  x: oneDayAgo,
                  y: null,
                },
                {
                  x: now,
                  y: null,
                },
              ],
            })}
            layers={[
              'grid',
              'markers',
              'axes',
              'areas',
              'lines',
              'slices',
              (props) => (
                <BandLayer
                  redHigh={sensor?.redHighBand}
                  amberHigh={sensor?.amberHighBand}
                  amberLow={sensor?.amberLowBand}
                  redLow={sensor?.redLowBand}
                  {...props}
                />
              ),
              'legends',
            ]}
            yScale={{
              type: 'linear',
              min: 0,
              max: autoMaxY * 1.1,
            }}
            axisLeft={{
              tickValues: 3,
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -50,
              legendPosition: 'middle',
            }}
            axisBottom={{
              tickValues: 4,
              format: (d: Date) =>
                moment(
                  DateServiceSingleton.ConvertToTimezoneTime(d, timezone)
                ).format('h a'),
            }}
          />
        )}
      </Card>
    </Link>
  );
};
