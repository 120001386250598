import moment from 'moment';
import { timezones } from './timezones';

export class DateService {
  public GetTimezones() {
    return timezones;
  }

  private ToTime(offset: number) {
    return (
      (offset < 0 ? '-' : '') +
      Math.abs(Math.floor(offset / 60))
        .toString()
        .padStart(2, '0') +
      ':' +
      Math.abs(offset % 60)
        .toString()
        .padStart(2, '0')
    );
  }

  public GetDateTime(
    currentDate: Date,
    displayUtc: boolean,
    format?: string
  ): string {
    if (displayUtc) {
      return moment(currentDate)
        .utc()
        .format(format ?? 'h AA');
    } else {
      return moment(currentDate).format(format ?? 'h AA');
    }
  }

  public GetRelativeTimezoneOffset(timezone: string | number) {
    let tz = this.CalculateTimezone(timezone);
    const currentTimezoneOffset = moment.tz(moment.tz.guess()).utcOffset();
    const offset = moment.tz(tz).utcOffset();

    return currentTimezoneOffset - offset;
  }

  public GetMyTimezoneOffset() {
    return moment.tz(moment.tz.guess()).utcOffset();
  }

  public ConvertToLocalTime(timezoneTime: Date, siteTimezone: string | number) {
    //Say I'm an admin in Charlottetown (-3:00 (during DST)) trying to check the readings
    // for a client in Toronto(-4:00), who says they're seeing something funny at 9 AM.
    // For them, 9 AM would be 1 PM UTC, but for us, 9 AM is 12 PM UTC. To convert our
    // UTC time to the UTC time that we actually want, we're going to need to apply
    // the difference between our timezones to get the proper date and time which then
    // gets converted to UTC. This is in minutes though, so the resulting math is
    // ((-180) - (-240)) = 60 minutes. This gives us 10 AM our time, which is 9 AM
    // their time, which is 1 PM UTC, which is our final goal.
    return moment(timezoneTime)
      .add(this.GetRelativeTimezoneOffset(siteTimezone), 'minutes')
      .toDate();
  }

  public ConvertToTimezoneTime(localTime: Date, siteTimezone: string | number) {
    return moment(localTime)
      .subtract(this.GetRelativeTimezoneOffset(siteTimezone), 'minutes')
      .toDate();
  }

  //Well this is pretty nasty! When running the app locally and
  // filtering readings on the server by date, the server converts the UTC
  // filters to LOCAL time and then runs the filter. That means running the
  // server locally here on PEI and trying to fetch a range of readings
  // between 5PM-7PM will give you readings between 2PM-4PM. All the dates
  // on the readings are right! It just returns the wrong range!
  //
  //To see what centered data looks like, we're going to take our date
  // range and shift our times as if we're currently sitting in a UTC
  // timezone. This gives us correctly dated sensor readings within
  // the correct range, but this WILL NOT WORK if you hook it up
  // to a server that's actually in UTC
  public __APPLY_TIMEZONE_WRONGLY(date: Date, timezone: string | number) {
    const timezoneOffset =
      this.GetRelativeTimezoneOffset(timezone) - this.GetMyTimezoneOffset();

    return new Date(date.getTime() + timezoneOffset * 1000 * 60);
  }

  /**
   * This method is a bit of an embarrasment, but it is necessary for dev work. It follows the
   * same logic as the abberration above. In this case, we have a date in UTC, but
   * Date appends our local time at the end. The code below sets the date back into local time
   * without changing the local time designation, and proceeds to convert it to the timezone desired.
   * Essentially, if we assume a time in UTC -4 of 12:00, the date object will look like
   * 16:00 UTC-4 (which is incorrect because it should not have the -4)
   * we then force it into 12:00 (UTC-4) this time it is the correct local time
   * then we create a new date object with the timezone desired. This will give us what we want
   */
  public __APPLY_TIMEZONE_FROM_WRONGLY_TIMEZONED_UTC_TIME(
    date: Date,
    timezone: string | number
  ) {
    return this.ConvertToTimezoneTime(
      new Date(
        date.getTime() + DateServiceSingleton.GetMyTimezoneOffset() * 60 * 1000
      ),
      timezone
    );
  }

  private CalculateTimezone(timezone: string | number) {
    const d = moment(Date.now());
    let tz;

    if (typeof timezone === 'number') {
      tz = moment.tz.guess();
    } else {
      d.tz(timezone);
      tz = d.tz();
    }

    if (tz === undefined) {
      throw new Error('Could not set time zone.');
    }
    return tz;
  }
}

export const DateServiceSingleton = new DateService();
