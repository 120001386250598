import { Stack, Tooltip } from '@torqit/torq-tools-react';
import { DateServiceSingleton } from 'App/utils/DateService';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { SensorReadingsRangeData } from 'SensorReadings/hooks/useSensorReadings';
import { SentryApiClient } from '_generated/api';
import styles from './LegendItemStats.module.css';

export interface LegendItemStatsProps {
  readings: SensorReadingsRangeData;
  timezone?: string;
}

export const LegendItemStats: React.FC<LegendItemStatsProps> = ({
  readings,
  timezone,
}) => {
  const getGroupingLabel = (grouping?: SentryApiClient.GroupByParam) => {
    switch (grouping) {
      case SentryApiClient.GroupByParam.Minute:
        return 'Minutely';
      case SentryApiClient.GroupByParam.Hour:
        return 'Hourly';
      case SentryApiClient.GroupByParam.Day:
        return 'Daily';
      default:
        return 'Raw data';
    }
  };

  const displayWeirdDate = (date?: Date) => {
    if (!date || !timezone) {
      return '';
    }

    return moment(
      DateServiceSingleton.ConvertToTimezoneTime(new Date(date), timezone)
    ).format('lll');
  };

  if (readings.modulo && readings.data?.length === 0) {
    return <strong className={styles.tiny}>No Data</strong>;
  }

  return (
    <Stack gap={30}>
      <Stack direction="vertical">
        <div className={styles.tiny}>
          Averaging: <strong>{getGroupingLabel(readings.grouping)}</strong>
        </div>
        <Tooltip
          title={
            readings.modulo === 1
              ? 'All sensor readings are shown'
              : `Only 1 in every ${readings.modulo} readings are currently shown. Outlier
                        readings are shown at full resolution.`
          }
        >
          <div className={classNames(styles.tiny, styles.dotted)}>
            Sample rate:{' '}
            <strong>
              {readings.modulo === 1 ? 'Full' : '1/' + readings.modulo}
            </strong>
          </div>
        </Tooltip>
      </Stack>
      <Stack direction="vertical">
        <Tooltip
          title={
            'Occurred at: ' + displayWeirdDate(readings.minReading?.readingTime)
          }
          placement="top"
        >
          <div
            className={classNames(
              styles.tiny,
              styles.dotted,
              styles.fixedWidth
            )}
          >
            Min:{' '}
            <strong>{readings.minReading?.rawValue?.toPrecision(3)}</strong>
          </div>
        </Tooltip>

        <Tooltip
          title={
            'Occurred at: ' + displayWeirdDate(readings.maxReading?.readingTime)
          }
          placement="bottom"
        >
          <div className={classNames(styles.tiny, styles.dotted)}>
            Max:{' '}
            <strong>{readings.maxReading?.rawValue?.toPrecision(3)}</strong>
          </div>
        </Tooltip>
      </Stack>
    </Stack>
  );
};
