import {
  ExperimentalForm,
  FormInstance,
  Input,
  Select,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { Fieldset } from 'App/components/FormHelpers';
import { LetterIcon } from 'App/components/LetterIcon';
import { useApi } from 'App/hooks/useApi';
import React, { useState } from 'react';
import { buildFieldName } from 'Auth/buildFieldName';
import { SiteFormFields } from './SiteFormFields';
import { SentryApiClient } from '_generated/api';
import { SearchSelect } from 'App/components/SearchSelect';
import { DashboardItemEmpty } from 'App/components/DashboardItemEmpty';

export const NO_SITE_SELECTED = -1;
export const CREATED_SITE = -2;

export interface SiteSelectOrCreateFieldsProps {
  active: boolean;
  visible: boolean;
  form: FormInstance<any>;
  namespace?: string | string[];
  allowEmpty?: boolean;
}

export const SiteSelectOrCreateFields: React.FC<SiteSelectOrCreateFieldsProps> = ({
  active,
  visible,
  form,
  namespace,
  allowEmpty = false,
}) => {
  const { siteApi } = useApi();
  const [value, setValue] = useState(NO_SITE_SELECTED);
  const [search, setSearch] = useState<string>();

  const {
    data: sitePermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(() => siteApi.getUserPermissions(0), [siteApi]);

  const { data: siteList, isLoading } = useAsyncData(
    () =>
      siteApi.get(
        100,
        1,
        SentryApiClient.SiteFilters.Name,
        SentryApiClient.OrderDirection.Ascending,
        search
          ? [
              {
                field: SentryApiClient.SiteFilters.Name,
                operator: SentryApiClient.Operator.Contains,
                value: search,
              },
            ]
          : undefined
      ),
    [search, siteApi]
  );

  function updateFields(selectedSiteId: number) {
    if (
      selectedSiteId === NO_SITE_SELECTED ||
      selectedSiteId === CREATED_SITE
    ) {
      form.setFields([
        { name: buildFieldName(namespace, 'company'), value: null },
        { name: buildFieldName(namespace, 'name'), value: null },
        { name: buildFieldName(namespace, 'tagline'), value: null },
        { name: buildFieldName(namespace, 'strapline'), value: null },
        { name: buildFieldName(namespace, 'details'), value: null },
        { name: buildFieldName(namespace, 'latitude'), value: 0 },
        { name: buildFieldName(namespace, 'longitude'), value: 0 },
        { name: buildFieldName(namespace, 'name'), value: null },
        {
          name: buildFieldName(namespace, ['address', 'address1']),
          value: null,
        },
        {
          name: buildFieldName(namespace, ['address', 'address2']),
          value: null,
        },
        { name: buildFieldName(namespace, ['address', 'city']), value: null },
        {
          name: buildFieldName(namespace, ['address', 'country']),
          value: null,
        },
        {
          name: buildFieldName(namespace, ['address', 'region']),
          value: null,
        },
        { name: buildFieldName(namespace, ['address', 'zip']), value: null },
      ]);
    } else {
      const site = siteList?.items?.find((s) => s.id === selectedSiteId)!;
      form.setFields([
        { name: buildFieldName(namespace, 'id'), value: site.id },
        { name: buildFieldName(namespace, 'company'), value: site.company },
        { name: buildFieldName(namespace, 'name'), value: site.name },
        { name: buildFieldName(namespace, 'tagline'), value: site.tagLine },
        { name: buildFieldName(namespace, 'strapline'), value: site.strapLine },
        { name: buildFieldName(namespace, 'details'), value: site.details },
        { name: buildFieldName(namespace, 'latitude'), value: site.latitude },
        { name: buildFieldName(namespace, 'longitude'), value: site.longitude },
        {
          name: buildFieldName(namespace, ['address', 'address1']),
          value: site.address?.address1,
        },
        {
          name: buildFieldName(namespace, ['address', 'address2']),
          value: site.address?.address2,
        },
        {
          name: buildFieldName(namespace, ['address', 'city']),
          value: site.address?.city,
        },
        {
          name: buildFieldName(namespace, ['address', 'country']),
          value: site.address?.country,
        },
        {
          name: buildFieldName(namespace, ['address', 'region']),
          value: site.address?.region,
        },
        {
          name: buildFieldName(namespace, ['address', 'zip']),
          value: site.address?.zip,
        },
        { name: buildFieldName(namespace, 'timeZone'), value: site.timezone },
      ]);
    }

    setValue(selectedSiteId);
  }

  if (
    !isLoading &&
    !permissionsLoading &&
    !allowEmpty &&
    siteList?.totalItems === 0 &&
    !sitePermissions?.canCreate
  ) {
    //Refer to CompanySelectOrCreateFields
    return (
      <Fieldset id="user" active={active} visible={visible}>
        <ExperimentalForm.Item
          name={buildFieldName(namespace, 'id')}
          hidden
          rules={[
            {
              required: true,
              message: 'Please select an option',
            },
          ]}
        >
          <Input />
        </ExperimentalForm.Item>
        <DashboardItemEmpty message="There are no sites to choose from" />
      </Fieldset>
    );
  }

  return (
    <Fieldset id="user" active={active} visible={visible}>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'id')}
        label={<span>Site</span>}
        initialValue={allowEmpty ? NO_SITE_SELECTED : undefined}
        rules={[
          {
            required: true,
            message: 'Please select an option',
          },
        ]}
      >
        <SearchSelect
          loading={isLoading && permissionsLoading}
          onChange={(v) => updateFields(v as number)}
          onDebouncedSearch={setSearch}
        >
          {allowEmpty && (
            <Select.Option value={NO_SITE_SELECTED}>None</Select.Option>
          )}
          {siteList?.items?.map((s) => (
            <Select.Option key={s.id} value={s.id ?? 0}>
              <LetterIcon name={s.name || ''} small /> {s.name}
            </Select.Option>
          ))}
          {sitePermissions?.canCreate && (
            <Select.Option value={CREATED_SITE}>
              Create new site...
            </Select.Option>
          )}
        </SearchSelect>
      </ExperimentalForm.Item>
      <SiteFormFields
        form={form}
        active={active && value !== NO_SITE_SELECTED}
        visible={visible && value === CREATED_SITE}
        namespace={namespace}
      />
    </Fieldset>
  );
};
