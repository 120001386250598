import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@torqit/torq-tools-react';
import React from 'react';
import styles from './StateIcon.module.css';

interface IStateProps {
  icon: IconDefinition;
  color: string;
  label: string;
}

const StateIcon: React.FC<IStateProps> = ({ icon, color, label }) => {
  const css = {
    '--state-icon-color': color,
  } as React.CSSProperties;

  return (
    <Stack>
      <Stack alignment="middle" gap="xs">
        <FontAwesomeIcon icon={icon} color={color} />
        <b className={styles.tag}>{label}</b>
      </Stack>
    </Stack>
  );
};

export { StateIcon };
