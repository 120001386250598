import { createContext, useContext, useState } from 'react';

export interface BreadcrumbItem {
  linkTo?: string;
  display?: string;
  loading?: boolean;
}

export interface BreadcrumbsData {
  breadcrumbs: BreadcrumbItem[];
  setBreadcrumbs: (breadcrumbs: BreadcrumbItem[]) => void;
}

const BreadcrumbsContext = createContext<BreadcrumbsData>({
  breadcrumbs: [{ display: 'Need BreadcrumbProvider' }],
  setBreadcrumbs: () => {
    throw new Error('This hook requires the use of a BreadcrumbsProvider');
  },
});

export interface BreadcrumbsProviderProps {}

export const BreadcrumbsProvider: React.FC<BreadcrumbsProviderProps> = ({
  children,
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);

  return (
    <BreadcrumbsContext.Provider value={{ breadcrumbs, setBreadcrumbs }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export function useBreadcrumbs() {
  return useContext(BreadcrumbsContext);
}
