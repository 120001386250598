import React, { useEffect, useState } from 'react';
import {
  Card,
  ExperimentalForm,
  Stack,
  useAsyncData,
  useHistory,
  useParams,
  Affix,
} from '@torqit/torq-tools-react';
import Form from 'antd/lib/form';
import { useApi } from 'App/hooks/useApi';
import { SentryApiClient } from '_generated/api';
import {
  PagePresenter,
  PageState,
} from 'App/components/PagePresenter/PagePresenter';
import { FormNavigator } from 'App/components/FormHelpers';
import { SiteFormFields } from '../../forms/SiteFormFields';

export const SiteEdit: React.FC = () => {
  const [form] = Form.useForm();
  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const { siteApi } = useApi();
  const history = useHistory();
  const params = useParams<{ siteId?: string }>();
  const siteId = (params.siteId && parseInt(params.siteId)) || -1;
  const { data: siteDetails, isLoading, error } = useAsyncData(
    () => siteApi.getById(siteId),
    [siteApi, siteId]
  );

  const [currentName, setCurrentName] = useState(
    siteDetails?.name ?? 'Example Site'
  );
  const onChange = (_v: any, result: SentryApiClient.SiteDTO) => {
    setCurrentName(result?.name || '');
  };
  useEffect(() => {
    if (isLoading) setPageState(PageState.Loading);
    if (error) setPageState(PageState.Error);
    if (siteDetails) setPageState(PageState.Loaded);
    if (siteDetails?.name) setCurrentName(siteDetails.name);
  }, [siteDetails, error, isLoading]);

  const onSave = async (site: SentryApiClient.SiteDTO) => {
    setPageState(PageState.Loading);
    if (!site || !siteDetails || !siteDetails.id) {
      setPageState(PageState.Error);
      return;
    }

    if (!site.panels) {
      site.panels = [];
    }

    if (!site.users) {
      site.users = [];
    }

    if (!site.sensors) {
      site.sensors = [];
    }

    siteApi
      .put(siteDetails.id, site)
      .then(() => {
        setPageState(PageState.Loaded);
        params.siteId && history.replace(`/site/${params.siteId}/details`);
      })
      .catch((e) => setPageState(PageState.Error));
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack gap="md" direction="vertical" stretch>
        <Card
          title={
            <Stack>
              <Stack alignment="middle" gap={10}>
                Edit {siteDetails?.name}
              </Stack>
            </Stack>
          }
        >
          {siteDetails ? (
            <ExperimentalForm<SentryApiClient.SiteDTO>
              labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 10 },
              }}
              form={form}
              name="config"
              onValuesChange={onChange}
              onFinish={onSave}
              initialValues={siteDetails}
              scrollToFirstError
            >
              <SiteFormFields
                active={true}
                visible={true}
                name={currentName}
                form={form}
              />
            </ExperimentalForm>
          ) : null}
        </Card>
        <Affix offsetBottom={0}>
          <Card>
            <FormNavigator onSubmit={() => form.submit()} />
          </Card>
        </Affix>
      </Stack>
    </PagePresenter>
  );
};

export default SiteEdit;
