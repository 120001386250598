import React, { useEffect, useState } from 'react';
import {
  Affix,
  Box,
  Card,
  ExperimentalForm,
  Stack,
  useAsyncData,
  useHistory,
  useParams,
} from '@torqit/torq-tools-react';
import Form from 'antd/lib/form';
import { useApi } from 'App/hooks/useApi';
import { SentryApiClient } from '_generated/api';
import {
  PagePresenter,
  PageState,
} from 'App/components/PagePresenter/PagePresenter';
import { FormNavigator } from 'App/components/FormHelpers';
import { PanelFormFields } from '../../forms/PanelFormFields';
import { BankConfigurer } from '../../components/BankConfigurer';

export const PanelEdit: React.FC = () => {
  const [form] = Form.useForm<SentryApiClient.PanelDTO>();
  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const { panelApi } = useApi();
  const history = useHistory();
  const params = useParams<{ panel?: string }>();
  const panelId = (params.panel && parseInt(params.panel)) || -1;
  const { data: panelDetails, isLoading, error } = useAsyncData(
    () => panelApi.getById(panelId),
    [panelApi, panelId]
  );
  const [
    internalPanel,
    setInternalPanel,
  ] = useState<SentryApiClient.PanelDTO>();

  useEffect(() => {
    setInternalPanel(panelDetails);
  }, [panelDetails]);

  useEffect(() => {
    if (internalPanel) {
      form.setFieldsValue(internalPanel);
    }
  }, [form, internalPanel]);

  const [currentName, setCurrentName] = useState(
    panelDetails?.name ?? 'Example Panel'
  );

  const onChange = (_v: any, result: SentryApiClient.PanelDTO) => {
    setCurrentName(result?.name || '');
  };

  useEffect(() => {
    if (isLoading) setPageState(PageState.Loading);
    if (error) setPageState(PageState.Error);
    if (panelDetails) setPageState(PageState.Loaded);
    if (panelDetails?.name) setCurrentName(panelDetails.name);
  }, [panelDetails, isLoading, error]);

  const onSave = async (panel: SentryApiClient.PanelDTO) => {
    setPageState(PageState.Loading);
    if (!panel || !panelDetails || !panelDetails.id) {
      setPageState(PageState.Error);
      return;
    }

    panelApi
      .put(panelDetails.id, panel)
      .then(() => {
        setPageState(PageState.Loaded);
        params.panel && history.replace(`/panel/${params.panel}/details`);
      })
      .catch((e) => setPageState(PageState.Error));
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack gap="md" direction="vertical" stretch>
        <Card
          title={
            <Stack>
              <Stack alignment="middle" gap={10}>
                Edit {panelDetails?.name}
              </Stack>
            </Stack>
          }
        >
          {internalPanel ? (
            <ExperimentalForm<SentryApiClient.PanelDTO>
              labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 10 },
              }}
              form={form}
              name="config"
              onValuesChange={onChange}
              onFinish={onSave}
              initialValues={internalPanel}
              scrollToFirstError
            >
              <PanelFormFields
                active={true}
                visible={true}
                name={currentName}
                form={form}
              />
            </ExperimentalForm>
          ) : null}
        </Card>
        <Card title="Sensors">
          {internalPanel ? (
            <Box padding={{ bottom: 'md' }}>
              <BankConfigurer
                panel={internalPanel}
                editable
                onChange={setInternalPanel}
              />
            </Box>
          ) : null}
        </Card>
        <Affix offsetBottom={0}>
          <Card>
            <FormNavigator onSubmit={() => form.submit()} />
          </Card>
        </Affix>
      </Stack>
    </PagePresenter>
  );
};

export default PanelEdit;
