export enum UserType {
  Unknown,
  Administrator,
  Distributor,
  ClientManager,
  CustomerManager,
  Customer,
}

export function getUserTypeName(type: UserType) {
  switch (type) {
    case UserType.Administrator:
      return 'Administrator';
    case UserType.ClientManager:
      return 'Client Manager';
    case UserType.Distributor:
      return 'Distributor';
    case UserType.CustomerManager:
      return 'Customer Manager';
    case UserType.Customer:
      return 'Customer';
    default:
      return 'Unknown User Type';
  }
}
