import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useAuthContext } from './AuthProvider';
import { useUser } from '../Users/hooks/useUser';

let TOKEN: string;

export const useAuthorization = () => {
  const authConfig = useAuthContext();
  const [token, setToken] = useState<any>(TOKEN);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error>();
  const { instance, inProgress } = useMsal();
  const user = useUser();

  useEffect(() => {
    if (
      !token &&
      user &&
      inProgress === InteractionStatus.None &&
      authConfig.clientScopes
    ) {
      setLoading(true);
      instance
        .acquireTokenSilent({
          ...{ scopes: authConfig.clientScopes },
          account: user,
        })
        .then((tokenRes) => {
          setLoading(false);
          setToken(tokenRes.accessToken);
          TOKEN = tokenRes.accessToken;
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [user, inProgress, authConfig, instance, token]);

  return [token, loading, error] as const;
};
