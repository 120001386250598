import { CompanyFieldsReview, NO_COMPANY_SELECTED } from 'Companies';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import { UserFieldsReview } from '../UserFieldsReview';
import { UserFormFieldsShape } from '../UserFormFields';
import styles from './UserCreateReview.module.css';

export interface UserCreateReviewProps {
  active: boolean;
  company?: SentryApiClient.CompanyDTO;
  user?: UserFormFieldsShape;
  onBackToCompany?: () => void;
  onBackToUser?: () => void;
}

export const UserCreateReview: React.FC<UserCreateReviewProps> = ({
  active,
  company,
  user,
  onBackToCompany,
  onBackToUser,
}) => {
  if (!active) {
    return null;
  }
  return (
    <>
      <div className={styles.section}>
        <UserFieldsReview user={user} onBackToUser={onBackToUser} />
      </div>
      <div className={styles.section}>
        {company && company.id !== NO_COMPANY_SELECTED && (
          <CompanyFieldsReview
            company={company}
            onBackToCompany={onBackToCompany}
          />
        )}
      </div>
    </>
  );
};
