import { Redirect } from '@torqit/torq-tools-react';
import React from 'react';
import { useSentryUser } from 'Users/hooks/SentryUserProvider';

export interface SiteRedirectorProps {}

export const SiteRedirector: React.FC<SiteRedirectorProps> = ({}) => {
  const { user } = useSentryUser();

  //We don't want to jump the gun and redirect before we've even figured out
  // who we are, otherwise the page would redirect to /dashboard immediately
  // regardless of what kind of user you are
  return user ? <Redirect to="/dashboard" /> : null;
};
