import React from 'react';
import { SignalFilled } from '@ant-design/icons';
import { Stack, Box } from '@torqit/torq-tools-react';
import { Point } from '@nivo/line';
import styles from './TimeSeriesLineTooltip.module.css';
import { Coords } from '../LineChart';
import moment from 'moment';

export interface PointDateOffset {
  id?: string;
  offset: number;
}

export interface TimeSeriesLineTooltipProps {
  points: Point[];
  dateOffsets: PointDateOffset[];
  yUnit?: string;
}

export const TimeSeriesLineTooltip: React.FC<TimeSeriesLineTooltipProps> = ({
  points,
  yUnit = 'SENTRY Signal',
  dateOffsets,
}) => {
  const extractName = (id: string) => {
    const index = id.indexOf(' - ');
    return id.substr(0, index);
  };

  const getOffset = (id: string) => {
    const dateOffset = dateOffsets.find((d) =>
      d.id ? id.startsWith(d.id) : false
    );
    return dateOffset?.offset ?? 0;
  };

  return (
    <div className={styles.tooltip}>
      <Box padding="sm">
        <Stack direction="vertical" gap="sm">
          {
            //If we end up in case where rounding has placed more than one
            // reading at the same time, we just filter out the extra readings
            // here so that the tooltip only displays one reading per sensor.
            // We do this by exploiting the fact that find() returns the first
            // value that matches what we're looking for, so for every reading
            // if it's equal to the first reading object with the same id,
            // then we render it. Any additional readings with that ID are
            // tossed!
            points
              .filter(
                (p) =>
                  p ===
                  points.find(
                    (fp) =>
                      (fp.data as Coords).rangeId === (p.data as Coords).rangeId
                  )
              )
              .map((point, index) => {
                const data = point.data as Coords;

                return (
                  <Stack
                    key={point.id + '_' + index}
                    gap="xs"
                    direction="vertical"
                  >
                    <Stack alignment="middle" gap={10}>
                      <SignalFilled style={{ color: point.serieColor }} />
                      <strong> {extractName(point.id)}</strong>
                    </Stack>
                    <Stack gap={10}>
                      Date:
                      <strong>
                        {
                          //Because minutely averaging is always on and because of
                          // rounding, seconds don't really mean anything in our
                          // reading time so we format them out
                          moment(
                            (data.actualDate?.getTime() ?? 0) -
                              getOffset(point.id)
                          ).format('l LT')
                        }
                      </strong>
                    </Stack>
                    <Stack gap={10}>
                      {yUnit}:
                      <strong>{Number(data.actualValue).toPrecision(3)}</strong>
                    </Stack>
                    {data.temperature && (
                      <Stack alignment="middle" gap={10}>
                        Temperature:
                        <strong>
                          {data.temperature}
                          {'\u00b0'}C
                        </strong>
                      </Stack>
                    )}
                  </Stack>
                );
              })
          }
        </Stack>
      </Box>
    </div>
  );
};
