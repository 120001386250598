import fullWidthStyleModule from './FullWidth.module.css';
import positionsStyleModule from './Positions.module.css';
import dashboardStyleModule from './Dashboard.module.css';

const fullWidthStyles = fullWidthStyleModule as { fullWidth: string };

export { fullWidthStyles };

const positionsStyles = positionsStyleModule as { relative: string };

export { positionsStyles };

const dashboardStyles = dashboardStyleModule as { largeHeader: string };

export { dashboardStyles };
