import { CustomLayerProps } from '@nivo/line';
import { binarySearchLowerBound } from 'App/utils/binarySearch';
import { DateServiceSingleton } from 'App/utils/DateService';
import React, { useMemo } from 'react';
import { useChartClicker } from '../SensorReadingsGraph/ChartClickerProvider';
import { ChartDataProps } from '../SensorReadingsGraph/Charts/LineChart';
import styles from './ClickedNoteLayer.module.css';

export interface ClickedNoteLayerProps extends CustomLayerProps {
  chartData?: ChartDataProps[];
  timezone: string;
}

export const ClickedNoteLayer: React.FC<ClickedNoteLayerProps> = ({
  chartData,
  xScale,
  yScale,
  timezone,
}) => {
  const xScaleFunc = (xScale as any) as (scale: Date) => number;
  const yScaleFunc = (yScale as any) as (scale: number) => number;

  const { point } = useChartClicker();

  const target = useMemo(() => {
    //We want to display a dot on our primary range, but it's kind of hard
    // to figure out which range that is since they all kind of get jumbled,
    // so honestly, the best bet we have is to just guess. Here, we're just
    // fetching all the lines that belong to the primary sensor.
    const primarySensorLines = chartData?.filter((d) => d.isPrimarySensor);

    if (primarySensorLines?.length === 0 || !point) {
      return undefined;
    }

    const potentialPoints = primarySensorLines?.map((p) =>
      binarySearchLowerBound(
        p.data,
        {
          actualDate: DateServiceSingleton.ConvertToTimezoneTime(
            point.date,
            timezone
          ),
        },
        (c) => c.actualDate as Date
      )
    );

    //We only want to display one dot here so we fetch the first valid dot
    // since ideally, multiple ranges on the same sensor should all be
    // completely different time ranges, so there should only be one valid dot
    // to begin with
    return potentialPoints?.find((p) => p != null);
  }, [chartData, point, timezone]);

  if (!point || !target) {
    return null;
  }

  return (
    <g>
      <ellipse
        className={styles.icon}
        cx={xScaleFunc(target.x as Date)}
        cy={yScaleFunc(target.y as number)}
        rx={4.2}
        ry={4.2}
      />
    </g>
  );
};
