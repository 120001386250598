import { Box, Stack } from '@torqit/torq-tools-react';
import { useBreakpoints } from 'App/components/Theme';
import React from 'react';
import styles from './FormReviewItem.module.css';

export interface FormReviewItemProps {
  field: string;
  value?: React.ReactNode;
}

export const FormReviewItem: React.FC<FormReviewItemProps> = ({
  field,
  value,
}) => {
  const { smd } = useBreakpoints();

  return (
    <Box padding={smd ? 'md' : 'sm'}>
      <Stack gap={smd ? 70 : 20} alignment="middle">
        <Stack.Item size={smd ? 1 : 0.6}>
          <div className={styles.label}>{field ? field + ':' : null}</div>
        </Stack.Item>
        <Stack.Item>
          <div className={smd ? styles.value : undefined}>{value}</div>
        </Stack.Item>
      </Stack>
    </Box>
  );
};
