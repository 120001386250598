import { ChartableSensorReading } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { ReadingsChangeListener, ReadingsPipe } from './ReadingsPipe';

export class Pipeline {
  private pipes: ReadingsPipe[] = [];
  protected exitListener?: ReadingsChangeListener;

  public append(pipe: ReadingsPipe) {
    if (this.pipes.length > 0) {
      this.pipes[this.pipes.length - 1].append(pipe);
    }

    this.pipes.push(pipe);
  }

  public setOnExit(listener: ReadingsChangeListener) {
    this.pipes[this.pipes.length - 1].setOnExit(listener);
  }

  public enter(readings: ChartableSensorReading[]): void {
    this.pipes[0].enter(readings);
  }

  public stop() {
    this.pipes.forEach((p) => p.stop());
  }
}
