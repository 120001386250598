import { useEffect, useState } from 'react';

/**
 * This takes a value, and will return it to use in a useEffect dependency list after the delay is up.
 * https://usehooks.com/useDebounce/
 */

export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
