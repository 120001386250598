import { UndoOutlined } from '@ant-design/icons';
import { Button, Card, Stack } from '@torqit/torq-tools-react';
import { AddressDisplay } from 'Address/AddressDisplay';
import { FormReviewItem } from 'App/components/FormHelpers';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import { CREATED_COMPANY } from '../CompanyFormFields/CompanySelectOrCreateFields';

export interface CompanyFieldsReviewProps {
  company?: SentryApiClient.CompanyDTO;
  onBackToCompany?: () => void;
}

export const CompanyFieldsReview: React.FC<CompanyFieldsReviewProps> = ({
  company,
  onBackToCompany,
}) => {
  return (
    <Card
      title={
        <Stack alignment="middle" gap="apart">
          {!company?.id || company?.id === CREATED_COMPANY
            ? 'New Company'
            : 'Existing Company'}
          <Button
            type="primary"
            icon={<UndoOutlined />}
            onClick={onBackToCompany}
          >
            Update
          </Button>
        </Stack>
      }
    >
      <FormReviewItem field="Name" value={company?.name} />
      <FormReviewItem
        field="Address"
        value={<AddressDisplay address={company?.address} />}
      />
    </Card>
  );
};
