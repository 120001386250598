import React from 'react';
import {
  Box,
  Button,
  Dropdown,
  Row,
  Stack,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { Menu } from 'antd';
import {
  ClusterOutlined,
  PlusOutlined,
  HomeOutlined,
  TeamOutlined,
  UserOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useHistory } from '@torqit/torq-tools-react';
import styles from './GlobalHeader.module.css';
import { useBreakpoints } from 'App/components/Theme';
import { UserDropdown } from 'App/components/UserDropdown';
import { useApi } from 'App';
import { EMPTY_GUID } from 'Users/forms';

export const GlobalHeader: React.FC = () => {
  const { push } = useHistory();
  const { smd } = useBreakpoints();

  const { companyApi, siteApi, panelApi, userApi } = useApi();
  const {
    data: companyPermissions,
    isLoading: loadingCompanyPermissions,
  } = useAsyncData(() => companyApi.getUserPermissions(0), [companyApi]);
  const {
    data: sitePermissions,
    isLoading: loadingSitePermissions,
  } = useAsyncData(() => siteApi.getUserPermissions(0), [siteApi]);
  const {
    data: panelPermissions,
    isLoading: loadingPanelPermissions,
  } = useAsyncData(() => panelApi.getUserPermissions(0), [panelApi]);
  const {
    data: userPermissions,
    isLoading: loadingUserPermissions,
  } = useAsyncData(() => userApi.getUserPermissions(EMPTY_GUID), [userApi]);

  const renderMenuItem = (
    isLoading: boolean,
    canCreate: boolean | undefined,
    key: number,
    entity: string,
    icon: React.ReactNode
  ) => {
    if (isLoading) {
      return (
        <Menu.Item key={key}>
          <LoadingOutlined />
        </Menu.Item>
      );
    }

    if (canCreate) {
      return (
        <Menu.Item
          key={key}
          icon={icon}
          onClick={() => push(`/${entity}/create`)}
        >
          {entity.charAt(0).toUpperCase() + entity.slice(1)}
        </Menu.Item>
      );
    }
  };

  const canCreateSomething =
    companyPermissions?.canCreate ||
    sitePermissions?.canCreate ||
    panelPermissions?.canCreate ||
    userPermissions?.canCreate;

  return (
    <Stack alignment="middle" gap="apart" fill>
      {!canCreateSomething && <div>{/*Trigger "apart" gap*/}</div>}
      {canCreateSomething && (
        <Stack>
          <Box padding={{ horizontal: smd ? undefined : 'xl' }}></Box>
          <Dropdown
            overlay={
              <Menu className={'menu'}>
                {renderMenuItem(
                  loadingCompanyPermissions,
                  companyPermissions?.canCreate,
                  1,
                  'company',
                  <TeamOutlined />
                )}
                {renderMenuItem(
                  loadingSitePermissions,
                  sitePermissions?.canCreate,
                  2,
                  'site',
                  <HomeOutlined />
                )}
                {renderMenuItem(
                  loadingPanelPermissions,
                  panelPermissions?.canCreate,
                  3,
                  'panel',
                  <ClusterOutlined />
                )}
                {renderMenuItem(
                  loadingUserPermissions,
                  userPermissions?.canCreate,
                  4,
                  'user',
                  <UserOutlined />
                )}
              </Menu>
            }
            trigger={['click']}
          >
            <Button type="primary" icon={<PlusOutlined />}>
              Add
            </Button>
          </Dropdown>
        </Stack>
      )}

      <Stack gap={20}>
        <Stack>
          <Row justify={'end'} className={styles.userPanel}>
            <UserDropdown linkTo="frontoffice" />
          </Row>
        </Stack>
      </Stack>
    </Stack>
  );
};
