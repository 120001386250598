import {
  Button,
  Card,
  FetchGridParameters,
  Stack,
  useAsyncData,
  useHistory,
} from '@torqit/torq-tools-react';
import { PagePresenter } from 'App/components/PagePresenter';
import { PageState } from 'App/components/PagePresenter/PagePresenter';
import { useApi } from 'App/hooks/useApi';
import React, { useState } from 'react';
import { SiteTable } from '../../components/SiteTable/SiteTable';
import { SentryApiClient } from '_generated/api';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { DEFAULT_PAGE_PARAMS } from 'App/components/TableHelpers/defaultPageSettings';
import { siteFilterMap } from 'Sites/components/SiteTable/siteFilterMap';
import { toOrderDirection } from 'App/utils/toOrderDirection';
import { useFirstLoadIsEmpty } from 'App/hooks/useFirstLoadIsEmpty';
import { useLocationState } from 'react-router-use-location-state';

export const Sites: React.FC = () => {
  const { siteApi } = useApi();
  const { push } = useHistory();

  const [params, setParams] = useLocationState<
    FetchGridParameters<SentryApiClient.SiteDTO>
  >('params', { ...DEFAULT_PAGE_PARAMS, orderKey: 'name' });

  const {
    data: sitePermissions,
    isLoading: loadingPermissions,
  } = useAsyncData(() => siteApi.getUserPermissions(0), [siteApi]);

  const { data: sitesList, isLoading, error } = useAsyncData(
    () =>
      siteApi.get(
        params.pageSize,
        params.page,
        siteFilterMap.toFilter(params.orderKey),
        toOrderDirection(params.order),
        siteFilterMap.convert(params.filters)
      ),
    [params, siteApi]
  );

  const isInitialLoadEmpty = useFirstLoadIsEmpty(sitesList?.items);

  const getPageState = () => {
    if (error) return PageState.Error;
    if (isLoading) return PageState.Loading;
    else if (isInitialLoadEmpty) return PageState.Empty;
    else return PageState.Loaded;
  };

  return (
    <PagePresenter pageState={getPageState()}>
      <Card
        title={
          <Stack gap={'apart'}>
            <Stack>Sites</Stack>
            {loadingPermissions && <LoadingOutlined />}
            {sitePermissions?.canCreate && (
              <Stack>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => push('site/create')}
                >
                  Add
                </Button>
              </Stack>
            )}
          </Stack>
        }
      >
        <SiteTable
          isLoading={isLoading}
          total={sitesList?.totalItems}
          params={params}
          sites={sitesList?.items}
          canAdd
          onChange={setParams}
        />
      </Card>
    </PagePresenter>
  );
};
