import classNames from 'classnames';
import React from 'react';
import styles from './SensorBandFormIndicator.module.css';

export interface SensorBandFormIndicatorProps {
  redHigh?: number;
  amberHigh?: number;
  amberLow?: number;
  redLow?: number;
}

export const SensorBandFormIndicator: React.FC<SensorBandFormIndicatorProps> = ({
  redHigh,
  amberHigh,
  amberLow,
  redLow,
}) => {
  const prioritize = (...values: (number | undefined)[]) => {
    return values.find((v) => v != null || v === 0)!;
  };

  const getFlex = (value: number) => {
    return {
      '--color-indicator-flex': value,
    } as React.CSSProperties;
  };

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.red, styles.topBlock, {
          [styles.gone]: !redHigh,
        })}
      ></div>

      <div
        className={classNames(styles.amber, {
          [styles.topBlock]: !redHigh,
          [styles.gone]: !redHigh && !amberHigh,
          [styles.fill]: redHigh,
        })}
        style={getFlex((redHigh ?? 0) - (amberHigh ?? redHigh ?? 0))}
      ></div>
      <div
        className={classNames({
          [styles.topBlock]: !redHigh && !amberHigh,
          [styles.fill]: redHigh || amberHigh,
        })}
        style={getFlex(
          prioritize(amberHigh, redHigh, 0) - prioritize(amberLow, redLow, 0)
        )}
      ></div>
      <div
        className={classNames(styles.amber, styles.fill)}
        style={getFlex((amberLow ?? redLow ?? 0) - (redLow ?? 0))}
      ></div>
      <div
        className={classNames(styles.red, styles.fill)}
        style={getFlex(redLow ?? 0)}
      ></div>
    </div>
  );
};
