import moment from 'moment';

export const DATE_SLIDER_NOW = new Date();
export const DATE_SLIDER_ONE_YEAR_AGO = moment().subtract(1, 'year').toDate();
export const YEAR_SPAN = 1000 * 60 * 60 * 24 * 365;

export function getDateSliderItemSize(start?: Date, end?: Date) {
  //Still dealing with the swagger thing where dates aren't actuually dates
  // so this makes things extra safe
  const actualStart = start ? new Date(start) : DATE_SLIDER_ONE_YEAR_AGO;
  const actualEnd = end ? new Date(end) : DATE_SLIDER_NOW;

  const span =
    Math.min(actualEnd.getTime(), DATE_SLIDER_NOW.getTime()) -
    Math.max(actualStart.getTime(), DATE_SLIDER_ONE_YEAR_AGO.getTime());

  return span / YEAR_SPAN;
}
