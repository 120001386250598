import { useEffect, useState } from 'react';

enum InitialLoad {
  Unloaded,
  Empty,
  NotEmpty,
}

//Originally, a lot of the top level pages (user list, company list, etc) displayed
// a full page Empty message if the resulting array was empty. That was all fine and
// dandy before, but now that filtering is implemented, if your filters result in an
// empty data set, you suddenly get a full screen empty message you can't get out of.
// To mitigate that, you can pass your data to this hook, which will check if the
// FIRST valid data set is empty or not.
export function useFirstLoadIsEmpty(data?: any[]) {
  const [initialLoad, setInitialLoad] = useState(InitialLoad.Unloaded);

  useEffect(() => {
    if (initialLoad === InitialLoad.Unloaded && data) {
      setInitialLoad(
        data.length > 0 ? InitialLoad.NotEmpty : InitialLoad.Empty
      );
    }
  }, [initialLoad, data]);

  return initialLoad === InitialLoad.Empty;
}
