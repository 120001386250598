import { Box, Stack } from '@torqit/torq-tools-react';
import { CompanyFieldsReview } from 'Companies';
import React from 'react';
import { SiteFieldsReview } from 'Sites/forms/SiteFieldsReview';
import { SentryApiClient } from '_generated/api';

export interface CreateSiteReviewProps {
  active: boolean;
  site?: SentryApiClient.SiteDTO;
  company?: SentryApiClient.CompanyDTO;
  onBackToSite?: () => void;
  onBackToCompany?: () => void;
}

export const CreateSiteReview: React.FC<CreateSiteReviewProps> = ({
  active,
  site,
  company,
  onBackToSite,
  onBackToCompany,
}) => {
  if (!active) {
    return null;
  }

  return (
    <Box padding="sm">
      <Stack direction="vertical" gap={10} stretch>
        <SiteFieldsReview site={site} onBackToSite={onBackToSite} />
        {company && company.id && company.id > 0 && (
          <CompanyFieldsReview
            company={company}
            onBackToCompany={onBackToCompany}
          />
        )}
      </Stack>
    </Box>
  );
};
