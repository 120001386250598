import { CustomLayerProps } from '@nivo/line';
import React from 'react';
import styles from './BandLayer.module.css';

export interface BandLayerProps extends CustomLayerProps {
  redHigh?: number;
  amberHigh?: number;
  amberLow?: number;
  redLow?: number;
}

export const BandLayer: React.FC<BandLayerProps> = ({
  innerWidth,
  yScale,
  redHigh,
  redLow,
  amberHigh,
  amberLow,
}) => {
  //Every example just shows this thing being used as a function
  // and it 100% works if I do that too;
  const yScaleFunc = (yScale as any) as (scale: number) => number;

  return (
    //Originally, all these rects were intercepting cursor events (namely
    // hover), meaning the tooltip would never show up over any of the bands,
    // so to fix that, we just disable pointer events on everything in this
    // group
    <g className={styles.noPointerEvents}>
      {redHigh && (
        <rect
          y={0}
          width={innerWidth}
          height={yScaleFunc(redHigh)}
          fill="rgba(255,0,0,.15)"
        />
      )}
      {amberHigh && (
        <rect
          y={redHigh ? yScaleFunc(redHigh) : 0}
          width={innerWidth}
          height={yScaleFunc(amberHigh) - (redHigh ? yScaleFunc(redHigh) : 0)}
          fill="rgba(255,255,0,.15)"
        />
      )}
      {amberLow && (
        <rect
          y={yScaleFunc(amberLow)}
          width={innerWidth}
          height={yScaleFunc(redLow ?? 0) - yScaleFunc(amberLow)}
          fill="rgba(255,255,0,.15)"
        />
      )}
      {redLow && (
        <rect
          y={yScaleFunc(redLow)}
          width={innerWidth}
          height={yScaleFunc(0) - yScaleFunc(redLow)}
          fill="rgba(255,0,0,.15)"
        />
      )}
    </g>
  );
};
