import { CheckOutlined, DeleteFilled } from '@ant-design/icons';
import {
  Box,
  Button,
  Select,
  Stack,
  TableFilterProps,
} from '@torqit/torq-tools-react';
import { fullWidthStyles } from 'App/styles';
import React from 'react';

export interface SelectTableFilterProps extends TableFilterProps {
  options: { text: string; value: string | number }[];
  placeholder?: string;
}

export const SelectTableFilter: React.FC<SelectTableFilterProps> = ({
  options,
  placeholder,
  filters,
  setFilters,
  onClear,
  onConfirm,
}) => {
  const { value } = filters.length !== 0 ? filters[0] : { value: undefined };
  if (
    typeof value !== 'number' &&
    typeof value !== 'string' &&
    typeof value !== 'undefined'
  ) {
    throw new Error('Expected a string or number');
  }

  return (
    <Box padding="sm">
      <Stack alignment="middle" gap={10} stretch>
        <Box width={170}>
          <Stack direction="horizontal" gap={5}>
            <Select
              value={value}
              placeholder={placeholder}
              onChange={(v) => setFilters([{ comparison: 'equals', value: v }])}
              className={fullWidthStyles.fullWidth}
            >
              {options.map((o) => (
                <Select.Option key={o.value} value={o.value}>
                  {o.text}
                </Select.Option>
              ))}
            </Select>
            <Button
              type="primary"
              icon={<CheckOutlined />}
              onClick={onConfirm}
            ></Button>
          </Stack>
        </Box>

        <Stack direction="vertical" gap={5}>
          <Button
            type="primary"
            danger
            icon={<DeleteFilled />}
            onClick={onClear}
          />
        </Stack>
      </Stack>
    </Box>
  );
};
