import {
  Affix,
  Button,
  Card,
  ExperimentalForm,
  Input,
  Stack,
  useHistory,
} from '@torqit/torq-tools-react';
import { ConfirmDialog } from 'App';
import { useApi } from 'App/hooks';
import { useState } from 'react';
import { SentryApiClient } from '_generated/api';

export const AnnouncementForm: React.FC = () => {
  const { announcementApi } = useApi();
  const [form] = ExperimentalForm.useForm<SentryApiClient.AnnouncementDTO>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmAnnouncement, setConfirmAnnouncement] = useState(false);

  const onFinish = async (announcement: SentryApiClient.AnnouncementDTO) => {
    setConfirmAnnouncement(false);
    setIsLoading(true);
    await announcementApi.post(announcement);
    setIsLoading(false);
    form.resetFields();
  };

  const onConfirm = () => {
    form.submit();
  };

  return (
    <Stack direction="vertical" gap={10} stretch>
      <Card title={'New Announcement'}>
        <ExperimentalForm<SentryApiClient.AnnouncementDTO>
          labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 12 },
            md: { span: 10 },
          }}
          form={form}
          onFinish={onFinish}
        >
          <ExperimentalForm.Item name={'subject'} label={'Subject'}>
            <Input disabled={isLoading} placeholder="Subject" />
          </ExperimentalForm.Item>
          <ExperimentalForm.Item name={'body'} label={'Body'}>
            <Input.TextArea
              disabled={isLoading}
              placeholder="Type announcement here..."
              rows={4}
            />
          </ExperimentalForm.Item>
        </ExperimentalForm>
        <Stack alignment="end">
          <Button
            disabled={isLoading}
            type="primary"
            onClick={() => setConfirmAnnouncement(true)}
          >
            Submit
          </Button>
        </Stack>
        <ConfirmDialog
          body={`Are you sure you want send this announcement?`}
          onConfirm={onConfirm}
          onCancel={() => setConfirmAnnouncement(false)}
          open={confirmAnnouncement}
          okButton={{ danger: true, children: 'Confirm' }}
        />
      </Card>
    </Stack>
  );
};
