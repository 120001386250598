import { faChevronLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Tooltip } from '@torqit/torq-tools-react';
import { usePropState } from 'App/hooks';
import React, { useState } from 'react';
import styles from './HorizontalCollapsibleCard.module.css';

export interface HorizontalCollapsibleCardProps {
  sider?: React.ReactNode;
  siderTooltip?: string;
  openWidth: number;
  openLeft?: boolean;
  isOpen?: boolean;
  style?: React.CSSProperties;
  onChange?: (open: boolean) => void;
}

export const HorizontalCollapsibleCard: React.FC<HorizontalCollapsibleCardProps> = ({
  sider,
  siderTooltip,
  openWidth,
  children,
  openLeft,
  isOpen,
  style,
  onChange,
}) => {
  return (
    <div className={styles.card} style={style}>
      <Stack stretch fill>
        {openLeft && (
          <div
            style={{ width: isOpen ? openWidth : undefined }}
            className={styles.body}
          >
            <div style={{ width: openWidth, height: '100%' }}>
              <Box padding="md">{children}</Box>
            </div>
          </div>
        )}

        <Tooltip title={siderTooltip}>
          <div
            className={styles.sider}
            onClick={() => {
              onChange && onChange(!isOpen);
            }}
          >
            <Stack direction="vertical" gap="apart" fill alignment="middle">
              <div>{sider}</div>
              {!openLeft && (
                <FontAwesomeIcon
                  className={styles.smoothIcon}
                  icon={faChevronUp}
                  rotation={isOpen ? 90 : undefined}
                  size="lg"
                />
              )}
              {openLeft && (
                <FontAwesomeIcon
                  className={styles.smoothIcon}
                  icon={faChevronLeft}
                  rotation={isOpen ? undefined : 90}
                  size="lg"
                />
              )}
            </Stack>
          </div>
        </Tooltip>

        {!openLeft && (
          <div
            style={{ width: isOpen ? openWidth : undefined }}
            className={styles.body}
          >
            <div style={{ width: openWidth, height: '100%' }}>
              <Box padding="md">{children}</Box>
            </div>
          </div>
        )}
      </Stack>
    </div>
  );
};
