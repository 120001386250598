import {
  Button,
  Card,
  FetchGridParameters,
  Stack,
  useAsyncData,
  useHistory,
} from '@torqit/torq-tools-react';
import { PagePresenter } from 'App/components/PagePresenter';
import { PageState } from 'App/components/PagePresenter/PagePresenter';
import { useApi } from 'App/hooks/useApi';
import React, { useEffect, useState } from 'react';
import { PanelTable } from '../../components/PanelTable/PanelTable';
import { SentryApiClient } from '_generated/api';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { DEFAULT_PAGE_PARAMS } from 'App/components/TableHelpers/defaultPageSettings';
import { panelFilterMap } from 'Panels/components/PanelTable/panelFilterMap';
import { toOrderDirection } from 'App/utils/toOrderDirection';
import { useFirstLoadIsEmpty } from 'App/hooks/useFirstLoadIsEmpty';
import { useLocationState } from 'react-router-use-location-state';

export const Panels: React.FC = () => {
  const { panelApi } = useApi();
  const { push } = useHistory();

  const [params, setParams] = useLocationState<
    FetchGridParameters<SentryApiClient.PanelDTO>
  >('params', { ...DEFAULT_PAGE_PARAMS, orderKey: 'name' });

  const {
    data: panelPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(() => panelApi.getUserPermissions(0), [panelApi]);

  const { data: panelsList, isLoading, error } = useAsyncData(
    () =>
      panelApi.get(
        params.pageSize,
        params.page,
        panelFilterMap.toFilter(params.orderKey),
        toOrderDirection(params.order),
        panelFilterMap.convert(params.filters)
      ),
    [params, panelApi]
  );

  const isInitialLoadEmpty = useFirstLoadIsEmpty(panelsList?.items);

  const getPageState = () => {
    if (error) return PageState.Error;
    if (isLoading) return PageState.Loading;
    else if (isInitialLoadEmpty) return PageState.Empty;
    else return PageState.Loaded;
  };

  return (
    <PagePresenter pageState={getPageState()}>
      <Card
        title={
          <Stack gap={'apart'}>
            <Stack>Panels</Stack>
            {permissionsLoading && <LoadingOutlined />}
            {panelPermissions?.canCreate && (
              <Stack>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => push('panel/create')}
                >
                  Add
                </Button>
              </Stack>
            )}
          </Stack>
        }
      >
        <PanelTable
          panels={panelsList?.items}
          isLoading={isLoading}
          total={panelsList?.totalItems}
          canAdd
          params={params}
          onChange={setParams}
          includeSite
        />
      </Card>
    </PagePresenter>
  );
};
