import { Box, Stack } from '@torqit/torq-tools-react';
import { LetterIcon } from 'App/components/LetterIcon';
import { MultiSelectItemProps } from 'App/components/MultiSelectList';
import classNames from 'classnames';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import styles from './SelectableSite.module.css';

interface SelectableSiteProps
  extends MultiSelectItemProps<SentryApiClient.SiteDTO> {}

export const SelectableSite: React.FC<SelectableSiteProps> = ({
  item,
  selected,
  disabled,
  isLastClicked,
}) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
        [styles.lastClicked]: isLastClicked,
      })}
    >
      <Stack>
        <Stack alignment="middle" gap={10}>
          <LetterIcon name={item.name ?? ''} />
          <div>
            <div className={styles.displayName}>{item.name}</div>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};
