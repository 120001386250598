import {
  FilterComparisons,
  GridParameterFilter,
} from '@torqit/torq-tools-react';
import { SentryApiClient } from '_generated/api';

const operatorMap: Partial<
  Record<FilterComparisons, SentryApiClient.Operator>
> = {
  contains: SentryApiClient.Operator.Contains,
  endsWith: SentryApiClient.Operator.EndsWith,
  startsWith: SentryApiClient.Operator.StartsWith,
  equals: SentryApiClient.Operator.Equals,
  greaterThan: SentryApiClient.Operator.GreaterThan,
  lessThan: SentryApiClient.Operator.LesserThan,
  notEqual: SentryApiClient.Operator.NotEquals,
};

interface ApiFilter<FilterType> {
  field?: FilterType;
  operator?: SentryApiClient.Operator;
  value?: string | undefined;
}

/**
 * The FilterConverter stores a list of relations between a given entity's fields
 * and the sentry filters they map to. Through this, the FilterConverter can
 * convert Torq FilteredTable filters into Sentry filters
 *
 * @template FilterObject The entity being filtered
 * @template QueryFilters The Sentry enum that the entity fields are mapped to
 */
export class FilterConverter<
  FilterObject extends object,
  QueryFilters extends string | number
> {
  private fieldToFilter: Partial<Record<keyof FilterObject, QueryFilters>> = {};

  /** @param mappings An object that maps the entity fields to the Sentry filter enums */
  public constructor(
    mappings: Partial<Record<keyof FilterObject, QueryFilters>>
  ) {
    this.fieldToFilter = mappings;
  }

  /**
   * Converts an entity field to its corresponding Sentry filter enum supplied
   * in the constructor
   */
  public toFilter(field?: keyof FilterObject): QueryFilters | undefined {
    if (!field) {
      return undefined;
    }

    return this.fieldToFilter[field];
  }

  /**
   * Converts Torq's FilteredTable filters (GridParameterFilter) into
   * filters compatable with Sentry's API endpoints
   */
  public convert(
    tableFilters?: Partial<Record<keyof FilterObject, GridParameterFilter[]>>
  ): ApiFilter<QueryFilters>[] {
    const filters: ApiFilter<QueryFilters>[] = [];

    for (let field in tableFilters) {
      if ((tableFilters[field]?.length ?? 0) > 0) {
        //The TorqTools FilteredTable is only every going to emit one filter per field
        // so we might as well skip the pomp and circumstance of going through the whole
        // array and just cut to the chase.
        const tableFilter = tableFilters[field]![0];
        const sentryFilterTyping = this.fieldToFilter[field];

        if (sentryFilterTyping) {
          filters.push({
            field: sentryFilterTyping,
            operator: operatorMap[tableFilter.comparison],
            value: tableFilter.value.toString(),
          });
        }
      }
    }

    return filters;
  }
}
