import { Stack } from '@torqit/torq-tools-react';
import React from 'react';
import { CalculationTooltip } from 'Sensors';
import { SensorStateIcon } from 'Sensors/components/SensorStateIcon';
import { SentryApiClient } from '_generated/api';

import styles from './SensorHeader.module.css';

interface ISensorHeader {
  sensorState?: SentryApiClient.SensorStateType;
  calculation?: string;
  sensorName?: string;
  sensorDescription?: string;
}

const SensorHeader: React.FC<ISensorHeader> = ({
  sensorState,
  calculation,
  sensorName,
  sensorDescription,
}) => {
  return (
    <Stack gap="sm">
      {sensorDescription}
      {sensorName && (
        <span>
          • <span className={styles.sensorName}>{sensorName}</span>
        </span>
      )}
      <CalculationTooltip calculation={calculation} />
      <SensorStateIcon state={sensorState} />
    </Stack>
  );
};

export { SensorHeader };
