import { ChartableSensorReading } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { SentryApiClient } from '_generated/api';
import { ReadingsPipe } from './ReadingsPipe';

//Funny thing we learned about NSwag: Because our type generation is set
// to "interface", all of our DTOs generate without any of the JSON parsing
// logic that sets stuff to its proper type, ie, transforming strings into
// actual dates. As a result, every DTO that uses a Date is actually just
// a string instead. To fix this, we've got a few options:
//
// 1) Set NSwag type generation to "class" mode. This fixes our dates, but
//  it would require refactoring the frontend in its entirety to support
//  the new types
//
// 2) Set NSwag Date typing to just type as strings again. Much better, but
//  still requires refactoring since everything using sensor readings now has
//  to dance around the string format
//
// 3) Leave NSwag be and just sort of hack the string into a date ourselves
//  since this is one of the only features in the whole app where dates are
//  important
//
// For the sake of simplicity, we're sticking with 3 for now but in the future,
// we might need to play around with it.

export class DateTransformPipe extends ReadingsPipe {
  public enter(readings: ChartableSensorReading[]): void {
    const readingsCopy = readings.slice();

    readingsCopy.forEach((r) => {
      if (r.readingTime) {
        r.readingTime = new Date(r.readingTime + 'Z');
      }
    });

    this.exitListener && this.exitListener(readingsCopy);
  }
}
