import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Routable } from '@torqit/torq-tools-react';
import { Users } from './List';
import { UserCreate } from './UserCreate';
import { UserDetails } from './UserDetails';
import { UserEdit } from './UserEdit';
import { UserNotificationsEdit } from './UserNotificationsEdit';

export const userRoutes: Routable[] = [
  {
    menuName: 'Users',
    route: '/users',
    icon: <UserOutlined />,
    content: <Users />,
  },
  {
    route: '/user',
    pages: [
      {
        route: '/create',
        content: <UserCreate company={true} />,
      },
      {
        route: '/:userId',
        pages: [
          {
            route: '/details',
            content: <UserDetails />,
          },
          {
            route: '/edit',
            content: <UserEdit />,
          },
          {
            route: '/notifications',
            content: <UserNotificationsEdit />,
          },
        ],
      },
    ],
  },
];
