import { ChartableSensorReading } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { SentryApiClient } from '_generated/api';
import { ReadingsPipe } from './ReadingsPipe';

//When displaying tooltips, Nivo looks for sensor readings with the same
// date, but this becomes a problem when Sensor123 recorded a reading
// at 1:25:30PM and Sensor456 recorded a reading at 1:25:31PM. The two
// readings occurred at different times and thus have different tooltips
// despite being almost indistinguishably the same date. To counteract
// this, we round all of our rendered dates to the nearest grouping
// and modulo.
//
//Formerly, this was done directly in SensorReadingGraph (and honestly
// still could be) but now that there's a precedent for just attaching
// values to the readings, it's much more performant to do this
// rounding once and then never do it again
export class RoundDatePipe extends ReadingsPipe {
  private mod: number;
  private grouping?: SentryApiClient.GroupByParam;

  public constructor(mod: number, grouping?: SentryApiClient.GroupByParam) {
    super();
    this.mod = mod;
    this.grouping = grouping;
  }

  public enter(readings: ChartableSensorReading[]): void {
    this.exitListener &&
      this.exitListener(
        //Once again, the outlier is found via the DetectOutlierPipe so
        // we've got nothing to worry about here
        readings.map((r) => ({
          ...r,
          roundedDate: this.roundToNearestGrouping(
            r.readingTime?.getTime() ?? 0,
            r.isOutlier,
            this.grouping,
            this.mod
          ),
        }))
      );
  }

  private roundToNearestGrouping(
    dateTime: number,
    isOutlier?: boolean,
    grouping?: SentryApiClient.GroupByParam,
    modulo?: number
  ) {
    //Before we figure out what timespan we're rounding to, we have to figure
    // out which modulo this reading is actually using. Outliers bypass the
    // the modulo, so we need to account for that in our rounding.
    const actualMod = isOutlier ? 1 : modulo ?? 1;

    let roundingCoefficient = 0;

    //Now that we have our mod, we figure out our timespan to round by based
    // on the grouping. For example, if we had minutely grouping with a modulo
    // of 5, then we want to round our readings to every 5 minutes. This extended
    // rounding is needed in case one sensor is offset from another, say if
    // readings for one sensor come down at "0, 5, 10, 15" minutes but another
    // sensor's comes down at "2, 7, 12, 17" minutes.
    switch (grouping) {
      case undefined:
      case SentryApiClient.GroupByParam.Minute:
        roundingCoefficient = 1000 * 60 * actualMod;
        break;
      case SentryApiClient.GroupByParam.HalfHour:
        roundingCoefficient = 1000 * 60 * 30 * actualMod;
        break;
      case SentryApiClient.GroupByParam.Hour:
        roundingCoefficient = 1000 * 60 * 60 * actualMod;
        break;
      case SentryApiClient.GroupByParam.Day:
        roundingCoefficient = 1000 * 60 * 60 * 24 * actualMod;
        break;
    }

    return new Date(
      Math.round(dateTime / roundingCoefficient) * roundingCoefficient
    );
  }
}
