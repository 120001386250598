import { ChartableSensorReading } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';
import { ReadingsPipe } from './ReadingsPipe';

export class ModuloPipe extends ReadingsPipe {
  private mod: number;

  public constructor(mod: number) {
    super();
    this.mod = mod;
  }

  public enter(readings: ChartableSensorReading[]): void {
    this.exitListener &&
      this.exitListener(
        //If a reading is an outlier, we want to show it so we let those pass
        // through. Thankfully, we already know which readings are outliers
        // thanks to the DetectOutlierPipe
        //
        //Furthermore, because normalization now happens before the modulo,
        // there may be added readings with null values added to hint to Nivo
        // that it should split the line. We definitely want these to go
        // through so if a reading has a null value, we allow it.
        readings.filter(
          (r, index) =>
            index % this.mod === 0 || r.isOutlier || r.rawValue == null
        )
      );
  }
}
