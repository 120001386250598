import { SentryApiClient } from '_generated/api';
import { ReadingsPipe } from './ReadingsPipe';
import YCalculatorWorker from '../YCalculation/yCalculator.worker.ts';
import {
  CalculationError,
  CalculationRequest,
  CalculationResponse,
} from '../YCalculation/yCalculationMessages';
import { ChartableSensorReading } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';

export class CalculatorPipe extends ReadingsPipe {
  private yCalculator: Worker = new YCalculatorWorker();
  private calculation: string;

  public constructor(calculation: string) {
    super();

    this.calculation = calculation;
    this.yCalculator.onmessage = (
      message: MessageEvent<CalculationResponse | CalculationError>
    ) => {
      if (this.stopped) {
        return;
      }

      if (message.data?.name === 'error') {
        this.exitListener &&
          this.exitListener(message.data.value.originalReadings);
      } else if (message.data?.name === 'data') {
        this.exitListener && this.exitListener(message.data.value);
      }
    };
  }

  public enter(readings: ChartableSensorReading[]) {
    this.runYCalc(readings);
  }

  private runYCalc(readings: SentryApiClient.SensorReadingDTO[]) {
    const message: CalculationRequest = {
      name: 'y-calc',
      value: {
        readings,
        grouping: 'day',
        calculation: this.calculation,
      },
    };

    this.yCalculator.postMessage(message);
  }
}
