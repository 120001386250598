import { FilterConverter } from 'App/utils/FilterConverter';
import { SentryApiClient } from '_generated/api';

const userFilterMap = new FilterConverter<
  SentryApiClient.UserDTO,
  SentryApiClient.UserFilters
>({
  displayName: SentryApiClient.UserFilters.DisplayName,
  email: SentryApiClient.UserFilters.Email,
  id: SentryApiClient.UserFilters.Id,
  phoneNumber: SentryApiClient.UserFilters.PhoneNumber,
  userTypeId: SentryApiClient.UserFilters.UserTypeId,
  username: SentryApiClient.UserFilters.Username,
});

export { userFilterMap };
