import { UploadOutlined } from '@ant-design/icons';
import { Button } from '@torqit/torq-tools-react';
import { EntitySelector } from 'App';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { SelectablePanel } from './SelectablePanel';

export interface SiteUserPanelSelectorProps {
  siteName?: string;
  visible?: boolean;
  allPanels?: SentryApiClient.PanelDTO[];
  page: number;
  pageSize: number;
  total?: number;
  loadingPanels?: boolean;
  onSelect?: (panels: SentryApiClient.PanelDTO[]) => Promise<void>;
  onPageChange?: (page: number) => void;
  onSearch?: (search?: string) => void;
  onClose?: () => void;
}

export const SitePanelSelector: React.FC<SiteUserPanelSelectorProps> = ({
  onSelect,
  visible,
  allPanels,
  loadingPanels,
  onPageChange,
  siteName,
  onSearch,
  onClose,
  page,
  pageSize,
  total,
}) => {
  const [selection, setSelection] = useState<number[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const submit = async () => {
    setSubmitting(true);
    if (onSelect) {
      await onSelect(
        selection.map((u) => allPanels?.find((panel) => panel.id === u)!)
      );
    }
    setSubmitting(false);

    setSelection([]);
  };

  return (
    <EntitySelector
      data={allPanels ?? []}
      title={`Add panels to ${siteName}`}
      visible={visible}
      renderItem={(props) => <SelectablePanel {...props} />}
      page={page}
      pageSize={pageSize}
      itemId={(u) => u.id!}
      total={total}
      selected={selection}
      isLoading={loadingPanels}
      footer={
        <Button
          type="primary"
          icon={<UploadOutlined />}
          disabled={selection.length === 0}
          onClick={submit}
          loading={isSubmitting}
        >
          Add to {siteName}
        </Button>
      }
      onSelect={(selection) => setSelection(selection as number[])}
      onSearch={onSearch}
      onClose={onClose}
      onPageChange={onPageChange}
      emptyMessage="No panels found"
    />
  );
};
