import { LineChartOutlined } from '@ant-design/icons';
import { Routable } from '@torqit/torq-tools-react';
import { Sensors } from './SensorsList';
import { SensorDetails } from './SensorDetails';

export const sensorRoutes: Routable[] = [
  {
    menuName: 'Sensors',
    route: '/sensors',
    icon: <LineChartOutlined />,
    content: <Sensors />,
  },
  {
    route: '/sensor',
    pages: [
      {
        route: '/:sensorId(\\d+)',
        pages: [
          {
            route: '/details',
            content: <SensorDetails />,
          },
        ],
      },
    ],
  },
];
