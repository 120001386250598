import { Routable } from '@torqit/torq-tools-react';
import { AuthLayout } from './AuthLayout';
import { Authorize } from './Authorize';
import { Login } from './Pages/Login';
import { Logout } from './Pages/Logout';
import { ResetPassword } from './Pages/ResetPassword';

export const authRoutes: Routable[] = [
  {
    layout: AuthLayout,
    pages: [
      {
        authRequired: true,
        route: '/reset-password',
        content: <ResetPassword />,
      },
      {
        route: '/login',
        content: <Login />,
      },
      {
        route: '/logout',
        content: <Logout />,
      },
      {
        route: '/authorize',
        content: <Authorize />,
      },
    ],
  },
];
