export enum SensorType {
  Sentry = 1,
}

export function getSensorTypeName(type: SensorType) {
  switch (type) {
    case SensorType.Sentry:
      return 'Sentry Sensor';
    default:
      return 'Unknown Sensor';
  }
}
