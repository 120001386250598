import { useIsAuthenticated } from '@azure/msal-react';
import { useLocation } from '@torqit/torq-tools-react';
import { useAuthorization } from 'Auth';
import React from 'react';
import { useEffect } from 'react';
import { useAuthentication } from './useAuthentication';

interface TokenForceRefresherProps {}

export const TokenForceRefresher: React.FC<TokenForceRefresherProps> = ({
  children,
}) => {
  const { pathname } = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const [token, loadingToken, tokenError] = useAuthorization();
  const { login } = useAuthentication(pathname);

  useEffect(() => {
    if (isAuthenticated && !token && !loadingToken && tokenError) {
      login();
    }
  }, [isAuthenticated, token, loadingToken, tokenError, login]);

  return <>{children}</>;
};
