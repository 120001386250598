import { AlertOutlined, FormOutlined } from '@ant-design/icons';
import {
  Box,
  Button,
  Col,
  Drawer,
  FetchGridParameters,
  Row,
  Stack,
  useAsyncData,
  useParams,
} from '@torqit/torq-tools-react';
import {
  dashboardStyles,
  DEFAULT_PAGE_PARAMS,
  PagePresenter,
  PageState,
  useApi,
} from 'App';
import { CollapsibleCard } from 'App/components/CollapsibleCard';
import { DashboardItemEmpty } from 'App/components/DashboardItemEmpty';
import { useBreadcrumbs } from 'App/hooks/BreadcrumbsProvider';
import { redAndAmberFilters } from 'App/utils/redAndAmberFilters';
import { MultiSensorEventLogCreate } from 'EventLogs';
import moment from 'moment-timezone';
import React, { useEffect, useMemo, useState } from 'react';
import { SensorStateTable } from 'SensorStates';
import { SentryApiClient } from '_generated/api';
import { SiteDetailsSection } from './SiteDetailsSection';
import { SiteSensorCard } from './SiteSensorCard';

export interface SiteDashboardProps {}

export const SiteDashboard: React.FC<SiteDashboardProps> = ({}) => {
  const { siteApi, sensorApi } = useApi();
  const { siteId } = useParams<{ siteId?: string }>();
  const parsedSiteId = siteId ? parseInt(siteId) : undefined;

  const { data: siteData, isLoading, error } = useAsyncData(
    async () => (parsedSiteId ? siteApi.getById(parsedSiteId) : undefined),
    [parsedSiteId, siteApi]
  );

  const { setBreadcrumbs } = useBreadcrumbs();
  useEffect(() => {
    setBreadcrumbs([
      {
        display: siteData?.name,
        linkTo: `/dashboard/site/${siteData?.id}`,
      },
    ]);
  }, [siteData, setBreadcrumbs]);

  const {
    data: sensorList,
    isLoading: loadingSensors,
  } = useAsyncData(async () => {
    if (parsedSiteId) {
      return await siteApi.getSensorsOnPanels(parsedSiteId);
    }
  }, [siteId, sensorApi]);

  const [sensorStatesPageParams, setSensorStatesPageParams] = useState<
    FetchGridParameters<SentryApiClient.SensorStateDTO>
  >(DEFAULT_PAGE_PARAMS);
  const {
    data: siteSensorStates,
    isLoading: loadingSensorStates,
  } = useAsyncData(async () => {
    if (parsedSiteId) {
      return siteApi.getSensorStates(
        parsedSiteId,
        10,
        sensorStatesPageParams.page,
        SentryApiClient.SensorStateFilters.StartTime,
        SentryApiClient.OrderDirection.Descending,
        redAndAmberFilters
      );
    } else {
      return undefined;
    }
  }, [parsedSiteId, sensorStatesPageParams, siteApi]);

  const [showAlerts, setShowAlerts] = useState(false);
  const [showEventCreator, setShowEventCreator] = useState(false);

  const getPageState = () => {
    if (isLoading || loadingSensors) {
      return PageState.Loading;
    }

    if (error) {
      return PageState.Error;
    }

    return PageState.Loaded;
  };

  const grid = useMemo<{
    xl: number;
    lg: number;
    md: number;
    sm: number;
  }>(() => {
    if (sensorList?.length === 1 || sensorList?.length === 2) {
      return { xl: 24, lg: 24, md: 24, sm: 24 };
    }
    if (sensorList?.length === 3 || sensorList?.length === 4) {
      return { xl: 12, lg: 12, md: 12, sm: 24 };
    } else return { xl: 6, lg: 8, md: 12, sm: 24 };
  }, [sensorList]);

  return (
    <PagePresenter pageState={getPageState()}>
      <Box padding={{ horizontal: 'md' }}>
        <Stack gap="md">
          <Stack.Item>
            <CollapsibleCard
              defaultHidden
              title={
                <div className={dashboardStyles.largeHeader}>
                  {siteData?.name}
                </div>
              }
            >
              {siteData && <SiteDetailsSection site={siteData} />}
            </CollapsibleCard>
          </Stack.Item>
          <div>
            <Box padding={{ vertical: 'md' }}>
              <Button
                type="primary"
                size="large"
                onClick={() => setShowAlerts(true)}
                icon={<AlertOutlined />}
              >
                View Alerts
              </Button>
            </Box>
          </div>
          <div>
            <Box padding={{ vertical: 'md' }}>
              <Button
                type="primary"
                size="large"
                onClick={() => setShowEventCreator(true)}
                icon={<FormOutlined />}
              >
                Log an event
              </Button>
            </Box>
          </div>
        </Stack>
      </Box>
      <Box padding="md">
        <Row gutter={[24, 24]}>
          {sensorList?.length === 0 && (
            <Col xl={24} lg={24} md={24} sm={24}>
              <div style={{ paddingTop: 150 }}>
                <DashboardItemEmpty message="This site has no sensors" />
              </div>
            </Col>
          )}
          {sensorList?.length !== 0 &&
            siteData &&
            sensorList?.map((s) => (
              <Col
                key={s.id}
                xl={grid.xl}
                lg={grid.lg}
                md={grid.md}
                sm={grid.sm}
              >
                <SiteSensorCard
                  sensor={s}
                  timezone={siteData.timezone ?? moment.tz.guess()}
                  tall={sensorList?.length === 1}
                />
              </Col>
            ))}
        </Row>
      </Box>
      <Drawer
        title="Alerts"
        width={800}
        visible={showAlerts}
        onClose={() => setShowAlerts(false)}
      >
        <SensorStateTable
          sensorStates={siteSensorStates?.items}
          total={siteSensorStates?.totalItems}
          showSensorName={true}
          isLoading={loadingSensorStates}
          params={sensorStatesPageParams}
          timezone={siteData?.timezone ?? moment.tz.guess()}
          onChange={setSensorStatesPageParams}
        />
      </Drawer>
      <Drawer
        width={400}
        title="Log an event"
        visible={showEventCreator}
        onClose={() => setShowEventCreator(false)}
      >
        <MultiSensorEventLogCreate
          availableSensors={sensorList ?? []}
          onCreate={() => setShowEventCreator(false)}
          timezone={siteData?.timezone ?? moment.tz.guess()}
        />
      </Drawer>
    </PagePresenter>
  );
};
