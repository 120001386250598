import { ChartableSensorReading } from 'SensorReadings/hooks/useSensorReadings/useSensorReadings';

export type ReadingsChangeListener = (
  readings: ChartableSensorReading[]
) => void;

export abstract class ReadingsPipe {
  protected exitListener?: ReadingsChangeListener;
  protected stopped = false;

  public abstract enter(readings: ChartableSensorReading[]): void;

  public setOnExit(listener: ReadingsChangeListener) {
    this.exitListener = listener;
  }

  public append(pipe: ReadingsPipe) {
    this.exitListener = (readings) => pipe.enter(readings);
  }

  public stop() {
    this.stopped = true;
  }
}
