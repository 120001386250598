import React, { useEffect, useState } from 'react';
import { Card, RouterLayoutProps, useLocation } from '@torqit/torq-tools-react';
import Layout, { Content } from 'antd/lib/layout/layout';
import styles from './AuthLayout.module.css';
import { useAuthentication } from 'Auth/useAuthentication';

export const AuthLayout: React.FC<RouterLayoutProps> = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuthentication();
  const [title, setTitle] = useState('Redirecting...');
  useEffect(() => {
    if (location.pathname === '/logout' && isAuthenticated)
      setTitle('Logging out');
    if (location.pathname === '/logout' && !isAuthenticated)
      setTitle('Logged out');
  }, [location]);

  return (
    <Layout className={styles.wrapper}>
      <Content>
        <div className="sentry-gradient-content">
          <Card title={title} className={styles.card}>
            <div className={styles.body}>{children}</div>
          </Card>
        </div>
      </Content>
    </Layout>
  );
};
