import { useApi } from 'App/hooks/useApi';
import { SentryApiClient } from '_generated/api';

export function useSiteUserApi() {
  const { userApi } = useApi();

  return {
    removeUserFromSite: async (
      user: SentryApiClient.UserDTO | undefined,
      siteId: number
    ) => {
      if (!user || !user.id || siteId === -1) {
        return;
      }
      user.sites = user.sites ? user.sites.filter((us) => us !== siteId) : [];

      await userApi.put(user.id, user);
    },

    addUserToSite: async (
      user: SentryApiClient.UserDTO | undefined,
      siteId: number
    ) => {
      if (!user || !user.id || siteId === -1) {
        return;
      }

      user.sites = user.sites ? user.sites.concat([siteId]) : [siteId];

      await userApi.put(user.id, user);
    },
  };
}
