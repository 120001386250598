import {
  Drawer,
  FetchGridParameters,
  Result,
  useAsyncData,
} from '@torqit/torq-tools-react';
import { DEFAULT_PAGE_PARAMS, useDebounce } from 'App';
import { useApi } from 'App/hooks/useApi';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { SitePanelList } from './SitePanelList';
import { SitePanelSelector } from './SitePanelSelector';
import { useSitePanelApi } from './useSitePanelApi';
import { useLocationState } from 'react-router-use-location-state';
import { panelFilterMap } from 'Panels/components/PanelTable/panelFilterMap';
import { toOrderDirection } from 'App/utils/toOrderDirection';

export interface SitePanelPanelProps {
  site?: SentryApiClient.SiteDTO;
  showPanelDrawer?: boolean;
  canRemovePanels?: boolean;
  onPanelDrawerChange?: (visible: boolean) => void;
}

const PAGE_SIZE = 20;

export const SitePanelCompartment: React.FC<SitePanelPanelProps> = ({
  site,
  showPanelDrawer,
  canRemovePanels,
  onPanelDrawerChange,
}) => {
  const siteId = site?.id ?? -1;

  const { siteApi, panelApi } = useApi();
  const { addPanelToSite } = useSitePanelApi();

  const [panelChangeError, setPanelChangeError] = useState<Error>();

  const [params, setParams] = useLocationState<
    FetchGridParameters<SentryApiClient.PanelDTO>
  >('params', { ...DEFAULT_PAGE_PARAMS, orderKey: 'name' });

  const {
    data: panelData,
    isLoading: panelsLoading,
    executeRequest: reloadPanels,
    error: sitePanelsError,
  } = useAsyncData(async () => {
    if (site?.id) {
      return siteApi.getPanelsBySiteId(
        site.id,
        params.pageSize,
        params.page,
        panelFilterMap.toFilter(params.orderKey),
        toOrderDirection(params.order),
        panelFilterMap.convert(params.filters)
      );
    } else {
      return undefined;
    }
  }, [params, site, siteApi]);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState<string>();
  const debouncedSearch = useDebounce(search, 300);
  const {
    data: panelsWithoutSites,
    isLoading: panelsWithoutSitesLoading,
    executeRequest: reloadpanelsWithoutSites,
    error: panelsWithoutSitesError,
  } = useAsyncData(() => {
    const filters: SentryApiClient.QueryFiltersOfPanelFilters[] = [
      {
        field: SentryApiClient.PanelFilters.SiteId,
        operator: SentryApiClient.Operator.Equals,
        value: undefined,
      },
    ];

    if (debouncedSearch) {
      filters.push({
        field: SentryApiClient.PanelFilters.Name,
        operator: SentryApiClient.Operator.Contains,
        value: debouncedSearch,
      });
    }

    return panelApi.get(
      PAGE_SIZE,
      page,
      SentryApiClient.PanelFilters.Name,
      SentryApiClient.OrderDirection.Ascending,
      filters
    );
  }, [page, debouncedSearch, panelApi]);

  const addExistingPanels = async (panels: SentryApiClient.PanelDTO[]) => {
    try {
      await addPanels(panels);
    } catch (error) {
      setPanelChangeError(error as Error);
    }

    onPanelDrawerChange && onPanelDrawerChange(false);
    reloadPanels();
    reloadpanelsWithoutSites();
  };
  const addPanels = (panelList: SentryApiClient.PanelDTO[]) => {
    return Promise.all(
      panelList.map(async (panel) => addPanelToSite(panel, siteId))
    );
  };

  const resfreshPanels = () => {
    reloadPanels();
    reloadpanelsWithoutSites();
  };

  if (panelChangeError || sitePanelsError || panelsWithoutSitesError) {
    return <Result status="error" title="Something went wrong" />;
  }

  return (
    <>
      <SitePanelList
        siteId={siteId}
        panels={panelData?.items}
        totalPanels={panelData?.totalItems}
        isLoading={panelsLoading}
        canRemovePanels={canRemovePanels}
        onPanelsRemoved={resfreshPanels}
        onRemoveFailed={setPanelChangeError}
        onChange={setParams}
        params={params}
      />
      <SitePanelSelector
        visible={showPanelDrawer}
        onClose={() => onPanelDrawerChange && onPanelDrawerChange(false)}
        siteName={site?.name}
        allPanels={panelsWithoutSites?.items}
        total={panelsWithoutSites?.totalItems}
        loadingPanels={panelsWithoutSitesLoading}
        page={page}
        pageSize={PAGE_SIZE}
        onPageChange={setPage}
        onSelect={addExistingPanels}
        onSearch={setSearch}
      />
    </>
  );
};
