import React, { useEffect } from 'react';
import { Skeleton, useHistory, useLocation } from '@torqit/torq-tools-react';
import styles from './Authorize.module.css';
import { getRedirectUri } from 'Auth/redirectUri';
import { UserType } from 'Users/userTypes';
import { useSentryUser } from 'Users/hooks/SentryUserProvider';
import { useApi } from 'App';
import { SentryApiClient } from '_generated/api';

export interface AuthenticatedProps {}

export const Authorize: React.FC<AuthenticatedProps> = () => {
  const { user } = useSentryUser();
  const { push } = useHistory();
  const { search } = useLocation();
  const { loginEventApi } = useApi();


  useEffect(() => {
    if (user) {
      const redirectUri = getRedirectUri(search);

      loginEventApi.post();
      push(
        redirectUri ||
          (user?.userTypeId === UserType.Customer ||
          user?.userTypeId === UserType.CustomerManager ||
          user?.userTypeId === UserType.Distributor ||
          user?.userTypeId === UserType.ClientManager
            ? '/dashboard'
            : '/')
      );
    }
  }, [push, search, user]);

  return (
    <div className={styles.wrapper}>
      <Skeleton />
    </div>
  );
};
