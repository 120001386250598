import React, { useEffect, useState } from 'react';
import {
  Affix,
  Card,
  ExperimentalForm,
  Stack,
  useAsyncData,
  useHistory,
  useParams,
} from '@torqit/torq-tools-react';
import Form from 'antd/lib/form';
import { useApi } from 'App/hooks/useApi';
import { SentryApiClient } from '_generated/api';
import { CompanyFormFields } from '../../forms/CompanyFormFields';
import {
  PagePresenter,
  PageState,
} from 'App/components/PagePresenter/PagePresenter';
import { FormNavigator } from 'App/components/FormHelpers';

export const CompanyEdit: React.FC = () => {
  const [form] = Form.useForm();
  const [pageState, setPageState] = useState<PageState>(PageState.Loading);
  const { companyApi } = useApi();
  const history = useHistory();
  const params = useParams<{ companyId?: string }>();
  const companyId = (params.companyId && parseInt(params.companyId)) || -1;
  const { data: companyDetails, isLoading, error } = useAsyncData(
    () => companyApi.getById(companyId),
    [companyApi, companyId]
  );

  const [currentName, setCurrentName] = useState(
    companyDetails?.name ?? 'Example Company'
  );
  const onChange = (_v: any, result: SentryApiClient.CompanyDTO) => {
    setCurrentName(result?.name || '');
  };
  useEffect(() => {
    if (isLoading) setPageState(PageState.Loading);
    if (error) setPageState(PageState.Error);
    if (companyDetails) setPageState(PageState.Loaded);
    if (companyDetails?.name) setCurrentName(companyDetails.name);
  }, [companyDetails]);

  const onSave = async (company: SentryApiClient.CompanyDTO) => {
    setPageState(PageState.Loading);
    if (!company || !companyDetails || !companyDetails.id) {
      setPageState(PageState.Error);
      return;
    }

    companyApi
      .put(companyDetails.id, company)
      .then(() => {
        setPageState(PageState.Loaded);
        params.companyId &&
          history.replace(`/company/${params.companyId}/details`);
      })
      .catch((e) => setPageState(PageState.Error));
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack direction="vertical" gap="md" stretch>
        <Card
          title={
            <Stack>
              <Stack alignment="middle" gap={10}>
                Edit {companyDetails?.name}
              </Stack>
            </Stack>
          }
        >
          {companyDetails ? (
            <ExperimentalForm<SentryApiClient.CompanyDTO>
              labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 10 },
              }}
              form={form}
              name="config"
              onValuesChange={onChange}
              onFinish={onSave}
              initialValues={companyDetails}
              scrollToFirstError
            >
              <CompanyFormFields
                active={true}
                visible={true}
                name={currentName}
                companyId={companyId.toString()}
              />
            </ExperimentalForm>
          ) : null}
        </Card>
        <Affix offsetBottom={0}>
          <Card>
            <FormNavigator onSubmit={() => form.submit()} />
          </Card>
        </Affix>
      </Stack>
    </PagePresenter>
  );
};

export default CompanyEdit;
