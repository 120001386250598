import { InteractionStatus } from '@azure/msal-browser';
import { useAuthorization } from 'Auth';
import { useAuthentication } from 'Auth/useAuthentication';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSentryUser } from 'Users/hooks/SentryUserProvider';
import { SentryLoader } from '../SentryLoader';
import styles from './SentrySplash.module.css';

const visibleSplashStates = [
  InteractionStatus.Startup,
  InteractionStatus.HandleRedirect,
];

export interface SentrySplashData {
  hideSplashScreen: () => void;
}

const SentrySplashContext = createContext<SentrySplashData | null>(null);

export interface SentrySplashProviderProps {}

export const SentrySplashProvider: React.FC<SentrySplashProviderProps> = ({
  children,
}) => {
  const [isInitialPageLoad, setInitialPageLoad] = useState(true);

  const { inProgress, isAuthenticated } = useAuthentication();
  const { user } = useSentryUser();

  const visible = useMemo(
    () =>
      isInitialPageLoad &&
      (visibleSplashStates.includes(inProgress) || (isAuthenticated && !user)),
    [isInitialPageLoad, inProgress, isAuthenticated, user]
  );

  //We only want our splash screen to show on page load, but there are some
  // oddball cases (such as logging out) where everything lines up perfectly
  // for the splash screen to be visible again despite the fact that we don't
  // want that. Instead, we only want the splash screen to be visible on page
  // load, so once we're finished loading, we basically lock ourselves out of
  // the splash screen
  useEffect(() => {
    if (!visible) {
      setInitialPageLoad(false);
    }
  }, [visible]);

  const isSensotraq = window.location.href.includes('sensotraq');

  let logoImage = '';
  if (isSensotraq) {
    logoImage = '/sensotraqWhite.png';
  } else {
    logoImage = '/sentry-logo.png';
  }

  return (
    <SentrySplashContext.Provider
      value={{ hideSplashScreen: () => setInitialPageLoad(false) }}
    >
      <div style={{ position: 'relative' }}>
        <div
          className={styles.splash}
          style={{
            opacity: visible ? 1 : 0,
            pointerEvents: visible ? undefined : 'none',
          }}
        >
          <div className={styles.logoWrapper}>
            <img src={logoImage} alt="Logo" width={300} />
          </div>
          <SentryLoader big />
        </div>
        {children}
      </div>
    </SentrySplashContext.Provider>
  );
};

export function useSentrySplash() {
  const result = useContext(SentrySplashContext);

  if (!result) {
    throw new Error(
      'useSentrySplash() cannot be used without being wrapped by a SentrySplashProvider'
    );
  }

  return result;
}
