import { Result } from '@torqit/torq-tools-react';
import React from 'react';

interface IProps {
  error?: Error | string;
}

const ErrorMessage: React.FC<IProps> = ({
  error = 'An unspecified error has occurred while trying to load the data',
}) => {
  return (
    <Result
      status="error"
      title="Loading failed"
      subTitle={typeof error === 'string' ? error : error.message}
    />
  );
};

export { ErrorMessage };
