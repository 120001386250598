import React, { createContext, useEffect, useState } from 'react';
import { BuiltInBreakpoints } from './useBreakpoints';

export type ThemeContextShape = ThemeProviderProps & {
  rootFontSize: number;
  breakpoints?: BuiltInBreakpoints;
};

export const ThemeContext = createContext<ThemeContextShape>({
  rootFontSize: 16,
});

export type ThemeProviderProps = {
  breakpoints?: BuiltInBreakpoints;
};

export const ThemeProvider: React.FC<ThemeProviderProps> = ({
  breakpoints = {},
  children,
}) => {
  const allBreakpoints = {
    xs: 380,
    sm: 480,
    smd: 575,
    md: 768,
    lg: 960,
    xl: 1024,
    xxl: 1200,
    ...breakpoints,
  };
  const [rootFontSize, setRootFontSize] = useState(16);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const rawFontSize = window
        .getComputedStyle(window.document.body)
        .getPropertyPriority('font-size');
      const fontSize = parseFloat(rawFontSize);
      setRootFontSize(fontSize);
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        rootFontSize,
        breakpoints: allBreakpoints,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
