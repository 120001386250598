import { CloseCircleFilled } from '@ant-design/icons';
import { Box, Button, InputNumber, Stack } from '@torqit/torq-tools-react';
import React from 'react';

export interface YAxisOptionsProps {
  label: string;
  minY?: number;
  maxY?: number;
  enabled?: boolean;
  onChange?: (min?: number, max?: number) => void;
}

const LABEL_WIDTH = 40;

export const YAxisOptions: React.FC<YAxisOptionsProps> = ({
  label,
  minY,
  maxY,
  enabled = true,
  onChange,
}) => {
  return (
    <Stack direction="vertical" gap="xs" alignment="middle">
      {label}
      <Stack alignment="middle">
        <Stack>
          <Box padding={{ right: 'xs' }} width={LABEL_WIDTH}>
            Min:
          </Box>
        </Stack>
        <InputNumber
          placeholder="Auto"
          //We need to be able to clear out this input via a number, but just
          // setting the value to undefined doesn't work since it just switches
          // the input to its internal state. If we set our value to null, then
          // we explicitly clear the value. Ant seems to be of the opinion that
          // only defined numbers work, but thankfully, ant is lying.
          value={minY ?? ((null as any) as number)}
          onChange={(v) => onChange && onChange(v, maxY)}
          disabled={!enabled}
        />
        <Button
          icon={<CloseCircleFilled />}
          onClick={() => onChange && onChange(undefined, maxY)}
          disabled={!enabled}
        />
      </Stack>
      <Stack alignment="middle">
        <Stack>
          <Box padding={{ right: 'xs' }} width={LABEL_WIDTH}>
            Max:
          </Box>
        </Stack>
        <InputNumber
          placeholder="Auto"
          //Refer to above big comment
          value={maxY ?? ((null as any) as number)}
          onChange={(v) => onChange && onChange(minY, v)}
          disabled={!enabled}
        />
        <Button
          icon={<CloseCircleFilled />}
          onClick={() => onChange && onChange(minY, undefined)}
          disabled={!enabled}
        />
      </Stack>
    </Stack>
  );
};
