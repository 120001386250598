import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@torqit/torq-tools-react';
import { Tooltip } from 'antd';
import React from 'react';

export interface CalculationTooltipProps {
  calculation?: string;
}

export const CalculationTooltip: React.FC<CalculationTooltipProps> = ({
  calculation,
}) => {
  if (!calculation) {
    return <></>;
  }

  return (
    <Tooltip
      title={
        <div>
          These readings are calculated using the following formula
          <Box padding={{ top: 'sm' }}>
            <code>Value{calculation}</code>
          </Box>
        </div>
      }
      placement="right"
    >
      <div>
        <Box padding={{ horizontal: 'sm' }}>
          <FontAwesomeIcon icon={faCalculator} />
        </Box>
      </div>
    </Tooltip>
  );
};
