import { Drawer, Result, useAsyncData } from '@torqit/torq-tools-react';
import { useApi } from 'App/hooks/useApi';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { SiteUserList } from './SiteUserList';
import { SiteUserSelector } from './SiteUserSelector';
import { useSiteUserApi } from './useSiteUserApi';

export interface SiteUserPanelProps {
  site?: SentryApiClient.SiteDTO;
  showUserDrawer?: boolean;
  canRemoveUsers?: boolean;
  onUserDrawerChange?: (visible: boolean) => void;
}

export const SiteUserPanel: React.FC<SiteUserPanelProps> = ({
  site,
  showUserDrawer,
  canRemoveUsers,
  onUserDrawerChange,
}) => {
  const siteId = site?.id ?? -1;

  const { siteApi, companyApi } = useApi();
  const { addUserToSite } = useSiteUserApi();

  const [userChangeError, setUserChangeError] = useState<Error>();

  const {
    data: userData,
    isLoading: usersLoading,
    executeRequest: reloadUsers,
    error: siteUsersError,
  } = useAsyncData(
    async () => (site?.id ? siteApi.getUsers(site.id) : undefined),
    [site, siteApi]
  );

  const companyId = site?.company ?? -1;
  const {
    data: companyUsers,
    isLoading: allUsersLoading,
    executeRequest: reloadAllUsers,
    error: allUsersError,
  } = useAsyncData(() => companyApi.getUsers(companyId), [
    companyId,
    companyApi,
  ]);

  const addExistingUsers = async (users: SentryApiClient.UserDTO[]) => {
    try {
      await addUsers(users);
    } catch (error) {
      setUserChangeError(error);
    }

    onUserDrawerChange && onUserDrawerChange(false);
    reloadUsers();
    reloadAllUsers();
  };
  const addUsers = (userList: SentryApiClient.UserDTO[]) => {
    return Promise.all(
      userList.map(async (user) => addUserToSite(user, siteId))
    );
  };

  const refreshUsers = () => {
    reloadUsers();
    reloadAllUsers();
  };

  const [search, setSearch] = useState<string>();

  if (userChangeError || siteUsersError || allUsersError) {
    return <Result status="error" title="Something went wrong" />;
  }

  return (
    <>
      <SiteUserList
        siteId={siteId}
        users={userData}
        isLoading={usersLoading}
        canRemoveUsers={canRemoveUsers}
        onUsersRemoved={refreshUsers}
        onRemoveFailed={setUserChangeError}
      />
      <SiteUserSelector
        visible={showUserDrawer}
        page={1}
        //Wouldn't you know it, there's currently no way to filter the site users
        // but because this list only contains users who are already in the company
        // it's never going to be that huge, so let's just show everyone
        pageSize={100000}
        siteName={site?.name}
        allUsers={companyUsers
          ?.filter(
            (u) =>
              search == null ||
              u.displayName.toLowerCase().includes(search.toLowerCase())
          )
          .map(
            (
              u: SentryApiClient.UserDTO
            ): SentryApiClient.UserWithPermissionsDTO => {
              return {
                user: u,
              };
            }
          )}
        loadingUsers={allUsersLoading}
        userIdsInCompany={
          userData?.map((u) => u.id).filter((u) => u != null) as string[]
        }
        onSearch={setSearch}
        onSelect={addExistingUsers}
        onClose={() => onUserDrawerChange && onUserDrawerChange(false)}
      />
    </>
  );
};
