import React from 'react';
import { HomeOutlined } from '@ant-design/icons';
import { Routable } from '@torqit/torq-tools-react';
import { Sites } from 'Sites/pages/SitesList/SitesList';
import { SiteCreate } from './SiteCreate';
import { SiteDetails } from './SiteDetails';
import { SiteEdit } from './SiteEdit/SiteEdit';

export const siteRoutes: Routable[] = [
  {
    menuName: 'Sites',
    route: '/sites',
    icon: <HomeOutlined />,
    content: <Sites />,
  },
  {
    route: '/site',
    pages: [
      {
        route: '/create',
        content: <SiteCreate company={true} />,
      },
      {
        route: '/:siteId(\\d+)',
        pages: [
          {
            route: '/details',
            content: <SiteDetails />,
          },
          {
            route: '/edit',
            content: <SiteEdit />,
          },
        ],
      },
    ],
  },
];
