import { useApi } from 'App';
import { useEffect, useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { DATE_SLIDER_ONE_YEAR_AGO } from './getDateSliderItemSize';

/** Fetches ALL of the sensor readings over the last year for a given sensor in
 *  descending order
 */
export function useYearOfSensorStates(sensorId: number) {
  const { sensorStateApi } = useApi();

  const [states, setStates] = useState<SentryApiClient.SensorStateDTO[]>([]);
  const [isLoading, setLoading] = useState(false);

  //More than likely, you'll be wanting to look for data that happened more
  // recently, so for that purpose, we load our data in descending date order
  // so that our more recent data shows first.
  useEffect(() => {
    const fetchStates = async () => {
      let states: SentryApiClient.SensorStateDTO[] = [];
      setStates([]);
      setLoading(true);

      //First things first, we need to fetch our current active state. This
      // requires filters that just don't fit in the ordinary query so
      // we have to fetch it independently.
      const firstState = await fetchCurrentState(sensorStateApi, sensorId);
      states = states.concat(firstState.items ?? []);
      setStates(states);

      //After that, we fetch our first batch of sensor states which doubles
      // as our indicator of how many more we have to go.
      const result = await fetchFinishedStates(sensorStateApi, 1, sensorId);
      states = states.concat(result.items ?? []);
      setStates(states);

      let page = 2;

      //While we've got pages left, cycle through and load the rest of the
      // states
      while (page <= (result.totalPages ?? 0)) {
        const nextResult = await fetchFinishedStates(
          sensorStateApi,
          page,
          sensorId
        );
        states = states.concat(nextResult.items ?? []);
        setStates(states);

        page++;
      }

      setLoading(false);
    };

    fetchStates();
  }, [sensorId, sensorStateApi]);

  return { states, isLoading };
}

async function fetchCurrentState(
  sensorStateApi: SentryApiClient.SensorStatesClient,
  sensorId: number
) {
  return await sensorStateApi.get(
    1,
    1,
    SentryApiClient.SensorStateFilters.StartTime,
    SentryApiClient.OrderDirection.Descending,
    [
      {
        field: SentryApiClient.SensorStateFilters.SensorId,
        operator: SentryApiClient.Operator.Equals,
        value: sensorId.toString(),
      },
    ]
  );
}

async function fetchFinishedStates(
  sensorStateApi: SentryApiClient.SensorStatesClient,
  page: number,
  sensorId: number
) {
  return await sensorStateApi.get(
    100,
    page,
    SentryApiClient.SensorStateFilters.StartTime,
    SentryApiClient.OrderDirection.Descending,
    [
      {
        field: SentryApiClient.SensorStateFilters.SensorId,
        operator: SentryApiClient.Operator.Equals,
        value: sensorId.toString(),
      },
      {
        field: SentryApiClient.SensorStateFilters.EndTime,
        operator: SentryApiClient.Operator.GreaterThan,
        value: DATE_SLIDER_ONE_YEAR_AGO.toISOString(),
      },
    ]
  );
}
