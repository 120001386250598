import React, { useMemo, useState } from 'react';
import {
  ExperimentalForm,
  Card,
  useHistory,
  Affix,
  Stack,
} from '@torqit/torq-tools-react';
import Form from 'antd/lib/form';
import { CompanyFormFields } from '../../forms/CompanyFormFields';
import { useApi } from 'App/hooks/useApi';
import { CreateCompanyReview } from '../../forms/CreateCompanyReview';
import { SentryApiClient } from '_generated/api';
import {
  PagePresenter,
  PageState,
} from 'App/components/PagePresenter/PagePresenter';
import { FormNavigator } from 'App/components/FormHelpers';
import {
  CREATE_USER,
  NO_USER,
  UserSelectOrCreateFields,
  UserSelectOrCreateFormFieldsShape,
} from 'Users/forms/UserSelectOrCreateFields';

export interface CompanyCreateProps {
  user?: Required<SentryApiClient.UserDTO> | boolean;
}

export interface FormResults {
  company: SentryApiClient.CompanyDTO;
  user: UserSelectOrCreateFormFieldsShape;
}

export const CompanyCreate: React.FC<CompanyCreateProps> = ({ user }) => {
  const [formStep, setFormStep] = useState(0);
  const onPrevious = () => {
    if (formStep === 2 && user !== true) {
      setFormStep(0);
    } else {
      setFormStep(formStep - 1);
    }
  };

  const [nextFormStep, setNextFormStep] = useState<number>();
  const getNextStep = () => {
    if (nextFormStep) {
      return nextFormStep;
    } else if (formStep === 0 && user !== true) {
      return 2;
    } else {
      return formStep + 1;
    }
  };

  const [form] = Form.useForm<FormResults>();
  const [formProgress, setFormProgress] = useState(0);
  const onNext = () => {
    form.validateFields().then(() => {
      const targetStep = getNextStep();

      setFormStep(targetStep);
      setFormProgress(Math.max(targetStep, formProgress));

      //If we had a next step queued, it's definitely been consumed by now
      // so we'll just clear it.
      setNextFormStep(undefined);
    });
  };

  const onBackToCompany = () => {
    setFormStep(0);
    setNextFormStep(2);
  };

  const onBackToUser = () => {
    setFormStep(1);
    setNextFormStep(2);
  };

  const [pageState, setPageState] = useState<PageState>(PageState.Loaded);
  const { userApi, companyApi } = useApi();
  const router = useHistory();
  const onFinish = async (result: FormResults) => {
    let company;
    let user;

    setPageState(PageState.Loading);

    try {
      company = await companyApi.post(result.company);

      if (result.user.id === CREATE_USER) {
        result.user.id = undefined;
        const userPayload = {
          ...result.user,
          companies: [company.id!],
        };

        user = await userApi.post(userPayload);
      } else if (result.user.id && result.user.id !== NO_USER) {
        const actualUser = await userApi.getById(result.user.id);
        actualUser.companies?.push(company.id!);

        user = await userApi.put(actualUser.id!, actualUser);
      }

      router.push(`/company/${company.id}/details`);
    } catch (error) {
      setPageState(PageState.Error);

      if (company) await companyApi.delete(company.id as number);
      if (user && result.user.id === null)
        await userApi.delete(user.id as string);
    }
  };

  const loadedUser: UserSelectOrCreateFormFieldsShape | null = useMemo(() => {
    if (typeof user === 'object') {
      return {
        ...user,
        first_name: user.displayName?.split(' ')[0],
        last_name: user.displayName?.split(' ')[1],
      };
    }

    return null;
  }, [user]);

  const [currentName, setCurrentName] = useState('Example Company');
  const onChange = (_v: any, result: FormResults) => {
    setCurrentName(result.company.name || '');
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack direction="vertical" gap={10} stretch>
        <Card title={'New Company'}>
          <ExperimentalForm<FormResults>
            labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
            wrapperCol={{
              xs: { span: 24 },
              sm: { span: 12 },
              md: { span: 10 },
            }}
            form={form}
            name="config"
            initialValues={{ user: loadedUser }}
            onValuesChange={onChange}
            onFinish={onFinish}
            scrollToFirstError
          >
            <CompanyFormFields
              visible={formStep === 0}
              active /* Always active */
              namespace={'company'}
              name={currentName}
            />
            <UserSelectOrCreateFields
              visible={formStep === 1}
              active={formProgress >= 1}
              form={form}
              namespace={'user'}
              allowEmpty
            />
            <CreateCompanyReview
              active={formStep === 2}
              company={form.getFieldsValue()?.company}
              user={form.getFieldsValue()?.user}
              onBackToCompany={onBackToCompany}
              onBackToUser={onBackToUser}
            />
          </ExperimentalForm>
        </Card>
        <Affix offsetBottom={0}>
          <Card>
            <FormNavigator
              currentStep={formStep}
              steps={3}
              onPrevious={onPrevious}
              onNext={onNext}
              onSubmit={() => form.submit()}
            />
          </Card>
        </Affix>
      </Stack>
    </PagePresenter>
  );
};
