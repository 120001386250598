import React from 'react';
import { UserFieldsReview } from 'Users';
import { UserSelectOrCreateFormFieldsShape } from 'Users/forms/UserSelectOrCreateFields';
import { SentryApiClient } from '_generated/api';
import { CompanyFieldsReview } from '../CompanyFieldsReview';
import styles from './CreateCompanyReview.module.css';

export interface CreateCompanyReviewProps {
  active: boolean;
  company?: SentryApiClient.CompanyDTO;
  user?: UserSelectOrCreateFormFieldsShape;
  onBackToCompany?: () => void;
  onBackToUser?: () => void;
}

export const CreateCompanyReview: React.FC<CreateCompanyReviewProps> = ({
  active,
  company,
  user,
  onBackToCompany,
  onBackToUser,
}) => {
  if (!active) {
    return null;
  }

  return (
    <>
      <div className={styles.section}>
        <CompanyFieldsReview
          company={company}
          onBackToCompany={onBackToCompany}
        />
      </div>
      <div className={styles.section}>
        {user && user.id !== 'NONE' && (
          <UserFieldsReview user={user} onBackToUser={onBackToUser} />
        )}
      </div>
    </>
  );
};
