import {
  Box,
  Col,
  ExperimentalForm,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Stack,
  Select,
} from '@torqit/torq-tools-react';
import { Fieldset } from 'App/components/FormHelpers';
import { LetterIcon } from 'App/components/LetterIcon';
import { buildFieldName } from 'Auth/buildFieldName';
import React from 'react';
import { useState } from 'react';
import { DateServiceSingleton } from 'App/utils/DateService';
import styles from './SiteFormFields.module.css';

export interface SiteFormFieldsProps {
  name?: string;
  active: boolean;
  visible: boolean;
  form: FormInstance<any>;
  namespace?: string | string[];
}

export const SiteFormFields: React.FC<SiteFormFieldsProps> = ({
  name,
  active,
  visible,
  form,
  namespace,
}) => {
  const timezones = DateServiceSingleton.GetTimezones();

  return (
    <Fieldset id={styles.site} active={active} visible={visible}>
      {name && (
        <Stack direction="vertical" alignment="middle" gap={20}>
          <LetterIcon name={name} />
          <Stack.Item />
        </Stack>
      )}
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'name')}
        label={<span>Site Name</span>}
        rules={[
          {
            required: true,
            message: 'Please add a full name!',
          },
        ]}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'tagLine')}
        label={<span>Tag Line</span>}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'strapLine')}
        label={<span>Strap Line</span>}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'details')}
        label={<span>Details</span>}
      >
        <Input.TextArea />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, 'timezone')}
        label={<span>Timezone</span>}
      >
        <Select>
          {timezones?.map((s) => (
            <Select.Option key={s} value={s}>
              {s}
            </Select.Option>
          ))}
        </Select>
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, ['address', 'address1'])}
        label={<span>Address</span>}
        extra={<span>Line 1</span>}
        rules={[
          {
            required: true,
            message: 'At least address 1 is required',
          },
        ]}
        className={styles.reduceBottomMargin}
      >
        <Input />
      </ExperimentalForm.Item>
      <ExperimentalForm.Item
        name={buildFieldName(namespace, ['address', 'address2'])}
        colon={false}
        label={<span></span>}
        extra={<span>Line 2</span>}
        className={styles.reduceBottomMargin}
      >
        <Input />
      </ExperimentalForm.Item>
      <Row className={styles.reduceBottomMargin}>
        <Col xs={0} sm={7}></Col>
        <Col sm={6} md={5}>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, ['address', 'city'])}
            wrapperCol={{ sm: 24 }}
            colon={false}
            extra={<span>City</span>}
            rules={[
              {
                required: true,
                message: 'Site must be in a city',
              },
            ]}
            className={styles.addressCol + ' ' + styles.reduceBottomMargin}
          >
            <Input />
          </ExperimentalForm.Item>
        </Col>
        <Col sm={6} md={5}>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, ['address', 'country'])}
            wrapperCol={{ sm: 24 }}
            colon={false}
            extra={<span>Country</span>}
            rules={[
              {
                required: true,
                message: 'Site must be in a country',
              },
            ]}
            className={styles.addressCol + ' ' + styles.reduceBottomMargin}
          >
            <Input />
          </ExperimentalForm.Item>
        </Col>
      </Row>
      <Row className={styles.addressRow}>
        <Col xs={0} sm={7}></Col>
        <Col sm={6} md={5}>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, ['address', 'region'])}
            colon={false}
            wrapperCol={{ sm: 24 }}
            extra={<span>Region</span>}
            rules={[
              {
                required: true,
                message: 'Need a province or state',
              },
            ]}
            className={styles.addressCol + ' ' + styles.addressRow}
          >
            <Input />
          </ExperimentalForm.Item>
        </Col>
        <Col sm={6} md={5}>
          <ExperimentalForm.Item
            name={buildFieldName(namespace, ['address', 'zip'])}
            colon={false}
            wrapperCol={{ sm: 24 }}
            extra={<span>Postal Code</span>}
            rules={[
              {
                required: true,
                message: 'Need a postal code or zip code',
              },
            ]}
            className={styles.addressCol + ' ' + styles.addressRow}
          >
            <Input />
          </ExperimentalForm.Item>
        </Col>
      </Row>
      <ExperimentalForm.Item hidden name={buildFieldName(namespace, 'company')}>
        <Input />
      </ExperimentalForm.Item>
    </Fieldset>
  );
};
