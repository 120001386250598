import React, { useEffect, useState } from 'react';
import {
  CloudServerOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Link,
  Stack,
  Tooltip,
  Typography,
  useAsyncData,
  useHistory,
  useParams,
} from '@torqit/torq-tools-react';
import { FormReviewItem } from 'App/components/FormHelpers';
import { LetterIcon } from 'App/components/LetterIcon';
import {
  PagePresenter,
  PageState,
} from 'App/components/PagePresenter/PagePresenter';
import { useApi } from 'App/hooks/useApi';
import { ConfirmDialog } from 'App/components/ConfirmDelete';
import { AddressDisplay } from 'Address/AddressDisplay';
import { CompanyUserPanel } from './CompanyUserPanel';
import { CompanySitePanel } from './CompanySites/CompanySitePanel';
import { MobileIconButton } from 'App/components/MobileIconButton/MobileIconButton';

interface IProps {}

const CompanyDetails: React.FC<IProps> = ({}) => {
  const { companyApi } = useApi();
  const [showUserDrawer, setShowUserDrawer] = useState(false);
  const [showSiteDrawer, setShowSiteDrawer] = useState(false);
  const params = useParams<{ companyId?: string }>();
  const companyId = (params.companyId && parseInt(params.companyId)) || -1;

  const {
    data: companyDetails,
    isLoading: companyLoading,
    error: companyError,
  } = useAsyncData(
    async () => (companyId ? companyApi.getById(companyId) : undefined),
    [companyId, companyApi]
  );

  const {
    data: companyPermissions,
    isLoading: permissionsLoading,
  } = useAsyncData(
    async () =>
      companyId ? companyApi.getUserPermissions(companyId) : undefined,
    [companyApi, companyId]
  );

  const {
    data: companyArchivePermissions,
    isLoading: archivePermissionsLoading,
  } = useAsyncData(
    async () =>
      companyId ? companyApi.getUserArchivePermissions(companyId) : undefined,
    [companyApi, companyId]
  );

  const [pageState, setPageState] = useState(PageState.Loaded);
  useEffect(() => {
    if (companyLoading) setPageState(PageState.Loading);
    else if (companyError) setPageState(PageState.Error);
    else if (companyDetails) setPageState(PageState.Loaded);
  }, [companyDetails, companyLoading, companyError]);

  const router = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);

  const onDelete = async () => {
    try {
      setConfirmDelete(false);
      setPageState(PageState.Loading);

      if (companyId) await companyApi.delete(companyId);

      router.push('/companies');
    } catch (error) {
      setPageState(PageState.Error);
    }
  };

  const onArchive = async () => {
    setPageState(PageState.Loading);
    if (!companyDetails || !companyDetails.id) {
      setPageState(PageState.Error);
      return;
    }

    try {
      setConfirmDelete(false);
      setPageState(PageState.Loading);
      companyDetails.archived = !companyDetails.archived;
      if (companyId) await companyApi.archive(companyId, companyDetails);

      router.push(`/company/${companyDetails?.id}/details`);
      setConfirmArchive(false);
      setPageState(PageState.Loaded);
    } catch (error) {
      setPageState(PageState.Error);
    }
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack direction="vertical" gap={20} stretch>
        <Card
          title={
            <Stack gap="apart" alignment="middle">
              <Stack alignment="middle" gap={10}>
                {companyDetails?.name}
                {companyDetails?.archived && (
                  <Tooltip title="Archived">
                    <CloudServerOutlined />
                  </Tooltip>
                )}
              </Stack>
              <Stack alignment="end" gap={10}>
                {permissionsLoading && archivePermissionsLoading && (
                  <LoadingOutlined />
                )}
                {!archivePermissionsLoading &&
                  companyArchivePermissions?.canUpdate && (
                    <MobileIconButton
                      type="primary"
                      color="red"
                      icon={<CloudServerOutlined />}
                      onClick={() => setConfirmArchive(true)}
                      danger
                    >
                      {companyDetails?.archived ? 'Unarchive' : 'Archive'}
                    </MobileIconButton>
                  )}
                {!permissionsLoading && companyPermissions?.canUpdate && (
                  <Link to={`/company/${companyDetails?.id}/edit`}>
                    <MobileIconButton type="primary" icon={<EditOutlined />}>
                      Edit
                    </MobileIconButton>
                  </Link>
                )}
                {!permissionsLoading && companyPermissions?.canDelete && (
                  <MobileIconButton
                    type="primary"
                    color="red"
                    icon={<DeleteOutlined />}
                    onClick={() => setConfirmDelete(true)}
                    danger
                  >
                    Delete
                  </MobileIconButton>
                )}
              </Stack>
            </Stack>
          }
        >
          <ConfirmDialog
            body={`Are you sure you want to delete ${companyDetails?.name}?`}
            onConfirm={onDelete}
            onCancel={() => setConfirmDelete(false)}
            open={confirmDelete}
            okButton={{ danger: true, children: 'Delete' }}
            title="Confirm Delete"
          />
          <ConfirmDialog
            body={`Are you sure you want to ${
              companyDetails?.archived ? 'unarchive' : 'archive'
            } ${companyDetails?.name}?`}
            onConfirm={onArchive}
            onCancel={() => setConfirmArchive(false)}
            open={confirmArchive}
            okButton={{
              danger: true,
              children: companyDetails?.archived ? 'Unarchive' : 'Archive',
            }}
            title={`Confirm ${
              companyDetails?.archived ? 'Unarchive' : 'Archive'
            }`}
          />
        </Card>
        <Card
          title={
            <Stack gap={'apart'}>
              <Stack>Sites</Stack>
              <Stack>
                {permissionsLoading && <LoadingOutlined />}
                {companyPermissions?.canUpdate && (
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setShowSiteDrawer(true)}
                  >
                    Add
                  </Button>
                )}
              </Stack>
            </Stack>
          }
        >
          <CompanySitePanel
            company={companyDetails}
            canRemoveSites={companyPermissions?.canUpdate}
            onSiteDrawerChange={setShowSiteDrawer}
            showSiteDrawer={showSiteDrawer}
          />
        </Card>
        <Card
          title={
            <Stack gap={'apart'}>
              <Stack>Users</Stack>
              {permissionsLoading && <LoadingOutlined />}
              {companyPermissions?.canUpdate && (
                <Stack>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setShowUserDrawer(true)}
                  >
                    Add
                  </Button>
                </Stack>
              )}
            </Stack>
          }
        >
          <CompanyUserPanel
            company={companyDetails}
            onUserDrawerChange={setShowUserDrawer}
            canRemoveUsers={companyPermissions?.canUpdate}
            showUserDrawer={showUserDrawer}
          />
        </Card>
      </Stack>
    </PagePresenter>
  );
};

export { CompanyDetails };
