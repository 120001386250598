import {
  Affix,
  Card,
  ExperimentalForm,
  Stack,
  useAsyncData,
  useHistory,
  useParams,
} from '@torqit/torq-tools-react';
import { FormNavigator, PagePresenter, PageState, useApi } from 'App';
import React, { useEffect, useState } from 'react';
import { UserFormFields, UserFormFieldsShape } from 'Users/forms';

interface IProps {}

export const UserNotificationsEdit: React.FC<IProps> = ({}) => {
  const [form] = ExperimentalForm.useForm();
  const { userApi } = useApi();
  const { userId } = useParams<{ userId?: string }>();
  const {
    data: originalUserDetails,
    isLoading,
    error,
  } = useAsyncData(async () => {
    if (!userId) {
      return undefined;
    }

    return userApi.getById(userId);
  }, [userId, userApi]);
  const [currentName, setCurrentName] = useState(
    originalUserDetails?.displayName ?? 'Example User'
  );
  const onChange = (_v: any, result: UserFormFieldsShape) => {
    setCurrentName(result.displayName);
  };
  useEffect(() => {
    if (isLoading) setPageState(PageState.Loading);
    if (error) setPageState(PageState.Error);
    if (originalUserDetails) setPageState(PageState.Loaded);
    if (originalUserDetails?.displayName)
      setCurrentName(originalUserDetails.displayName);
  }, [isLoading, error, originalUserDetails]);

  const { push } = useHistory();
  const [pageState, setPageState] = useState(PageState.Loaded);
  const onSave = async (formResult: UserFormFieldsShape) => {
    try {
      if (!userId || !originalUserDetails || !originalUserDetails.id) {
        setPageState(PageState.Error);
        return;
      }

      // Since these fields are not shown in the form, they are set to empty
      // values in the formResult object. Since we don't want them erased,
      // we set them back to their original values.
      formResult.companies = originalUserDetails.companies;
      formResult.sites = originalUserDetails.sites;

      await userApi.put(userId, {
        ...formResult,
      });

      push(`/user/${userId}/details`);
    } catch (error) {
      setPageState(PageState.Error);
    }
  };

  return (
    <PagePresenter pageState={pageState}>
      <Stack gap="md" direction="vertical" stretch>
        <Card
          title={
            <Stack alignment="middle" gap="apart">
              <Stack alignment="middle" gap={10}>
                Edit your Notification Settings
              </Stack>
              <Stack.Item />
            </Stack>
          }
        >
          {originalUserDetails ? (
            <ExperimentalForm
              labelCol={{ xs: { span: 24 }, sm: { span: 7 } }}
              wrapperCol={{
                xs: { span: 24 },
                sm: { span: 12 },
                md: { span: 10 },
              }}
              form={form}
              name="config"
              onValuesChange={onChange}
              onFinish={onSave}
              initialValues={{
                ...originalUserDetails,
              }}
              scrollToFirstError
            >
              <UserFormFields
                active={true}
                visible={true}
                disableUserSettings={true}
                disableNotificationSettings={false}
                displayName={currentName}
                userId={userId}
              />
            </ExperimentalForm>
          ) : null}
        </Card>
        <Affix offsetBottom={0}>
          <Card>
            <FormNavigator onSubmit={() => form.submit()} />
          </Card>
        </Affix>
      </Stack>
    </PagePresenter>
  );
};
