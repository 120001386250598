import { FilterConverter } from 'App/utils/FilterConverter';
import { SentryApiClient } from '_generated/api';

const panelFilterMap = new FilterConverter<
  SentryApiClient.PanelDTO,
  SentryApiClient.PanelFilters
>({
  name: SentryApiClient.PanelFilters.Name,
  hardwareId: SentryApiClient.PanelFilters.HardwareId,
  connectionStateTimestamp:
    SentryApiClient.PanelFilters.ConnectionStateTimestamp,
  stateType: SentryApiClient.PanelFilters.StateType,
  siteId: SentryApiClient.PanelFilters.SiteId,
});

export { panelFilterMap };
