import {
  FilteredTable,
  FilteredTableProps,
  TableColumn,
} from '@torqit/torq-tools-react';
import { DateServiceSingleton } from 'App/utils/DateService';
import moment from 'moment-timezone';
import React from 'react';
import { useState } from 'react';
import { start } from 'repl';
import { SensorStateIcon } from 'Sensors/components/SensorStateIcon';
import { SiteTimezoneSwitch } from 'Sites/components/SiteTimezoneSwitch';
import { SentryApiClient } from '_generated/api';

export interface SensorStateTableProps
  extends Omit<
    FilteredTableProps<SentryApiClient.SensorStateDTO>,
    'data' | 'columns' | 'rowKey'
  > {
  sensorStates?: SentryApiClient.SensorStateDTO[];
  showSensorName?: boolean;
  timezone: string;
}

const buildColumns = (
  showSensorName: boolean,
  timezone: string
): TableColumn<SentryApiClient.SensorStateDTO>[] => {
  let columns: TableColumn<SentryApiClient.SensorStateDTO>[] = [];
  if (showSensorName) {
    columns[0] = {
      title: 'Sensor',
      field: 'sensorName',
    };
  }
  columns = columns.concat([
    {
      width: 95,
      title: 'State',
      field: 'stateType',
      render: (state?: SentryApiClient.SensorStateType) =>
        state != undefined ? <SensorStateIcon state={state} /> : undefined,
    },
    {
      field: 'startTime',
      render: (startTime: string) =>
        DateServiceSingleton.ConvertToTimezoneTime(
          new Date(startTime + 'Z'),
          timezone
        ).toLocaleString(),
      title: 'From',
    },
    {
      field: 'endTime',
      render: (endTime?: string) =>
        endTime
          ? DateServiceSingleton.ConvertToTimezoneTime(
              new Date(endTime + 'Z'),
              timezone
            ).toLocaleString()
          : '',
      title: 'To',
    },
  ]);
  return columns;
};

export const SensorStateTable: React.FC<SensorStateTableProps> = ({
  sensorStates,
  showSensorName = false,
  timezone,
  ...props
}) => {
  const [isSiteTimezone, setIsSiteTimezone] = useState(true);

  return (
    <FilteredTable
      columns={buildColumns(
        showSensorName,
        isSiteTimezone ? timezone : moment.tz.guess()
      ).concat({
        render: () => null,
        title: () => (
          <SiteTimezoneSwitch
            checked={isSiteTimezone}
            onChange={setIsSiteTimezone}
          />
        ),
        width: 120,
      })}
      data={sensorStates}
      rowKey={(e) => e.id ?? -1}
      {...props}
    />
  );
};
