import { faArchive } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Draggable,
  Link,
  LinkProps,
  Stack,
} from '@torqit/torq-tools-react';
import { LetterIcon } from 'App/components/LetterIcon';
import { MobileIconButton } from 'App/components/MobileIconButton/MobileIconButton';
import { useBreakpoints } from 'App/components/Theme';
import classNames from 'classnames';
import React from 'react';
import { SensorStateIcon } from 'Sensors/components/SensorStateIcon';
import { SentryApiClient } from '_generated/api';
import styles from './DraggableSensor.module.css';

export interface ToggleLinkProps extends LinkProps {
  disabled?: boolean;
}

//I want to wrap the WHOLE sensor with a link, but I also want that link to be
// disabled if we're currently editing the sensors (since you're supposed to
// be able to drag and drop them), so instead of rearranging the whole sensor,
// I just whipped up this component quick as a disableable link that wraps
// the whole sensor
export const ToggleLink: React.FC<ToggleLinkProps> = ({
  disabled,
  children,
  ...props
}) => {
  if (disabled) {
    return <>{children}</>;
  }

  return <Link {...props}>{children}</Link>;
};

interface DraggableSensorProps {
  sensor: SentryApiClient.SensorDetailsDTO;
  bankTitle: string;
  editable?: boolean;
  onArchive?: () => void;
}

export const DraggableSensor: React.FC<DraggableSensorProps> = ({
  sensor,
  bankTitle,
  editable,
  onArchive,
}) => {
  const { smd } = useBreakpoints();

  return (
    <Draggable
      item={{ id: sensor.id }}
      className={classNames(styles.sensor, { [styles.draggable]: editable })}
      dragClassName={styles.dragging}
      disabled={!editable}
    >
      <ToggleLink to={`/sensor/${sensor.id}/details`} disabled={editable}>
        <Box padding="xs">
          <Stack
            alignment="middle"
            direction="vertical"
            gap={editable ? 'apart' : undefined}
            fill
          >
            <div className={styles.label}>{bankTitle}</div>
            <Stack.Item>
              <Stack alignment="middle" fill gap="sm">
                <LetterIcon name={sensor.sensorName ?? ''} small={!smd} />
                <div>
                  <div
                    className={classNames(styles.sensorName, {
                      [styles.small]: !smd,
                    })}
                  >
                    {sensor.sensorName}
                  </div>
                  <SensorStateIcon state={sensor.stateType} />
                </div>
              </Stack>
            </Stack.Item>
            {editable && (
              <MobileIconButton
                danger
                icon={
                  <div className={styles.faIconWhereAntIconShouldBe}>
                    <FontAwesomeIcon icon={faArchive} />
                  </div>
                }
                onClick={onArchive}
                block
              >
                Remove from panel
              </MobileIconButton>
            )}
          </Stack>
        </Box>
      </ToggleLink>
    </Draggable>
  );
};
