import { SentryApiClient } from '_generated/api';

export const redAndAmberFilters: SentryApiClient.QueryFiltersOfSensorStateFilters[] = [
  {
    field: SentryApiClient.SensorStateFilters.StateType,
    operator: SentryApiClient.Operator.NotEquals,
    value: SentryApiClient.SensorStateType.Empty.toString(),
  },
  {
    field: SentryApiClient.SensorStateFilters.StateType,
    operator: SentryApiClient.Operator.NotEquals,
    value: SentryApiClient.SensorStateType.Normal.toString(),
  },
  {
    field: SentryApiClient.SensorStateFilters.StateType,
    operator: SentryApiClient.Operator.NotEquals,
    value: SentryApiClient.SensorStateType.Offline.toString(),
  },
];
