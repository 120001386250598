import { UploadOutlined } from '@ant-design/icons';
import { Box, Button } from '@torqit/torq-tools-react';
import { EntitySelector } from 'App/components/EntitySelector';
import React, { useState } from 'react';
import { SentryApiClient } from '_generated/api';
import { SelectableSite } from './SelectableSite';

export interface CompanySiteSelectorProps {
  companyName?: string;
  allSites?: SentryApiClient.SiteDTO[];
  totalSites?: number;
  page: number;
  pageSize: number;
  loadingSites?: boolean;
  visible?: boolean;
  search?: string;
  onSearch?: (search?: string) => void;
  onSelect?: (users: SentryApiClient.SiteDTO[]) => Promise<void>;
  onPageChange?: (page: number) => void;
  onClose?: () => void;
}

export const CompanySiteSelector: React.FC<CompanySiteSelectorProps> = ({
  allSites,
  totalSites,
  loadingSites,
  companyName,
  page,
  pageSize,
  visible,
  search,
  onSearch,
  onClose,
  onPageChange,
  onSelect,
}) => {
  const [selection, setSelection] = useState<number[]>([]);
  const [isSubmitting, setSubmitting] = useState(false);

  const submit = async () => {
    setSubmitting(true);
    if (onSelect) {
      var selected = selection.map(
        (u) => allSites?.find((site) => site.id === u)!
      );
      await onSelect(selected);
    }
    setSubmitting(false);

    setSelection([]);
  };

  return (
    <EntitySelector
      title={`Add sites to ${companyName}`}
      visible={visible}
      search={search}
      data={allSites ?? []}
      renderItem={(props) => <SelectableSite {...props} />}
      itemId="id"
      selected={selection}
      onSelect={(selection) => setSelection(selection as number[])}
      page={page}
      onPageChange={onPageChange}
      isLoading={loadingSites}
      pageSize={pageSize}
      total={totalSites}
      footer={
        <Button
          type="primary"
          icon={<UploadOutlined />}
          disabled={selection.length === 0}
          onClick={submit}
          loading={isSubmitting}
        >
          Add to {companyName}
        </Button>
      }
      onSearch={onSearch}
      onClose={onClose}
      emptyMessage="No sites found"
    />
  );
};
