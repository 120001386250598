import { Result, Spin } from 'antd';
import { SentryLoader } from 'App/components/SentryLoader';
import React, { useEffect, useState } from 'react';
import { ScaleLoader } from 'react-spinners';

interface IProps {
  phrases: string[];
  onAllPhrasesDisplayed?: () => void;
}

const LoadingMessage: React.FC<IProps> = ({
  phrases,
  onAllPhrasesDisplayed,
}) => {
  const [phraseIndex, setPhraseIndex] = useState(0);

  useEffect(() => {
    if (phrases.length == 0) {
      return;
    }

    const interval = setInterval(() => {
      setPhraseIndex((index) => {
        if (index === phrases.length - 1 && onAllPhrasesDisplayed) {
          onAllPhrasesDisplayed();
        }
        return (index + 1) % phrases.length;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [phrases]);

  return (
    <Result
      icon={<SentryLoader />}
      title={phrases.length > 0 ? phrases[phraseIndex] : 'Loading...'}
    />
  );
};

export { LoadingMessage };
