import { FilterConverter } from 'App/utils/FilterConverter';
import { SentryApiClient } from '_generated/api';

const sensorFilterMap = new FilterConverter<
  SentryApiClient.SensorDetailsDTO,
  SentryApiClient.SensorFilters
>({
  sensorName: SentryApiClient.SensorFilters.SensorName,
  sensorType: SentryApiClient.SensorFilters.SensorType,
  stateType: SentryApiClient.SensorFilters.StateType,
});

export { sensorFilterMap };
