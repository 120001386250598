import { Box, Stack, Tooltip } from '@torqit/torq-tools-react';
import { LetterIcon } from 'App/components/LetterIcon';
import { MultiSelectItemProps } from 'App/components/MultiSelectList';
import classNames from 'classnames';
import React from 'react';
import { SentryApiClient } from '_generated/api';
import styles from './SelectableSensor.module.css';

interface SelectableSensorProps
  extends MultiSelectItemProps<SentryApiClient.SensorDTO> {}

export const SelectableSensor: React.FC<SelectableSensorProps> = ({
  item,
  selected,
  disabled,
  isLastClicked,
}) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.selected]: selected,
        [styles.disabled]: disabled,
        [styles.lastClicked]: isLastClicked,
      })}
    >
      <Stack>
        <Stack alignment="middle" gap={10}>
          <LetterIcon name={item.sensorName ?? ''} small />
          <div>
            <div className={styles.displayName}>{item.sensorName}</div>
          </div>
        </Stack>
      </Stack>
    </div>
  );
};
