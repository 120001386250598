import { useApi } from 'App';
import { useCallback, useEffect, useRef, useState } from 'react';
import { SensorRange } from 'SensorReadings/interfaces';
import { SentryApiClient } from '_generated/api';
import { SensorReadingsManager } from './SensorReadingsManager';

export enum SensorReadingLoadState {
  NotStarted = 'Not Started',
  Loading = 'Loading',
  Empty = 'Empty',
  Rendering = 'Rendering',
  Done = 'Done',
  Stopped = 'Stopped',
}

export interface ChartableSensorReading
  extends SentryApiClient.SensorReadingDTO {
  isOutlier?: boolean;
  roundedDate?: Date;
}

export interface SensorReadingsRangeData {
  id: string;
  sensor: SentryApiClient.SensorDTO;
  startDate: Date;
  endDate: Date;
  grouping?: SentryApiClient.GroupByParam;
  minReading?: SentryApiClient.SensorReadingDTO;
  maxReading?: SentryApiClient.SensorReadingDTO;
  isRightAxis: boolean;
  modulo?: number;
  data?: ChartableSensorReading[];
  events?: SentryApiClient.EventLogDTO[];
  offset: number;
  loadState: SensorReadingLoadState;
}

export function useSensorReadings() {
  const { sensorReadingApi, sensorApi } = useApi();
  const [readings, setReadings] = useState<SensorReadingsRangeData[]>([]);

  const managerRef = useRef<SensorReadingsManager>();

  useEffect(() => {
    managerRef.current = new SensorReadingsManager(sensorReadingApi, sensorApi);
    managerRef.current?.setOnChangeListener(setReadings);

    return () => {
      managerRef.current?.removeOnChangeListener();
      managerRef.current?.stopAll();
    };
  }, [sensorReadingApi, sensorApi]);

  const setRanges = useCallback(
    (ranges: SensorRange[], primaryStartDate: Date) =>
      managerRef.current?.set(ranges, primaryStartDate),
    []
  );

  const resetSensorRanges = useCallback(
    (sensor: SentryApiClient.SensorDTO) => managerRef.current?.reload(sensor),
    []
  );

  const refreshEventLogs = useCallback(
    () => managerRef.current?.refreshEventLogs(),
    []
  );

  const flipYAxis = useCallback(
    (rangeId: string) => managerRef.current?.flipYAxis(rangeId),
    []
  );

  return {
    readings,
    setRanges,
    resetSensorRanges,
    refreshEventLogs,
    flipYAxis,
  };
}
